import { useEffect } from 'react';
import { useGlobal } from 'reactn';

const useAutoUpdateData = ({
	data,
	updateFunction,
	updateFunctionParams,
	interval = 1000 * 60,
	isAllowed = true
}) => {

	const [isIdle] = useGlobal('isIdle');
	
	useEffect(() => {
		if (data && isAllowed) {
			const commonListsTimer = setInterval(() => {
				if (!isIdle) {
					updateFunction(updateFunctionParams || '');
				}
			}, interval);
			return () => clearInterval(commonListsTimer);
		}
	}, [data, isIdle, isAllowed]); //eslint-disable-line
};

export default useAutoUpdateData;
