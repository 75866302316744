import React, { useState } from 'react';
import { useGlobal } from 'reactn';
import { barcodesLeftFn, percentClassName } from '../../helpers/barcodesFuctions';
import CustomButton from '../UI/CustomButton/CustomButton';
import CardItemSection from './CardItemSection';
import Icon from '../UI/Icon/Icon';
import { formatNumber } from '../../helpers/formatData';

const BarcodesCardsViewItem = (props) => {

	const [isOpen, setIsOpen] = useState(false);

	const toggleSublist = () => {
		setIsOpen(!isOpen);
	};

	const { rangeItemData, hideCarrier, handleDelete } = props;
	const { user_id, carrier_id, barcode_group, remaining_all, ranges, remaining_percent_all } = rangeItemData;
	const [commonListsObj] = useGlobal('commonListsObj');
	const { apiUsers, carriers } = commonListsObj;
	const percentClass = percentClassName(remaining_percent_all);

	const subRangeList = ranges.map(subItemRange => {
		const { prefix, range_begin, range_end, remaining, range_current, id } = subItemRange;
		const { percentClass, percentLeft } = barcodesLeftFn(range_begin, range_end, range_current);
		// const isActiveRange = range_current >= range_begin && range_current <= range_end;
		const remainingData = (<>{formatNumber(remaining)} <span
			className={`text-${percentClass} font-weight-normal`}>({percentLeft}%)</span></>);
		return <div className="ranges-details-list"
								key={`${range_begin}${range_end}${remaining}${prefix}`}>
			<CardItemSection
				label="Range prefix"
				data={prefix || '--'}
			/>
			<CardItemSection
				label="Begin"
				data={formatNumber(range_begin)}
			/>
			<CardItemSection
				label="End"
				data={formatNumber(range_end)}
			/>
			<CardItemSection
				label="Remaining"
				data={remainingData}
			/>
			<CustomButton
				onClick={() => handleDelete({ rangeId: id })}
				kind="text"
				buttonText="delete"
				iconLeft="delete_forever"
				small
				tooltip="Delete barcode range"
				// disabled={isActiveRange}
				addedClassName="text-capitalize font-weight-light delete-barcode"
			/>
		</div>;
	});

	const remainingData = (<>{formatNumber(remaining_all)} <span
		className={`text-${percentClass} font-weight-normal`}>({remaining_percent_all}%)</span></>);

	return (
		<div className="grid-item">
			<div className="card card-item">
				<div className="card-header d-flex align-content-center">
					<Icon iconName="account_circle" size={20} iconClass="mr-2" /> {apiUsers[user_id]}
				</div>
				<div className="card-body">
					<div className="common-data">
						{!hideCarrier && <CardItemSection
							label="Carrier"
							data={carriers[carrier_id]}
						/>}
						<CardItemSection
							label="Barcode group"
							data={barcode_group}
						/>
						<CardItemSection
							label="Remaining"
							data={remainingData}
						/>
					</div>
					<div className={`details-data ${isOpen ? 'is-open' : ''}`}>
						{subRangeList}
					</div>
					<div className="button-wrapper">
						<CustomButton
							onClick={toggleSublist}
							kind="text"
							buttonText={`${isOpen ? "hide details" : "show details"}`}
							small
							addedClassName="m-auto w-100"
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

export default BarcodesCardsViewItem;
