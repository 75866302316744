import React, { useContext } from 'react';

import RadioButtonsGroup from '../../UI/RadioButtonGroup/RadioButtonGroup';
import PaginationBox from '../../PaginationBox/PaginationBoxLite';
import AToolsHealthCheckDetailsList from './AToolsHealthCheckDetailsList';
import './AToolsHealthCheckDetailsPanel.scss';
import HealthCheckDetailsPageContext from '../../../context/pagesContext/healthCheckDetailsPageContext';
import PageDataPanel from '../../UI/PageDataPanel/PageDataPanel';
import CustomButton from '../../UI/CustomButton/CustomButton';
import { useRouter } from '../../hooks/useRouter';

const AToolsHealthCheckDetailsPanel = ({currentData}) => {

	const {
		changeCurrentPage,
		changeQuantity,
		healthStatusesDetailsData,
		changeHealthStatus,
		isFetching,
		getHealthCheckDetails,
	} = useContext(HealthCheckDetailsPageContext);

	const {location} = useRouter();
	const {hasMoreItems, items} = healthStatusesDetailsData || {};
	const {page, quantity, status } = currentData;


	const buttonsList = [
		{ name: 'all', label: 'All' },
		{ name: '0', label: 'Error' },
		{ name: '1', label: 'Success' },
	];

	const panelHeading =
		<>
			<RadioButtonsGroup
				buttonsList={buttonsList}
				onRadioChange={changeHealthStatus}
				initialActiveButton={status || "all"}
				customClass="d-none d-xl-inline-flex"/>
			<CustomButton
				isAnimated={isFetching}
				addedClassName="refresh-button"
				iconLeft="refresh"
				kind="secondary"
				onClick={() => getHealthCheckDetails(location.search)}
			/>
		</>


	const panelHeadingMobile = <>
		<RadioButtonsGroup
			buttonsList={buttonsList}
			onRadioChange={changeHealthStatus}
			initialActiveButton={status || "all"}/>
	</>

	const panelContent =
		<>
			<AToolsHealthCheckDetailsList/>
			<PaginationBox
			currentPage={page || '1'}
			quantity={quantity || '32'}
			changeCurrentPage={changeCurrentPage}
			changeQuantity={changeQuantity}
			hasNextPage={hasMoreItems}
			noItems={items && items.length === 0}
			/>
		</>;

	return(
		<>
			<PageDataPanel
				panelHeading={panelHeading}
				panelHeadingMobile={panelHeadingMobile}
				panelContent={panelContent}
			/>
		</>
	)
};

export default AToolsHealthCheckDetailsPanel;
