import React, { useEffect, useCallback } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { setGlobal, useGlobal } from 'reactn';
import IdleTimer from 'react-idle-timer'
import AppGlobalContext from '../../context/appGlobalContext';
import useSapiService from '../hooks/useSapiService';
import useAutoUpdateData from '../hooks/useAutoApdateData';

import Cookies from 'js-cookie';

import 'popper.js';
import 'bootstrap/dist/js/bootstrap.bundle.min';

import Layout from '../UI/Layout/Layout';
import LoginPage from '../../pages/LoginPage';
import Routes from '../../routes/routes';

import './App.scss';
import useArrayFromObject from '../hooks/useArrayFromObject';
import CustomToast from '../UI/CustomToast/CustomToast';
import { localCommonLists } from '../../localTestingData';
import useListSort from '../hooks/useListSort';
import ErrorPage from '../../pages/ErrorPage';
import { useRouter } from '../hooks/useRouter';
import Spinner from '../Spinner/Spinner';
import ErrorBoundary from '../ErrorBoundary/ErrorBoundary';
import useDocumentTitle from '../hooks/useDocumentTitle';
import { updateServicesCommonLists } from '../../helpers/listUpdates';

let defaultUserName = '';
if (process.env.NODE_ENV !== 'production') {
	const whyDidYouRender = require('@welldone-software/why-did-you-render');
	defaultUserName = 'Test user';
	whyDidYouRender(React);
}

setGlobal({
	isOpenTopbar: false,
	isUserLogged: true,
	userName: defaultUserName,
	commonListsArr: null,
	commonListsObj: null,
	currentPageUrl: null,
	currentPageName: null,
	accessUrl: null,
	isIdle: false,
	jsBuild: ''
});

const App = () => {
	const [isUserLogged, setIsUserLogged] = useGlobal('isUserLogged');
	const [userName, setUserName] = useGlobal('userName');
	const [, setCommonListsArr] = useGlobal('commonListsArr');
	const [commonListsObj, setCommonListsObj] = useGlobal('commonListsObj');
	const [, setBaseUrl] = useGlobal('baseUrl');
	const [accessUrl, setAccessUrl] = useGlobal('accessUrl');
	const [, setCurrentPageName] = useGlobal('currentPageName');
	const [, setIsIdle] = useGlobal('isIdle');

	const { getCommonListsReq, isProduction } = useSapiService();
	const { changeObjValues } = useArrayFromObject();
	const { alphabetSort } = useListSort();
	const { history, location } = useRouter();

	const setLocationStringToStorage = useCallback(() => {
		if (!location.pathname.includes('/login') && !location.pathname.includes('/activation')) {
			sessionStorage.setItem('lastUrl', `${location.pathname}${location.search}`);
		}
	}, [location.pathname, location.search]);

	useDocumentTitle();

	const alphabetSortAllCommonLists = useCallback((data) => {
	const changed = changeObjValues(data);
	const updated = updateServicesCommonLists(changed);
	return Object.entries(updated).reduce((acc, [key, value]) => {
		if (Array.isArray(value) && value.every(el => typeof el.name === 'string')) {
			const newValue = alphabetSort(value, 'name');
			return {...acc, [key]: newValue }
		}
		return { ...acc, [key]: value };
	}, {});

	}, [changeObjValues, alphabetSort]);

	const setLocalData = useCallback(() => {
		console.log('Using Local COMMON LIST');
		setCommonListsArr(alphabetSortAllCommonLists(localCommonLists));
		setCommonListsObj(localCommonLists);
		setAccessUrl(localCommonLists && localCommonLists.accessUrl || []);
	}, [setCommonListsArr, alphabetSortAllCommonLists, setCommonListsObj, setAccessUrl]);
	//
	const getCommonLists = useCallback(() => {
		return isProduction()
			? getCommonListsReq()
				.then(({ data })=> {
					setCommonListsArr(alphabetSortAllCommonLists(data));
					setCommonListsObj(data);
					setAccessUrl(data.accessUrl || []);
					if(!Cookies.get('userLogin') && data.currentUser && data.currentUser.login
						|| Cookies.get('userLogin') !== userName) {
						Cookies.set('userLogin', data.currentUser.login);
						setUserName(data.currentUser.login);
					}
				})
				.catch(() => {
					if(!accessUrl) {
						setAccessUrl( []);
					}
				})
			: setLocalData();
	}, [alphabetSortAllCommonLists, getCommonListsReq, isProduction, setAccessUrl, setCommonListsArr, setCommonListsObj, setLocalData, accessUrl ]); // eslint-disable-line

const isAllowedCommonListAutoUpdate = isUserLogged && !location.pathname.includes('/label');

	useAutoUpdateData({
		data:commonListsObj,
		updateFunction: getCommonLists,
		interval: 1000 * 60 * 5,
		isAllowed: isAllowedCommonListAutoUpdate
	});
// red favicon for dev servers
	useEffect(() => {
		const faviconUpdate = async () => {
			const favicon = document.getElementById("favicon");
			if (window.location.hostname.includes('sapi-light-dev')) {
				favicon.href = "/assets/backend/react-app/build/faviconRed.svg";
			}
		};
		faviconUpdate();
	}, []);

	useEffect(() => {
		setLocationStringToStorage();
		if (location.pathname === '/login') {
			setCurrentPageName('Login page');
		}
	}, [location.search, location.pathname]); //eslint-disable-line

	useEffect(() => {
		if (isUserLogged) {
			getCommonLists();
		}
		if(isUserLogged && sessionStorage.getItem('lastUrl')) {
			history.replace(sessionStorage.getItem('lastUrl'));
		}
	}, [isUserLogged]); //eslint-disable-line

	useEffect(() => {
		setBaseUrl(window.location.origin);
		if (Cookies.get('PHPSESSID') === localStorage.getItem('PHPSESSID')) {
			setIsUserLogged(true);
		}
	}, []); //eslint-disable-line

	const notLoggedInRoutes =
		<Switch>
			<Route path={'/login'}
						 render={() =>
								<ErrorBoundary>
									<LoginPage />
								</ErrorBoundary>}
					exact />
			<Redirect to="/login" />
		</Switch>


	const redirect = isUserLogged
		? (accessUrl && userName ? <Layout>
				{window.navigator.onLine &&
				<Routes />}
				{!window.navigator.onLine && isProduction && <ErrorPage offline/>}
			</Layout> : <Spinner/>)
		: notLoggedInRoutes

	return (
		<AppGlobalContext.Provider value={{
			getCommonLists,
		}}>
		<div className="App">
			<IdleTimer
				element={document}
				onActive={() => setIsIdle(false)}
				onIdle={() => setIsIdle(true)}
				timeout={ 1000 * 60 * 10 } />
			{redirect}
			<CustomToast hideProgressBar />
		</div>
		</AppGlobalContext.Provider>
	);
};

export default App;
