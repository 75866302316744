import React from 'react';
import BarcodesPageContext  from '../../context/pagesContext/barcodesPageContext';
import BarcodesPanel from '../../components/barcodes/BarcodesPanel';
import useBarcodesPage from './useBarcodesPage';
import Spinner from '../../components/Spinner/Spinner';
import { Route, Switch } from 'react-router-dom';
import CreateBarcodeRange from '../../components/barcodes/CreateBarcodeRange';
import ErrorIndicator from '../../components/ErrorIndicator/ErrorIndicator';
import PageLayout from '../../components/pageLayout/PageLayout';

const BarcodesPage = () => {

	const barcodesPageData = useBarcodesPage();
	const { barcodesData, isFetching } = barcodesPageData;

	const pageContent = <>
		{!barcodesData && isFetching && <Spinner/>}
		{!barcodesData && !isFetching && <ErrorIndicator globalError/>}
		{	barcodesData &&	<BarcodesPanel />	}
	</>


	return (
			<BarcodesPageContext.Provider value={barcodesPageData}>
				<PageLayout
					addedClassName="barcodes-page"
					pageContent={pageContent}
				/>
				<Switch>
					<Route
						path={['/barcode_ranges/create']}
						render={props => <CreateBarcodeRange {...props} exact/>}
					/>
				</Switch>
			</BarcodesPageContext.Provider>
	);
};

export default React.memo(BarcodesPage);
