const toEnglishText = (string, fromLang = "ru" ) => {

		const fromRu = {"й":"q","ц":"w","у":"e","к":"r","е":"t","н":"y","г":"u","ш":"i","щ":"o","з":"p","х":"[","Х":"{","ъ":"]","Ъ":"}","/":"|","ё":"`","Ё":"~","ф":"a","ы":"s","в":"d","а":"f","п":"g","р":"h","о":"j","л":"k","д":"l","ж":";","Ж":":","э":"'","Э":"\"","я":"z","ч":"x","с":"c","м":"v","и":"b","т":"n","ь":"m","б":",","Б":"<","ю":".","Ю":">",".":"/",",":"?","\"":"@","№":"#",";":"$",":":"^","?":"&"};

		let replacer;
		//swap keys and values
		// let tmp = {};
		// for (let letter in fromRu) {
		// 	tmp = {...tmp, [fromRu[letter]] : letter}
		// }
		// console.log(JSON.stringify(tmp));

		switch(fromLang) {
			case 'ru' :
				replacer = fromRu;
				break;
			default :
				replacer = fromRu;
		}

		for(let i=0; i < string.length; i++){
			if( replacer[ string[i].toLowerCase() ] !== undefined){
				let replace;
				if(string[i] === string[i].toLowerCase()){
					replace = replacer[ string[i].toLowerCase() ];
				} else if(string[i] === string[i].toUpperCase()){
					replace = replacer[ string[i].toLowerCase() ].toUpperCase();
				}

				string = string.replace(string[i], replace);
			}
		}

		return string;

}

export default toEnglishText;
