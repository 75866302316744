import React from 'react';

const healthCheckDetailsPageContext = React.createContext({
	healthStatusesDetailsData: {},
	resetHealthCheckDetailsFilter: () => {},
	getHealthCheckDetails: () => {},
	error: '',
	isFetching: false,
});

export default healthCheckDetailsPageContext;