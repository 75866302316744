import React from 'react';
import './CustomPre.scss';
import { stringObjectCheck } from '../../../helpers/stringObjectCheck';

const CustomPre = ({ title = "", data }) => {

	return (
		<div className="custom-pre">
			<div className="title">
				{title}
			</div>
			<pre>
					{stringObjectCheck(data)}
				</pre>
		</div>
	);
};

export default CustomPre;
