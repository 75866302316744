import React from 'react';

const CardItemSection = ({ label, data }) => {
	return (
		<div className={`card-item-section`} >
			<div className="card-item-label">{label}: </div>
			<div className="card-item-data">{data}</div>
		</div>
	);
};

export default CardItemSection;
