import { useGlobal } from 'reactn';
import useSapiService from '../../hooks/useSapiService';

import Cookies from 'js-cookie';
import { useRouter } from '../useRouter';

const useAuthentication = () => {
	const [, setUserName] = useGlobal('userName');
	const [, setIsUserLogged] = useGlobal('isUserLogged');
	const { history } = useRouter();

	const {
		loginReq,
		logoutReq,
		activationReq,
		isFetching,
		error,
		isProduction,
	} = useSapiService();


	function login(reqData) {
		!isProduction
			? setIsUserLogged(true)
			: loginReq(reqData)
				.then(() => {
					setIsUserLogged(true);
					localStorage.setItem('PHPSESSID', Cookies.get('PHPSESSID'));
				});
	}

	function activate(reqData) {
		!isProduction
			? setIsUserLogged(true)
			: activationReq(reqData)
				.then(() => {
					history.replace('/login');
				});
	}

	function logout() {
		!isProduction
			? setIsUserLogged(false)
			: logoutReq()
				.then(()=> {
					setUserName('');
					setIsUserLogged(false);
					Cookies.remove('userLogin');
					Cookies.remove('PHPSESSID');
					history.push('/login');
				});
	}



	return {
		login,
		logout,
		activate,
		isFetching,
		error,
	};
};

export default useAuthentication;
