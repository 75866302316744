import React from 'react';
import PropTypes from 'prop-types';
import   './Icon.scss';

const Icon = ({
	iconName,
	iconColor,
	boxed = false,
	size = 12,
	iconClass ='',
	...props
}) => {

	const boxClass = boxed ? 'boxed' : '';

	const iconStyle = {
		 fontSize: `${size}px`,
			color: iconColor
	};

	return (
		<span className={`icon-item ${boxClass} ${iconClass}`} {...props} >
			<i className="material-icons" style={iconStyle}>{iconName}</i>
		</span>
	);
};

Icon.propTypes = {
	iconName: PropTypes.string.isRequired,
	iconClass: PropTypes.string,
	boxed:PropTypes.bool,
	size: PropTypes.number,
};


export default Icon;

/**
 * iconName  - Icon name from Material Design Icons
 * iconClass - add custom class to icon component.
 * boxed - add frame to icon
 * size  - icon-font size
 */
