import React from 'react';
import PropTypes from 'prop-types';

import Icon from '../Icon/Icon';
import { Button } from 'react-bootstrap';
import './ButtonIcon.scss';

/**
 *
 * @param variant - bootstrap-4 button style
 * @param buttonText - button text
 * @param iconName - material icon name
 * @param size  - font size for google-font icon
 * @param onClick  - on click handler function
 * @param isAnimated  - spin icon if true
 */

const ButtonIcon = ({
	variant,
	buttonText,
	iconName,
	size,
	isAnimated,
	addedClassName = '',
	...props
}) => {
	const text = buttonText ? <span className="ml-2">{buttonText}</span> : null;

	return (
		<Button
			variant={variant}
			className={`button-icon d-flex align-items-center justify-content-center ${addedClassName} ${isAnimated && 'animated-icon'}`}
			{...props}
		>
			<span className="icon-wrapper" style={{height: `${size}px`, width: `${size}px`}}>
				<Icon iconName={iconName} size={size} />
			</span> {text}
		</Button>
	);
};

ButtonIcon.propTypes = {
	variant: PropTypes.string,
	buttonText: PropTypes.string,
	addedClassName: PropTypes.string,
	iconName: PropTypes.string.isRequired,
	size: PropTypes.number,
};

export default ButtonIcon;
