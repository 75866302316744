import React from 'react';
import PropTypes from 'prop-types';

import { Modal } from 'react-bootstrap';

import './Modal.scss';
import Icon from '../Icon/Icon';

const NewModal = ({ show, close, children, bsSize, customClass }) => {
	return (
		<Modal show={show}
					 size={bsSize}
					 className={customClass}
					 centered
					 backdrop
					 onHide={close}>
			<span className="close-button"
						onClick={close}>
				<Icon iconName="close" size={24}></Icon>
			</span>
			{children}
		</Modal>
	);
};

NewModal.propTypes = {
	show: PropTypes.bool.isRequired,
	close: PropTypes.func.isRequired,
	customClass: PropTypes.string,
	bsSize: PropTypes.string,
};

export default NewModal;
