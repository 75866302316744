/*--------------------
 Common Data
 --------------------------*/
export const localCommonLists = {
	"users": {
		"1": "ir_group_admin",
		"2": "admin"
	},
	"apiUsers": {
		"1": "ir_group_admin"
	},
	"carriers": {
		"1": "ACP",
		"2": "MyPack",
		"3": "AnPost",
		"4": "Royale",
		"5": "DHL Global Mail Europe",
		"6": "CTT",
		"7": "DHL Connect",
		"8": "DPD",
		"9": "Easy Post",
		"10": "BPost",
		"11": "Hub Europe",
		"12": "Correos PUDO",
		"13": "Ninja Van",
		"14": "Sing Post",
		"15": "PostNL Radar",
		"16": "AAS Switzerland",
		"17": "Royal Mail",
		"18": "Citi Logistics",
		"19": "GEPS",
		"20": "Mara Express",
		"21": "GLS",
		"22": "Relais Colis",
		"23": "Colis Prive",
		"24": "USPS Epacket",
		"25": "Collect Plus",
		"26": "SEKO",
		"27": "Hermes",
		"28": "BoxBerry",
		"29": "PostNL",
		"30": "Fastway",
		"31": "Mondial",
		"32": "SDA Express",
		"33": "Royale",
		"34": "Parcels 4U",
		"35": "UPS",
		"36": "Aramex",
		"37": "Doddle",
		"38": "DHL DE",
		"39": "Omniva",
		"40": "Hermes Germany",
		"41": "Parcel Point",
		"42": "NZ Post",
		"43": "Iceland Post",
		"44": "Hermes HSI",
		"45": "CDEK",
		"46": "InPost",
		"47": "Canada Post",
		"48": "To You",
		"49": "Packetery",
		"50": "DPD United Kingdom",
		"51": "Pitney Bowes",
		"52": "Direct Link",
		"53": "Box It",
		"54": "Business Reply",
		"55": "Colissimo",
		"56": "Estafeta",
		"57": "Malta Post",
		"58": "DHL USPS",
		"59": "Canpar",
		"60": "Temando",
		"61": "PMP",
		"62": "Consignor",
		"63": "Aprisa",
		"64": "Skynet",
		"65": "ASM",
		"66": "DHL NL",
		"67": "Janio Asia",
		"68": "AMS",
		"69": "DHL Paket",
		"71": "DPD",
		"72": "Jersey Post",
		"73": "Guernsey Post",
		"74": "Crown Logistic",
		"75": "ECargo",
		"76": "Sky Postal",
		"77": "DHL Express",
		"78": "Poland Post",
		"79": "Viva Xpress",
		"80": "Pakpobox",
		"81": "Mailwise",
		"82": "Celeritas",
		"83": "Emirates Post",
		"84": "Mail Alliance",
		"85": "Pony Express",
		"86": "Taxidromiki",
		"87": "Docket Hub",
		"88": "Parcelforce",
		"89": "DHL UK",
		"90": "OSM",
		"91": "Test Carrier",
		"92": "Parcel Connect",
		"93": "Posti",
		"94": "Magyar Posta",
		"95": "Latvia Post",
		"96": "Lithuania Post",
		"97": "Bulgaria Post",
		"98": "Austrian Post",
		"99": "Asendia wnDirect",
		"100": "Post Luxembourg",
		"101": "DAI",
		"102": "Homerr",
		"103": "Correos PUDO",
		"104": "Pitney Bowes",
		"105": "PostNL Packet",
		"106": "Hermes HSI",
		"107": "Shipa Delivery",
		"108": "Yodel",
		"109": "Hermes HSI",
		"110": "Mail Americas",
		"111": "Coll-8",
		"112": "Sending",
		"113": "Gaash",
		"114": "IMN",
		"115": "DHL FastTrack",
		"116": "Cacesa",
		"117": "Spring",
		"118": "FedEx",
		"119": "4PX",
		"120": "Correos Paq",
		"121": "IBC FedEx",
		"122": "GLS",
		"123": "GLS",
		"124": "Untracked",
		"125": "United Broker",
		"126": "Waitrose"
	},
	"carrierNames": {
		"1": "ACP",
		"2": "MyPack",
		"3": "AnPost",
		"4": "Royale",
		"5": "DHLGME",
		"6": "CTT",
		"7": "DHLConnect",
		"8": "DPDDE",
		"9": "EasyPost",
		"10": "BPost",
		"11": "HubEurope",
		"12": "CorreosPUDO",
		"13": "NinjaVan",
		"14": "SingPost",
		"15": "PostNLRadar",
		"16": "AAS",
		"17": "RoyalMail",
		"18": "CitiLogistics",
		"19": "GEPS",
		"20": "MaraExpress",
		"21": "GLS",
		"22": "RelaisColis",
		"23": "ColisPrive",
		"24": "USPSEpacket",
		"25": "CollectPlus",
		"26": "SEKO",
		"27": "Hermes",
		"28": "BoxBerry",
		"29": "PostNL",
		"30": "Fastway",
		"31": "Mondial",
		"32": "SDAExpress",
		"33": "RoyaleV2",
		"34": "Parcels4U",
		"35": "UPS",
		"36": "Aramex",
		"37": "Doddle",
		"38": "DHLDE",
		"39": "Omniva",
		"40": "HermesGermany",
		"41": "ParcelPoint",
		"42": "NZPost",
		"43": "IcelandPost",
		"44": "HermesHSI",
		"45": "CDEK",
		"46": "InPost",
		"47": "CanadaPost",
		"48": "ToYou",
		"49": "Packetery",
		"50": "DPDUK",
		"51": "PitneyBowes",
		"52": "DirectLink",
		"53": "BoxIt",
		"54": "BusinessReply",
		"55": "Colissimo",
		"56": "Estafeta",
		"57": "MaltaPost",
		"58": "DHLUSPS",
		"59": "Canpar",
		"60": "Temando",
		"61": "PMP",
		"62": "Consignor",
		"63": "Aprisa",
		"64": "Skynet",
		"65": "ASM",
		"66": "DHLNL",
		"67": "Janio",
		"68": "AMS",
		"69": "DHLPaket",
		"71": "DPDBE",
		"72": "JerseyPost",
		"73": "GuernseyPost",
		"74": "CrownLogistic",
		"75": "ECargo",
		"76": "SkyPostal",
		"77": "DHLExpress",
		"78": "PolandPost",
		"79": "VivaXpress",
		"80": "Pakpobox",
		"81": "Mailwise",
		"82": "Celeritas",
		"83": "EmiratesPost",
		"84": "MailAlliance",
		"85": "PonyExpress",
		"86": "Taxidromiki",
		"87": "DocketHub",
		"88": "Parcelforce",
		"89": "DHLUK",
		"90": "OSM",
		"91": "TestCarrier",
		"92": "ParcelConnect",
		"93": "Posti",
		"94": "MagyarPosta",
		"95": "LatviaPost",
		"96": "LithuaniaPost",
		"97": "BulgariaPost",
		"98": "AustrianPost",
		"99": "AsendiaWND",
		"100": "PostLuxembourg",
		"101": "DAI",
		"102": "Homerr",
		"103": "CorreosPUDOAPI",
		"104": "PitneyBowesShipping",
		"105": "PostNLPacket",
		"106": "HermesHSI20Digits",
		"107": "ShipaDelivery",
		"108": "Yodel",
		"109": "HermesHSIInternational",
		"110": "MailAmericas",
		"111": "Coll8",
		"112": "Sending",
		"113": "Gaash",
		"114": "IMN",
		"115": "DHLFastTrack",
		"116": "Cacesa",
		"117": "Spring",
		"118": "FedEx",
		"119": "FourPX",
		"120": "CorreosPaq",
		"121": "IbcFedEx",
		"122": "GLSGermany",
		"123": "GLSItaly",
		"124": "Untracked",
		"125": "UnitedBroker",
		"126": "Waitrose"
	},
	"services": {
		"1": "Return - ACP Ground",
		"2": "Return - ACP Priority",
		"3": "Return - ACP Lite",
		"4": "Return - MyPack Drop Off",
		"5": "Return - MyPack Pakke Drop Off",
		"6": "Return - AnPost Drop Off",
		"7": "Return - Royale Parcel Shop Return",
		"8": "Return - DHL Global Mail Europe",
		"9": "Return - CTT",
		"10": "Return - DHL Connect",
		"11": "Return - DPD Germany",
		"12": "Return - Easy Post",
		"13": "Return - BPost",
		"16": "Return - Ninja Van Courier",
		"19": "Return - PostNL Radar Return Tracked Packet 81RS",
		"20": "Return - PostNL Radar Return Tracked",
		"21": "Return - PostNL Radar Return Tracked Packet 85RS",
		"24": "Return - Ninja Van PUDO",
		"27": "Return - Royal Mail Tracked Returns 48",
		"33": "Return - Sing Post - Drop Off",
		"34": "Return - Relais Colis",
		"39": "Return - Collect Plus",
		"42": "Return - Hermes PUDO",
		"43": "Return - Hermes Courier",
		"44": "Return - BoxBerry",
		"46": "Return - PostNL PUDO",
		"47": "Return - Fastway",
		"48": "Return - Mondial",
		"49": "Return - SDA Express",
		"54": "Return - Royale 7-11",
		"55": "Return - Citi Logistics",
		"56": "Return - Royale Alfred",
		"57": "Return - BoxBerry Courier",
		"58": "Return - UPS Ground",
		"59": "Return - UPS Standard",
		"60": "Return - UPS Mail Innovations Returns",
		"61": "Return - UPS Access Point™ Economy",
		"62": "Return - Aramex Drop Off",
		"63": "Return - Aramex Courier",
		"64": "Return - Correos PUDO API",
		"65": "Return - Doddle",
		"66": "Return - DHL DE DHL Retoure",
		"67": "Return - Omniva",
		"68": "Return - Hermes Germany",
		"69": "Return - Parcel Point Pudo",
		"70": "Return - Parcel Point Courier",
		"71": "Return - NZ Post",
		"77": "Return - CDEK pudo",
		"78": "Return - CDEK courier",
		"79": "Return - InPost",
		"80": "Return - Canada Post",
		"82": "Return - Hermes PUDO PrintInStore",
		"83": "Return - To You",
		"84": "Return - Royale Circle K",
		"87": "Return - DPD United Kingdom",
		"88": "Return - Pitney Bowes",
		"107": "Return - Direct Link",
		"108": "Return - Box It Shop",
		"109": "Return - Box It Locker",
		"110": "Return - Business Reply",
		"111": "Return - Colissimo Pudo",
		"112": "Return - Colissimo Courier",
		"113": "Return - Estafeta",
		"115": "Return - Malta Post",
		"116": "Return - DHL USPS Ground",
		"117": "Return - DHL USPS Priority",
		"118": "Return - DHL USPS First Class",
		"119": "Return - Canpar",
		"122": "Return - Hermes HSI International",
		"125": "Return - PMP",
		"126": "Return - Consignor Australia Post",
		"127": "Return - Consignor Austria Post",
		"128": "Return - Consignor Bring",
		"129": "Return - Consignor DHL Service Point",
		"130": "Return - Consignor GLS Denmark",
		"131": "Return - Consignor Posti UB",
		"133": "Return - Consignor Swiss Post",
		"136": "Return - Skynet Collection",
		"137": "Return - Skynet Post-To",
		"138": "Return - Skynet Drop-Off",
		"139": "Return - ASM courier",
		"143": "Return - Consignor BPost",
		"145": "Return - DHL NL",
		"147": "Return - Janio Asia Janio",
		"156": "Return - DHL Paket",
		"165": "Return - DPD Belgium",
		"167": "Return - ACP Fedex Smart Post",
		"169": "Return - Jersey Post",
		"171": "Return - Guernsey Post",
		"179": "Return - ECargo",
		"197": "Return - Sky Postal Courier",
		"198": "Return - Sky Postal PUDO",
		"219": "Return - Consignor Celeritas",
		"220": "Return - Consignor Omniva",
		"221": "Return - Poland Post Pudo",
		"223": "Return - Pakpobox",
		"225": "Return - Celeritas RMA PUP",
		"226": "Return - InPost Hermes",
		"236": "Return - Consignor InPost Poland",
		"241": "Return - InPost L2A 2DAY",
		"242": "Return - InPost L2A NDAY",
		"252": "Return - Consignor Parcelforce",
		"299": "Return - Emirates Post PUDO",
		"300": "Return - Emirates Post Courier",
		"317": "Return - Pitney Bowes Standard Return",
		"335": "Return - DHL Express Worldwide (non doc)",
		"336": "Return - DHL Express Economy Select (non doc)",
		"337": "Return - DHL Express Economy Select (eu)",
		"338": "Return - DHL Express Worldwide (eu)",
		"355": "Return - Pony Express PUDO Return",
		"356": "Return - Pony Express Courier Return",
		"358": "Return - Coll-8 drop2shop-Return",
		"431": "Return - Royale AU",
		"432": "Return - Royale 7-11TW",
		"433": "Return - Homerr",
		"434": "Return - Waitrose"
	},
	"availableServices": {
		"1": "Return - ACP Ground",
		"2": "Return - ACP Priority",
		"3": "Return - ACP Lite",
		"4": "Return - MyPack Drop Off",
		"5": "Return - MyPack Pakke Drop Off",
		"6": "Return - AnPost Drop Off",
		"7": "Return - Royale Parcel Shop Return",
		"8": "Return - DHL Global Mail Europe",
		"9": "Return - CTT",
		"10": "Return - DHL Connect",
		"11": "Return - DPD Germany",
		"12": "Return - Easy Post",
		"13": "Return - BPost",
		"16": "Return - Ninja Van Courier",
		"19": "Return - PostNL Radar Return Tracked Packet 81RS",
		"20": "Return - PostNL Radar Return Tracked",
		"21": "Return - PostNL Radar Return Tracked Packet 85RS",
		"24": "Return - Ninja Van PUDO",
		"27": "Return - Royal Mail Tracked Returns 48",
		"33": "Return - Sing Post - Drop Off",
		"34": "Return - Relais Colis",
		"39": "Return - Collect Plus",
		"42": "Return - Hermes PUDO",
		"43": "Return - Hermes Courier",
		"44": "Return - BoxBerry",
		"46": "Return - PostNL PUDO",
		"47": "Return - Fastway",
		"48": "Return - Mondial",
		"49": "Return - SDA Express",
		"54": "Return - Royale 7-11",
		"55": "Return - Citi Logistics",
		"56": "Return - Royale Alfred",
		"57": "Return - BoxBerry Courier",
		"58": "Return - UPS Ground",
		"59": "Return - UPS Standard",
		"60": "Return - UPS Mail Innovations Returns",
		"61": "Return - UPS Access Point™ Economy",
		"62": "Return - Aramex Drop Off",
		"63": "Return - Aramex Courier",
		"64": "Return - Correos PUDO API",
		"65": "Return - Doddle",
		"66": "Return - DHL DE DHL Retoure",
		"67": "Return - Omniva",
		"68": "Return - Hermes Germany",
		"69": "Return - Parcel Point Pudo",
		"70": "Return - Parcel Point Courier",
		"71": "Return - NZ Post",
		"77": "Return - CDEK pudo",
		"78": "Return - CDEK courier",
		"79": "Return - InPost",
		"80": "Return - Canada Post",
		"82": "Return - Hermes PUDO PrintInStore",
		"83": "Return - To You",
		"84": "Return - Royale Circle K",
		"87": "Return - DPD United Kingdom",
		"88": "Return - Pitney Bowes",
		"107": "Return - Direct Link",
		"108": "Return - Box It Shop",
		"109": "Return - Box It Locker",
		"110": "Return - Business Reply",
		"111": "Return - Colissimo Pudo",
		"112": "Return - Colissimo Courier",
		"113": "Return - Estafeta",
		"115": "Return - Malta Post",
		"116": "Return - DHL USPS Ground",
		"117": "Return - DHL USPS Priority",
		"118": "Return - DHL USPS First Class",
		"119": "Return - Canpar",
		"122": "Return - Hermes HSI International",
		"125": "Return - PMP",
		"126": "Return - Consignor Australia Post",
		"127": "Return - Consignor Austria Post",
		"128": "Return - Consignor Bring",
		"129": "Return - Consignor DHL Service Point",
		"130": "Return - Consignor GLS Denmark",
		"131": "Return - Consignor Posti UB",
		"133": "Return - Consignor Swiss Post",
		"136": "Return - Skynet Collection",
		"137": "Return - Skynet Post-To",
		"138": "Return - Skynet Drop-Off",
		"139": "Return - ASM courier",
		"143": "Return - Consignor BPost",
		"145": "Return - DHL NL",
		"147": "Return - Janio Asia Janio",
		"156": "Return - DHL Paket",
		"165": "Return - DPD Belgium",
		"167": "Return - ACP Fedex Smart Post",
		"169": "Return - Jersey Post",
		"171": "Return - Guernsey Post",
		"179": "Return - ECargo",
		"197": "Return - Sky Postal Courier",
		"198": "Return - Sky Postal PUDO",
		"219": "Return - Consignor Celeritas",
		"220": "Return - Consignor Omniva",
		"221": "Return - Poland Post Pudo",
		"223": "Return - Pakpobox",
		"225": "Return - Celeritas RMA PUP",
		"226": "Return - InPost Hermes",
		"236": "Return - Consignor InPost Poland",
		"241": "Return - InPost L2A 2DAY",
		"242": "Return - InPost L2A NDAY",
		"252": "Return - Consignor Parcelforce",
		"299": "Return - Emirates Post PUDO",
		"300": "Return - Emirates Post Courier",
		"317": "Return - Pitney Bowes Standard Return",
		"335": "Return - DHL Express Worldwide (non doc)",
		"336": "Return - DHL Express Economy Select (non doc)",
		"337": "Return - DHL Express Economy Select (eu)",
		"338": "Return - DHL Express Worldwide (eu)",
		"355": "Return - Pony Express PUDO Return",
		"356": "Return - Pony Express Courier Return",
		"358": "Return - Coll-8 drop2shop-Return",
		"431": "Return - Royale AU",
		"432": "Return - Royale 7-11TW",
		"433": "Return - Homerr",
		"434": "Return - Waitrose"
	},
	"typeOfServices": {
		"pudo": "PUDO",
		"courier": "Courier",
		"pallet": "Pallet"
	},
	"kindOfServices": {
		"return": "Return",
		"direct": "Direct",
		"pallet": "Pallet"
	},
	"countries": {
		"AF": "Afghanistan",
		"AX": "Aland Islands",
		"AL": "Albania",
		"DZ": "Algeria",
		"AS": "American Samoa",
		"AD": "Andorra",
		"AO": "Angola",
		"AI": "Anguilla",
		"AQ": "Antarctica",
		"AG": "Antigua and Barbuda",
		"AR": "Argentina",
		"AM": "Armenia",
		"AW": "Aruba",
		"AU": "Australia",
		"AT": "Austria",
		"AZ": "Azerbaijan",
		"BS": "Bahamas",
		"BH": "Bahrain",
		"BD": "Bangladesh",
		"BB": "Barbados",
		"BY": "Belarus",
		"BE": "Belgium",
		"BZ": "Belize",
		"BJ": "Benin",
		"BM": "Bermuda",
		"BT": "Bhutan",
		"BO": "Bolivia",
		"BQ": "Bonaire",
		"BA": "Bosnia and Herzegovina",
		"BW": "Botswana",
		"BV": "Bouvet Island",
		"BR": "Brazil",
		"IO": "British Indian Ocean Territory",
		"BN": "Brunei Darussalam",
		"BG": "Bulgaria",
		"BF": "Burkina Faso",
		"BI": "Burundi",
		"CV": "Cabo Verde",
		"KH": "Cambodia",
		"CM": "Cameroon",
		"CA": "Canada",
		"KY": "Cayman Islands",
		"CF": "Central African Republic",
		"TD": "Chad",
		"CL": "Chile",
		"CN": "China",
		"CX": "Christmas Island",
		"CC": "Cocos (Keeling) Islands",
		"CO": "Colombia",
		"KM": "Comoros",
		"CG": "Congo",
		"CD": "Congo (the Democratic Republic of the)",
		"CK": "Cook Islands",
		"CR": "Costa Rica",
		"HR": "Croatia",
		"CU": "Cuba",
		"CW": "Curacao",
		"CY": "Cyprus",
		"CZ": "Czechia",
		"CI": "Cote d'Ivoire",
		"DK": "Denmark",
		"DJ": "Djibouti",
		"DM": "Dominica",
		"DO": "Dominican Republic",
		"EC": "Ecuador",
		"EG": "Egypt",
		"SV": "El Salvador",
		"GQ": "Equatorial Guinea",
		"ER": "Eritrea",
		"EE": "Estonia",
		"SZ": "Eswatini",
		"ET": "Ethiopia",
		"FK": "Falkland Islands",
		"FO": "Faroe Islands",
		"FJ": "Fiji",
		"FI": "Finland",
		"FR": "France",
		"FX": "France (European Ter.)",
		"PF": "French Polynesia",
		"TF": "French Southern Territories",
		"GF": "French Guiana",
		"GA": "Gabon",
		"GM": "Gambia",
		"GE": "Georgia",
		"DE": "Germany",
		"GH": "Ghana",
		"GI": "Gibraltar",
		"GR": "Greece",
		"GL": "Greenland",
		"GD": "Grenada",
		"GP": "Guadeloupe",
		"GU": "Guam",
		"GT": "Guatemala",
		"GG": "Guernsey",
		"GN": "Guinea",
		"GW": "Guinea-Bissau",
		"GY": "Guyana",
		"HT": "Haiti",
		"HM": "Heard Island and McDonald Islands",
		"VA": "Holy See",
		"HN": "Honduras",
		"HK": "Hong Kong",
		"HU": "Hungary",
		"IS": "Iceland",
		"IN": "India",
		"ID": "Indonesia",
		"IR": "Iran",
		"IQ": "Iraq",
		"IE": "Ireland",
		"IM": "Isle of Man",
		"IL": "Israel",
		"IT": "Italy",
		"JM": "Jamaica",
		"JP": "Japan",
		"JE": "Jersey",
		"JO": "Jordan",
		"KZ": "Kazakhstan",
		"KE": "Kenya",
		"KI": "Kiribati",
		"KP": "Korea (North)",
		"KR": "Korea (South)",
		"XK": "Republic of Kosovo",
		"KW": "Kuwait",
		"KG": "Kyrgyzstan",
		"LA": "Lao People's Democratic Republic",
		"LV": "Latvia",
		"LB": "Lebanon",
		"LS": "Lesotho",
		"LR": "Liberia",
		"LY": "Libya",
		"LI": "Liechtenstein",
		"LT": "Lithuania",
		"LU": "Luxembourg",
		"MO": "Macao",
		"MG": "Madagascar",
		"MW": "Malawi",
		"MY": "Malaysia",
		"MV": "Maldives",
		"ML": "Mali",
		"MT": "Malta",
		"MH": "Marshall Islands",
		"MQ": "Martinique",
		"MR": "Mauritania",
		"MU": "Mauritius",
		"YT": "Mayotte",
		"MX": "Mexico",
		"FM": "Micronesia",
		"MD": "Moldova",
		"MC": "Monaco",
		"MN": "Mongolia",
		"ME": "Montenegro",
		"MS": "Montserrat",
		"MA": "Morocco",
		"MZ": "Mozambique",
		"MM": "Myanmar",
		"NA": "Namibia",
		"NR": "Nauru",
		"NP": "Nepal",
		"AN": "Netherland Antilles",
		"NL": "Netherlands",
		"NT": "Neutral Zone",
		"NC": "New Caledonia",
		"NZ": "New Zealand",
		"NI": "Nicaragua",
		"NE": "Niger",
		"NG": "Nigeria",
		"NU": "Niue",
		"NF": "Norfolk Island",
		"MK": "North Macedonia",
		"MP": "Northern Mariana Islands",
		"NO": "Norway",
		"OM": "Oman",
		"PK": "Pakistan",
		"PW": "Palau",
		"PS": "Palestine",
		"PA": "Panama",
		"PG": "Papua New Guinea",
		"PY": "Paraguay",
		"PE": "Peru",
		"PH": "Philippines",
		"PN": "Pitcairn",
		"PL": "Poland",
		"PT": "Portugal",
		"PR": "Puerto Rico",
		"QA": "Qatar",
		"RO": "Romania",
		"RU": "Russian Federation",
		"RW": "Rwanda",
		"RE": "Reunion",
		"MF": "Saint Martin",
		"PM": "Saint Pierre and Miquelon",
		"BL": "Saint Barthelemy",
		"SH": "Saint Helena",
		"KN": "Saint Kitts and Nevis",
		"LC": "Saint Lucia",
		"VC": "Saint Vincent and the Grenadines",
		"WS": "Samoa",
		"SM": "San Marino",
		"ST": "Sao Tome and Principe",
		"SA": "Saudi Arabia",
		"SN": "Senegal",
		"RS": "Serbia",
		"SC": "Seychelles",
		"SL": "Sierra Leone",
		"SG": "Singapore",
		"SX": "Sint Maarten",
		"SK": "Slovakia",
		"SI": "Slovenia",
		"SB": "Solomon Islands",
		"SO": "Somalia",
		"ZA": "South Africa",
		"GS": "South Georgia and the South Sandwich Islands",
		"SS": "South Sudan",
		"ES": "Spain",
		"LK": "Sri Lanka",
		"SD": "Sudan",
		"SR": "Suriname",
		"SJ": "Svalbard",
		"SE": "Sweden",
		"CH": "Switzerland",
		"SY": "Syrian Arab Republic",
		"TW": "Taiwan",
		"TJ": "Tajikistan",
		"TZ": "Tanzania",
		"TH": "Thailand",
		"TL": "Timor-Leste",
		"TG": "Togo",
		"TK": "Tokelau",
		"TO": "Tonga",
		"TT": "Trinidad and Tobago",
		"TN": "Tunisia",
		"TR": "Turkey",
		"TM": "Turkmenistan",
		"TC": "Turks and Caicos Islands",
		"TV": "Tuvalu",
		"UG": "Uganda",
		"UA": "Ukraine",
		"AE": "United Arab Emirates",
		"UK": "United Kingdom",
		"GB": "Great Britain",
		"UM": "United States Minor Outlying Islands",
		"US": "United States",
		"UY": "Uruguay",
		"UZ": "Uzbekistan",
		"VU": "Vanuatu",
		"VE": "Venezuela",
		"VN": "Viet Nam",
		"VG": "Virgin Islands (British)",
		"VI": "Virgin Islands (US)",
		"WF": "Wallis and Futuna",
		"EH": "Western Sahara",
		"YE": "Yemen",
		"YU": "Yugoslavia",
		"ZR": "Zaire",
		"ZM": "Zambia",
		"ZW": "Zimbabwe"
	},
	"states": {
		"US": {
			"al": "Alabama",
			"ak": "Alaska",
			"as": "American Samoa",
			"az": "Arizona",
			"ar": "Arkansas",
			"af": "Armed Forces Africa",
			"aa": "Armed Forces Americas",
			"ac": "Armed Forces Canada",
			"ae": "Armed Forces Europe",
			"am": "Armed Forces Middle East",
			"ap": "Armed Forces Pacific",
			"ca": "California",
			"co": "Colorado",
			"ct": "Connecticut",
			"de": "Delaware",
			"dc": "District of Columbia",
			"fm": "Federated States Of Micronesia",
			"fl": "Florida",
			"ga": "Georgia",
			"gu": "Guam",
			"hi": "Hawaii",
			"id": "Idaho",
			"il": "Illinois",
			"in": "Indiana",
			"ia": "Iowa",
			"ks": "Kansas",
			"ky": "Kentucky",
			"la": "Louisiana",
			"me": "Maine",
			"mh": "Marshall Islands",
			"md": "Maryland",
			"ma": "Massachusetts",
			"mi": "Michigan",
			"mn": "Minnesota",
			"ms": "Mississippi",
			"mo": "Missouri",
			"mt": "Montana",
			"ne": "Nebraska",
			"nv": "Nevada",
			"nh": "New Hampshire",
			"nj": "New Jersey",
			"nm": "New Mexico",
			"ny": "New York",
			"nc": "North Carolina",
			"nd": "North Dakota",
			"mp": "Northern Mariana Islands",
			"oh": "Ohio",
			"ok": "Oklahoma",
			"or": "Oregon",
			"pw": "Palau",
			"pa": "Pennsylvania",
			"pr": "Puerto Rico",
			"ri": "Rhode Island",
			"sc": "South Carolina",
			"sd": "South Dakota",
			"tn": "Tennessee",
			"tx": "Texas",
			"ut": "Utah",
			"vt": "Vermont",
			"vi": "Virgin Islands",
			"va": "Virginia",
			"wa": "Washington",
			"wv": "West Virginia",
			"wi": "Wisconsin",
			"wy": "Wyoming"
		},
		"CA": {
			"ab": "Alberta",
			"bc": "British Columbia",
			"mb": "Manitoba",
			"nf": "Newfoundland",
			"nb": "New Brunswick",
			"ns": "Nova Scotia",
			"nt": "Northwest Territories",
			"nu": "Nunavut",
			"on": "Ontario",
			"pe": "Prince Edward Island",
			"qc": "Quebec",
			"sk": "Saskatchewan",
			"yt": "Yukon Territory"
		},
		"DE": {
			"ni": "Niedersachsen",
			"bw": "Baden-Württemberg",
			"by": "Bayern",
			"be": "Berlin",
			"bb": "Brandenburg",
			"hb": "Bremen",
			"hh": "Hamburg",
			"he": "Hessen",
			"mv": "Mecklenburg-Vorpommern",
			"nw": "Nordrhein-Westfalen",
			"rp": "Rheinland-Pfalz",
			"sl": "Saarland",
			"sn": "Sachsen",
			"st": "Sachsen-Anhalt",
			"sh": "Schleswig-Holstein",
			"th": "Thüringen"
		},
		"AT": {
			"wi": "Wien",
			"no": "Niederösterreich",
			"oo": "Oberösterreich",
			"sb": "Salzburg",
			"kn": "Kärnten",
			"st": "Steiermark",
			"ti": "Tirol",
			"bl": "Burgenland",
			"vb": "Voralberg"
		},
		"CH": {
			"ag": "Aargau",
			"ai": "Appenzell Innerrhoden",
			"ar": "Appenzell Ausserrhoden",
			"be": "Bern",
			"bl": "Basel-Landschaft",
			"bs": "Basel-Stadt",
			"fr": "Freiburg",
			"ge": "Genf",
			"gl": "Glarus",
			"gr": "Graubünden",
			"ju": "Jura",
			"lu": "Luzern",
			"ne": "Neuenburg",
			"nw": "Nidwalden",
			"ow": "Obwalden",
			"sg": "St. Gallen",
			"sh": "Schaffhausen",
			"so": "Solothurn",
			"sz": "Schwyz",
			"tg": "Thurgau",
			"ti": "Tessin",
			"ur": "Uri",
			"vd": "Waadt",
			"vs": "Wallis",
			"zg": "Zug",
			"zh": "Zürich"
		},
		"AU": {
			"act": "Australian Capital Territory",
			"nsw": "New South Wales",
			"nt": "Northern Territory",
			"qld": "Queensland",
			"sa": "South Australia",
			"tas": "Tasmania",
			"vic": "Victoria",
			"wa": "Western Australia"
		},
		"ES": {
			"a coruña": "A Coruña",
			"álava": "Álava",
			"albacete": "Albacete",
			"alicante": "Alicante",
			"almería": "Almería",
			"asturias": "Asturias",
			"ávila": "Ávila",
			"badajoz": "Badajoz",
			"balearic islands": "Balearic Islands",
			"barcelona": "Barcelona",
			"biscay": "Biscay",
			"burgos": "Burgos",
			"cáceres": "Cáceres",
			"cádiz": "Cádiz",
			"cantabria": "Cantabria",
			"castellón": "Castellón",
			"ceuta": "Ceuta",
			"ciudad real": "Ciudad Real",
			"córdoba": "Córdoba",
			"cuenca": "Cuenca",
			"gipuzkoa": "Gipuzkoa",
			"girona": "Girona",
			"granada": "Granada",
			"guadalajara": "Guadalajara",
			"huelva": "Huelva",
			"huesca": "Huesca",
			"jaén": "Jaén",
			"la rioja": "La Rioja",
			"las palmas": "Las Palmas",
			"león": "León",
			"lleida": "Lleida",
			"lugo": "Lugo",
			"madrid": "Madrid",
			"málaga": "Málaga",
			"melilla": "Melilla",
			"murcia": "Murcia",
			"navarre": "Navarre",
			"ourense": "Ourense",
			"palencia": "Palencia",
			"pontevedra": "Pontevedra",
			"salamanca": "Salamanca",
			"santa cruz de tenerife": "Santa Cruz de Tenerife",
			"segovia": "Segovia",
			"seville": "Seville",
			"soria": "Soria",
			"tarragona": "Tarragona",
			"teruel": "Teruel",
			"toledo": "Toledo",
			"valencia": "Valencia",
			"valladolid": "Valladolid",
			"zamora": "Zamora",
			"zaragoza": "Zaragoza"
		},
		"HK": {
			"aberdeen": "Aberdeen",
			"admiralty": "Admiralty",
			"ap lei chau": "Ap Lei Chau",
			"beacon hill": "Beacon Hill",
			"braemar hil": "Braemar Hil",
			"causeway bay": "Causeway Bay",
			"central": "Central",
			"chai wan": "Chai Wan",
			"cheung chau": "Cheung Chau",
			"cheung muk tau": "Cheung Muk Tau",
			"cheung sha wan": "Cheung Sha Wan",
			"choi hung": "Choi Hung",
			"chung hom kok": "Chung Hom Kok",
			"clear water bay": "Clear Water Bay",
			"diamond hill": "Diamond Hill",
			"fanling": "Fanling",
			"fo tan": "Fo Tan",
			"ha tsuen": "Ha Tsuen",
			"hang hau": "Hang Hau",
			"happy valley": "Happy Valley",
			"ho man tin": "Ho Man Tin",
			"hung hom": "Hung Hom",
			"jordan": "Jordan",
			"hung shui kiu": "Hung Shui Kiu",
			"jardine's lookout": "Jardine's Lookout",
			"jordan valley": "Jordan Valley",
			"kai tak": "Kai Tak",
			"kam tin": "Kam Tin",
			"kei ling ha": "Kei Ling Ha",
			"kennedy town": "Kennedy Town",
			"king's park": "King's Park",
			"kowloon bay": "Kowloon Bay",
			"kowloon city": "Kowloon City",
			"kowloon tong": "Kowloon Tong",
			"kwai chung": "Kwai Chung",
			"kwun tong": "Kwun Tong",
			"lai chi kok": "Lai Chi Kok",
			"lam tei": "Lam Tei",
			"lam tin": "Lam Tin",
			"lamma island": "Lamma Island",
			"lantau island": "Lantau Island",
			"lau fau shan": "Lau Fau Shan",
			"lei muk shue": "Lei Muk Shue",
			"lei yue mun": "Lei Yue Mun",
			"lok fu": "Lok Fu",
			"lok ma chau": "Lok Ma Chau",
			"luen wo hui": "Luen Wo Hui",
			"luk keng": "Luk Keng",
			"ma liu shui": "Ma Liu Shui",
			"ma on shan": "Ma On Shan",
			"ma tau chung": "Ma Tau Chung",
			"mei foo sun chuen": "Mei Foo Sun Chuen",
			"ma tau kok": "Ma Tau Kok",
			"ma tau wai": "Ma Tau Wai",
			"ma wan": "Ma Wan",
			"ma yau tong": "Ma Yau Tong ",
			"mei foo": "Mei Foo",
			"mid-levels": "Mid-levels",
			"mong kok": "Mong Kok",
			"ngau chi wan": "Ngau Chi Wan",
			"ngau tau kok": "Ngau Tau Kok",
			"north point": "North Point",
			"pat heung": "Pat Heung",
			"peak": "Peak",
			"peng chau": "Peng Chau",
			"ping shek": "Ping Shek",
			"pok fu lam": "Pok Fu Lam",
			"quarry bay": "Quarry Bay",
			"repulse bay": "Repulse Bay",
			"sai kung": "Sai Kung",
			"sai wan ho": "Sai Wan Ho",
			"sai ying pun": "Sai Ying Pun",
			"san po kong": "San Po Kong",
			"san tin": "San Tin",
			"sau mau ping": "Sau Mau Ping",
			"sha tau kok": "Sha Tau Kok",
			"sha tin": "Sha Tin",
			"shamshuipo": "Shamshuipo",
			"sham shui po": "Sham Shui Po",
			"sham tseng": "Sham Tseng",
			"shau kei wan": "Shau Kei Wan",
			"shek kip mei": "Shek Kip Mei",
			"shek kong": "Shek Kong",
			"shek o": "Shek O",
			"shek tong tsui": "Shek Tong Tsui",
			"shek wu hui": "Shek Wu Hui",
			"sheung shui": "Sheung Shui",
			"sheung wan": "Sheung Wan",
			"shouson hill": "Shouson Hill",
			"shuen wan": "Shuen Wan",
			"siu sai wan": "Siu Sai Wan",
			"so kon po": "So Kon Po",
			"so kwun wat": "So Kwun Wat",
			"stanley": "Stanley",
			"stonecutters island": "Stonecutters Island",
			"sunny bay": "Sunny bay",
			"tai hang": "Tai Hang",
			"tai kok tsui": "Tai Kok Tsui",
			"tai koo shing": "Tai Koo Shing",
			"tai lam chung": "Tai Lam Chung",
			"tai mei tuk": "Tai Mei Tuk",
			"tai mong tsai": "Tai Mong Tsai",
			"tai po": "Tai Po",
			"tai po kau": "Tai Po Kau",
			"tai po market": "Tai Po Market",
			"tai tam": "Tai Tam",
			"tai wai": "Tai Wai",
			"tai wo": "Tai Wo",
			"tai wo ping": "Tai Wo Ping",
			"tin hau": "Tin Hau",
			"tin shui wai": "Tin Shui Wai",
			"to kwan wan": "To Kwan Wan",
			"ting kau": "Ting Kau",
			"tiu keng leng": "Tiu Keng Leng",
			"to kwa wan": "To Kwa Wan",
			"tokwawan": "Tokwawan",
			"tseung kwan o": "Tseung Kwan O",
			"tsim sha tsui": "Tsim Sha Tsui",
			"tsing lung tau": "Tsing Lung Tau",
			"tsing yi": "Tsing Yi",
			"tsuen wan": "Tsuen Wan",
			"tsz wan shan": "Tsz Wan Shan",
			"tuen mun": "Tuen Mun",
			"tung tau": "Tung Tau",
			"tung chung": "Tung Chung",
			"wan chai": "Wan Chai",
			"western": "Western",
			"wang tau hom": "Wang Tau Hom",
			"west kowloon reclamation": "West Kowloon Reclamation",
			"wong chuk hang": "Wong Chuk Hang",
			"wong tai sin": "Wong Tai Sin",
			"wu kai sha": "Wu Kai Sha",
			"wu kau tang": "Wu Kau Tang",
			"yau ma tei": "Yau Ma Tei",
			"yau tong": "Yau Tong",
			"yaumatei": "Yaumatei",
			"yau yat tsuen": "Yau Yat Tsuen",
			"yuen long": "Yuen Long"
		},
		"IT": {
			"ag": "Agrigento",
			"al": "Alessandria",
			"an": "Ancona",
			"ao": "Aosta",
			"ar": "Arezzo",
			"ap": "Ascoli Piceno",
			"at": "Asti",
			"av": "Avellino",
			"ba": "Bari",
			"bt": "Barletta-Andria-Trani",
			"bl": "Belluno",
			"bn": "Benevento or Beneventum",
			"bg": "Bergamo",
			"bi": "Biella",
			"bo": "Bologna",
			"bz": "Bolzano/Bozen",
			"bs": "Brescia",
			"br": "Brindisi",
			"ca": "Cagliari",
			"cl": "Caltanissetta",
			"cb": "Campobasso",
			"ci": "Carbonia-Iglesias",
			"ce": "Caserta",
			"ct": "Catania",
			"cz": "Catanzaro",
			"ch": "Chieti",
			"co": "Como",
			"cs": "Cosenza",
			"cr": "Cremona",
			"kr": "Crotone",
			"cn": "Cuneo",
			"en": "Enna",
			"fm": "Fermo",
			"fe": "Ferrara",
			"fi": "Firenze",
			"fg": "Foggia",
			"fc": "Forlì-Cesena",
			"fr": "Frosinone",
			"ge": "Genoa",
			"go": "Gorizia",
			"gr": "Grosseto",
			"im": "Imperia",
			"is": "Isernia",
			"aq": "L'Aquila",
			"sp": "La Spezia",
			"lt": "Latina",
			"le": "Lecce",
			"lc": "Lecco",
			"li": "Livorno or Leghorn",
			"lo": "Lodi",
			"lu": "Lucca",
			"mc": "Macerata",
			"mn": "Mantua",
			"ms": "Massa-Carrara",
			"mt": "Matera",
			"vs": "Medio Campidano",
			"me": "Messina",
			"mi": "Milano",
			"mo": "Modena",
			"mb": "Monza and Brianza",
			"na": "Napoli",
			"no": "Novara",
			"nu": "Nuoro",
			"og": "Ogliastra",
			"ot": "Olbia-Tempio",
			"or": "Oristano",
			"pd": "Padua",
			"pa": "Palermo",
			"pr": "Parma",
			"pv": "Pavia",
			"pg": "Perugia",
			"pu": "Pesaro e Urbino",
			"pe": "Pescara",
			"pc": "Piacenza",
			"pi": "Pisa",
			"pt": "Pistoia",
			"pn": "Pordenone",
			"pz": "Potenza",
			"po": "Prato",
			"rg": "Ragusa",
			"ra": "Ravenna",
			"rc": "Reggio Calabria",
			"re": "Reggio Emilia",
			"rsm": "Republic of San Marino",
			"ri": "Rieti",
			"rn": "Rimini",
			"rm": "Roma",
			"ro": "Rovigo",
			"sa": "Salerno",
			"ss": "Sassari",
			"sv": "Savona",
			"si": "Siena",
			"sr": "Siracusa",
			"so": "Sondrio",
			"ta": "Taranto",
			"te": "Teramo",
			"tr": "Terni",
			"tp": "Trapani",
			"tn": "Trento",
			"tv": "Treviso",
			"ts": "Trieste",
			"to": "Turin",
			"ud": "Udine",
			"va": "Varese",
			"scv": "Vatican City",
			"ve": "Venezia",
			"vb": "Verbano-Cusio-Ossola",
			"vc": "Vercelli",
			"vr": "Verona",
			"vv": "Vibo Valentia",
			"vi": "Vicenza",
			"vt": "Viterbo"
		},
		"NZ": {
			"auk": "Auckland",
			"bop": "Bay of Plenty",
			"can": "Canterbury",
			"gis": "Gisborne",
			"hkb": "Hawke's Bay",
			"mbh": "Marlborough",
			"mwt": "Manawatu-Wanganui",
			"nsn": "Nelson",
			"ntl": "Northland",
			"ota": "Otago",
			"stl": "Southland",
			"tas": "Tasman",
			"tki": "Taranaki",
			"wko": "Waikato",
			"wgn": "Wellington",
			"wtc": "West Coast",
			"cit": "Chatham Islands Territory"
		}
	},
	"trackingStatuses": {
		"0": "Without statuses",
		"1": "Not mapped",
		"2": "Delayed",
		"100": "New",
		"200": "Received by carrier",
		"500": "In transit",
		"1000": "Delivered",
		"1100": "Canceled",
		"1200": "Lost",
		"2000": "Return to sender"
	},
	"createdStatuses": {
		"0": "Created via carrier",
		"2": "Created via tracking POST",
		"3": "Created via bulk parcels"
	},
	"currencies": {
		"AED": "AED",
		"AFN": "AFN",
		"ALL": "ALL",
		"AMD": "AMD",
		"ANG": "ANG",
		"AOA": "AOA",
		"ARS": "ARS",
		"AUD": "AUD",
		"AWG": "AWG",
		"AZN": "AZN",
		"BAM": "BAM",
		"BBD": "BBD",
		"BDT": "BDT",
		"BGN": "BGN",
		"BHD": "BHD",
		"BIF": "BIF",
		"BMD": "BMD",
		"BND": "BND",
		"BOB": "BOB",
		"BRL": "BRL",
		"BSD": "BSD",
		"BTC": "BTC",
		"BTN": "BTN",
		"BWP": "BWP",
		"BYN": "BYN",
		"BYR": "BYR",
		"BZD": "BZD",
		"CAD": "CAD",
		"CDF": "CDF",
		"CHF": "CHF",
		"CLF": "CLF",
		"CLP": "CLP",
		"CNY": "CNY",
		"COP": "COP",
		"CRC": "CRC",
		"CUC": "CUC",
		"CUP": "CUP",
		"CVE": "CVE",
		"CZK": "CZK",
		"DJF": "DJF",
		"DKK": "DKK",
		"DOP": "DOP",
		"DZD": "DZD",
		"EGP": "EGP",
		"ERN": "ERN",
		"ETB": "ETB",
		"EUR": "EUR",
		"FJD": "FJD",
		"FKP": "FKP",
		"GBP": "GBP",
		"GEL": "GEL",
		"GGP": "GGP",
		"GHS": "GHS",
		"GIP": "GIP",
		"GMD": "GMD",
		"GNF": "GNF",
		"GTQ": "GTQ",
		"GYD": "GYD",
		"HKD": "HKD",
		"HNL": "HNL",
		"HRK": "HRK",
		"HTG": "HTG",
		"HUF": "HUF",
		"IDR": "IDR",
		"ILS": "ILS",
		"IMP": "IMP",
		"INR": "INR",
		"IQD": "IQD",
		"IRR": "IRR",
		"ISK": "ISK",
		"JEP": "JEP",
		"JMD": "JMD",
		"JOD": "JOD",
		"JPY": "JPY",
		"KES": "KES",
		"KGS": "KGS",
		"KHR": "KHR",
		"KMF": "KMF",
		"KPW": "KPW",
		"KRW": "KRW",
		"KWD": "KWD",
		"KYD": "KYD",
		"KZT": "KZT",
		"LAK": "LAK",
		"LBP": "LBP",
		"LKR": "LKR",
		"LRD": "LRD",
		"LSL": "LSL",
		"LTL": "LTL",
		"LVL": "LVL",
		"LYD": "LYD",
		"MAD": "MAD",
		"MDL": "MDL",
		"MGA": "MGA",
		"MKD": "MKD",
		"MMK": "MMK",
		"MNT": "MNT",
		"MOP": "MOP",
		"MRO": "MRO",
		"MUR": "MUR",
		"MVR": "MVR",
		"MWK": "MWK",
		"MXN": "MXN",
		"MYR": "MYR",
		"MZN": "MZN",
		"NAD": "NAD",
		"NGN": "NGN",
		"NIO": "NIO",
		"NOK": "NOK",
		"NPR": "NPR",
		"NZD": "NZD",
		"OMR": "OMR",
		"PAB": "PAB",
		"PEN": "PEN",
		"PGK": "PGK",
		"PHP": "PHP",
		"PKR": "PKR",
		"PLN": "PLN",
		"PYG": "PYG",
		"QAR": "QAR",
		"RON": "RON",
		"RSD": "RSD",
		"RUB": "RUB",
		"RWF": "RWF",
		"SAR": "SAR",
		"SBD": "SBD",
		"SCR": "SCR",
		"SDG": "SDG",
		"SEK": "SEK",
		"SGD": "SGD",
		"SHP": "SHP",
		"SLL": "SLL",
		"SOS": "SOS",
		"SRD": "SRD",
		"STD": "STD",
		"SVC": "SVC",
		"SYP": "SYP",
		"SZL": "SZL",
		"THB": "THB",
		"TJS": "TJS",
		"TMT": "TMT",
		"TND": "TND",
		"TOP": "TOP",
		"TRY": "TRY",
		"TTD": "TTD",
		"TWD": "TWD",
		"TZS": "TZS",
		"UAH": "UAH",
		"UGX": "UGX",
		"USD": "USD",
		"UYU": "UYU",
		"UZS": "UZS",
		"VEF": "VEF",
		"VND": "VND",
		"VUV": "VUV",
		"WST": "WST",
		"XAF": "XAF",
		"XAG": "XAG",
		"XAU": "XAU",
		"XCD": "XCD",
		"XDR": "XDR",
		"XOF": "XOF",
		"XPF": "XPF",
		"YER": "YER",
		"ZAR": "ZAR",
		"ZMK": "ZMK",
		"ZMW": "ZMW",
		"ZWL": "ZWL"
	},
	"labelFormats": {
		"Pdf6x4": "PDF file 6x4 inches",
		"PdfA4": "PDF file A4",
		"Pdf": "PDF file unknown size",
		"Png": "PNG image",
		"Zpl200": "ZPL-200 file",
		"Zpl300": "ZPL-300 file",
		"QR": "QR-code image",
		"DataMatrix": "DataMatrix image",
		"Barcode128": "Barcode 128 image",
		"Barcode39": "Barcode 39 image",
		"Aztec": "Aztec-code image",
		"PDF417": "PDF417-code image",
		"WithoutLabel": "Without label"
	},
	"labelTemplates": {
		"1": [],
		"2": [],
		"3": [],
		"4": [
			"Default",
			"DualBarcode"
		],
		"5": [
			"Default",
			"DualBarcode"
		],
		"6": [
			"Default"
		],
		"7": [
			"Default",
			"DualBarcode"
		],
		"8": [],
		"9": [],
		"10": [],
		"11": [],
		"12": [],
		"13": [
			"Default"
		],
		"16": [
			"Default"
		],
		"19": [],
		"20": [],
		"21": [],
		"24": [
			"Default"
		],
		"27": [
			"Default",
			"DualBarcode",
			"QROrderReference",
			"QRExtraFields"
		],
		"33": [],
		"34": [],
		"39": [
			"Default",
			"DualBarcode"
		],
		"42": [
			"Default",
			"DualBarcode"
		],
		"43": [
			"Default",
			"DualBarcode"
		],
		"44": [
			"Default"
		],
		"46": [],
		"47": [
			"Default"
		],
		"48": [
			"DefaultFR",
			"Default",
			"DisplayBarcodeFR",
			"DisplayBarcode",
			"DualBarcode"
		],
		"49": [
			"Default"
		],
		"54": [
			"Default",
			"OldDefault",
			"OldDualBarcode",
			"QRCode"
		],
		"55": [],
		"56": [
			"Default",
			"OldDefault",
			"OldDualBarcode",
			"QRCode"
		],
		"57": [
			"Default"
		],
		"58": [],
		"59": [],
		"60": [],
		"61": [],
		"62": [],
		"63": [],
		"64": [
			"Default"
		],
		"65": [],
		"66": [
			"Default"
		],
		"67": [
			"Default"
		],
		"68": [],
		"69": [
			"Default"
		],
		"70": [
			"Default"
		],
		"71": [
			"Default",
			"DualBarcode"
		],
		"77": [
			"Default"
		],
		"78": [
			"Default"
		],
		"79": [],
		"80": [],
		"82": [
			"Default",
			"DualBarcode"
		],
		"83": [],
		"84": [
			"Default",
			"OldDefault",
			"OldDualBarcode",
			"QRCode"
		],
		"87": [],
		"88": [],
		"107": [
			"CN22",
			"Default"
		],
		"108": [
			"Default"
		],
		"109": [
			"Default"
		],
		"110": [
			"Default"
		],
		"111": [
			"Default"
		],
		"112": [
			"Default"
		],
		"113": [],
		"115": [
			"Default"
		],
		"116": [],
		"117": [],
		"118": [],
		"119": [],
		"122": [],
		"125": [
			"Default"
		],
		"126": [],
		"127": [],
		"128": [],
		"129": [],
		"130": [],
		"131": [],
		"133": [],
		"136": [
			"Default"
		],
		"137": [
			"Default"
		],
		"138": [
			"Default"
		],
		"139": [
			"Default"
		],
		"143": [],
		"145": [
			"Default"
		],
		"147": [
			"Default"
		],
		"156": [
			"Default"
		],
		"165": [],
		"167": [],
		"169": [
			"Default",
			"DualBarcode"
		],
		"171": [],
		"179": [
			"Default",
			"DualBarcode"
		],
		"197": [],
		"198": [],
		"219": [],
		"220": [],
		"221": [
			"Default"
		],
		"223": [
			"Default"
		],
		"225": [],
		"226": [],
		"236": [],
		"241": [],
		"242": [],
		"252": [],
		"299": [
			"Default"
		],
		"300": [
			"Default"
		],
		"317": [],
		"335": [
			"Default",
			"ECOM26_84_A4_001",
			"ECOM26_84_001",
			"ECOM_TC_A4",
			"ECOM26_A6_001",
			"ECOM26_84CI_001",
			"ECOM_A4_RU_002",
			"ECOM26_A6_002",
			"ECOM26_84CI_002",
			"ECOM26_84CI_003"
		],
		"336": [
			"Default",
			"ECOM26_84_A4_001",
			"ECOM26_84_001",
			"ECOM_TC_A4",
			"ECOM26_A6_001",
			"ECOM26_84CI_001",
			"ECOM_A4_RU_002",
			"ECOM26_A6_002",
			"ECOM26_84CI_002",
			"ECOM26_84CI_003"
		],
		"337": [
			"Default",
			"ECOM26_84_A4_001",
			"ECOM26_84_001",
			"ECOM_TC_A4",
			"ECOM26_A6_001",
			"ECOM26_84CI_001",
			"ECOM_A4_RU_002",
			"ECOM26_A6_002",
			"ECOM26_84CI_002",
			"ECOM26_84CI_003"
		],
		"338": [
			"Default",
			"ECOM26_84_A4_001",
			"ECOM26_84_001",
			"ECOM_TC_A4",
			"ECOM26_A6_001",
			"ECOM26_84CI_001",
			"ECOM_A4_RU_002",
			"ECOM26_A6_002",
			"ECOM26_84CI_002",
			"ECOM26_84CI_003"
		],
		"355": [
			"Default"
		],
		"356": [
			"Default"
		],
		"358": [
			"Default"
		],
		"431": [
			"Default",
			"OldDefault",
			"OldDualBarcode",
			"QRCode"
		],
		"432": [
			"Default",
			"OldDefault",
			"OldDualBarcode",
			"QRCode"
		],
		"433": [],
		"434": []
	},
	"accessUrl": [
		"/requests_log",
		"/requests_log/details/",
		"/healthcheck/getData",
		"/healthcheck",
		"/healthcheck/detail",
		"/barcode_ranges"
	],
	"currentUser": {
		"id": 2,
		"login": "admin",
		"canManageUsers": true,
		"isSuperAdmin": true,
		"accessLevel": {
			"superadmin": "System superadmin",
			"api": "Send API requests",
			"control_panel": "Access to control panel",
			"users": "Manage users within a group",
			"all_users": "Manage all users in the system"
		}
	}
};


/*--------------------
 Reports Page (Dashboard)
 --------------------------*/

// export const localReportsData = {
// 	columns: {
// 		'1': 'ir_group_admin',
// 		'382': 'test'
// 	},
// 	report_data: {
// 		'1': 7347,
// 		'382': 3567
// 	},
// 	total: 10914,
// 	group_by: 'user_id',
// 	group_by_list: {
// 		day: 'Day',
// 		user_id: 'User',
// 		service_id: 'Service',
// 		destination_country: 'Destination country',
// 		sender_country: 'Sender country'
// 	}
// };

/*--------------------
 ParcelsPage Page
 --------------------------*/



/*--------------------
 PalletsPage Page
 --------------------------*/

/*--------------------
 Requests log Page
 --------------------------*/

export const localRequestLogData = {
	requests: [
		{
			id: 140763,
			request_id: '2031c6vg3xeiopop',
			httpResponseCode: 500,
			requestTime: 1604591201,
			innerTime: 0.093,
			outerTime: 0.293,
			ip: '217.73.86.51',
			country: 'UA',
			execTime: 0.386,
			host: 'api.qa.shippingapidev.com',
			requestMethod: 'POST',
			uri: '/parcels',
			userId: 1,
			healthCheck: null,
			healthCheckIds: {
				'76372': 'Colissimo Return Collection Dates',
				'76373': 'Colissimo Return Booking'
			}
		},
		{
			id: 140689,
			request_id: '2031c6vfw6r7knu6',
			httpResponseCode: 500,
			requestTime: 1604590595,
			innerTime: 0.098,
			outerTime: 0.55,
			ip: '217.73.86.51',
			country: 'UA',
			execTime: 0.648,
			host: 'api.qa.shippingapidev.com',
			requestMethod: 'POST',
			uri: '/parcels',
			userId: 1,
			healthCheck: null,
			healthCheckIds: {
				'76366': 'Colissimo Return Collection Dates',
				'76367': 'Colissimo Return Booking'
			}
		},
		{
			id: 140672,
			request_id: '2031c6vfu6r0q8uo',
			httpResponseCode: 500,
			requestTime: 1604590438,
			innerTime: 0.086,
			outerTime: 0.294,
			ip: '217.73.86.51',
			country: 'UA',
			execTime: 0.38,
			host: 'api.qa.shippingapidev.com',
			requestMethod: 'POST',
			uri: '/parcels',
			userId: 1,
			healthCheck: null,
			healthCheckIds: {
				'76364': 'Colissimo Return Collection Dates',
				'76365': 'Colissimo Return Booking'
			}
		},
		{
			id: 138934,
			request_id: '2031c6veg5xjanva',
			httpResponseCode: 500,
			requestTime: 1604586518,
			innerTime: 0.189,
			outerTime: 0.28,
			ip: '217.73.86.51',
			country: 'UA',
			execTime: 0.469,
			host: 'api.qa.shippingapidev.com',
			requestMethod: 'POST',
			uri: '/parcels',
			userId: 1,
			healthCheck: null,
			healthCheckIds: {
				'75196': 'Colissimo Return Collection Dates',
				'75197': 'Colissimo Return Booking'
			}
		},
		{
			id: 138933,
			request_id: '2031c6veg5ovgvia',
			httpResponseCode: 500,
			requestTime: 1604586517,
			innerTime: 0.076,
			outerTime: 0.255,
			ip: '217.73.86.51',
			country: 'UA',
			execTime: 0.331,
			host: 'api.qa.shippingapidev.com',
			requestMethod: 'POST',
			uri: '/parcels',
			userId: 1,
			healthCheck: null,
			healthCheckIds: {
				'75194': 'Colissimo Return Collection Dates',
				'75195': 'Colissimo Return Booking'
			}
		},
		{
			id: 138930,
			request_id: '2031c6veg5fnoiju',
			httpResponseCode: 500,
			requestTime: 1604586517,
			innerTime: 0.139,
			outerTime: 0.268,
			ip: '217.73.86.51',
			country: 'UA',
			execTime: 0.407,
			host: 'api.qa.shippingapidev.com',
			requestMethod: 'POST',
			uri: '/parcels',
			userId: 1,
			healthCheck: null,
			healthCheckIds: {
				'75192': 'Colissimo Return Collection Dates',
				'75193': 'Colissimo Return Booking'
			}
		},
		{
			id: 138924,
			request_id: '2031c6veg508nxac',
			httpResponseCode: 500,
			requestTime: 1604586516,
			innerTime: 0.079,
			outerTime: 0.26,
			ip: '217.73.86.51',
			country: 'UA',
			execTime: 0.339,
			host: 'api.qa.shippingapidev.com',
			requestMethod: 'POST',
			uri: '/parcels',
			userId: 1,
			healthCheck: null,
			healthCheckIds: {
				'75189': 'Colissimo Return Collection Dates',
				'75190': 'Colissimo Return Booking'
			}
		},
		{
			id: 138922,
			request_id: '2031c6veg4m9p6cb',
			httpResponseCode: 500,
			requestTime: 1604586515,
			innerTime: 0.341,
			outerTime: 0.341,
			ip: '217.73.86.51',
			country: 'UA',
			execTime: 0.682,
			host: 'api.qa.shippingapidev.com',
			requestMethod: 'POST',
			uri: '/parcels',
			userId: 1,
			healthCheck: null,
			healthCheckIds: {
				'75187': 'Colissimo Return Collection Dates',
				'75188': 'Colissimo Return Booking'
			}
		},
		{
			id: 138919,
			request_id: '2031c6veg47q9dme',
			httpResponseCode: 500,
			requestTime: 1604586514,
			innerTime: 0.109,
			outerTime: 0.238,
			ip: '217.73.86.51',
			country: 'UA',
			execTime: 0.347,
			host: 'api.qa.shippingapidev.com',
			requestMethod: 'POST',
			uri: '/parcels',
			userId: 1,
			healthCheck: null,
			healthCheckIds: {
				'75185': 'Colissimo Return Collection Dates',
				'75186': 'Colissimo Return Booking'
			}
		},
		{
			id: 138918,
			request_id: '2031c6veg3xidbz3',
			httpResponseCode: 500,
			requestTime: 1604586513,
			innerTime: 0.167,
			outerTime: 0.298,
			ip: '217.73.86.51',
			country: 'UA',
			execTime: 0.465,
			host: 'api.qa.shippingapidev.com',
			requestMethod: 'POST',
			uri: '/parcels',
			userId: 1,
			healthCheck: null,
			healthCheckIds: {
				'75183': 'Colissimo Return Collection Dates',
				'75184': 'Colissimo Return Booking'
			}
		},
		{
			id: 138915,
			request_id: '2031c6veg3pv6c5t',
			httpResponseCode: 500,
			requestTime: 1604586513,
			innerTime: 0.081,
			outerTime: 0.234,
			ip: '217.73.86.51',
			country: 'UA',
			execTime: 0.315,
			host: 'api.qa.shippingapidev.com',
			requestMethod: 'POST',
			uri: '/parcels',
			userId: 1,
			healthCheck: null,
			healthCheckIds: {
				'75181': 'Colissimo Return Collection Dates',
				'75182': 'Colissimo Return Booking'
			}
		},
		{
			id: 138914,
			request_id: '2031c6veg3h6805t',
			httpResponseCode: 500,
			requestTime: 1604586512,
			innerTime: 0.086,
			outerTime: 0.288,
			ip: '217.73.86.51',
			country: 'UA',
			execTime: 0.374,
			host: 'api.qa.shippingapidev.com',
			requestMethod: 'POST',
			uri: '/parcels',
			userId: 1,
			healthCheck: null,
			healthCheckIds: {
				'75179': 'Colissimo Return Collection Dates',
				'75180': 'Colissimo Return Booking'
			}
		},
		{
			id: 138911,
			request_id: '2031c6veg374ymrx',
			httpResponseCode: 500,
			requestTime: 1604586512,
			innerTime: 0.214,
			outerTime: 0.254,
			ip: '217.73.86.51',
			country: 'UA',
			execTime: 0.468,
			host: 'api.qa.shippingapidev.com',
			requestMethod: 'POST',
			uri: '/parcels',
			userId: 1,
			healthCheck: null,
			healthCheckIds: {
				'75177': 'Colissimo Return Collection Dates',
				'75178': 'Colissimo Return Booking'
			}
		},
		{
			id: 138910,
			request_id: '2031c6veg2yjrfdz',
			httpResponseCode: 500,
			requestTime: 1604586511,
			innerTime: 0.097,
			outerTime: 0.266,
			ip: '217.73.86.51',
			country: 'UA',
			execTime: 0.363,
			host: 'api.qa.shippingapidev.com',
			requestMethod: 'POST',
			uri: '/parcels',
			userId: 1,
			healthCheck: null,
			healthCheckIds: {
				'75175': 'Colissimo Return Collection Dates',
				'75176': 'Colissimo Return Booking'
			}
		},
		{
			id: 138908,
			request_id: '2031c6veg2q708tb',
			httpResponseCode: 500,
			requestTime: 1604586511,
			innerTime: 0.072,
			outerTime: 0.275,
			ip: '217.73.86.51',
			country: 'UA',
			execTime: 0.347,
			host: 'api.qa.shippingapidev.com',
			requestMethod: 'POST',
			uri: '/parcels',
			userId: 1,
			healthCheck: null,
			healthCheckIds: {
				'75173': 'Colissimo Return Collection Dates',
				'75174': 'Colissimo Return Booking'
			}
		},
		{
			id: 138905,
			request_id: '2031c6veg2i2u05i',
			httpResponseCode: 500,
			requestTime: 1604586510,
			innerTime: 0.085,
			outerTime: 0.271,
			ip: '217.73.86.51',
			country: 'UA',
			execTime: 0.356,
			host: 'api.qa.shippingapidev.com',
			requestMethod: 'POST',
			uri: '/parcels',
			userId: 1,
			healthCheck: null,
			healthCheckIds: {
				'75170': 'Colissimo Return Collection Dates',
				'75171': 'Colissimo Return Booking'
			}
		},
		{
			id: 138903,
			request_id: '2031c6veg2admft1',
			httpResponseCode: 500,
			requestTime: 1604586510,
			innerTime: 0.088,
			outerTime: 0.232,
			ip: '217.73.86.51',
			country: 'UA',
			execTime: 0.32,
			host: 'api.qa.shippingapidev.com',
			requestMethod: 'POST',
			uri: '/parcels',
			userId: 1,
			healthCheck: null,
			healthCheckIds: {
				'75167': 'Colissimo Return Collection Dates',
				'75168': 'Colissimo Return Booking'
			}
		},
		{
			id: 138902,
			request_id: '2031c6veg221wfwa',
			httpResponseCode: 500,
			requestTime: 1604586509,
			innerTime: 0.075,
			outerTime: 0.286,
			ip: '217.73.86.51',
			country: 'UA',
			execTime: 0.361,
			host: 'api.qa.shippingapidev.com',
			requestMethod: 'POST',
			uri: '/parcels',
			userId: 1,
			healthCheck: null,
			healthCheckIds: {
				'75163': 'Colissimo Return Collection Dates',
				'75164': 'Colissimo Return Booking'
			}
		},
		{
			id: 138899,
			request_id: '2031c6veg1u6uw9x',
			httpResponseCode: 500,
			requestTime: 1604586509,
			innerTime: 0.082,
			outerTime: 0.241,
			ip: '217.73.86.51',
			country: 'UA',
			execTime: 0.323,
			host: 'api.qa.shippingapidev.com',
			requestMethod: 'POST',
			uri: '/parcels',
			userId: 1,
			healthCheck: null,
			healthCheckIds: {
				'75159': 'Colissimo Return Collection Dates',
				'75160': 'Colissimo Return Booking'
			}
		},
		{
			id: 138897,
			request_id: '2031c6veg1lewlr5',
			httpResponseCode: 500,
			requestTime: 1604586508,
			innerTime: 0.088,
			outerTime: 0.283,
			ip: '217.73.86.51',
			country: 'UA',
			execTime: 0.371,
			host: 'api.qa.shippingapidev.com',
			requestMethod: 'POST',
			uri: '/parcels',
			userId: 1,
			healthCheck: null,
			healthCheckIds: {
				'75156': 'Colissimo Return Collection Dates',
				'75157': 'Colissimo Return Booking'
			}
		},
		{
			id: 138894,
			request_id: '2031c6veg164ve4l',
			httpResponseCode: 500,
			requestTime: 1604586507,
			innerTime: 0.133,
			outerTime: 0.275,
			ip: '217.73.86.51',
			country: 'UA',
			execTime: 0.408,
			host: 'api.qa.shippingapidev.com',
			requestMethod: 'POST',
			uri: '/parcels',
			userId: 1,
			healthCheck: null,
			healthCheckIds: {
				'75152': 'Colissimo Return Collection Dates',
				'75153': 'Colissimo Return Booking'
			}
		},
		{
			id: 138890,
			request_id: '2031c6veg0xjzodu',
			httpResponseCode: 500,
			requestTime: 1604586507,
			innerTime: 0.085,
			outerTime: 0.298,
			ip: '217.73.86.51',
			country: 'UA',
			execTime: 0.383,
			host: 'api.qa.shippingapidev.com',
			requestMethod: 'POST',
			uri: '/parcels',
			userId: 1,
			healthCheck: null,
			healthCheckIds: {
				'75149': 'Colissimo Return Collection Dates',
				'75151': 'Colissimo Return Booking'
			}
		},
		{
			id: 138888,
			request_id: '2031c6veg0p4r96b',
			httpResponseCode: 500,
			requestTime: 1604586506,
			innerTime: 0.092,
			outerTime: 0.247,
			ip: '217.73.86.51',
			country: 'UA',
			execTime: 0.339,
			host: 'api.qa.shippingapidev.com',
			requestMethod: 'POST',
			uri: '/parcels',
			userId: 1,
			healthCheck: null,
			healthCheckIds: {
				'75147': 'Colissimo Return Collection Dates',
				'75148': 'Colissimo Return Booking'
			}
		},
		{
			id: 138886,
			request_id: '2031c6veg0ef1t88',
			httpResponseCode: 500,
			requestTime: 1604586506,
			innerTime: 0.279,
			outerTime: 0.213,
			ip: '217.73.86.51',
			country: 'UA',
			execTime: 0.492,
			host: 'api.qa.shippingapidev.com',
			requestMethod: 'POST',
			uri: '/parcels',
			userId: 1,
			healthCheck: null,
			healthCheckIds: {
				'75144': 'Colissimo Return Collection Dates',
				'75145': 'Colissimo Return Booking'
			}
		},
		{
			id: 138883,
			request_id: '2031c6veg02arsj6',
			httpResponseCode: 500,
			requestTime: 1604586505,
			innerTime: 0.153,
			outerTime: 0.233,
			ip: '217.73.86.51',
			country: 'UA',
			execTime: 0.386,
			host: 'api.qa.shippingapidev.com',
			requestMethod: 'POST',
			uri: '/parcels',
			userId: 1,
			healthCheck: null,
			healthCheckIds: {
				'75141': 'Colissimo Return Collection Dates',
				'75143': 'Colissimo Return Booking'
			}
		},
		{
			id: 138881,
			request_id: '2031c6vefzuozrp9',
			httpResponseCode: 500,
			requestTime: 1604586504,
			innerTime: 0.074,
			outerTime: 0.237,
			ip: '217.73.86.51',
			country: 'UA',
			execTime: 0.311,
			host: 'api.qa.shippingapidev.com',
			requestMethod: 'POST',
			uri: '/parcels',
			userId: 1,
			healthCheck: null,
			healthCheckIds: {
				'75139': 'Colissimo Return Collection Dates',
				'75140': 'Colissimo Return Booking'
			}
		},
		{
			id: 138878,
			request_id: '2031c6vefzjqxc99',
			httpResponseCode: 500,
			requestTime: 1604586504,
			innerTime: 0.151,
			outerTime: 0.336,
			ip: '217.73.86.51',
			country: 'UA',
			execTime: 0.487,
			host: 'api.qa.shippingapidev.com',
			requestMethod: 'POST',
			uri: '/parcels',
			userId: 1,
			healthCheck: null,
			healthCheckIds: {
				'75136': 'Colissimo Return Collection Dates',
				'75137': 'Colissimo Return Booking'
			}
		},
		{
			id: 138874,
			request_id: '2031c6vefz43ffb8',
			httpResponseCode: 500,
			requestTime: 1604586503,
			innerTime: 0.074,
			outerTime: 0.236,
			ip: '217.73.86.51',
			country: 'UA',
			execTime: 0.31,
			host: 'api.qa.shippingapidev.com',
			requestMethod: 'POST',
			uri: '/parcels',
			userId: 1,
			healthCheck: null,
			healthCheckIds: {
				'75133': 'Colissimo Return Collection Dates',
				'75134': 'Colissimo Return Booking'
			}
		},
		{
			id: 138872,
			request_id: '2031c6vefytwhul6',
			httpResponseCode: 500,
			requestTime: 1604586502,
			innerTime: 0.192,
			outerTime: 0.278,
			ip: '217.73.86.51',
			country: 'UA',
			execTime: 0.47,
			host: 'api.qa.shippingapidev.com',
			requestMethod: 'POST',
			uri: '/parcels',
			userId: 1,
			healthCheck: null,
			healthCheckIds: {
				'75130': 'Colissimo Return Collection Dates',
				'75132': 'Colissimo Return Booking'
			}
		},
		{
			id: 138870,
			request_id: '2031c6vefylk0lv1',
			httpResponseCode: 500,
			requestTime: 1604586502,
			innerTime: 0.086,
			outerTime: 0.24,
			ip: '217.73.86.51',
			country: 'UA',
			execTime: 0.326,
			host: 'api.qa.shippingapidev.com',
			requestMethod: 'POST',
			uri: '/parcels',
			userId: 1,
			healthCheck: null,
			healthCheckIds: {
				'75128': 'Colissimo Return Collection Dates',
				'75129': 'Colissimo Return Booking'
			}
		},
		{
			id: 138867,
			request_id: '2031c6vefyddmx88',
			httpResponseCode: 500,
			requestTime: 1604586501,
			innerTime: 0.097,
			outerTime: 0.261,
			ip: '217.73.86.51',
			country: 'UA',
			execTime: 0.358,
			host: 'api.qa.shippingapidev.com',
			requestMethod: 'POST',
			uri: '/parcels',
			userId: 1,
			healthCheck: null,
			healthCheckIds: {
				'75125': 'Colissimo Return Collection Dates',
				'75127': 'Colissimo Return Booking'
			}
		},
		{
			id: 138865,
			request_id: '2031c6vefy44x1q7',
			httpResponseCode: 500,
			requestTime: 1604586500,
			innerTime: 0.085,
			outerTime: 0.24,
			ip: '217.73.86.51',
			country: 'UA',
			execTime: 0.325,
			host: 'api.qa.shippingapidev.com',
			requestMethod: 'POST',
			uri: '/parcels',
			userId: 1,
			healthCheck: null,
			healthCheckIds: {
				'75123': 'Colissimo Return Collection Dates',
				'75124': 'Colissimo Return Booking'
			}
		}
	],
	total: 0,
	uriList: [
		'/direct_webhook_tracking',
		'/health_check/details',
		'/health_check/request_and_response',
		'/health_check',
		'/pallets',
		'/parcels/bulk',
		'/parcels',
		'/pudo',
		'/return_webhook_tracking',
		'/service',
		'/services/credentials',
		'/services/courier_dates',
		'/services',
		'/tracking/by_date_range',
		'/tracking'
	],
	codesList: [
		'200',
		'400',
		'401',
		'403',
		'404',
		'406',
		'500'
	],
	hasMoreItems: true
};

export const localRequestLogDetails = {
	request: {
		method: 'GET',
		header: {
			Connection: 'Keep-Alive',
			'X-Forwarded-Proto': 'https',
			'X-Forwarded-For': '104.155.112.32, 34.107.244.242',
			Via: '1.1 google',
			'X-Cloud-Trace-Context': '4797e9e0afc8db6d63b7345caea83495/13100123571316251454',
			Host: 'api.shippingapilive.com',
			Accept: '*/*',
			'Content-Length': '',
			'Content-Type': ''
		},
		body: {
			service_ids: {
				'0': '1',
				'1': '2',
				'3': '167'
			},
			is_test: '0',
			date_from: '2020-12-23 19:19:22',
			date_to: '2020-12-23 19:20:01',
			page: '1'
		}
	},
	response: {
		code: 200,
		header: [],
		body: {
			items: [],
			total_pages: 1
		}
	},
	requestTime: 1608751285,
	execTime: 0.013,
	innerTime: 0.013,
	outerTime: 0,
	host: 'api.shippingapilive.com',
	uri: '/tracking/by_date_range',
	ip: '104.155.112.32',
	userId: 1,
	memoryUsage: 2097152,
	services: {
		'0': '1',
		'1': '2',
		'2': '3',
		'3': '4',
		'4': '5',
		'5': '6',
		'6': '7',
		'7': '8',
		'8': '9',
		'9': '10',
		'10': '11',
		'11': '12',
		'12': '13',
		'13': '14',
		'14': '15',
		'15': '16'
	},
	requestId: '3205c80eqhz0nqn8',
	healthCheckIds: []
};

/*--------------------
 Services Page
 --------------------------*/

/*--------------------
 Users Page
 --------------------------*/

/*--------------------
 Tracking Mapping Page
 --------------------------*/

/*--------------------
 Health Check Page
 --------------------------*/

export const localsHealthStatusesList = [
	{
		label: 'ACP Return Tracking',
		additional_label: '',
		periods: {
			'3600': {
				total: 7,
				failed: 0,
				avg: 233,
				rate: 100
			},
			'28800': {
				total: 28,
				failed: 0,
				avg: 355,
				rate: 100
			},
			'86400': {
				total: 799,
				failed: 1,
				avg: 187,
				rate: 99.87
			}
		}
	},
	{
		label: 'AMS Direct Booking',
		additional_label: '',
		periods: {
			'600': {
				total: 1,
				failed: 0,
				avg: 4767,
				rate: 100
			},
			'3600': {
				total: 20,
				failed: 1,
				avg: 19587,
				rate: 95
			},
			'28800': {
				total: 30,
				failed: 2,
				avg: 19084,
				rate: 93.33
			},
			'86400': {
				total: 54,
				failed: 4,
				avg: 12994,
				rate: 92.59
			}
		}
	},
	{
		label: 'AMS Direct Tracking',
		additional_label: '',
		periods: {
			'3600': {
				total: 2,
				failed: 0,
				avg: 0,
				rate: 100
			},
			'28800': {
				total: 16,
				failed: 1,
				avg: 0,
				rate: 93.75
			},
			'86400': {
				total: 48,
				failed: 1,
				avg: 0,
				rate: 97.92
			}
		}
	},
	{
		label: 'AMS Pallet Booking',
		additional_label: '',
		periods: {
			'28800': {
				total: 1,
				failed: 0,
				avg: 0,
				rate: 100
			},
			'86400': {
				total: 1,
				failed: 0,
				avg: 0,
				rate: 100
			}
		}
	},
	{
		label: 'AnPost Return Booking',
		additional_label: '',
		periods: {
			'28800': {
				total: 1,
				failed: 0,
				avg: 0,
				rate: 100
			},
			'86400': {
				total: 9,
				failed: 0,
				avg: 0,
				rate: 100
			}
		}
	},
	{
		label: 'AnPost Return Tracking',
		additional_label: '',
		periods: {
			'3600': {
				total: 1,
				failed: 0,
				avg: 0,
				rate: 100
			},
			'28800': {
				total: 4,
				failed: 0,
				avg: 0,
				rate: 100
			},
			'86400': {
				total: 12,
				failed: 0,
				avg: 0,
				rate: 100
			}
		}
	},
	{
		label: 'Aramex Return Booking',
		additional_label: '',
		periods: {
			'600': {
				total: 36,
				failed: 0,
				avg: 852,
				rate: 100
			},
			'3600': {
				total: 222,
				failed: 0,
				avg: 860,
				rate: 100
			},
			'28800': {
				total: 1943,
				failed: 0,
				avg: 751,
				rate: 100
			},
			'86400': {
				total: 3730,
				failed: 0,
				avg: 742,
				rate: 100
			}
		}
	},
	{
		label: 'Aramex Return Pudo',
		additional_label: '',
		periods: {
			'28800': {
				total: 405,
				failed: 1,
				avg: 3392,
				rate: 99.75
			},
			'86400': {
				total: 405,
				failed: 1,
				avg: 3392,
				rate: 99.75
			}
		}
	},
	{
		label: 'Aramex Return Tracking',
		additional_label: '',
		periods: {
			'3600': {
				total: 1,
				failed: 0,
				avg: 0,
				rate: 100
			},
			'28800': {
				total: 8,
				failed: 0,
				avg: 0,
				rate: 100
			},
			'86400': {
				total: 24,
				failed: 0,
				avg: 0,
				rate: 100
			}
		}
	},
	{
		label: 'AsendiaWND Direct Booking',
		additional_label: '',
		periods: {
			'3600': {
				total: 2,
				failed: 0,
				avg: 0,
				rate: 100
			},
			'28800': {
				total: 2,
				failed: 0,
				avg: 0,
				rate: 100
			},
			'86400': {
				total: 3,
				failed: 0,
				avg: 0,
				rate: 100
			}
		}
	},
	{
		label: 'AsendiaWND Direct Tracking',
		additional_label: '',
		periods: {
			'3600': {
				total: 1,
				failed: 0,
				avg: 0,
				rate: 100
			},
			'28800': {
				total: 4,
				failed: 0,
				avg: 0,
				rate: 100
			},
			'86400': {
				total: 12,
				failed: 0,
				avg: 0,
				rate: 100
			}
		}
	},
	{
		label: 'AustrianPost Return Pudo',
		additional_label: '',
		periods: {
			'28800': {
				total: 1,
				failed: 0,
				avg: 2948,
				rate: 100
			},
			'86400': {
				total: 1,
				failed: 0,
				avg: 2948,
				rate: 100
			}
		}
	},
	{
		label: 'BoxBerry Return Booking',
		additional_label: '',
		periods: {
			'600': {
				total: 14,
				failed: 0,
				avg: 187,
				rate: 100
			},
			'3600': {
				total: 88,
				failed: 0,
				avg: 188,
				rate: 100
			},
			'28800': {
				total: 321,
				failed: 0,
				avg: 200,
				rate: 100
			},
			'86400': {
				total: 1051,
				failed: 0,
				avg: 200,
				rate: 100
			}
		}
	},
	{
		label: 'BoxBerry Return Tracking',
		additional_label: '',
		periods: {
			'600': {
				total: 6139,
				failed: 2,
				avg: 55,
				rate: 99.97
			},
			'3600': {
				total: 25400,
				failed: 20,
				avg: 56,
				rate: 99.92
			},
			'28800': {
				total: 117560,
				failed: 88,
				avg: 54,
				rate: 99.93
			},
			'86400': {
				total: 367381,
				failed: 321,
				avg: 55,
				rate: 99.91
			}
		}
	},
	{
		label: 'BoxIt Return Pudo',
		additional_label: '',
		periods: {
			'28800': {
				total: 2,
				failed: 0,
				avg: 2302,
				rate: 100
			},
			'86400': {
				total: 2,
				failed: 0,
				avg: 2302,
				rate: 100
			}
		}
	},
	{
		label: 'BulgariaPost Return Pudo',
		additional_label: '',
		periods: {
			'28800': {
				total: 1,
				failed: 0,
				avg: 1644,
				rate: 100
			},
			'86400': {
				total: 1,
				failed: 0,
				avg: 1644,
				rate: 100
			}
		}
	},
	{
		label: 'CanadaPost Direct Tracking',
		additional_label: '',
		periods: {
			'3600': {
				total: 4,
				failed: 0,
				avg: 0,
				rate: 100
			},
			'28800': {
				total: 32,
				failed: 0,
				avg: 0,
				rate: 100
			},
			'86400': {
				total: 96,
				failed: 0,
				avg: 0,
				rate: 100
			}
		}
	},
	{
		label: 'CanadaPost Return Tracking',
		additional_label: '',
		periods: {
			'3600': {
				total: 1,
				failed: 0,
				avg: 0,
				rate: 100
			},
			'28800': {
				total: 4,
				failed: 0,
				avg: 0,
				rate: 100
			},
			'86400': {
				total: 12,
				failed: 0,
				avg: 0,
				rate: 100
			}
		}
	},
	{
		label: 'CDEK Direct Pudo',
		additional_label: '',
		periods: {
			'28800': {
				total: 1,
				failed: 0,
				avg: 1148,
				rate: 100
			},
			'86400': {
				total: 1,
				failed: 0,
				avg: 1148,
				rate: 100
			}
		}
	},
	{
		label: 'CDEK Return Booking',
		additional_label: '',
		periods: {
			'600': {
				total: 10,
				failed: 0,
				avg: 1887,
				rate: 100
			},
			'3600': {
				total: 49,
				failed: 1,
				avg: 1857,
				rate: 97.96
			},
			'28800': {
				total: 193,
				failed: 1,
				avg: 1448,
				rate: 99.48
			},
			'86400': {
				total: 572,
				failed: 1,
				avg: 1161,
				rate: 99.83
			}
		}
	},
	{
		label: 'CDEK Return Pudo',
		additional_label: '',
		periods: {
			'28800': {
				total: 1,
				failed: 0,
				avg: 1278,
				rate: 100
			},
			'86400': {
				total: 1,
				failed: 0,
				avg: 1278,
				rate: 100
			}
		}
	},
	{
		label: 'CDEK Return Tracking',
		additional_label: '',
		periods: {
			'600': {
				total: 2389,
				failed: 0,
				avg: 133,
				rate: 100
			},
			'3600': {
				total: 10277,
				failed: 6,
				avg: 136,
				rate: 99.94
			},
			'28800': {
				total: 42594,
				failed: 25,
				avg: 133,
				rate: 99.94
			},
			'86400': {
				total: 137836,
				failed: 94,
				avg: 131,
				rate: 99.93
			}
		}
	},
	{
		label: 'Celeritas Return Booking',
		additional_label: '',
		periods: {
			'600': {
				total: 3,
				failed: 0,
				avg: 577,
				rate: 100
			},
			'3600': {
				total: 12,
				failed: 0,
				avg: 623,
				rate: 100
			},
			'28800': {
				total: 41,
				failed: 0,
				avg: 610,
				rate: 100
			},
			'86400': {
				total: 140,
				failed: 1,
				avg: 654,
				rate: 99.29
			}
		}
	},
	{
		label: 'Celeritas Return Pudo',
		additional_label: '',
		periods: {
			'28800': {
				total: 1,
				failed: 0,
				avg: 0,
				rate: 100
			},
			'86400': {
				total: 1,
				failed: 0,
				avg: 0,
				rate: 100
			}
		}
	},
	{
		label: 'Celeritas Return Tracking',
		additional_label: '',
		periods: {
			'3600': {
				total: 3646,
				failed: 0,
				avg: 184,
				rate: 100
			},
			'28800': {
				total: 14494,
				failed: 0,
				avg: 170,
				rate: 100
			},
			'86400': {
				total: 50700,
				failed: 0,
				avg: 164,
				rate: 100
			}
		}
	},
	{
		label: 'CitiLogistics Direct Booking',
		additional_label: '',
		periods: {
			'86400': {
				total: 80,
				failed: 5,
				avg: 2398,
				rate: 93.75
			}
		}
	},
	{
		label: 'CitiLogistics Direct Manifest',
		additional_label: ''
	},
	{
		label: 'CitiLogistics Direct Tracking',
		additional_label: '',
		periods: {
			'3600': {
				total: 2875,
				failed: 1,
				avg: 174,
				rate: 99.97
			},
			'28800': {
				total: 11500,
				failed: 1,
				avg: 171,
				rate: 99.99
			},
			'86400': {
				total: 35030,
				failed: 1,
				avg: 171,
				rate: 100
			}
		}
	},
	{
		label: 'CitiLogistics Return Tracking',
		additional_label: '',
		periods: {
			'3600': {
				total: 1,
				failed: 0,
				avg: 0,
				rate: 100
			},
			'28800': {
				total: 4,
				failed: 0,
				avg: 0,
				rate: 100
			},
			'86400': {
				total: 12,
				failed: 0,
				avg: 0,
				rate: 100
			}
		}
	},
	{
		label: 'ColisPrive Direct Booking',
		additional_label: '',
		periods: {
			'600': {
				total: 81,
				failed: 0,
				avg: 0,
				rate: 100
			},
			'3600': {
				total: 431,
				failed: 0,
				avg: 0,
				rate: 100
			},
			'28800': {
				total: 1390,
				failed: 0,
				avg: 0,
				rate: 100
			},
			'86400': {
				total: 2207,
				failed: 0,
				avg: 0,
				rate: 100
			}
		}
	},
	{
		label: 'ColisPrive Direct Manifest',
		additional_label: '',
		periods: {
			'600': {
				total: 23,
				failed: 0,
				avg: 0,
				rate: 100
			},
			'3600': {
				total: 42,
				failed: 0,
				avg: 0,
				rate: 100
			},
			'28800': {
				total: 72,
				failed: 0,
				avg: 0,
				rate: 100
			},
			'86400': {
				total: 123,
				failed: 0,
				avg: 0,
				rate: 100
			}
		}
	},
	{
		label: 'ColisPrive Direct Tracking',
		additional_label: '',
		periods: {
			'3600': {
				total: 3,
				failed: 0,
				avg: 0,
				rate: 100
			},
			'28800': {
				total: 24,
				failed: 0,
				avg: 0,
				rate: 100
			},
			'86400': {
				total: 72,
				failed: 0,
				avg: 0,
				rate: 100
			}
		}
	},
	{
		label: 'Colissimo Return Booking',
		additional_label: '',
		periods: {
			'600': {
				total: 39,
				failed: 0,
				avg: 64,
				rate: 100
			},
			'3600': {
				total: 218,
				failed: 0,
				avg: 22,
				rate: 100
			},
			'28800': {
				total: 571,
				failed: 0,
				avg: 17,
				rate: 100
			},
			'86400': {
				total: 2461,
				failed: 0,
				avg: 14,
				rate: 100
			}
		}
	},
	{
		label: 'Colissimo Return Collection Dates',
		additional_label: '',
		periods: {
			'600': {
				total: 18,
				failed: 0,
				avg: 165,
				rate: 100
			},
			'3600': {
				total: 46,
				failed: 0,
				avg: 175,
				rate: 100
			},
			'28800': {
				total: 103,
				failed: 0,
				avg: 176,
				rate: 100
			},
			'86400': {
				total: 445,
				failed: 0,
				avg: 178,
				rate: 100
			}
		}
	},
	{
		label: 'Colissimo Return Manifest',
		additional_label: '',
		periods: {
			'86400': {
				total: 31,
				failed: 11,
				avg: 0,
				rate: 64.52
			}
		}
	},
	{
		label: 'Colissimo Return Pudo',
		additional_label: '',
		periods: {
			'28800': {
				total: 1,
				failed: 0,
				avg: 0,
				rate: 100
			},
			'86400': {
				total: 1,
				failed: 0,
				avg: 0,
				rate: 100
			}
		}
	},
	{
		label: 'Colissimo Return Robots',
		additional_label: '',
		periods: {
			'86400': {
				total: 2,
				failed: 0,
				avg: 0,
				rate: 100
			}
		}
	},
	{
		label: 'Colissimo Return Tracking',
		additional_label: '',
		periods: {
			'3600': {
				total: 4,
				failed: 0,
				avg: 0,
				rate: 100
			},
			'28800': {
				total: 16,
				failed: 0,
				avg: 0,
				rate: 100
			},
			'86400': {
				total: 48,
				failed: 0,
				avg: 0,
				rate: 100
			}
		}
	},
	{
		label: 'CollectPlus Return Booking',
		additional_label: '',
		periods: {
			'600': {
				total: 66,
				failed: 0,
				avg: 426,
				rate: 100
			},
			'3600': {
				total: 441,
				failed: 0,
				avg: 412,
				rate: 100
			},
			'28800': {
				total: 1157,
				failed: 0,
				avg: 412,
				rate: 100
			},
			'86400': {
				total: 7045,
				failed: 0,
				avg: 412,
				rate: 100
			}
		}
	},
	{
		label: 'CollectPlus Return Pudo',
		additional_label: '',
		periods: {
			'600': {
				total: 636,
				failed: 0,
				avg: 338,
				rate: 100
			},
			'3600': {
				total: 3561,
				failed: 0,
				avg: 334,
				rate: 100
			},
			'28800': {
				total: 8285,
				failed: 0,
				avg: 327,
				rate: 100
			},
			'86400': {
				total: 42382,
				failed: 0,
				avg: 334,
				rate: 100
			}
		}
	},
	{
		label: 'CollectPlus Return Tracking',
		additional_label: '',
		periods: {
			'3600': {
				total: 3,
				failed: 0,
				avg: 0,
				rate: 100
			},
			'28800': {
				total: 12,
				failed: 0,
				avg: 0,
				rate: 100
			},
			'86400': {
				total: 36,
				failed: 0,
				avg: 0,
				rate: 100
			}
		}
	},
	{
		label: 'Consignor Return Booking',
		additional_label: '',
		periods: {
			'600': {
				total: 10,
				failed: 0,
				avg: 2024,
				rate: 100
			},
			'3600': {
				total: 38,
				failed: 0,
				avg: 1806,
				rate: 100
			},
			'28800': {
				total: 129,
				failed: 0,
				avg: 1661,
				rate: 100
			},
			'86400': {
				total: 311,
				failed: 0,
				avg: 1821,
				rate: 100
			}
		}
	},
	{
		label: 'Consignor Return Pudo',
		additional_label: ''
	},
	{
		label: 'Consignor Return Tracking',
		additional_label: '',
		periods: {
			'28800': {
				total: 4,
				failed: 0,
				avg: 0,
				rate: 100
			},
			'86400': {
				total: 12,
				failed: 0,
				avg: 0,
				rate: 100
			}
		}
	},
	{
		label: 'CorreosPUDO Direct Booking',
		additional_label: '',
		periods: {
			'600': {
				total: 16,
				failed: 0,
				avg: 0,
				rate: 100
			},
			'3600': {
				total: 166,
				failed: 0,
				avg: 0,
				rate: 100
			},
			'28800': {
				total: 914,
				failed: 0,
				avg: 0,
				rate: 100
			},
			'86400': {
				total: 1469,
				failed: 0,
				avg: 0,
				rate: 100
			}
		}
	},
	{
		label: 'CorreosPUDO Direct Manifest',
		additional_label: '',
		periods: {
			'600': {
				total: 3,
				failed: 0,
				avg: 0,
				rate: 100
			},
			'3600': {
				total: 3,
				failed: 0,
				avg: 0,
				rate: 100
			},
			'28800': {
				total: 11,
				failed: 0,
				avg: 0,
				rate: 100
			},
			'86400': {
				total: 31,
				failed: 0,
				avg: 0,
				rate: 100
			}
		}
	},
	{
		label: 'CorreosPUDO Direct Robots',
		additional_label: '',
		periods: {
			'86400': {
				total: 1,
				failed: 0,
				avg: 0,
				rate: 100
			}
		}
	},
	{
		label: 'CorreosPUDO Direct Tracking',
		additional_label: '',
		periods: {
			'3600': {
				total: 2,
				failed: 0,
				avg: 0,
				rate: 100
			},
			'28800': {
				total: 8,
				failed: 0,
				avg: 0,
				rate: 100
			},
			'86400': {
				total: 24,
				failed: 0,
				avg: 0,
				rate: 100
			}
		}
	},
	{
		label: 'CorreosPUDO Return Booking',
		additional_label: '',
		periods: {
			'600': {
				total: 11,
				failed: 0,
				avg: 1361,
				rate: 100
			},
			'3600': {
				total: 75,
				failed: 0,
				avg: 1443,
				rate: 100
			},
			'28800': {
				total: 184,
				failed: 3,
				avg: 1652,
				rate: 98.37
			},
			'86400': {
				total: 699,
				failed: 10,
				avg: 1505,
				rate: 98.57
			}
		}
	},
	{
		label: 'CorreosPUDO Return Tracking',
		additional_label: '',
		periods: {
			'600': {
				total: 151,
				failed: 0,
				avg: 3599,
				rate: 100
			},
			'3600': {
				total: 2141,
				failed: 1,
				avg: 1624,
				rate: 99.95
			},
			'28800': {
				total: 49267,
				failed: 1,
				avg: 495,
				rate: 100
			},
			'86400': {
				total: 121482,
				failed: 8698,
				avg: 409,
				rate: 92.84
			}
		}
	},
	{
		label: 'CrownLogistic Direct Tracking',
		additional_label: '',
		periods: {
			'86400': {
				total: 240,
				failed: 0,
				avg: 202,
				rate: 100
			}
		}
	},
	{
		label: 'CTT Return Booking',
		additional_label: '',
		periods: {
			'3600': {
				total: 1,
				failed: 0,
				avg: 3345,
				rate: 100
			},
			'28800': {
				total: 1,
				failed: 0,
				avg: 3345,
				rate: 100
			},
			'86400': {
				total: 23,
				failed: 1,
				avg: 3976,
				rate: 95.65
			}
		}
	},
	{
		label: 'CTT Return Pudo',
		additional_label: '',
		periods: {
			'28800': {
				total: 1,
				failed: 0,
				avg: 3307,
				rate: 100
			},
			'86400': {
				total: 1,
				failed: 0,
				avg: 3307,
				rate: 100
			}
		}
	},
	{
		label: 'CTT Return Tracking',
		additional_label: '',
		periods: {
			'600': {
				total: 758,
				failed: 0,
				avg: 80,
				rate: 100
			},
			'3600': {
				total: 756,
				failed: 0,
				avg: 80,
				rate: 100
			},
			'28800': {
				total: 8273,
				failed: 0,
				avg: 90,
				rate: 100
			},
			'86400': {
				total: 17325,
				failed: 0,
				avg: 87,
				rate: 100
			}
		}
	},
	{
		label: 'Currency Update',
		additional_label: '',
		periods: {
			'86400': {
				total: 1,
				failed: 0,
				avg: 342,
				rate: 100
			}
		}
	},
	{
		label: 'DHLConnect Return Booking',
		additional_label: '',
		periods: {
			'600': {
				total: 37,
				failed: 0,
				avg: 830,
				rate: 100
			},
			'3600': {
				total: 208,
				failed: 0,
				avg: 830,
				rate: 100
			},
			'28800': {
				total: 635,
				failed: 0,
				avg: 788,
				rate: 100
			},
			'86400': {
				total: 1953,
				failed: 0,
				avg: 795,
				rate: 100
			}
		}
	},
	{
		label: 'DHLConnect Return Pudo',
		additional_label: '',
		periods: {
			'600': {
				total: 51,
				failed: 1,
				avg: 1463,
				rate: 98.04
			},
			'3600': {
				total: 276,
				failed: 5,
				avg: 1229,
				rate: 98.19
			},
			'28800': {
				total: 900,
				failed: 7,
				avg: 1086,
				rate: 99.22
			},
			'86400': {
				total: 2900,
				failed: 10,
				avg: 973,
				rate: 99.66
			}
		}
	},
	{
		label: 'DHLConnect Return Tracking',
		additional_label: '',
		periods: {
			'600': {
				total: 4510,
				failed: 1,
				avg: 215,
				rate: 99.98
			},
			'3600': {
				total: 27286,
				failed: 4,
				avg: 216,
				rate: 99.99
			},
			'28800': {
				total: 217221,
				failed: 24,
				avg: 209,
				rate: 99.99
			},
			'86400': {
				total: 446319,
				failed: 55,
				avg: 205,
				rate: 99.99
			}
		}
	},
	{
		label: 'DHLDE Return Booking',
		additional_label: '',
		periods: {
			'600': {
				total: 203,
				failed: 0,
				avg: 0,
				rate: 100
			},
			'3600': {
				total: 1307,
				failed: 0,
				avg: 0,
				rate: 100
			},
			'28800': {
				total: 3661,
				failed: 0,
				avg: 0,
				rate: 100
			},
			'86400': {
				total: 12610,
				failed: 0,
				avg: 0,
				rate: 100
			}
		}
	},
	{
		label: 'DHLDE Return Manifest',
		additional_label: '',
		periods: {
			'3600': {
				total: 11,
				failed: 0,
				avg: 0,
				rate: 100
			},
			'28800': {
				total: 54,
				failed: 1,
				avg: 0,
				rate: 98.15
			},
			'86400': {
				total: 192,
				failed: 2,
				avg: 0,
				rate: 98.96
			}
		}
	},
	{
		label: 'DHLDE Return Pudo',
		additional_label: '',
		periods: {
			'600': {
				total: 252,
				failed: 0,
				avg: 376,
				rate: 100
			},
			'3600': {
				total: 1724,
				failed: 14,
				avg: 374,
				rate: 99.19
			},
			'28800': {
				total: 4926,
				failed: 33,
				avg: 393,
				rate: 99.33
			},
			'86400': {
				total: 17723,
				failed: 48,
				avg: 398,
				rate: 99.73
			}
		}
	},
	{
		label: 'DHLDE Return Tracking',
		additional_label: '',
		periods: {
			'600': {
				total: 3,
				failed: 0,
				avg: 0,
				rate: 100
			},
			'3600': {
				total: 3,
				failed: 0,
				avg: 0,
				rate: 100
			},
			'28800': {
				total: 12,
				failed: 0,
				avg: 0,
				rate: 100
			},
			'86400': {
				total: 36,
				failed: 0,
				avg: 0,
				rate: 100
			}
		}
	},
	{
		label: 'DHLExpress Direct Booking',
		additional_label: '',
		periods: {
			'3600': {
				total: 28,
				failed: 0,
				avg: 1470,
				rate: 100
			},
			'28800': {
				total: 157,
				failed: 0,
				avg: 1704,
				rate: 100
			},
			'86400': {
				total: 157,
				failed: 0,
				avg: 1704,
				rate: 100
			}
		}
	},
	{
		label: 'DHLExpress Direct Cancel',
		additional_label: '',
		periods: {
			'28800': {
				total: 1,
				failed: 0,
				avg: 0,
				rate: 100
			},
			'86400': {
				total: 1,
				failed: 0,
				avg: 0,
				rate: 100
			}
		}
	},
	{
		label: 'DHLExpress Direct Tracking',
		additional_label: '',
		periods: {
			'600': {
				total: 45,
				failed: 6,
				avg: 576,
				rate: 86.67
			},
			'3600': {
				total: 43,
				failed: 6,
				avg: 577,
				rate: 86.05
			},
			'28800': {
				total: 17440,
				failed: 26,
				avg: 496,
				rate: 99.85
			},
			'86400': {
				total: 42333,
				failed: 94,
				avg: 487,
				rate: 99.78
			}
		}
	},
	{
		label: 'DHLGME Return Booking',
		additional_label: 'AT',
		periods: {
			'600': {
				total: 2,
				failed: 0,
				avg: 5834,
				rate: 100
			},
			'3600': {
				total: 6,
				failed: 0,
				avg: 6030,
				rate: 100
			},
			'28800': {
				total: 32,
				failed: 15,
				avg: 4557,
				rate: 53.13
			},
			'86400': {
				total: 116,
				failed: 69,
				avg: 3282,
				rate: 40.52
			}
		}
	},
	{
		label: 'DHLGME Return Booking',
		additional_label: 'BE',
		periods: {
			'3600': {
				total: 3,
				failed: 0,
				avg: 6674,
				rate: 100
			},
			'28800': {
				total: 38,
				failed: 19,
				avg: 4419,
				rate: 50
			},
			'86400': {
				total: 132,
				failed: 78,
				avg: 3589,
				rate: 40.91
			}
		}
	},
	{
		label: 'DHLGME Return Booking',
		additional_label: 'BG',
		periods: {
			'3600': {
				total: 1,
				failed: 0,
				avg: 7445,
				rate: 100
			},
			'28800': {
				total: 1,
				failed: 0,
				avg: 7445,
				rate: 100
			},
			'86400': {
				total: 6,
				failed: 1,
				avg: 6077,
				rate: 83.33
			}
		}
	},
	{
		label: 'DHLGME Return Booking',
		additional_label: 'CY',
		periods: {
			'28800': {
				total: 1,
				failed: 1,
				avg: 790,
				rate: 0
			},
			'86400': {
				total: 2,
				failed: 1,
				avg: 1975,
				rate: 50
			}
		}
	},
	{
		label: 'DHLGME Return Booking',
		additional_label: 'CZ',
		periods: {
			'600': {
				total: 1,
				failed: 0,
				avg: 4484,
				rate: 100
			},
			'3600': {
				total: 4,
				failed: 0,
				avg: 5776,
				rate: 100
			},
			'28800': {
				total: 49,
				failed: 21,
				avg: 3950,
				rate: 57.14
			},
			'86400': {
				total: 160,
				failed: 91,
				avg: 3728,
				rate: 43.13
			}
		}
	},
	{
		label: 'DHLGME Return Booking',
		additional_label: 'DE',
		periods: {
			'600': {
				total: 2,
				failed: 0,
				avg: 3256,
				rate: 100
			},
			'3600': {
				total: 11,
				failed: 0,
				avg: 4271,
				rate: 100
			},
			'28800': {
				total: 47,
				failed: 21,
				avg: 3004,
				rate: 55.32
			},
			'86400': {
				total: 116,
				failed: 61,
				avg: 2459,
				rate: 47.41
			}
		}
	},
	{
		label: 'DHLGME Return Booking',
		additional_label: 'DK',
		periods: {
			'86400': {
				total: 1,
				failed: 0,
				avg: 2480,
				rate: 100
			}
		}
	},
	{
		label: 'DHLGME Return Booking',
		additional_label: 'EE',
		periods: {
			'3600': {
				total: 1,
				failed: 0,
				avg: 10228,
				rate: 100
			},
			'28800': {
				total: 5,
				failed: 1,
				avg: 5272,
				rate: 80
			},
			'86400': {
				total: 5,
				failed: 1,
				avg: 5272,
				rate: 80
			}
		}
	},
	{
		label: 'DHLGME Return Booking',
		additional_label: 'ES'
	},
	{
		label: 'DHLGME Return Booking',
		additional_label: 'FI'
	},
	{
		label: 'DHLGME Return Booking',
		additional_label: 'FR'
	},
	{
		label: 'DHLGME Return Booking',
		additional_label: 'GR',
		periods: {
			'3600': {
				total: 1,
				failed: 0,
				avg: 2988,
				rate: 100
			},
			'28800': {
				total: 2,
				failed: 0,
				avg: 3826,
				rate: 100
			},
			'86400': {
				total: 9,
				failed: 4,
				avg: 2841,
				rate: 55.56
			}
		}
	},
	{
		label: 'DHLGME Return Booking',
		additional_label: 'HU',
		periods: {
			'600': {
				total: 1,
				failed: 0,
				avg: 5244,
				rate: 100
			},
			'3600': {
				total: 6,
				failed: 0,
				avg: 5901,
				rate: 100
			},
			'28800': {
				total: 28,
				failed: 13,
				avg: 4081,
				rate: 53.57
			},
			'86400': {
				total: 43,
				failed: 21,
				avg: 3813,
				rate: 51.16
			}
		}
	},
	{
		label: 'DHLGME Return Booking',
		additional_label: 'IE'
	},
	{
		label: 'DHLGME Return Booking',
		additional_label: 'IT',
		periods: {
			'3600': {
				total: 1,
				failed: 0,
				avg: 6390,
				rate: 100
			},
			'28800': {
				total: 2,
				failed: 1,
				avg: 3737,
				rate: 50
			},
			'86400': {
				total: 27,
				failed: 16,
				avg: 2826,
				rate: 40.74
			}
		}
	},
	{
		label: 'DHLGME Return Booking',
		additional_label: 'LT',
		periods: {
			'3600': {
				total: 1,
				failed: 0,
				avg: 5945,
				rate: 100
			},
			'28800': {
				total: 3,
				failed: 1,
				avg: 6167,
				rate: 66.67
			},
			'86400': {
				total: 4,
				failed: 1,
				avg: 5828,
				rate: 75
			}
		}
	},
	{
		label: 'DHLGME Return Booking',
		additional_label: 'LU',
		periods: {
			'600': {
				total: 1,
				failed: 0,
				avg: 3845,
				rate: 100
			},
			'3600': {
				total: 4,
				failed: 0,
				avg: 3236,
				rate: 100
			},
			'28800': {
				total: 13,
				failed: 2,
				avg: 3226,
				rate: 84.62
			},
			'86400': {
				total: 39,
				failed: 12,
				avg: 2615,
				rate: 69.23
			}
		}
	},
	{
		label: 'DHLGME Return Booking',
		additional_label: 'LV',
		periods: {
			'86400': {
				total: 4,
				failed: 3,
				avg: 2011,
				rate: 25
			}
		}
	},
	{
		label: 'DHLGME Return Booking',
		additional_label: 'NL',
		periods: {
			'28800': {
				total: 9,
				failed: 5,
				avg: 3270,
				rate: 44.44
			},
			'86400': {
				total: 13,
				failed: 6,
				avg: 4095,
				rate: 53.85
			}
		}
	},
	{
		label: 'DHLGME Return Booking',
		additional_label: 'PL',
		periods: {
			'600': {
				total: 2,
				failed: 0,
				avg: 5344,
				rate: 100
			},
			'3600': {
				total: 19,
				failed: 0,
				avg: 4195,
				rate: 100
			},
			'28800': {
				total: 118,
				failed: 61,
				avg: 3069,
				rate: 48.31
			},
			'86400': {
				total: 355,
				failed: 197,
				avg: 2785,
				rate: 44.51
			}
		}
	},
	{
		label: 'DHLGME Return Booking',
		additional_label: 'PT'
	},
	{
		label: 'DHLGME Return Booking',
		additional_label: 'RO',
		periods: {
			'3600': {
				total: 6,
				failed: 0,
				avg: 2671,
				rate: 100
			},
			'28800': {
				total: 55,
				failed: 26,
				avg: 2925,
				rate: 52.73
			},
			'86400': {
				total: 87,
				failed: 44,
				avg: 2603,
				rate: 49.43
			}
		}
	},
	{
		label: 'DHLGME Return Booking',
		additional_label: 'SE',
		periods: {
			'28800': {
				total: 2,
				failed: 2,
				avg: 1661,
				rate: 0
			},
			'86400': {
				total: 2,
				failed: 2,
				avg: 1661,
				rate: 0
			}
		}
	},
	{
		label: 'DHLGME Return Booking',
		additional_label: 'SI',
		periods: {
			'3600': {
				total: 3,
				failed: 0,
				avg: 7164,
				rate: 100
			},
			'28800': {
				total: 12,
				failed: 2,
				avg: 6068,
				rate: 83.33
			},
			'86400': {
				total: 39,
				failed: 13,
				avg: 5016,
				rate: 66.67
			}
		}
	},
	{
		label: 'DHLGME Return Booking',
		additional_label: 'SK',
		periods: {
			'600': {
				total: 3,
				failed: 0,
				avg: 6741,
				rate: 100
			},
			'3600': {
				total: 9,
				failed: 0,
				avg: 6167,
				rate: 100
			},
			'28800': {
				total: 45,
				failed: 18,
				avg: 4326,
				rate: 60
			},
			'86400': {
				total: 127,
				failed: 67,
				avg: 3794,
				rate: 47.24
			}
		}
	},
	{
		label: 'DHLGME Return Pudo',
		additional_label: '',
		periods: {
			'600': {
				total: 8,
				failed: 0,
				avg: 398,
				rate: 100
			},
			'3600': {
				total: 51,
				failed: 0,
				avg: 509,
				rate: 100
			},
			'28800': {
				total: 183,
				failed: 0,
				avg: 487,
				rate: 100
			},
			'86400': {
				total: 520,
				failed: 0,
				avg: 492,
				rate: 100
			}
		}
	},
	{
		label: 'DHLGME Return Tracking',
		additional_label: '',
		periods: {
			'600': {
				total: 1103,
				failed: 0,
				avg: 99,
				rate: 100
			},
			'3600': {
				total: 6913,
				failed: 0,
				avg: 98,
				rate: 100
			},
			'28800': {
				total: 56739,
				failed: 2,
				avg: 101,
				rate: 100
			},
			'86400': {
				total: 157948,
				failed: 2,
				avg: 96,
				rate: 100
			}
		}
	},
	{
		label: 'DHLNL Return Booking',
		additional_label: '',
		periods: {
			'600': {
				total: 2,
				failed: 0,
				avg: 0,
				rate: 100
			},
			'3600': {
				total: 8,
				failed: 0,
				avg: 0,
				rate: 100
			},
			'28800': {
				total: 21,
				failed: 0,
				avg: 0,
				rate: 100
			},
			'86400': {
				total: 49,
				failed: 0,
				avg: 0,
				rate: 100
			}
		}
	},
	{
		label: 'DHLNL Return Manifest',
		additional_label: '',
		periods: {
			'3600': {
				total: 4,
				failed: 0,
				avg: 0,
				rate: 100
			},
			'28800': {
				total: 11,
				failed: 0,
				avg: 0,
				rate: 100
			},
			'86400': {
				total: 31,
				failed: 0,
				avg: 0,
				rate: 100
			}
		}
	},
	{
		label: 'DHLNL Return Pudo',
		additional_label: ''
	},
	{
		label: 'DHLNL Return Tracking',
		additional_label: '',
		periods: {
			'28800': {
				total: 4,
				failed: 0,
				avg: 0,
				rate: 100
			},
			'86400': {
				total: 12,
				failed: 0,
				avg: 0,
				rate: 100
			}
		}
	},
	{
		label: 'DHLPaket Direct Booking',
		additional_label: '',
		periods: {
			'86400': {
				total: 4,
				failed: 0,
				avg: 1575,
				rate: 100
			}
		}
	},
	{
		label: 'DHLPaket Direct Tracking',
		additional_label: '',
		periods: {
			'28800': {
				total: 241,
				failed: 0,
				avg: 217,
				rate: 100
			},
			'86400': {
				total: 7113,
				failed: 0,
				avg: 245,
				rate: 100
			}
		}
	},
	{
		label: 'DocketHub Direct Tracking',
		additional_label: '',
		periods: {
			'86400': {
				total: 4,
				failed: 0,
				avg: 314,
				rate: 100
			}
		}
	},
	{
		label: 'Doddle Return Pudo',
		additional_label: '',
		periods: {
			'28800': {
				total: 1,
				failed: 1,
				avg: 1677,
				rate: 0
			},
			'86400': {
				total: 1,
				failed: 1,
				avg: 1677,
				rate: 0
			}
		}
	},
	{
		label: 'DPDDE Direct Booking',
		additional_label: '',
		periods: {
			'600': {
				total: 1,
				failed: 0,
				avg: 968,
				rate: 100
			},
			'3600': {
				total: 4,
				failed: 0,
				avg: 1084,
				rate: 100
			},
			'28800': {
				total: 97,
				failed: 1,
				avg: 939,
				rate: 98.97
			},
			'86400': {
				total: 171,
				failed: 1,
				avg: 926,
				rate: 99.42
			}
		}
	},
	{
		label: 'DPDDE Direct Tracking',
		additional_label: '',
		periods: {
			'3600': {
				total: 1,
				failed: 0,
				avg: 0,
				rate: 100
			},
			'28800': {
				total: 4,
				failed: 0,
				avg: 0,
				rate: 100
			},
			'86400': {
				total: 12,
				failed: 0,
				avg: 0,
				rate: 100
			}
		}
	},
	{
		label: 'DPDDE Return Booking',
		additional_label: '',
		periods: {
			'28800': {
				total: 2,
				failed: 0,
				avg: 1041,
				rate: 100
			},
			'86400': {
				total: 9,
				failed: 0,
				avg: 1146,
				rate: 100
			}
		}
	},
	{
		label: 'DPDDE Return Pudo',
		additional_label: '',
		periods: {
			'28800': {
				total: 3,
				failed: 0,
				avg: 4160,
				rate: 100
			},
			'86400': {
				total: 10,
				failed: 0,
				avg: 3853,
				rate: 100
			}
		}
	},
	{
		label: 'DPDDE Return Tracking',
		additional_label: '',
		periods: {
			'3600': {
				total: 88,
				failed: 0,
				avg: 118,
				rate: 100
			},
			'28800': {
				total: 346,
				failed: 7,
				avg: 118,
				rate: 97.98
			},
			'86400': {
				total: 1202,
				failed: 7,
				avg: 118,
				rate: 99.42
			}
		}
	},
	{
		label: 'DPDUK Direct Tracking',
		additional_label: '',
		periods: {
			'3600': {
				total: 8,
				failed: 0,
				avg: 207,
				rate: 100
			},
			'28800': {
				total: 32,
				failed: 0,
				avg: 221,
				rate: 100
			},
			'86400': {
				total: 96,
				failed: 0,
				avg: 291,
				rate: 100
			}
		}
	},
	{
		label: 'ECargo Return Booking',
		additional_label: '',
		periods: {
			'600': {
				total: 1,
				failed: 0,
				avg: 1137,
				rate: 100
			},
			'3600': {
				total: 2,
				failed: 0,
				avg: 1312,
				rate: 100
			},
			'28800': {
				total: 4,
				failed: 0,
				avg: 1369,
				rate: 100
			},
			'86400': {
				total: 54,
				failed: 50,
				avg: 541,
				rate: 7.41
			}
		}
	},
	{
		label: 'ECargo Return Pudo',
		additional_label: '',
		periods: {
			'28800': {
				total: 1,
				failed: 0,
				avg: 0,
				rate: 100
			},
			'86400': {
				total: 1,
				failed: 0,
				avg: 0,
				rate: 100
			}
		}
	},
	{
		label: 'ECargo Return Tracking',
		additional_label: '',
		periods: {
			'3600': {
				total: 1,
				failed: 0,
				avg: 0,
				rate: 100
			},
			'28800': {
				total: 4,
				failed: 0,
				avg: 0,
				rate: 100
			},
			'86400': {
				total: 12,
				failed: 0,
				avg: 0,
				rate: 100
			}
		}
	},
	{
		label: 'EmiratesPost Direct Pudo',
		additional_label: '',
		periods: {
			'28800': {
				total: 8,
				failed: 0,
				avg: 645,
				rate: 100
			},
			'86400': {
				total: 8,
				failed: 0,
				avg: 645,
				rate: 100
			}
		}
	},
	{
		label: 'EmiratesPost Direct Robots',
		additional_label: '',
		periods: {
			'3600': {
				total: 4,
				failed: 2,
				avg: 2547,
				rate: 50
			},
			'28800': {
				total: 8,
				failed: 2,
				avg: 1788,
				rate: 75
			},
			'86400': {
				total: 24,
				failed: 2,
				avg: 1480,
				rate: 91.67
			}
		}
	},
	{
		label: 'EmiratesPost Direct Tracking',
		additional_label: '',
		periods: {
			'3600': {
				total: 38,
				failed: 2,
				avg: 1699,
				rate: 94.74
			},
			'28800': {
				total: 152,
				failed: 5,
				avg: 1378,
				rate: 96.71
			},
			'86400': {
				total: 474,
				failed: 13,
				avg: 1315,
				rate: 97.26
			}
		}
	},
	{
		label: 'EmiratesPost Return Booking',
		additional_label: ''
	},
	{
		label: 'Fastway Return Booking',
		additional_label: '',
		periods: {
			'600': {
				total: 3,
				failed: 0,
				avg: 0,
				rate: 100
			},
			'3600': {
				total: 24,
				failed: 0,
				avg: 0,
				rate: 100
			},
			'28800': {
				total: 51,
				failed: 0,
				avg: 0,
				rate: 100
			},
			'86400': {
				total: 308,
				failed: 0,
				avg: 0,
				rate: 100
			}
		}
	},
	{
		label: 'Fastway Return Pudo',
		additional_label: '',
		periods: {
			'600': {
				total: 6,
				failed: 0,
				avg: 729,
				rate: 100
			},
			'3600': {
				total: 33,
				failed: 0,
				avg: 743,
				rate: 100
			},
			'28800': {
				total: 67,
				failed: 0,
				avg: 762,
				rate: 100
			},
			'86400': {
				total: 418,
				failed: 1,
				avg: 780,
				rate: 99.76
			}
		}
	},
	{
		label: 'Fastway Return Tracking',
		additional_label: '',
		periods: {
			'600': {
				total: 1,
				failed: 0,
				avg: 0,
				rate: 100
			},
			'3600': {
				total: 1,
				failed: 0,
				avg: 0,
				rate: 100
			},
			'28800': {
				total: 5,
				failed: 0,
				avg: 0,
				rate: 100
			},
			'86400': {
				total: 12,
				failed: 0,
				avg: 0,
				rate: 100
			}
		}
	},
	{
		label: 'GEPS Direct Tracking',
		additional_label: '',
		periods: {
			'3600': {
				total: 1,
				failed: 1,
				avg: 0,
				rate: 0
			},
			'28800': {
				total: 4,
				failed: 4,
				avg: 0,
				rate: 0
			},
			'86400': {
				total: 12,
				failed: 12,
				avg: 0,
				rate: 0
			}
		}
	},
	{
		label: 'GLS Direct Tracking',
		additional_label: '',
		periods: {
			'86400': {
				total: 1,
				failed: 0,
				avg: 270,
				rate: 100
			}
		}
	},
	{
		label: 'Google GeoCoding',
		additional_label: '',
		periods: {
			'600': {
				total: 52,
				failed: 0,
				avg: 156,
				rate: 100
			},
			'3600': {
				total: 325,
				failed: 0,
				avg: 158,
				rate: 100
			},
			'28800': {
				total: 2606,
				failed: 0,
				avg: 143,
				rate: 100
			},
			'86400': {
				total: 7724,
				failed: 0,
				avg: 147,
				rate: 100
			}
		}
	},
	{
		label: 'Hermes Direct Booking',
		additional_label: '',
		periods: {
			'600': {
				total: 36,
				failed: 2,
				avg: 528,
				rate: 94.44
			},
			'3600': {
				total: 108,
				failed: 12,
				avg: 461,
				rate: 88.89
			},
			'28800': {
				total: 280,
				failed: 13,
				avg: 329,
				rate: 95.36
			},
			'86400': {
				total: 531,
				failed: 14,
				avg: 329,
				rate: 97.36
			}
		}
	},
	{
		label: 'Hermes Direct Pudo',
		additional_label: '',
		periods: {
			'28800': {
				total: 3,
				failed: 0,
				avg: 259,
				rate: 100
			},
			'86400': {
				total: 4,
				failed: 0,
				avg: 257,
				rate: 100
			}
		}
	},
	{
		label: 'Hermes Direct Tracking',
		additional_label: '',
		periods: {
			'3600': {
				total: 1,
				failed: 0,
				avg: 0,
				rate: 100
			},
			'28800': {
				total: 4,
				failed: 0,
				avg: 0,
				rate: 100
			},
			'86400': {
				total: 12,
				failed: 0,
				avg: 0,
				rate: 100
			}
		}
	},
	{
		label: 'Hermes Pallet Booking',
		additional_label: '',
		periods: {
			'28800': {
				total: 3,
				failed: 0,
				avg: 0,
				rate: 100
			},
			'86400': {
				total: 3,
				failed: 0,
				avg: 0,
				rate: 100
			}
		}
	},
	{
		label: 'Hermes Return Booking',
		additional_label: '',
		periods: {
			'600': {
				total: 131,
				failed: 0,
				avg: 450,
				rate: 100
			},
			'3600': {
				total: 723,
				failed: 0,
				avg: 437,
				rate: 100
			},
			'28800': {
				total: 1594,
				failed: 3,
				avg: 401,
				rate: 99.81
			},
			'86400': {
				total: 9466,
				failed: 28,
				avg: 381,
				rate: 99.7
			}
		}
	},
	{
		label: 'Hermes Return Pudo',
		additional_label: '',
		periods: {
			'600': {
				total: 815,
				failed: 29,
				avg: 193,
				rate: 96.44
			},
			'3600': {
				total: 4575,
				failed: 123,
				avg: 193,
				rate: 97.31
			},
			'28800': {
				total: 10340,
				failed: 237,
				avg: 194,
				rate: 97.71
			},
			'86400': {
				total: 52915,
				failed: 1079,
				avg: 187,
				rate: 97.96
			}
		}
	},
	{
		label: 'Hermes Return Tracking',
		additional_label: '',
		periods: {
			'3600': {
				total: 3,
				failed: 0,
				avg: 0,
				rate: 100
			},
			'28800': {
				total: 12,
				failed: 0,
				avg: 0,
				rate: 100
			},
			'86400': {
				total: 36,
				failed: 0,
				avg: 0,
				rate: 100
			}
		}
	},
	{
		label: 'HermesHSI Direct Booking',
		additional_label: '',
		periods: {
			'600': {
				total: 46,
				failed: 0,
				avg: 0,
				rate: 100
			},
			'3600': {
				total: 321,
				failed: 0,
				avg: 0,
				rate: 100
			},
			'28800': {
				total: 1048,
				failed: 0,
				avg: 0,
				rate: 100
			},
			'86400': {
				total: 1822,
				failed: 0,
				avg: 0,
				rate: 100
			}
		}
	},
	{
		label: 'HermesHSI Direct Manifest',
		additional_label: '',
		periods: {
			'600': {
				total: 2,
				failed: 0,
				avg: 0,
				rate: 100
			},
			'3600': {
				total: 2,
				failed: 0,
				avg: 0,
				rate: 100
			},
			'28800': {
				total: 8,
				failed: 0,
				avg: 0,
				rate: 100
			},
			'86400': {
				total: 24,
				failed: 0,
				avg: 0,
				rate: 100
			}
		}
	},
	{
		label: 'HermesHSI Direct Tracking',
		additional_label: '',
		periods: {
			'3600': {
				total: 2,
				failed: 0,
				avg: 0,
				rate: 100
			},
			'28800': {
				total: 8,
				failed: 0,
				avg: 0,
				rate: 100
			},
			'86400': {
				total: 24,
				failed: 0,
				avg: 0,
				rate: 100
			}
		}
	},
	{
		label: 'HermesHSI Return Booking',
		additional_label: ''
	},
	{
		label: 'HermesHSI Return Pudo',
		additional_label: '',
		periods: {
			'28800': {
				total: 1,
				failed: 0,
				avg: 0,
				rate: 100
			},
			'86400': {
				total: 1,
				failed: 0,
				avg: 0,
				rate: 100
			}
		}
	},
	{
		label: 'HermesHSI Return Tracking',
		additional_label: '',
		periods: {
			'3600': {
				total: 61,
				failed: 0,
				avg: 177,
				rate: 100
			},
			'28800': {
				total: 244,
				failed: 0,
				avg: 161,
				rate: 100
			},
			'86400': {
				total: 807,
				failed: 1,
				avg: 156,
				rate: 99.88
			}
		}
	},
	{
		label: 'HubEurope Direct Booking',
		additional_label: '',
		periods: {
			'3600': {
				total: 2,
				failed: 0,
				avg: 533,
				rate: 100
			},
			'28800': {
				total: 5,
				failed: 0,
				avg: 567,
				rate: 100
			},
			'86400': {
				total: 7,
				failed: 0,
				avg: 556,
				rate: 100
			}
		}
	},
	{
		label: 'HubEurope Direct Tracking',
		additional_label: '',
		periods: {
			'3600': {
				total: 1,
				failed: 0,
				avg: 0,
				rate: 100
			},
			'28800': {
				total: 4,
				failed: 0,
				avg: 0,
				rate: 100
			},
			'86400': {
				total: 12,
				failed: 0,
				avg: 0,
				rate: 100
			}
		}
	},
	{
		label: 'IcelandPost Direct Tracking',
		additional_label: '',
		periods: {
			'600': {
				total: 1,
				failed: 0,
				avg: 0,
				rate: 100
			},
			'3600': {
				total: 1,
				failed: 0,
				avg: 0,
				rate: 100
			},
			'28800': {
				total: 4,
				failed: 0,
				avg: 0,
				rate: 100
			},
			'86400': {
				total: 12,
				failed: 0,
				avg: 0,
				rate: 100
			}
		}
	},
	{
		label: 'InPost Return Booking',
		additional_label: '',
		periods: {
			'600': {
				total: 1,
				failed: 0,
				avg: 1751,
				rate: 100
			},
			'3600': {
				total: 12,
				failed: 0,
				avg: 3940,
				rate: 100
			},
			'28800': {
				total: 27,
				failed: 0,
				avg: 3493,
				rate: 100
			},
			'86400': {
				total: 158,
				failed: 0,
				avg: 2809,
				rate: 100
			}
		}
	},
	{
		label: 'InPost Return Pudo',
		additional_label: '',
		periods: {
			'28800': {
				total: 1,
				failed: 0,
				avg: 1151,
				rate: 100
			},
			'86400': {
				total: 1,
				failed: 0,
				avg: 1151,
				rate: 100
			}
		}
	},
	{
		label: 'InPost Return Tracking',
		additional_label: '',
		periods: {
			'600': {
				total: 1234,
				failed: 0,
				avg: 382,
				rate: 100
			},
			'3600': {
				total: 1231,
				failed: 0,
				avg: 382,
				rate: 100
			},
			'28800': {
				total: 12159,
				failed: 1,
				avg: 398,
				rate: 99.99
			},
			'86400': {
				total: 35390,
				failed: 12,
				avg: 396,
				rate: 99.97
			}
		}
	},
	{
		label: 'Janio Direct Tracking',
		additional_label: '',
		periods: {
			'600': {
				total: 28,
				failed: 0,
				avg: 366,
				rate: 100
			},
			'3600': {
				total: 28,
				failed: 0,
				avg: 366,
				rate: 100
			},
			'28800': {
				total: 112,
				failed: 0,
				avg: 372,
				rate: 100
			},
			'86400': {
				total: 340,
				failed: 0,
				avg: 370,
				rate: 100
			}
		}
	},
	{
		label: 'Janio Return Booking',
		additional_label: '',
		periods: {
			'3600': {
				total: 2,
				failed: 0,
				avg: 6484,
				rate: 100
			},
			'28800': {
				total: 16,
				failed: 0,
				avg: 6045,
				rate: 100
			},
			'86400': {
				total: 37,
				failed: 0,
				avg: 6453,
				rate: 100
			}
		}
	},
	{
		label: 'Janio Return Tracking',
		additional_label: '',
		periods: {
			'600': {
				total: 206,
				failed: 0,
				avg: 343,
				rate: 100
			},
			'3600': {
				total: 206,
				failed: 0,
				avg: 343,
				rate: 100
			},
			'28800': {
				total: 906,
				failed: 0,
				avg: 349,
				rate: 100
			},
			'86400': {
				total: 3220,
				failed: 0,
				avg: 343,
				rate: 100
			}
		}
	},
	{
		label: 'JerseyPost Return Booking',
		additional_label: '',
		periods: {
			'3600': {
				total: 1,
				failed: 0,
				avg: 0,
				rate: 100
			},
			'28800': {
				total: 4,
				failed: 0,
				avg: 0,
				rate: 100
			},
			'86400': {
				total: 24,
				failed: 0,
				avg: 0,
				rate: 100
			}
		}
	},
	{
		label: 'JerseyPost Return Manifest',
		additional_label: '',
		periods: {
			'600': {
				total: 1,
				failed: 0,
				avg: 0,
				rate: 100
			},
			'3600': {
				total: 1,
				failed: 0,
				avg: 0,
				rate: 100
			},
			'28800': {
				total: 2,
				failed: 0,
				avg: 0,
				rate: 100
			},
			'86400': {
				total: 12,
				failed: 0,
				avg: 0,
				rate: 100
			}
		}
	},
	{
		label: 'JerseyPost Return WebHook',
		additional_label: '',
		periods: {
			'28800': {
				total: 3,
				failed: 0,
				avg: 0,
				rate: 100
			},
			'86400': {
				total: 23,
				failed: 0,
				avg: 0,
				rate: 100
			}
		}
	},
	{
		label: 'LatviaPost Return Pudo',
		additional_label: '',
		periods: {
			'28800': {
				total: 1,
				failed: 0,
				avg: 1364,
				rate: 100
			},
			'86400': {
				total: 1,
				failed: 0,
				avg: 1364,
				rate: 100
			}
		}
	},
	{
		label: 'LithuaniaPost Return Pudo',
		additional_label: '',
		periods: {
			'28800': {
				total: 1,
				failed: 0,
				avg: 4442,
				rate: 100
			},
			'86400': {
				total: 1,
				failed: 0,
				avg: 4442,
				rate: 100
			}
		}
	},
	{
		label: 'MagyarPosta Return Pudo',
		additional_label: '',
		periods: {
			'28800': {
				total: 1,
				failed: 0,
				avg: 2830,
				rate: 100
			},
			'86400': {
				total: 1,
				failed: 0,
				avg: 2830,
				rate: 100
			}
		}
	},
	{
		label: 'MailAlliance Direct Booking',
		additional_label: ''
	},
	{
		label: 'MailAlliance Direct Tracking',
		additional_label: '',
		periods: {
			'3600': {
				total: 23,
				failed: 0,
				avg: 53,
				rate: 100
			},
			'28800': {
				total: 92,
				failed: 0,
				avg: 54,
				rate: 100
			},
			'86400': {
				total: 276,
				failed: 0,
				avg: 51,
				rate: 100
			}
		}
	},
	{
		label: 'Mailwise Direct Tracking',
		additional_label: '',
		periods: {
			'86400': {
				total: 2,
				failed: 2,
				avg: 90001,
				rate: 0
			}
		}
	},
	{
		label: 'MaltaPost Return Booking',
		additional_label: '',
		periods: {
			'3600': {
				total: 3,
				failed: 0,
				avg: 0,
				rate: 100
			},
			'28800': {
				total: 5,
				failed: 0,
				avg: 0,
				rate: 100
			},
			'86400': {
				total: 17,
				failed: 0,
				avg: 0,
				rate: 100
			}
		}
	},
	{
		label: 'MaltaPost Return Tracking',
		additional_label: '',
		periods: {
			'3600': {
				total: 1,
				failed: 0,
				avg: 0,
				rate: 100
			},
			'28800': {
				total: 4,
				failed: 0,
				avg: 0,
				rate: 100
			},
			'86400': {
				total: 12,
				failed: 0,
				avg: 0,
				rate: 100
			}
		}
	},
	{
		label: 'MaraExpress Direct Booking',
		additional_label: '',
		periods: {
			'600': {
				total: 3,
				failed: 0,
				avg: 8627,
				rate: 100
			},
			'3600': {
				total: 13,
				failed: 0,
				avg: 9404,
				rate: 100
			},
			'28800': {
				total: 29,
				failed: 0,
				avg: 9557,
				rate: 100
			},
			'86400': {
				total: 175,
				failed: 0,
				avg: 9496,
				rate: 100
			}
		}
	},
	{
		label: 'MaraExpress Direct Cancel',
		additional_label: '',
		periods: {
			'28800': {
				total: 1,
				failed: 0,
				avg: 5746,
				rate: 100
			},
			'86400': {
				total: 1,
				failed: 0,
				avg: 5746,
				rate: 100
			}
		}
	},
	{
		label: 'MaraExpress Direct Tracking',
		additional_label: '',
		periods: {
			'3600': {
				total: 816,
				failed: 0,
				avg: 405,
				rate: 100
			},
			'28800': {
				total: 3219,
				failed: 0,
				avg: 420,
				rate: 100
			},
			'86400': {
				total: 11301,
				failed: 0,
				avg: 419,
				rate: 100
			}
		}
	},
	{
		label: 'Mondial Return Booking',
		additional_label: '',
		periods: {
			'600': {
				total: 22,
				failed: 0,
				avg: 0,
				rate: 100
			},
			'3600': {
				total: 106,
				failed: 0,
				avg: 0,
				rate: 100
			},
			'28800': {
				total: 304,
				failed: 0,
				avg: 0,
				rate: 100
			},
			'86400': {
				total: 1383,
				failed: 0,
				avg: 0,
				rate: 100
			}
		}
	},
	{
		label: 'Mondial Return Manifest',
		additional_label: '',
		periods: {
			'86400': {
				total: 3,
				failed: 0,
				avg: 0,
				rate: 100
			}
		}
	},
	{
		label: 'Mondial Return Pudo',
		additional_label: '',
		periods: {
			'28800': {
				total: 1,
				failed: 0,
				avg: 0,
				rate: 100
			},
			'86400': {
				total: 1,
				failed: 0,
				avg: 0,
				rate: 100
			}
		}
	},
	{
		label: 'Mondial Return Robots',
		additional_label: '',
		periods: {
			'86400': {
				total: 1,
				failed: 0,
				avg: 0,
				rate: 100
			}
		}
	},
	{
		label: 'Mondial Return Tracking',
		additional_label: '',
		periods: {
			'3600': {
				total: 1,
				failed: 0,
				avg: 0,
				rate: 100
			},
			'28800': {
				total: 4,
				failed: 0,
				avg: 0,
				rate: 100
			},
			'86400': {
				total: 12,
				failed: 0,
				avg: 0,
				rate: 100
			}
		}
	},
	{
		label: 'MyPack Return Booking',
		additional_label: '',
		periods: {
			'600': {
				total: 24,
				failed: 0,
				avg: 0,
				rate: 100
			},
			'3600': {
				total: 129,
				failed: 0,
				avg: 0,
				rate: 100
			},
			'28800': {
				total: 407,
				failed: 0,
				avg: 0,
				rate: 100
			},
			'86400': {
				total: 1724,
				failed: 0,
				avg: 0,
				rate: 100
			}
		}
	},
	{
		label: 'MyPack Return Pudo',
		additional_label: '',
		periods: {
			'600': {
				total: 39,
				failed: 0,
				avg: 307,
				rate: 100
			},
			'3600': {
				total: 256,
				failed: 0,
				avg: 293,
				rate: 100
			},
			'28800': {
				total: 773,
				failed: 0,
				avg: 299,
				rate: 100
			},
			'86400': {
				total: 3188,
				failed: 5,
				avg: 294,
				rate: 99.84
			}
		}
	},
	{
		label: 'MyPack Return Tracking',
		additional_label: '',
		periods: {
			'600': {
				total: 1151,
				failed: 1,
				avg: 30,
				rate: 99.91
			},
			'3600': {
				total: 7171,
				failed: 6,
				avg: 30,
				rate: 99.92
			},
			'28800': {
				total: 58263,
				failed: 82,
				avg: 44,
				rate: 99.86
			},
			'86400': {
				total: 130614,
				failed: 250,
				avg: 36,
				rate: 99.81
			}
		}
	},
	{
		label: 'NinjaVan Return Booking',
		additional_label: '',
		periods: {
			'600': {
				total: 1,
				failed: 0,
				avg: 2651,
				rate: 100
			},
			'3600': {
				total: 9,
				failed: 0,
				avg: 1871,
				rate: 100
			},
			'28800': {
				total: 84,
				failed: 0,
				avg: 1467,
				rate: 100
			},
			'86400': {
				total: 154,
				failed: 0,
				avg: 1268,
				rate: 100
			}
		}
	},
	{
		label: 'NinjaVan Return Pudo',
		additional_label: 'sg',
		periods: {
			'28800': {
				total: 1,
				failed: 0,
				avg: 2382,
				rate: 100
			},
			'86400': {
				total: 1,
				failed: 0,
				avg: 2382,
				rate: 100
			}
		}
	},
	{
		label: 'NinjaVan Return WebHook',
		additional_label: '',
		periods: {
			'600': {
				total: 4,
				failed: 0,
				avg: 0,
				rate: 100
			},
			'3600': {
				total: 63,
				failed: 0,
				avg: 0,
				rate: 100
			},
			'28800': {
				total: 708,
				failed: 0,
				avg: 0,
				rate: 100
			},
			'86400': {
				total: 713,
				failed: 0,
				avg: 0,
				rate: 100
			}
		}
	},
	{
		label: 'Omniva Direct Pudo',
		additional_label: '',
		periods: {
			'28800': {
				total: 1,
				failed: 0,
				avg: 831,
				rate: 100
			},
			'86400': {
				total: 1,
				failed: 0,
				avg: 831,
				rate: 100
			}
		}
	},
	{
		label: 'Omniva Return Pudo',
		additional_label: '',
		periods: {
			'28800': {
				total: 1,
				failed: 0,
				avg: 908,
				rate: 100
			},
			'86400': {
				total: 1,
				failed: 0,
				avg: 908,
				rate: 100
			}
		}
	},
	{
		label: 'OSM Direct Booking',
		additional_label: ''
	},
	{
		label: 'OSM Direct Robots',
		additional_label: '',
		periods: {
			'86400': {
				total: 1,
				failed: 0,
				avg: 0,
				rate: 100
			}
		}
	},
	{
		label: 'OSM Direct Tracking',
		additional_label: '',
		periods: {
			'3600': {
				total: 1,
				failed: 0,
				avg: 0,
				rate: 100
			},
			'28800': {
				total: 4,
				failed: 0,
				avg: 0,
				rate: 100
			},
			'86400': {
				total: 12,
				failed: 0,
				avg: 0,
				rate: 100
			}
		}
	},
	{
		label: 'Packetery Direct Booking',
		additional_label: '',
		periods: {
			'600': {
				total: 7,
				failed: 0,
				avg: 1715,
				rate: 100
			},
			'3600': {
				total: 57,
				failed: 1,
				avg: 2288,
				rate: 98.25
			},
			'28800': {
				total: 145,
				failed: 2,
				avg: 2121,
				rate: 98.62
			},
			'86400': {
				total: 306,
				failed: 3,
				avg: 1804,
				rate: 99.02
			}
		}
	},
	{
		label: 'Packetery Direct Pudo',
		additional_label: '',
		periods: {
			'28800': {
				total: 2,
				failed: 0,
				avg: 2898,
				rate: 100
			},
			'86400': {
				total: 2,
				failed: 0,
				avg: 2898,
				rate: 100
			}
		}
	},
	{
		label: 'Packetery Direct Robots',
		additional_label: '',
		periods: {
			'28800': {
				total: 1,
				failed: 0,
				avg: 350,
				rate: 100
			},
			'86400': {
				total: 1,
				failed: 0,
				avg: 350,
				rate: 100
			}
		}
	},
	{
		label: 'Packetery Direct Tracking',
		additional_label: '',
		periods: {
			'600': {
				total: 4063,
				failed: 0,
				avg: 41,
				rate: 100
			},
			'3600': {
				total: 4063,
				failed: 0,
				avg: 41,
				rate: 100
			},
			'28800': {
				total: 15897,
				failed: 0,
				avg: 40,
				rate: 100
			},
			'86400': {
				total: 54555,
				failed: 0,
				avg: 39,
				rate: 100
			}
		}
	},
	{
		label: 'Pakpobox Return Booking',
		additional_label: ''
	},
	{
		label: 'Pakpobox Return Pudo',
		additional_label: '',
		periods: {
			'28800': {
				total: 2,
				failed: 0,
				avg: 1018,
				rate: 100
			},
			'86400': {
				total: 3,
				failed: 0,
				avg: 1027,
				rate: 100
			}
		}
	},
	{
		label: 'Pakpobox Return Tracking',
		additional_label: '',
		periods: {
			'28800': {
				total: 87,
				failed: 0,
				avg: 391,
				rate: 100
			},
			'86400': {
				total: 255,
				failed: 0,
				avg: 418,
				rate: 100
			}
		}
	},
	{
		label: 'ParcelConnect Return Pudo',
		additional_label: '',
		periods: {
			'28800': {
				total: 1,
				failed: 0,
				avg: 634,
				rate: 100
			},
			'86400': {
				total: 1,
				failed: 0,
				avg: 634,
				rate: 100
			}
		}
	},
	{
		label: 'Parcelforce Direct Tracking',
		additional_label: '',
		periods: {
			'3600': {
				total: 1,
				failed: 0,
				avg: 0,
				rate: 100
			},
			'28800': {
				total: 4,
				failed: 0,
				avg: 0,
				rate: 100
			},
			'86400': {
				total: 12,
				failed: 0,
				avg: 0,
				rate: 100
			}
		}
	},
	{
		label: 'ParcelPoint Return Booking',
		additional_label: ''
	},
	{
		label: 'ParcelPoint Return Pudo',
		additional_label: '',
		periods: {
			'28800': {
				total: 1,
				failed: 0,
				avg: 3349,
				rate: 100
			},
			'86400': {
				total: 1,
				failed: 0,
				avg: 3349,
				rate: 100
			}
		}
	},
	{
		label: 'ParcelPoint Return Tracking',
		additional_label: '',
		periods: {
			'3600': {
				total: 2534,
				failed: 0,
				avg: 310,
				rate: 100
			},
			'28800': {
				total: 10136,
				failed: 0,
				avg: 309,
				rate: 100
			},
			'86400': {
				total: 30485,
				failed: 2,
				avg: 310,
				rate: 99.99
			}
		}
	},
	{
		label: 'PitneyBowes Return Booking',
		additional_label: '',
		periods: {
			'3600': {
				total: 9,
				failed: 0,
				avg: 5602,
				rate: 100
			},
			'28800': {
				total: 434,
				failed: 0,
				avg: 6763,
				rate: 100
			},
			'86400': {
				total: 1714,
				failed: 0,
				avg: 7139,
				rate: 100
			}
		}
	},
	{
		label: 'PitneyBowes Return Tracking',
		additional_label: '',
		periods: {
			'3600': {
				total: 1,
				failed: 0,
				avg: 0,
				rate: 100
			},
			'28800': {
				total: 4,
				failed: 0,
				avg: 0,
				rate: 100
			},
			'86400': {
				total: 12,
				failed: 0,
				avg: 0,
				rate: 100
			}
		}
	},
	{
		label: 'Posti Return Pudo',
		additional_label: '',
		periods: {
			'28800': {
				total: 1,
				failed: 0,
				avg: 2490,
				rate: 100
			},
			'86400': {
				total: 1,
				failed: 0,
				avg: 2490,
				rate: 100
			}
		}
	},
	{
		label: 'PostNL Return Booking',
		additional_label: '',
		periods: {
			'3600': {
				total: 1,
				failed: 0,
				avg: 163,
				rate: 100
			},
			'28800': {
				total: 10,
				failed: 0,
				avg: 243,
				rate: 100
			},
			'86400': {
				total: 31,
				failed: 0,
				avg: 278,
				rate: 100
			}
		}
	},
	{
		label: 'PostNL Return Pudo',
		additional_label: '',
		periods: {
			'600': {
				total: 1,
				failed: 0,
				avg: 334,
				rate: 100
			},
			'3600': {
				total: 4,
				failed: 0,
				avg: 367,
				rate: 100
			},
			'28800': {
				total: 22,
				failed: 0,
				avg: 358,
				rate: 100
			},
			'86400': {
				total: 76,
				failed: 0,
				avg: 363,
				rate: 100
			}
		}
	},
	{
		label: 'PostNL Return Robots',
		additional_label: '',
		periods: {
			'28800': {
				total: 9,
				failed: 0,
				avg: 220,
				rate: 100
			},
			'86400': {
				total: 32,
				failed: 0,
				avg: 250,
				rate: 100
			}
		}
	},
	{
		label: 'PostNL Return Tracking',
		additional_label: '',
		periods: {
			'3600': {
				total: 946,
				failed: 0,
				avg: 379,
				rate: 100
			},
			'28800': {
				total: 3780,
				failed: 2,
				avg: 298,
				rate: 99.95
			},
			'86400': {
				total: 13692,
				failed: 2,
				avg: 319,
				rate: 99.99
			}
		}
	},
	{
		label: 'PostNLRadar Return Booking',
		additional_label: '72B',
		periods: {
			'28800': {
				total: 1,
				failed: 0,
				avg: 4654,
				rate: 100
			},
			'86400': {
				total: 1,
				failed: 0,
				avg: 4654,
				rate: 100
			}
		}
	},
	{
		label: 'PostNLRadar Return Booking',
		additional_label: '72G'
	},
	{
		label: 'PostNLRadar Return Booking',
		additional_label: '72N'
	},
	{
		label: 'PostNLRadar Return Booking',
		additional_label: '72RE'
	},
	{
		label: 'PostNLRadar Return Tracking',
		additional_label: '',
		periods: {
			'3600': {
				total: 87,
				failed: 0,
				avg: 607,
				rate: 100
			},
			'28800': {
				total: 345,
				failed: 0,
				avg: 891,
				rate: 100
			},
			'86400': {
				total: 1462,
				failed: 1,
				avg: 831,
				rate: 99.93
			}
		}
	},
	{
		label: 'RelaisColis Return Booking',
		additional_label: '',
		periods: {
			'600': {
				total: 6,
				failed: 0,
				avg: 152,
				rate: 100
			},
			'3600': {
				total: 31,
				failed: 0,
				avg: 211,
				rate: 100
			},
			'28800': {
				total: 97,
				failed: 0,
				avg: 202,
				rate: 100
			},
			'86400': {
				total: 385,
				failed: 0,
				avg: 187,
				rate: 100
			}
		}
	},
	{
		label: 'RelaisColis Return Pudo',
		additional_label: '',
		periods: {
			'28800': {
				total: 1,
				failed: 0,
				avg: 0,
				rate: 100
			},
			'86400': {
				total: 1,
				failed: 0,
				avg: 0,
				rate: 100
			}
		}
	},
	{
		label: 'RelaisColis Return Tracking',
		additional_label: '',
		periods: {
			'3600': {
				total: 3,
				failed: 0,
				avg: 0,
				rate: 100
			},
			'28800': {
				total: 12,
				failed: 0,
				avg: 0,
				rate: 100
			},
			'86400': {
				total: 36,
				failed: 0,
				avg: 0,
				rate: 100
			}
		}
	},
	{
		label: 'Royale Return Pudo',
		additional_label: '',
		periods: {
			'28800': {
				total: 1,
				failed: 0,
				avg: 4505,
				rate: 100
			},
			'86400': {
				total: 1,
				failed: 0,
				avg: 4505,
				rate: 100
			}
		}
	},
	{
		label: 'Royale Return Tracking',
		additional_label: '',
		periods: {
			'86400': {
				total: 4,
				failed: 0,
				avg: 1955,
				rate: 100
			}
		}
	},
	{
		label: 'RoyaleV2 Return Booking',
		additional_label: '',
		periods: {
			'3600': {
				total: 16,
				failed: 0,
				avg: 1085,
				rate: 100
			},
			'28800': {
				total: 161,
				failed: 0,
				avg: 1108,
				rate: 100
			},
			'86400': {
				total: 390,
				failed: 0,
				avg: 1090,
				rate: 100
			}
		}
	},
	{
		label: 'RoyaleV2 Return Pudo',
		additional_label: '',
		periods: {
			'600': {
				total: 4,
				failed: 0,
				avg: 2123,
				rate: 100
			},
			'3600': {
				total: 40,
				failed: 0,
				avg: 2206,
				rate: 100
			},
			'28800': {
				total: 534,
				failed: 0,
				avg: 2260,
				rate: 100
			},
			'86400': {
				total: 1128,
				failed: 0,
				avg: 2304,
				rate: 100
			}
		}
	},
	{
		label: 'RoyaleV2 Return Tracking',
		additional_label: '',
		periods: {
			'600': {
				total: 717,
				failed: 0,
				avg: 767,
				rate: 100
			},
			'3600': {
				total: 3908,
				failed: 0,
				avg: 771,
				rate: 100
			},
			'28800': {
				total: 41627,
				failed: 0,
				avg: 766,
				rate: 100
			},
			'86400': {
				total: 109654,
				failed: 0,
				avg: 763,
				rate: 100
			}
		}
	},
	{
		label: 'RoyalMail Return Booking',
		additional_label: '',
		periods: {
			'600': {
				total: 407,
				failed: 0,
				avg: 0,
				rate: 100
			},
			'3600': {
				total: 2247,
				failed: 0,
				avg: 0,
				rate: 100
			},
			'28800': {
				total: 4938,
				failed: 0,
				avg: 0,
				rate: 100
			},
			'86400': {
				total: 21649,
				failed: 0,
				avg: 0,
				rate: 100
			}
		}
	},
	{
		label: 'RoyalMail Return Pudo',
		additional_label: '',
		periods: {
			'600': {
				total: 630,
				failed: 1,
				avg: 343,
				rate: 99.84
			},
			'3600': {
				total: 3529,
				failed: 3,
				avg: 353,
				rate: 99.91
			},
			'28800': {
				total: 8204,
				failed: 6,
				avg: 367,
				rate: 99.93
			},
			'86400': {
				total: 41790,
				failed: 26,
				avg: 347,
				rate: 99.94
			}
		}
	},
	{
		label: 'RoyalMail Return Tracking',
		additional_label: '',
		periods: {
			'600': {
				total: 2,
				failed: 0,
				avg: 0,
				rate: 100
			},
			'3600': {
				total: 3,
				failed: 0,
				avg: 0,
				rate: 100
			},
			'28800': {
				total: 12,
				failed: 0,
				avg: 0,
				rate: 100
			},
			'86400': {
				total: 36,
				failed: 0,
				avg: 0,
				rate: 100
			}
		}
	},
	{
		label: 'SDAExpress Return Booking',
		additional_label: '',
		periods: {
			'600': {
				total: 9,
				failed: 0,
				avg: 0,
				rate: 100
			},
			'3600': {
				total: 42,
				failed: 0,
				avg: 0,
				rate: 100
			},
			'28800': {
				total: 109,
				failed: 0,
				avg: 0,
				rate: 100
			},
			'86400': {
				total: 354,
				failed: 0,
				avg: 0,
				rate: 100
			}
		}
	},
	{
		label: 'SDAExpress Return Manifest',
		additional_label: '',
		periods: {
			'86400': {
				total: 1,
				failed: 0,
				avg: 0,
				rate: 100
			}
		}
	},
	{
		label: 'SDAExpress Return Pudo',
		additional_label: '',
		periods: {
			'28800': {
				total: 2,
				failed: 0,
				avg: 0,
				rate: 100
			},
			'86400': {
				total: 2,
				failed: 0,
				avg: 0,
				rate: 100
			}
		}
	},
	{
		label: 'SDAExpress Return Tracking',
		additional_label: '',
		periods: {
			'3600': {
				total: 2,
				failed: 0,
				avg: 0,
				rate: 100
			},
			'28800': {
				total: 8,
				failed: 0,
				avg: 0,
				rate: 100
			},
			'86400': {
				total: 24,
				failed: 0,
				avg: 0,
				rate: 100
			}
		}
	},
	{
		label: 'SEKO Direct Manifest',
		additional_label: '',
		periods: {
			'600': {
				total: 1,
				failed: 0,
				avg: 1549,
				rate: 100
			},
			'3600': {
				total: 1,
				failed: 0,
				avg: 1549,
				rate: 100
			},
			'28800': {
				total: 1,
				failed: 0,
				avg: 1549,
				rate: 100
			},
			'86400': {
				total: 3,
				failed: 0,
				avg: 1609,
				rate: 100
			}
		}
	},
	{
		label: 'SingPost Direct Booking',
		additional_label: '',
		periods: {
			'600': {
				total: 1,
				failed: 0,
				avg: 6084,
				rate: 100
			},
			'3600': {
				total: 9,
				failed: 0,
				avg: 6111,
				rate: 100
			},
			'28800': {
				total: 21,
				failed: 0,
				avg: 6158,
				rate: 100
			},
			'86400': {
				total: 55,
				failed: 0,
				avg: 6284,
				rate: 100
			}
		}
	},
	{
		label: 'SingPost Direct Pudo',
		additional_label: ''
	},
	{
		label: 'SingPost Direct Tracking',
		additional_label: '',
		periods: {
			'28800': {
				total: 4,
				failed: 0,
				avg: 0,
				rate: 100
			},
			'86400': {
				total: 12,
				failed: 0,
				avg: 0,
				rate: 100
			}
		}
	},
	{
		label: 'SingPost Pallet Booking',
		additional_label: '',
		periods: {
			'28800': {
				total: 2,
				failed: 0,
				avg: 0,
				rate: 100
			},
			'86400': {
				total: 2,
				failed: 0,
				avg: 0,
				rate: 100
			}
		}
	},
	{
		label: 'SingPost Return Booking',
		additional_label: '',
		periods: {
			'600': {
				total: 1,
				failed: 0,
				avg: 6242,
				rate: 100
			},
			'3600': {
				total: 14,
				failed: 0,
				avg: 6275,
				rate: 100
			},
			'28800': {
				total: 74,
				failed: 0,
				avg: 6243,
				rate: 100
			},
			'86400': {
				total: 157,
				failed: 0,
				avg: 6366,
				rate: 100
			}
		}
	},
	{
		label: 'SingPost Return Pudo',
		additional_label: '',
		periods: {
			'600': {
				total: 9,
				failed: 0,
				avg: 787,
				rate: 100
			},
			'3600': {
				total: 49,
				failed: 0,
				avg: 784,
				rate: 100
			},
			'28800': {
				total: 282,
				failed: 0,
				avg: 808,
				rate: 100
			},
			'86400': {
				total: 557,
				failed: 0,
				avg: 823,
				rate: 100
			}
		}
	},
	{
		label: 'SingPost Return Tracking',
		additional_label: '',
		periods: {
			'600': {
				total: 1,
				failed: 0,
				avg: 0,
				rate: 100
			},
			'3600': {
				total: 1,
				failed: 0,
				avg: 0,
				rate: 100
			},
			'28800': {
				total: 4,
				failed: 0,
				avg: 0,
				rate: 100
			},
			'86400': {
				total: 12,
				failed: 0,
				avg: 0,
				rate: 100
			}
		}
	},
	{
		label: 'Skynet Direct Tracking',
		additional_label: '',
		periods: {
			'86400': {
				total: 5,
				failed: 0,
				avg: 951,
				rate: 100
			}
		}
	},
	{
		label: 'Skynet Return Booking',
		additional_label: '',
		periods: {
			'600': {
				total: 1,
				failed: 0,
				avg: 5942,
				rate: 100
			},
			'3600': {
				total: 7,
				failed: 0,
				avg: 5528,
				rate: 100
			},
			'28800': {
				total: 26,
				failed: 0,
				avg: 9582,
				rate: 100
			},
			'86400': {
				total: 53,
				failed: 0,
				avg: 7419,
				rate: 100
			}
		}
	},
	{
		label: 'Skynet Return Tracking',
		additional_label: '',
		periods: {
			'28800': {
				total: 5888,
				failed: 1543,
				avg: 466,
				rate: 73.79
			},
			'86400': {
				total: 15099,
				failed: 1543,
				avg: 469,
				rate: 89.78
			}
		}
	},
	{
		label: 'SkyPostal Return Tracking',
		additional_label: '',
		periods: {
			'3600': {
				total: 2,
				failed: 0,
				avg: 605,
				rate: 100
			},
			'28800': {
				total: 8,
				failed: 0,
				avg: 642,
				rate: 100
			},
			'86400': {
				total: 24,
				failed: 0,
				avg: 651,
				rate: 100
			}
		}
	},
	{
		label: 'ToYou Return Pudo',
		additional_label: '',
		periods: {
			'28800': {
				total: 1,
				failed: 1,
				avg: 90002,
				rate: 0
			},
			'86400': {
				total: 1,
				failed: 1,
				avg: 90002,
				rate: 0
			}
		}
	},
	{
		label: 'UPS Return Pudo',
		additional_label: ''
	},
	{
		label: 'USPSEpacket Direct Booking',
		additional_label: '',
		periods: {
			'600': {
				total: 1,
				failed: 0,
				avg: 936,
				rate: 100
			},
			'3600': {
				total: 81,
				failed: 0,
				avg: 1199,
				rate: 100
			},
			'28800': {
				total: 865,
				failed: 0,
				avg: 2209,
				rate: 100
			},
			'86400': {
				total: 2083,
				failed: 0,
				avg: 1583,
				rate: 100
			}
		}
	},
	{
		label: 'USPSEpacket Direct Cancel',
		additional_label: '',
		periods: {
			'3600': {
				total: 15,
				failed: 0,
				avg: 560,
				rate: 100
			},
			'28800': {
				total: 114,
				failed: 0,
				avg: 575,
				rate: 100
			},
			'86400': {
				total: 115,
				failed: 0,
				avg: 578,
				rate: 100
			}
		}
	},
	{
		label: 'USPSEpacket Direct Tracking',
		additional_label: '',
		periods: {
			'600': {
				total: 2716,
				failed: 0,
				avg: 160,
				rate: 100
			},
			'3600': {
				total: 12709,
				failed: 0,
				avg: 167,
				rate: 100
			},
			'28800': {
				total: 157780,
				failed: 0,
				avg: 169,
				rate: 100
			},
			'86400': {
				total: 466450,
				failed: 0,
				avg: 144,
				rate: 100
			}
		}
	}
];

/*--------------------
 Barcodee ranges
 --------------------------*/
export const localBarcodeRanges = {
	ranges: [
		{
			id: '2',
			user_id: '1',
			carrier_id: '3',
			barcode_group: 'default',
			prefix: '',
			range_begin: '91501000',
			range_end: '91548160',
			range_left: '18341',
			range_current: '91529819'
		},
		{
			id: '3',
			user_id: '1',
			carrier_id: '4',
			barcode_group: 'default',
			prefix: '',
			range_begin: '10000000',
			range_end: '99999999',
			range_left: '89721906',
			range_current: '10278093'
		},
		{
			id: '4',
			user_id: '1',
			carrier_id: '3',
			barcode_group: 'wiggle_crc',
			prefix: '',
			range_begin: '93929711',
			range_end: '93949710',
			range_left: '17144',
			range_current: '93932566'
		},
		{
			id: '5',
			user_id: '377',
			carrier_id: '12',
			barcode_group: 'RangeS0133149L',
			prefix: '',
			range_begin: '1',
			range_end: '9999999',
			range_left: '9999990',
			range_current: '9'
		},
		{
			id: '6',
			user_id: '1',
			carrier_id: '2',
			barcode_group: 'default',
			prefix: '',
			range_begin: '11000005',
			range_end: '11500005',
			range_left: '364311',
			range_current: '11135694'
		},
		{
			id: '7',
			user_id: '1',
			carrier_id: '2',
			barcode_group: 'default',
			prefix: '',
			range_begin: '11500006',
			range_end: '12000006',
			range_left: '500000',
			range_current: '11500006'
		},
		{
			id: '8',
			user_id: '1',
			carrier_id: '9',
			barcode_group: 'default',
			prefix: '',
			range_begin: '1',
			range_end: '999999999999',
			range_left: '999999999333',
			range_current: '666'
		},
		{
			id: '15',
			user_id: '1',
			carrier_id: '17',
			barcode_group: 'warehouse',
			prefix: '',
			range_begin: '85933801',
			range_end: '86033800',
			range_left: '91315',
			range_current: '85942485'
		},
		{
			id: '16',
			user_id: '1',
			carrier_id: '17',
			barcode_group: 'boohoo',
			prefix: '',
			range_begin: '84633801',
			range_end: '85633800',
			range_left: '998918',
			range_current: '84634882'
		},
		// {
		// 	id: '17',
		// 	user_id: '1',
		// 	carrier_id: '17',
		// 	barcode_group: 'nastygal',
		// 	prefix: '',
		// 	range_begin: '85733801',
		// 	range_end: '85833800',
		// 	range_left: '98986',
		// 	range_current: '85734814'
		// },
		// {
		// 	id: '18',
		// 	user_id: '1',
		// 	carrier_id: '17',
		// 	barcode_group: 'plt',
		// 	prefix: '',
		// 	range_begin: '85633801',
		// 	range_end: '85733800',
		// 	range_left: '98988',
		// 	range_current: '85634812'
		// },
		// {
		// 	id: '19',
		// 	user_id: '1',
		// 	carrier_id: '17',
		// 	barcode_group: 'wiggle',
		// 	prefix: '',
		// 	range_begin: '85833801',
		// 	range_end: '85883800',
		// 	range_left: '38751',
		// 	range_current: '85845049'
		// },
		// {
		// 	id: '20',
		// 	user_id: '1',
		// 	carrier_id: '17',
		// 	barcode_group: '0449305000',
		// 	prefix: '',
		// 	range_begin: '2250000000',
		// 	range_end: '4294967295',
		// 	range_left: '2044915214',
		// 	range_current: '2250052081'
		// },
		// {
		// 	id: '21',
		// 	user_id: '1',
		// 	carrier_id: '17',
		// 	barcode_group: '0423546000',
		// 	prefix: '',
		// 	range_begin: '2250000000',
		// 	range_end: '4294967295',
		// 	range_left: '2044941944',
		// 	range_current: '2250025351'
		// },
		// {
		// 	id: '22',
		// 	user_id: '1',
		// 	carrier_id: '17',
		// 	barcode_group: '0187315000',
		// 	prefix: '',
		// 	range_begin: '2250000000',
		// 	range_end: '4294967295',
		// 	range_left: '2044967257',
		// 	range_current: '2250000038'
		// },
		// {
		// 	id: '23',
		// 	user_id: '1',
		// 	carrier_id: '17',
		// 	barcode_group: '0468669000',
		// 	prefix: '',
		// 	range_begin: '2250000000',
		// 	range_end: '4294967295',
		// 	range_left: '2044967288',
		// 	range_current: '2250000007'
		// },
		// {
		// 	id: '24',
		// 	user_id: '1',
		// 	carrier_id: '17',
		// 	barcode_group: '0477034000',
		// 	prefix: '',
		// 	range_begin: '2250000000',
		// 	range_end: '4294967295',
		// 	range_left: '2044967291',
		// 	range_current: '2250000004'
		// },
		// {
		// 	id: '27',
		// 	user_id: '1',
		// 	carrier_id: '17',
		// 	barcode_group: '0411168000',
		// 	prefix: '',
		// 	range_begin: '2250000000',
		// 	range_end: '4294967295',
		// 	range_left: '2044957050',
		// 	range_current: '2250010245'
		// },
		// {
		// 	id: '28',
		// 	user_id: '1',
		// 	carrier_id: '17',
		// 	barcode_group: 'crc',
		// 	prefix: '',
		// 	range_begin: '85883801',
		// 	range_end: '85933800',
		// 	range_left: '47069',
		// 	range_current: '85886731'
		// },
		// {
		// 	id: '29',
		// 	user_id: '1',
		// 	carrier_id: '17',
		// 	barcode_group: '0559736001',
		// 	prefix: '',
		// 	range_begin: '2250000000',
		// 	range_end: '4294967295',
		// 	range_left: '2044964365',
		// 	range_current: '2250002930'
		// },
		// {
		// 	id: '31',
		// 	user_id: '377',
		// 	carrier_id: '16',
		// 	barcode_group: 'default',
		// 	prefix: '',
		// 	range_begin: '1',
		// 	range_end: '9999999999',
		// 	range_left: '9999999994',
		// 	range_current: '5'
		// },
		// {
		// 	id: '33',
		// 	user_id: '1',
		// 	carrier_id: '17',
		// 	barcode_group: 'default',
		// 	prefix: '',
		// 	range_begin: '96245801',
		// 	range_end: '96345800',
		// 	range_left: '62764',
		// 	range_current: '96283036'
		// },
		// {
		// 	id: '36',
		// 	user_id: '1',
		// 	carrier_id: '17',
		// 	barcode_group: 'warehouse_qr',
		// 	prefix: '',
		// 	range_begin: '99144801',
		// 	range_end: '99244800',
		// 	range_left: '82327',
		// 	range_current: '99162473'
		// },
		// {
		// 	id: '39',
		// 	user_id: '377',
		// 	carrier_id: '23',
		// 	barcode_group: 'default',
		// 	prefix: '',
		// 	range_begin: '1',
		// 	range_end: '9999999999',
		// 	range_left: '9999999954',
		// 	range_current: '45'
		// },
		// {
		// 	id: '40',
		// 	user_id: '1',
		// 	carrier_id: '17',
		// 	barcode_group: 'arsenal',
		// 	prefix: '',
		// 	range_begin: '1624101',
		// 	range_end: '1629100',
		// 	range_left: '4927',
		// 	range_current: '1624173'
		// },
		// {
		// 	id: '41',
		// 	user_id: '1',
		// 	carrier_id: '17',
		// 	barcode_group: 'idle_man',
		// 	prefix: '',
		// 	range_begin: '1629101',
		// 	range_end: '1634100',
		// 	range_left: '4714',
		// 	range_current: '1629386'
		// },
		// {
		// 	id: '42',
		// 	user_id: '1',
		// 	carrier_id: '17',
		// 	barcode_group: 'raging_bull_tracked_returns_48',
		// 	prefix: '',
		// 	range_begin: '1621101',
		// 	range_end: '1623100',
		// 	range_left: '1893',
		// 	range_current: '1621207'
		// },
		// {
		// 	id: '44',
		// 	user_id: '1',
		// 	carrier_id: '17',
		// 	barcode_group: 'raging_bull_print_in_store',
		// 	prefix: '',
		// 	range_begin: '1623101',
		// 	range_end: '1624100',
		// 	range_left: '927',
		// 	range_current: '1623173'
		// },
		// {
		// 	id: '45',
		// 	user_id: '1',
		// 	carrier_id: '17',
		// 	barcode_group: '0517548000',
		// 	prefix: '',
		// 	range_begin: '1',
		// 	range_end: '4294967295',
		// 	range_left: '4294967116',
		// 	range_current: '179'
		// },
		// {
		// 	id: '46',
		// 	user_id: '1',
		// 	carrier_id: '17',
		// 	barcode_group: '0506573000',
		// 	prefix: '',
		// 	range_begin: '1',
		// 	range_end: '4294967295',
		// 	range_left: '4294967222',
		// 	range_current: '73'
		// },
		// {
		// 	id: '47',
		// 	user_id: '1',
		// 	carrier_id: '17',
		// 	barcode_group: '0083602000',
		// 	prefix: '',
		// 	range_begin: '1',
		// 	range_end: '4294967295',
		// 	range_left: '4294967009',
		// 	range_current: '286'
		// },
		// {
		// 	id: '48',
		// 	user_id: '378',
		// 	carrier_id: '23',
		// 	barcode_group: 'default',
		// 	prefix: '',
		// 	range_begin: '1',
		// 	range_end: '9999999999',
		// 	range_left: '9999999959',
		// 	range_current: '40'
		// },
		// {
		// 	id: '49',
		// 	user_id: '378',
		// 	carrier_id: '23',
		// 	barcode_group: 'colissiomo-with-signature',
		// 	prefix: '',
		// 	range_begin: '3159681153',
		// 	range_end: '3159684152',
		// 	range_left: '2997',
		// 	range_current: '3159681155'
		// },
		// {
		// 	id: '50',
		// 	user_id: '378',
		// 	carrier_id: '23',
		// 	barcode_group: 'colissiomo-without-signature',
		// 	prefix: '',
		// 	range_begin: '2627946481',
		// 	range_end: '2627951480',
		// 	range_left: '4994',
		// 	range_current: '2627946486'
		// },
		// {
		// 	id: '53',
		// 	user_id: '377',
		// 	carrier_id: '14',
		// 	barcode_group: 'default',
		// 	prefix: '',
		// 	range_begin: '1',
		// 	range_end: '999999999999',
		// 	range_left: '999999999994',
		// 	range_current: '5'
		// },
		// {
		// 	id: '54',
		// 	user_id: '377',
		// 	carrier_id: '23',
		// 	barcode_group: 'ein',
		// 	prefix: '',
		// 	range_begin: '1',
		// 	range_end: '9999999999',
		// 	range_left: '9999999998',
		// 	range_current: '1'
		// },
		// {
		// 	id: '55',
		// 	user_id: '0',
		// 	carrier_id: '0',
		// 	barcode_group: 'internal_tracking_number',
		// 	prefix: '',
		// 	range_begin: '1',
		// 	range_end: '9223372036854775807',
		// 	range_left: '9223372036854741149',
		// 	range_current: '34658'
		// },
		// {
		// 	id: '56',
		// 	user_id: '1',
		// 	carrier_id: '2',
		// 	barcode_group: 'asosbarcodeID',
		// 	prefix: '',
		// 	range_begin: '1000',
		// 	range_end: '2000',
		// 	range_left: '712',
		// 	range_current: '1288'
		// },
		// {
		// 	id: '57',
		// 	user_id: '382',
		// 	carrier_id: '2',
		// 	barcode_group: ' \tdefault ',
		// 	prefix: '',
		// 	range_begin: '11500007',
		// 	range_end: '12500007',
		// 	range_left: '1000000',
		// 	range_current: '11500007'
		// },
		// {
		// 	id: '58',
		// 	user_id: '382',
		// 	carrier_id: '9',
		// 	barcode_group: 'default',
		// 	prefix: '',
		// 	range_begin: '999999999976',
		// 	range_end: '9999999999767',
		// 	range_left: '8999999998907',
		// 	range_current: '1000000000860'
		// },
		// {
		// 	id: '60',
		// 	user_id: '382',
		// 	carrier_id: '22',
		// 	barcode_group: 'default',
		// 	prefix: '',
		// 	range_begin: '1',
		// 	range_end: '9999999999',
		// 	range_left: '9999998358',
		// 	range_current: '1641'
		// },
		// {
		// 	id: '61',
		// 	user_id: '0',
		// 	carrier_id: '0',
		// 	barcode_group: 'test-range',
		// 	prefix: '',
		// 	range_begin: '1',
		// 	range_end: '9223372036854775807',
		// 	range_left: '9223372036854697438',
		// 	range_current: '78369'
		// },
		// {
		// 	id: '62',
		// 	user_id: '382',
		// 	carrier_id: '49',
		// 	barcode_group: 'TEST',
		// 	prefix: '',
		// 	range_begin: '1',
		// 	range_end: '9999999999',
		// 	range_left: '9999999744',
		// 	range_current: '255'
		// },
		// {
		// 	id: '63',
		// 	user_id: '1',
		// 	carrier_id: '49',
		// 	barcode_group: 'TEST',
		// 	prefix: '',
		// 	range_begin: '1',
		// 	range_end: '9999999999',
		// 	range_left: '9999994726',
		// 	range_current: '5273'
		// },
		// {
		// 	id: '64',
		// 	user_id: '1',
		// 	carrier_id: '54',
		// 	barcode_group: '1986824',
		// 	prefix: '',
		// 	range_begin: '1',
		// 	range_end: '9999',
		// 	range_left: '9111',
		// 	range_current: '888'
		// },
		// {
		// 	id: '72',
		// 	user_id: '382',
		// 	carrier_id: '58',
		// 	barcode_group: 'EMS_Home',
		// 	prefix: 'ET',
		// 	range_begin: '30895',
		// 	range_end: '40500',
		// 	range_left: '8276',
		// 	range_current: '32224'
		// },
		// {
		// 	id: '73',
		// 	user_id: '382',
		// 	carrier_id: '58',
		// 	barcode_group: 'Prime',
		// 	prefix: 'LT',
		// 	range_begin: '5168',
		// 	range_end: '10176',
		// 	range_left: '3814',
		// 	range_current: '6362'
		// },
		// {
		// 	id: '74',
		// 	user_id: '382',
		// 	carrier_id: '58',
		// 	barcode_group: 'Registered',
		// 	prefix: 'RT',
		// 	range_begin: '296',
		// 	range_end: '10176',
		// 	range_left: '8842',
		// 	range_current: '1334'
		// },
		// {
		// 	id: '75',
		// 	user_id: '382',
		// 	carrier_id: '58',
		// 	barcode_group: 'IEP_TEST',
		// 	prefix: 'ET',
		// 	range_begin: '10601',
		// 	range_end: '20500',
		// 	range_left: '8639',
		// 	range_current: '11861'
		// },
		// {
		// 	id: '80',
		// 	user_id: '1',
		// 	carrier_id: '48',
		// 	barcode_group: 'default',
		// 	prefix: '',
		// 	range_begin: '1000',
		// 	range_end: '1000000',
		// 	range_left: '998941',
		// 	range_current: '1059'
		// },
		// {
		// 	id: '81',
		// 	user_id: '382',
		// 	carrier_id: '48',
		// 	barcode_group: 'default',
		// 	prefix: '',
		// 	range_begin: '1000',
		// 	range_end: '1000000',
		// 	range_left: '998999',
		// 	range_current: '1001'
		// },
		// {
		// 	id: '82',
		// 	user_id: '382',
		// 	carrier_id: '23',
		// 	barcode_group: 'default',
		// 	prefix: '',
		// 	range_begin: '62794648',
		// 	range_end: '2627946481',
		// 	range_left: '2565151372',
		// 	range_current: '62795109'
		// },
		// {
		// 	id: '83',
		// 	user_id: '1',
		// 	carrier_id: '41',
		// 	barcode_group: 'default',
		// 	prefix: '',
		// 	range_begin: '1',
		// 	range_end: '11111111111111',
		// 	range_left: '11111111110910',
		// 	range_current: '201'
		// },
		// {
		// 	id: '85',
		// 	user_id: '1',
		// 	carrier_id: '69',
		// 	barcode_group: 'new',
		// 	prefix: '',
		// 	range_begin: '1',
		// 	range_end: '12321321321',
		// 	range_left: '12321321185',
		// 	range_current: '136'
		// },
		// {
		// 	id: '86',
		// 	user_id: '1',
		// 	carrier_id: '67',
		// 	barcode_group: 'new',
		// 	prefix: '',
		// 	range_begin: '999994000',
		// 	range_end: '999999000',
		// 	range_left: '3104',
		// 	range_current: '999995896'
		// },
		// {
		// 	id: '89',
		// 	user_id: '1',
		// 	carrier_id: '70',
		// 	barcode_group: 'test2',
		// 	prefix: '8R',
		// 	range_begin: '105044800',
		// 	range_end: '105049000',
		// 	range_left: '3492',
		// 	range_current: '105045508'
		// },
		// {
		// 	id: '90',
		// 	user_id: '1',
		// 	carrier_id: '70',
		// 	barcode_group: 'test',
		// 	prefix: '8R',
		// 	range_begin: '1',
		// 	range_end: '11111111111',
		// 	range_left: '11111110324',
		// 	range_current: '787'
		// },
		// {
		// 	id: '91',
		// 	user_id: '1',
		// 	carrier_id: '74',
		// 	barcode_group: 'BR',
		// 	prefix: '',
		// 	range_begin: '1',
		// 	range_end: '7635999999',
		// 	range_left: '7635999990',
		// 	range_current: '9'
		// },
		// {
		// 	id: '92',
		// 	user_id: '1',
		// 	carrier_id: '82',
		// 	barcode_group: 'new',
		// 	prefix: 'TS',
		// 	range_begin: '1',
		// 	range_end: '31234234234',
		// 	range_left: '31234234233',
		// 	range_current: '1'
		// },
		// {
		// 	id: '97',
		// 	user_id: '1',
		// 	carrier_id: '22',
		// 	barcode_group: 'default',
		// 	prefix: '',
		// 	range_begin: '1',
		// 	range_end: '9999999999',
		// 	range_left: '9999998834',
		// 	range_current: '1165'
		// },
		// {
		// 	id: '99',
		// 	user_id: '1',
		// 	carrier_id: '90',
		// 	barcode_group: 'ZAP',
		// 	prefix: '',
		// 	range_begin: '1',
		// 	range_end: '99999999',
		// 	range_left: '99999576',
		// 	range_current: '423'
		// },
		// {
		// 	id: '100',
		// 	user_id: '1',
		// 	carrier_id: '16',
		// 	barcode_group: '323',
		// 	prefix: '2',
		// 	range_begin: '1111',
		// 	range_end: '2222222222',
		// 	range_left: '2222221111',
		// 	range_current: '1111'
		// },
		// {
		// 	id: '101',
		// 	user_id: '1',
		// 	carrier_id: '16',
		// 	barcode_group: 'test',
		// 	prefix: '',
		// 	range_begin: '1',
		// 	range_end: '5',
		// 	range_left: '4',
		// 	range_current: '1'
		// },
		// {
		// 	id: '102',
		// 	user_id: '1',
		// 	carrier_id: '16',
		// 	barcode_group: 'eqw',
		// 	prefix: 'w',
		// 	range_begin: '1',
		// 	range_end: '2222222',
		// 	range_left: '2222221',
		// 	range_current: '1'
		// },
		// {
		// 	id: '105',
		// 	user_id: '382',
		// 	carrier_id: '23',
		// 	barcode_group: 'colissiomo-with-signature',
		// 	prefix: '',
		// 	range_begin: '1',
		// 	range_end: '9999999960',
		// 	range_left: '9999999881',
		// 	range_current: '79'
		// },
		// {
		// 	id: '106',
		// 	user_id: '382',
		// 	carrier_id: '23',
		// 	barcode_group: 'colissiomo-without-signature',
		// 	prefix: '',
		// 	range_begin: '1',
		// 	range_end: '2565151792',
		// 	range_left: '2565151447',
		// 	range_current: '345'
		// },
		// {
		// 	id: '107',
		// 	user_id: '382',
		// 	carrier_id: '54',
		// 	barcode_group: '1986824',
		// 	prefix: '',
		// 	range_begin: '1',
		// 	range_end: '9999',
		// 	range_left: '9997',
		// 	range_current: '2'
		// },
		// {
		// 	id: '108',
		// 	user_id: '1',
		// 	carrier_id: '12',
		// 	barcode_group: 'RangeS0235149L',
		// 	prefix: '',
		// 	range_begin: '9000000',
		// 	range_end: '9999999',
		// 	range_left: '999866',
		// 	range_current: '9000133'
		// },
		// {
		// 	id: '110',
		// 	user_id: '382',
		// 	carrier_id: '12',
		// 	barcode_group: 'RangeS0235149L',
		// 	prefix: '',
		// 	range_begin: '8000000',
		// 	range_end: '8999999',
		// 	range_left: '999232',
		// 	range_current: '8000767'
		// },
		// {
		// 	id: '111',
		// 	user_id: '1',
		// 	carrier_id: '82',
		// 	barcode_group: 'TSB5',
		// 	prefix: '',
		// 	range_begin: '1',
		// 	range_end: '99999999',
		// 	range_left: '99999902',
		// 	range_current: '97'
		// },
		// {
		// 	id: '112',
		// 	user_id: '382',
		// 	carrier_id: '66',
		// 	barcode_group: 'range3',
		// 	prefix: '',
		// 	range_begin: '7635995000',
		// 	range_end: '7635996000',
		// 	range_left: '936',
		// 	range_current: '7635995064'
		// },
		// {
		// 	id: '113',
		// 	user_id: '382',
		// 	carrier_id: '19',
		// 	barcode_group: 'default',
		// 	prefix: '',
		// 	range_begin: '999997999',
		// 	range_end: '999999999',
		// 	range_left: '1895',
		// 	range_current: '999998104'
		// },
		// {
		// 	id: '119',
		// 	user_id: '383',
		// 	carrier_id: '23',
		// 	barcode_group: 'default',
		// 	prefix: '',
		// 	range_begin: '5000',
		// 	range_end: '5100',
		// 	range_left: '92',
		// 	range_current: '5008'
		// },
		// {
		// 	id: '120',
		// 	user_id: '382',
		// 	carrier_id: '14',
		// 	barcode_group: 'default',
		// 	prefix: '',
		// 	range_begin: '4000',
		// 	range_end: '9000',
		// 	range_left: '4921',
		// 	range_current: '4079'
		// },
		// {
		// 	id: '121',
		// 	user_id: '0',
		// 	carrier_id: '0',
		// 	barcode_group: 'pallet-range',
		// 	prefix: '',
		// 	range_begin: '1',
		// 	range_end: '9223372036854775807',
		// 	range_left: '9223372036854775746',
		// 	range_current: '61'
		// },
		// {
		// 	id: '122',
		// 	user_id: '1',
		// 	carrier_id: '16',
		// 	barcode_group: '323',
		// 	prefix: '',
		// 	range_begin: '3434',
		// 	range_end: '2343234',
		// 	range_left: '2339800',
		// 	range_current: '3434'
		// },
		// {
		// 	id: '123',
		// 	user_id: '1',
		// 	carrier_id: '16',
		// 	barcode_group: 'test',
		// 	prefix: '',
		// 	range_begin: '6',
		// 	range_end: '10',
		// 	range_left: '4',
		// 	range_current: '6'
		// },
		// {
		// 	id: '124',
		// 	user_id: '378',
		// 	carrier_id: '69',
		// 	barcode_group: 'test',
		// 	prefix: '',
		// 	range_begin: '1',
		// 	range_end: '100',
		// 	range_left: '99',
		// 	range_current: '1'
		// },
		// {
		// 	id: '127',
		// 	user_id: '1',
		// 	carrier_id: '69',
		// 	barcode_group: 'test',
		// 	prefix: '',
		// 	range_begin: '101',
		// 	range_end: '200',
		// 	range_left: '99',
		// 	range_current: '101'
		// },
		// {
		// 	id: '128',
		// 	user_id: '1',
		// 	carrier_id: '69',
		// 	barcode_group: 'test',
		// 	prefix: '',
		// 	range_begin: '201',
		// 	range_end: '300',
		// 	range_left: '99',
		// 	range_current: '201'
		// },
		// {
		// 	id: '129',
		// 	user_id: '1',
		// 	carrier_id: '25',
		// 	barcode_group: 'default',
		// 	prefix: '',
		// 	range_begin: '5000',
		// 	range_end: '15000',
		// 	range_left: '9591',
		// 	range_current: '5409'
		// },
		// {
		// 	id: '131',
		// 	user_id: '382',
		// 	carrier_id: '66',
		// 	barcode_group: 'range2',
		// 	prefix: '',
		// 	range_begin: '7635991500',
		// 	range_end: '7635995000',
		// 	range_left: '1577',
		// 	range_current: '7635993423'
		// },
		// {
		// 	id: '132',
		// 	user_id: '1',
		// 	carrier_id: '94',
		// 	barcode_group: 'default',
		// 	prefix: '',
		// 	range_begin: '61379300000',
		// 	range_end: '61379399999',
		// 	range_left: '99969',
		// 	range_current: '61379300030'
		// },
		// {
		// 	id: '136',
		// 	user_id: '382',
		// 	carrier_id: '66',
		// 	barcode_group: 'default',
		// 	prefix: '',
		// 	range_begin: '7635940001',
		// 	range_end: '7635984999',
		// 	range_left: '36957',
		// 	range_current: '7635948042'
		// },
		// {
		// 	id: '142',
		// 	user_id: '382',
		// 	carrier_id: '66',
		// 	barcode_group: 'default1',
		// 	prefix: '',
		// 	range_begin: '7636380000',
		// 	range_end: '7636399999',
		// 	range_left: '18867',
		// 	range_current: '7636381132'
		// },
		// {
		// 	id: '144',
		// 	user_id: '386',
		// 	carrier_id: '16',
		// 	barcode_group: 'ert',
		// 	prefix: '',
		// 	range_begin: '1',
		// 	range_end: '1111',
		// 	range_left: '1110',
		// 	range_current: '1'
		// },
		// {
		// 	id: '145',
		// 	user_id: '383',
		// 	carrier_id: '16',
		// 	barcode_group: 'qwe',
		// 	prefix: '',
		// 	range_begin: '1',
		// 	range_end: '222222222222',
		// 	range_left: '222222222221',
		// 	range_current: '1'
		// },
		// {
		// 	id: '146',
		// 	user_id: '1',
		// 	carrier_id: '54',
		// 	barcode_group: 'test',
		// 	prefix: '',
		// 	range_begin: '1',
		// 	range_end: '10000',
		// 	range_left: '9999',
		// 	range_current: '1'
		// },
		// {
		// 	id: '147',
		// 	user_id: '382',
		// 	carrier_id: '23',
		// 	barcode_group: 'test',
		// 	prefix: '',
		// 	range_begin: '1',
		// 	range_end: '1000',
		// 	range_left: '999',
		// 	range_current: '1'
		// },
		// {
		// 	id: '149',
		// 	user_id: '1',
		// 	carrier_id: '25',
		// 	barcode_group: 'test',
		// 	prefix: '',
		// 	range_begin: '1',
		// 	range_end: '10000',
		// 	range_left: '9997',
		// 	range_current: '3'
		// },
		// {
		// 	id: '150',
		// 	user_id: '1',
		// 	carrier_id: '96',
		// 	barcode_group: 'default',
		// 	prefix: '',
		// 	range_begin: '1',
		// 	range_end: '99999',
		// 	range_left: '99931',
		// 	range_current: '68'
		// },
		// {
		// 	id: '155',
		// 	user_id: '390',
		// 	carrier_id: '16',
		// 	barcode_group: 'qwe',
		// 	prefix: '',
		// 	range_begin: '1',
		// 	range_end: '111',
		// 	range_left: '110',
		// 	range_current: '1'
		// },
		// {
		// 	id: '156',
		// 	user_id: '381',
		// 	carrier_id: '16',
		// 	barcode_group: 'фывфыв',
		// 	prefix: '',
		// 	range_begin: '1',
		// 	range_end: '222222222',
		// 	range_left: '222222221',
		// 	range_current: '1'
		// },
		// {
		// 	id: '157',
		// 	user_id: '399',
		// 	carrier_id: '16',
		// 	barcode_group: 'fdfd',
		// 	prefix: '',
		// 	range_begin: '1',
		// 	range_end: '2321321',
		// 	range_left: '2321320',
		// 	range_current: '1'
		// },
		// {
		// 	id: '158',
		// 	user_id: '383',
		// 	carrier_id: '16',
		// 	barcode_group: 'dsa',
		// 	prefix: '',
		// 	range_begin: '1',
		// 	range_end: '3333',
		// 	range_left: '3332',
		// 	range_current: '1'
		// },
		// {
		// 	id: '162',
		// 	user_id: '405',
		// 	carrier_id: '16',
		// 	barcode_group: '111',
		// 	prefix: '',
		// 	range_begin: '11',
		// 	range_end: '1111',
		// 	range_left: '1100',
		// 	range_current: '11'
		// },
		// {
		// 	id: '163',
		// 	user_id: '1',
		// 	carrier_id: '99',
		// 	barcode_group: 'default',
		// 	prefix: '',
		// 	range_begin: '1',
		// 	range_end: '999999999',
		// 	range_left: '999999784',
		// 	range_current: '215'
		// },
		// {
		// 	id: '164',
		// 	user_id: '382',
		// 	carrier_id: '12',
		// 	barcode_group: 'RangeS0133149L',
		// 	prefix: '',
		// 	range_begin: '1',
		// 	range_end: '9999999',
		// 	range_left: '9999858',
		// 	range_current: '141'
		// },
		// {
		// 	id: '165',
		// 	user_id: '382',
		// 	carrier_id: '12',
		// 	barcode_group: 'RangeS02356E5A',
		// 	prefix: '',
		// 	range_begin: '1',
		// 	range_end: '9999999',
		// 	range_left: '9999823',
		// 	range_current: '176'
		// },
		// {
		// 	id: '166',
		// 	user_id: '382',
		// 	carrier_id: '12',
		// 	barcode_group: 'RangeS02366E5A',
		// 	prefix: '',
		// 	range_begin: '1',
		// 	range_end: '9999999',
		// 	range_left: '9999948',
		// 	range_current: '51'
		// },
		// {
		// 	id: '167',
		// 	user_id: '382',
		// 	carrier_id: '12',
		// 	barcode_group: 'RangeS0236149L',
		// 	prefix: '',
		// 	range_begin: '1',
		// 	range_end: '9999999',
		// 	range_left: '9999941',
		// 	range_current: '58'
		// },
		// {
		// 	id: '168',
		// 	user_id: '382',
		// 	carrier_id: '12',
		// 	barcode_group: 'RangeS01326E5A',
		// 	prefix: '',
		// 	range_begin: '1',
		// 	range_end: '9999999',
		// 	range_left: '9999876',
		// 	range_current: '123'
		// },
		// {
		// 	id: '169',
		// 	user_id: '382',
		// 	carrier_id: '12',
		// 	barcode_group: 'RangeS0132149L',
		// 	prefix: '',
		// 	range_begin: '1',
		// 	range_end: '9999999',
		// 	range_left: '9999870',
		// 	range_current: '129'
		// },
		// {
		// 	id: '170',
		// 	user_id: '383',
		// 	carrier_id: '16',
		// 	barcode_group: 'fff',
		// 	prefix: '',
		// 	range_begin: '1',
		// 	range_end: '2222',
		// 	range_left: '2221',
		// 	range_current: '1'
		// },
		// {
		// 	id: '171',
		// 	user_id: '382',
		// 	carrier_id: '62',
		// 	barcode_group: '1022342',
		// 	prefix: '',
		// 	range_begin: '1',
		// 	range_end: '99999999',
		// 	range_left: '99999897',
		// 	range_current: '102'
		// },
		// {
		// 	id: '172',
		// 	user_id: '382',
		// 	carrier_id: '62',
		// 	barcode_group: '298343276',
		// 	prefix: '',
		// 	range_begin: '1',
		// 	range_end: '99999',
		// 	range_left: '99983',
		// 	range_current: '16'
		// },
		// {
		// 	id: '173',
		// 	user_id: '406',
		// 	carrier_id: '16',
		// 	barcode_group: 'ввв',
		// 	prefix: '',
		// 	range_begin: '-1223',
		// 	range_end: '13',
		// 	range_left: '1236',
		// 	range_current: '-1223'
		// },
		// {
		// 	id: '174',
		// 	user_id: '1',
		// 	carrier_id: '48',
		// 	barcode_group: '4518F',
		// 	prefix: '',
		// 	range_begin: '1000',
		// 	range_end: '1000000',
		// 	range_left: '998890',
		// 	range_current: '1110'
		// },
		// {
		// 	id: '175',
		// 	user_id: '382',
		// 	carrier_id: '48',
		// 	barcode_group: '4518F',
		// 	prefix: '',
		// 	range_begin: '1000',
		// 	range_end: '1000000',
		// 	range_left: '998949',
		// 	range_current: '1051'
		// },
		// {
		// 	id: '176',
		// 	user_id: '1',
		// 	carrier_id: '106',
		// 	barcode_group: '006',
		// 	prefix: '',
		// 	range_begin: '0',
		// 	range_end: '999999',
		// 	range_left: '999996',
		// 	range_current: '3'
		// },
		// {
		// 	id: '180',
		// 	user_id: '377',
		// 	carrier_id: '23',
		// 	barcode_group: 'LG',
		// 	prefix: '',
		// 	range_begin: '1',
		// 	range_end: '9999999999',
		// 	range_left: '9999999854',
		// 	range_current: '145'
		// },
		// {
		// 	id: '181',
		// 	user_id: '377',
		// 	carrier_id: '23',
		// 	barcode_group: 'GA',
		// 	prefix: '',
		// 	range_begin: '1',
		// 	range_end: '9999999999',
		// 	range_left: '9999999898',
		// 	range_current: '101'
		// },
		// {
		// 	id: '182',
		// 	user_id: '378',
		// 	carrier_id: '23',
		// 	barcode_group: 'N9',
		// 	prefix: '',
		// 	range_begin: '1',
		// 	range_end: '9999999999',
		// 	range_left: '9999999859',
		// 	range_current: '140'
		// },
		// {
		// 	id: '183',
		// 	user_id: '382',
		// 	carrier_id: '23',
		// 	barcode_group: 'LG',
		// 	prefix: '',
		// 	range_begin: '1',
		// 	range_end: '9999999999',
		// 	range_left: '9999999990',
		// 	range_current: '9'
		// },
		// {
		// 	id: '184',
		// 	user_id: '382',
		// 	carrier_id: '23',
		// 	barcode_group: 'NS',
		// 	prefix: '',
		// 	range_begin: '1',
		// 	range_end: '9999999999',
		// 	range_left: '9999999996',
		// 	range_current: '3'
		// },
		// {
		// 	id: '188',
		// 	user_id: '382',
		// 	carrier_id: '3',
		// 	barcode_group: 'default',
		// 	prefix: '',
		// 	range_begin: '91548160',
		// 	range_end: '92548160',
		// 	range_left: '999989',
		// 	range_current: '91548171'
		// },
		// {
		// 	id: '197',
		// 	user_id: '1',
		// 	carrier_id: '17',
		// 	barcode_group: 'default',
		// 	prefix: 'TT',
		// 	range_begin: '1',
		// 	range_end: '99999999',
		// 	range_left: '99999998',
		// 	range_current: '1'
		// },
		// {
		// 	id: '198',
		// 	user_id: '382',
		// 	carrier_id: '116',
		// 	barcode_group: 'default',
		// 	prefix: 'AR',
		// 	range_begin: '397000',
		// 	range_end: '421999',
		// 	range_left: '24998',
		// 	range_current: '397001'
		// },
		// {
		// 	id: '200',
		// 	user_id: '387',
		// 	carrier_id: '92',
		// 	barcode_group: 'DD',
		// 	prefix: 'DF',
		// 	range_begin: '1',
		// 	range_end: '4',
		// 	range_left: '3',
		// 	range_current: '1'
		// },
		// {
		// 	id: '203',
		// 	user_id: '382',
		// 	carrier_id: '127',
		// 	barcode_group: '902788311',
		// 	prefix: '',
		// 	range_begin: '1',
		// 	range_end: '9999999',
		// 	range_left: '9999996',
		// 	range_current: '3'
		// },
		// {
		// 	id: '204',
		// 	user_id: '382',
		// 	carrier_id: '23',
		// 	barcode_group: 'PR',
		// 	prefix: '',
		// 	range_begin: '1',
		// 	range_end: '9999999999',
		// 	range_left: '9999999997',
		// 	range_current: '2'
		// },
		// {
		// 	id: '205',
		// 	user_id: '382',
		// 	carrier_id: '129',
		// 	barcode_group: 'TT',
		// 	prefix: '',
		// 	range_begin: '1',
		// 	range_end: '9999999999',
		// 	range_left: '9999999998',
		// 	range_current: '1'
		// },
		// {
		// 	id: '208',
		// 	user_id: '1',
		// 	carrier_id: '54',
		// 	barcode_group: 'default',
		// 	prefix: '',
		// 	range_begin: '500000000',
		// 	range_end: '700000000',
		// 	range_left: '199999829',
		// 	range_current: '500000171'
		// },
		// {
		// 	id: '209',
		// 	user_id: '382',
		// 	carrier_id: '54',
		// 	barcode_group: 'default',
		// 	prefix: '',
		// 	range_begin: '77767677',
		// 	range_end: '7776767777',
		// 	range_left: '7699000082',
		// 	range_current: '77767695'
		// },
		// {
		// 	id: '210',
		// 	user_id: '382',
		// 	carrier_id: '27',
		// 	barcode_group: 'batch_number',
		// 	prefix: '',
		// 	range_begin: '1',
		// 	range_end: '99999',
		// 	range_left: '99975',
		// 	range_current: '24'
		// },
		// {
		// 	id: '211',
		// 	user_id: '382',
		// 	carrier_id: '129',
		// 	barcode_group: 'DZ',
		// 	prefix: '',
		// 	range_begin: '1',
		// 	range_end: '9999999999',
		// 	range_left: '9999999990',
		// 	range_current: '9'
		// },
		// {
		// 	id: '212',
		// 	user_id: '383',
		// 	carrier_id: '16',
		// 	barcode_group: '5555',
		// 	prefix: '111',
		// 	range_begin: '1',
		// 	range_end: '100',
		// 	range_left: '99',
		// 	range_current: '1'
		// },
		// {
		// 	id: '214',
		// 	user_id: '1',
		// 	carrier_id: '59',
		// 	barcode_group: 'ir_default',
		// 	prefix: 'TK',
		// 	range_begin: '11610000',
		// 	range_end: '11660000',
		// 	range_left: '49998',
		// 	range_current: '11610002'
		// },
		// {
		// 	id: '215',
		// 	user_id: '1',
		// 	carrier_id: '41',
		// 	barcode_group: 'new',
		// 	prefix: '',
		// 	range_begin: '51343100',
		// 	range_end: '51661099',
		// 	range_left: '317973',
		// 	range_current: '51343126'
		// },
		// {
		// 	id: '216',
		// 	user_id: '382',
		// 	carrier_id: '129',
		// 	barcode_group: 'DZ.ManifestNumber',
		// 	prefix: '',
		// 	range_begin: '1',
		// 	range_end: '9999999999',
		// 	range_left: '9999999994',
		// 	range_current: '5'
		// },
		// {
		// 	id: '218',
		// 	user_id: '382',
		// 	carrier_id: '63',
		// 	barcode_group: '2809',
		// 	prefix: '',
		// 	range_begin: '1',
		// 	range_end: '99999999',
		// 	range_left: '99999990',
		// 	range_current: '9'
		// },
		// {
		// 	id: '219',
		// 	user_id: '382',
		// 	carrier_id: '63',
		// 	barcode_group: '102331',
		// 	prefix: '',
		// 	range_begin: '1',
		// 	range_end: '99999999',
		// 	range_left: '99999980',
		// 	range_current: '19'
		// },
		// {
		// 	id: '220',
		// 	user_id: '382',
		// 	carrier_id: '137',
		// 	barcode_group: '102331',
		// 	prefix: '',
		// 	range_begin: '1',
		// 	range_end: '99999999',
		// 	range_left: '99999918',
		// 	range_current: '81'
		// },
		// {
		// 	id: '221',
		// 	user_id: '1',
		// 	carrier_id: '48',
		// 	barcode_group: '1234567',
		// 	prefix: '',
		// 	range_begin: '1',
		// 	range_end: '999999',
		// 	range_left: '999997',
		// 	range_current: '2'
		// },
		// {
		// 	id: '222',
		// 	user_id: '1',
		// 	carrier_id: '48',
		// 	barcode_group: '1234',
		// 	prefix: '',
		// 	range_begin: '1',
		// 	range_end: '9999999',
		// 	range_left: '9999996',
		// 	range_current: '3'
		// },
		// {
		// 	id: '223',
		// 	user_id: '1',
		// 	carrier_id: '48',
		// 	barcode_group: '12345',
		// 	prefix: '',
		// 	range_begin: '1',
		// 	range_end: '999999',
		// 	range_left: '999997',
		// 	range_current: '2'
		// }
	]
}

/*--------------------
 Health Check Details Page
 --------------------------*/

export const localsHealthStatusesDetailsData = {
	hasMoreItems: true,
	items: [
		{
			id: 62018,
			date: 1602237611,
			exec_time: 1905,
			status: '1',
			user_id: '38256'
		},
		{
			id: 60223,
			date: 1601901683,
			exec_time: 2543,
			status: '1',
			user_id: '382'
		},
		{
			id: 56603,
			date: 1601291309,
			exec_time: 2402,
			status: '1',
			user_id: '382'
		},
		{
			id: 55503,
			date: 1601290291,
			exec_time: 2103,
			status: '1',
			user_id: '382'
		},
		{
			id: 54073,
			date: 1600874397,
			exec_time: 1624,
			status: '1',
			user_id: '382'
		},
		{
			id: 53465,
			date: 1600775425,
			exec_time: 1579,
			status: '1',
			user_id: '382'
		},
		{
			id: 53144,
			date: 1600689360,
			exec_time: 1743,
			status: '1',
			user_id: '382'
		},
		{
			id: 52962,
			date: 1600686572,
			exec_time: 1746,
			status: '1',
			user_id: '382'
		},
		{
			id: 52567,
			date: 1600363803,
			exec_time: 2815,
			status: '1',
			user_id: '382'
		},
		{
			id: 50967,
			date: 1599818451,
			exec_time: 1759,
			status: '1',
			user_id: '382'
		},
		{
			id: 50966,
			date: 1599818439,
			exec_time: 1737,
			status: '1',
			user_id: '382'
		},
		{
			id: 50965,
			date: 1599818435,
			exec_time: 1733,
			status: '1',
			user_id: '382'
		},
		{
			id: 50964,
			date: 1599818430,
			exec_time: 1798,
			status: '1',
			user_id: '382'
		},
		{
			id: 50963,
			date: 1599818424,
			exec_time: 1939,
			status: '1',
			user_id: '382'
		},
		{
			id: 50962,
			date: 1599818415,
			exec_time: 2107,
			status: '1',
			user_id: '382'
		},
		{
			id: 50961,
			date: 1599818407,
			exec_time: 2382,
			status: '1',
			user_id: '382'
		},
		{
			id: 48078,
			date: 1599238417,
			exec_time: 2109,
			status: '1',
			user_id: '382'
		},
		{
			id: 46270,
			date: 1598976749,
			exec_time: 2371,
			status: '1',
			user_id: '382'
		},
		{
			id: 45201,
			date: 1598620453,
			exec_time: 1743,
			status: '1',
			user_id: '382'
		},
		{
			id: 45005,
			date: 1598612024,
			exec_time: 1863,
			status: '1',
			user_id: '382'
		},
		{
			id: 33672,
			date: 1596726505,
			exec_time: 2196,
			status: '1',
			user_id: '382'
		},
		{
			id: 33523,
			date: 1596719955,
			exec_time: 2018,
			status: '1',
			user_id: '382'
		},
		{
			id: 32493,
			date: 1596213344,
			exec_time: 1982,
			status: '1',
			user_id: '382'
		},
		{
			id: 32055,
			date: 1596116423,
			exec_time: 3906,
			status: '1',
			user_id: '382'
		},
		{
			id: 31981,
			date: 1596034259,
			exec_time: 2259,
			status: '1',
			user_id: '382'
		},
		{
			id: 31322,
			date: 1595942693,
			exec_time: 2301,
			status: '1',
			user_id: '382'
		},
		{
			id: 31317,
			date: 1595942689,
			exec_time: 2078,
			status: '1',
			user_id: '382'
		},
		{
			id: 31312,
			date: 1595942685,
			exec_time: 1710,
			status: '1',
			user_id: '382'
		},
		{
			id: 31311,
			date: 1595942681,
			exec_time: 1889,
			status: '1',
			user_id: '382'
		},
		{
			id: 31310,
			date: 1595942677,
			exec_time: 1825,
			status: '1',
			user_id: '382'
		},
		{
			id: 31309,
			date: 1595942674,
			exec_time: 2050,
			status: '1',
			user_id: '382'
		},
		{
			id: 31308,
			date: 1595942670,
			exec_time: 1929,
			status: '1',
			user_id: '382'
		}
	]
};
export const localHealthStatussesDetailsViewList = {
	requestId: '2031c6vg3xeiopop',
	requestsList: [
		{
			request: {
				url: 'https://ws.colissimo.fr/sls-ws/SlsServiceWS',
				headers: 'Content-Type: text/xml;charset=UTF-8',
				body: '<soapenv:Envelope xmlns:soapenv="http://schemas.xmlsoap.org/soap/envelope/" xmlns:sls="http://sls.ws.coliposte.fr">\n  <soapenv:Header/>\n  <soapenv:Body>\n    <sls:planPickup>\n      <planPickupRequest>\n        <contractNumber>837646</contractNumber>\n        <password>r3b0und!24</password>\n        <parcelNumber>8R01050453914</parcelNumber>\n        <mailBoxPickingDate>2020-11-06</mailBoxPickingDate>\n        <sender>\n          <companyName>Sender Test Company Name</companyName>\n          <lastName>Test</lastName>\n          <firstName>Test Test</firstName>\n          <line0/>\n          <line1/>\n          <line2>Rue Emile`\' Dubois</line2>\n          <line3>Bravo Food Market, Bravo Food Marke</line3>\n          <zipCode>75014</zipCode>\n          <city>Paris</city>\n          <countryCode>FR</countryCode>\n          <email>test.sender@mail.com</email>\n          <phoneNumber>0070951234567</phoneNumber>\n        </sender>\n      </planPickupRequest>\n    </sls:planPickup>\n  </soapenv:Body>\n</soapenv:Envelope>'
			},
			response: {
				code: 200,
				headers: 'HTTP/1.1 200 OK\r\nDate: Thu, 05 Nov 2020 15:46:41 GMT\r\nServer: Apache\r\nContent-Length: 556\r\nVary: User-Agent\r\nAccess-Control-Allow-Origin: *\r\nP3P: policyref="/w3c/p3p.xml", CP="NOI DSP COR NID CUR ADM DEV OUR BUS"\r\nContent-Type: multipart/related; type="application/xop+xml"; boundary="uuid:cc94abe4-b2a8-4247-884f-d512328ed5c2"; start="<root.message@cxf.apache.org>"; start-info="text/xml"',
				body: '--uuid:cc94abe4-b2a8-4247-884f-d512328ed5c2\r\nContent-Type: application/xop+xml; charset=UTF-8; type="text/xml";\r\nContent-Transfer-Encoding: binary\r\nContent-ID: <root.message@cxf.apache.org>\r\n\r\n<soap:Envelope xmlns:soap="http://schemas.xmlsoap.org/soap/envelope/"><soap:Body><ns2:planPickupResponse xmlns:ns2="http://sls.ws.coliposte.fr"><return><messages><id>30801</id><messageContent>Colis inexistant</messageContent><type>ERROR</type></messages></return></ns2:planPickupResponse></soap:Body></soap:Envelope>\r\n--uuid:cc94abe4-b2a8-4247-884f-d512328ed5c2--'
			}
		}
	],
	label: 'Colissimo Return Booking',
	additionalLabel: '',
	user_id: '1',
	status: '0'
};

/*--------------------
 Carrier Errors Mapping
 --------------------------*/

/*--------------------
 Label Builder
 --------------------------*/

/*--------------------
 App Version
 --------------------------*/

/*--------------------
 Cron Robots
 --------------------------*/

/*--------------------
 API console
 --------------------------*/

/*--------------------
Pudo points
 --------------------------*/
