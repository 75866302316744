import React, { useState } from 'react';
import { useGlobal } from 'reactn';

import useAuthentication from '../hooks/authentication/useAuthentication';
import './UserInfo.scss';
import CustomButton from '../UI/CustomButton/CustomButton';
import Modal from '../UI/Modal/Modal';
import Dialog from '../UI/Dialog/Dialog';


const UserInfo = ({customClassName}) => {

	const [userName] = useGlobal('userName');
	const { logout } = useAuthentication();
	const [showWarning, setShowWarning] = useState(false);

	const iconLetter = userName && userName.charAt(0);
	return (
		<div className={`user-info ${customClassName || ''}`}>
			<div className="user-name">
				<span className="icon-letter"><span>{iconLetter}</span></span><span>{userName}</span>
			</div>
			<CustomButton
				onClick={() => setShowWarning(true)}
				addedClassName="pl-0 pr-0"
				iconLeft="logout"
				kind="text"
				small
				id="log-out"
			/>
			<Modal
				show={showWarning}
				close={() => {
					setShowWarning(false);
				}}
				bsSize="sm"
				customClass="small-dialog"
			>
				<Dialog
					type="danger"
					headerText="Warning!"
					bodyText="You are going to logout. Continue?"
					iconName="report"
					actionButtonText="Logout"
					cancelButtonText="Stay"
					actionFn={logout}
					closeFn={() => {
						setShowWarning(false);
					}}
				/>
			</Modal>
		</div>
	);
};


export default React.memo(UserInfo);
