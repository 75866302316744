import React, { useEffect, useState, useCallback } from 'react';
import { Route, Switch } from 'react-router-dom';
import { useRouter } from '../components/hooks/useRouter';

import RequestLogFilter from '../components/forms/filters/RequestLogFilter/RequestLogFilter';
import RequestsLogPanel from '../components/RequestsLog/RequestsLogPanel/RequestsLogPanel';
import Spinner from '../components/Spinner/Spinner';
import useSapiService from '../components/hooks/useSapiService';
import RequestsLogPageContext from '../context/pagesContext/requestsLogPageContext';
import usePageFilter from '../components/hooks/usePageFilter';
import RequestLogDetails from '../components/RequestsLog/RequestsLogPanel/RequestLogDetails';
import { localRequestLogData } from '../localTestingData';
import AToolsHealthCheckDetailsView
	from '../components/aToolsHealthCheckDetails/aToolsHealthCheckDetailsView/AToolsHealthCheckDetailsView';
import ErrorIndicator from '../components/ErrorIndicator/ErrorIndicator';
import PageLayout from '../components/pageLayout/PageLayout';

const RequestLogPage = () => {
	const { match, history, location } = useRouter();
	const [requestLogsData, setRequestLogData] = useState(null);

	const {
		currentFilterData,
		resetFilterForm,
		register,
		errors,
		handleSubmit,
		setValue,
		reset,
		currentPage,
		getCurrentPage,
		changeCurrentPage,
		changeQuantity,
		applyFilter,
		selectedResponseCodes,
		selectedUsers,
		selectedServices,
		getSelectedResponseCodes,
		getSelectedUsers,
		getSelectedServices,
	} = usePageFilter({ history, match, location });

	const {
		getRequestsLogListReq,
		isFetching,
		error,
		isProduction,
	} = useSapiService();

	const targetPageIsCurrent = (location.state && location.state.pageFrom) === 'requests_log';

	useEffect(function fromOuterLink() {
		if (targetPageIsCurrent) {
			getRequestsLogList(location.search);
		}
	}, [targetPageIsCurrent]); //eslint-disable-line

	useEffect(function defaultLoad() {
		if (!targetPageIsCurrent) {
			getRequestsLogList(location.search);
		}
	}, []); //eslint-disable-line

	function resetRequestLogFilter() {
		resetFilterForm();
		getRequestsLogList();
	}

	function getLocalRequestList() {
		console.log('USING LOCAL Requests log DATA');
		setRequestLogData(localRequestLogData);
	}

	const getRequestsLogList = useCallback((queryData = '') => {
		return isProduction()
			? getRequestsLogListReq(queryData)
				.then(({ data }) => {
					setRequestLogData(data);
				})
			: getLocalRequestList();
	}, [isProduction, getRequestsLogListReq]);

	const filterHandler = useCallback(
		data => {
			applyFilter(data, getRequestsLogList);
		},
		[applyFilter, getRequestsLogList],
	);

	const changeCurrPageHandler = useCallback(data => {
		changeCurrentPage(data, getRequestsLogList);
	}, [changeCurrentPage, getRequestsLogList]);

	const changeCurrQuantityHandler = useCallback(data => {
		changeQuantity(data, getRequestsLogList);
	}, [changeQuantity, getRequestsLogList]);

	const topBarContent = <RequestLogFilter
		currentData={currentFilterData}
	/>;
	const pageContent = <>
		{!requestLogsData && isFetching && <Spinner />}
		{!requestLogsData && !isFetching && <ErrorIndicator globalError />}
		{requestLogsData && <RequestsLogPanel
			currentData={currentFilterData}
			requestLogsData={requestLogsData}
		/>}
		<Switch>
			<Route
				path="/requests_log/request_details/:id"
				render={(props) => <AToolsHealthCheckDetailsView {...props} />} />
			<Route
				path="/requests_log/:id"
				render={(props) => <RequestLogDetails {...props} />} />
		</Switch>
	</>;

	return (
		<>
			<RequestsLogPageContext.Provider value={{
				requestLogsData,
				currentPage,
				changeCurrentPage,
				getCurrentPage,
				changeQuantity,
				resetRequestLogFilter,
				isFetching,
				error,
				reset,
				register,
				errors,
				handleSubmit,
				filterHandler,
				changeCurrPageHandler,
				changeCurrQuantityHandler,
				setValue,
				getRequestsLogList,
				selectedResponseCodes,
				selectedUsers,
				selectedServices,
				getSelectedResponseCodes,
				getSelectedUsers,
				getSelectedServices,
			}}>
				<PageLayout
					addedClassName="request-log-page"
					topBarContent={topBarContent}
					pageContent={pageContent}
				/>
			</RequestsLogPageContext.Provider>
		</>
	);
};

export default RequestLogPage;

