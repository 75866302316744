import React, { useState, useCallback } from 'react';
import { useGlobal } from 'reactn';
import { useMediaQuery } from 'beautiful-react-hooks';
import { createCurrentSideBarConfigFn } from '../../routes/routesMenuConfig';
import NavBarItem from './NavBarItem';

const NavBar = () => {
	const [targetItem, setTargetItem] = useState('');
	const [accessUrl] = useGlobal('accessUrl');
	const [currentPageUrl] = useGlobal('currentPageUrl');
	const isLargeScreen = useMediaQuery('(min-width: 1200px)');
	const currentSideBarConfig = createCurrentSideBarConfigFn(accessUrl);

	const hasActiveChildFn = useCallback(
		(submenuArr, currUrl) =>
			submenuArr &&
			submenuArr.some(el => {
				return el.path ? el.path.split('/')[1] === currUrl : false;
			}),
		[]
	);

	const listItems = currentSideBarConfig.map(item => {
		if (
			(item.text === targetItem && !!item.subItem) ||
			(hasActiveChildFn(item.subItem, currentPageUrl) && isLargeScreen)
		) {
			item = { ...item, isExpanded: true };
		}
		return (
			<NavBarItem
				key={item.text}
				text={item.text}
				url={item.path}
				icon={item.icon}
				submenu={item.subItem}
				// isExpanded={isLargeScreen ? true : item.isExpanded }
				setTargetItem={setTargetItem}
				hasActiveChildFn={hasActiveChildFn}
			/>
		);
	});
	return <ul className="nav">{listItems}</ul>;
};

export default React.memo(NavBar);
