import React from 'react';
import {ReactComponent as NotFoundImage } from '../assets/images/404.svg';
import CustomButton from '../components/UI/CustomButton/CustomButton';
import { useRouter } from '../components/hooks/useRouter';
import './NotFoundPage.scss';


const NotFoundPage = () => {
	const { history } = useRouter()
	return (
		<div className="not-found-page">
			<div className="image-wrapper">
				<NotFoundImage/>
			</div>
			<div className="info">
				<h1>Opps! Page not found</h1>
				<div className="subinfo">Don`t worry. Return to the previous page.</div>
			</div>
			<CustomButton
				buttonText="back to previous page"
				onClick={history.goBack}
			/>
		</div>
	);
};

export default NotFoundPage;
