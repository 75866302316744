import { useCallback } from 'react';
import { useRouter } from './useRouter';

const useDialogType = () => {

	const {history, match, location} = useRouter();
	const searchString = location.search || '';
	const edit = useCallback((id) =>  {
		history.push({
			pathname: `${match.path}/${id}`,
			search: `${searchString}`,
		});
	}, [searchString, match.path]); //eslint-disable-line

	const create = useCallback (() => {
		history.push({
			pathname: `${match.path}/create`,
			search: `${searchString}`,
		});
	}, [searchString, match.path]); //eslint-disable-line

	const view = useCallback((id) => {
		history.push({
			pathname: `${match.path}/${id}`,
			search: `${searchString}`,
		});
	}, [searchString, match.path]); //eslint-disable-line

	const viewParcelLabel = useCallback(
		({ id, isFromDetails = false }) => {
			history.push({
				pathname: `/parcels/label/${id}`,
				search: `${searchString}`,
				state: { isFromDetails },
			});
		},
		[history, searchString]
	);

	const viewPalletLabel = useCallback(
		({ id, isFromDetails = false }) => {
			history.push({
				pathname: `/pallets/label/${id}`,
				search: `${searchString}`,
				state: { isFromDetails },
			});
		},
		[history, searchString]
	);


	const viewInvoice = useCallback(
		({ id, isFromDetails = true }) => {
			history.push({
				pathname: `/parcels/invoice/${id}`,
				search: `${searchString}`,
				state: { isFromDetails },
			});
		},
		[history, searchString]
	);

	const viewRequestDetails = useCallback(
		id => {
			history.push({
				pathname: `${match.path}/request_details/${id}`,
				search: `${searchString}`,
			});
		},
		[history, searchString]); //eslint-disable-line

	const viewErrorMappingExample = useCallback(
		id => {
			history.push({
				pathname: `${match.path}/example/${id}`,
				search: `${searchString}`,
			});
		},
		[history, searchString, match.path]
	);

	function goBack() {
		if (location.state && location.state.isFromDetails) {
			return history.push({
				pathname: `/${match.url.split('/').splice(1)[0]}/${match.params.id}`,
				search: `${searchString}`,
			});
		}
		if (location.state && location.state === 'details') {
			return history.push({
				pathname: `/${match.url.split('/').splice(1)[0]}/${location.state}`,
				search: `${searchString}`,
			});
		}

		if (
			match.url.split('/').includes('details') &&
			match.url.split('/').includes('healthcheck')
		) {
			return history.push({
				pathname: `/${match.url.split('/').splice(1)[0]}/details`,
				search: `${searchString}`,
			});
		}
		return history.push({
			pathname: `/${match.url.split('/').splice(1)[0]}`,
			search: `${searchString}`,
		});
	}

	return {
		edit,
		view,
		create,
		viewParcelLabel,
		viewPalletLabel,
		viewInvoice,
		viewRequestDetails,
		viewErrorMappingExample,
		goBack,
	};
};

export default useDialogType;
