import React, {useState} from 'react';
import './CredentialsDetailsPanel.scss';
import CustomInput from "../UI/СustomInput/CustomInput";
import CustomButton from "../UI/CustomButton/CustomButton";

const CredentialsGroup = ({environment, client, credentials, setCredentials}) => {

    const [isOpen, setIsOpen] = useState(client === 'default');
    const toggleGroup = () => {
        setIsOpen(!isOpen)
    }
    const changeCredential = (e, credentialName) => {
        credentials[environment][client][credentialName] = e.target.value;
        setCredentials(credentials);
    }

    return (
        <div className={`credentials-group ${isOpen ? 'is-open' : ''}`}>
            <div className="row justify-content-between cursor-pointer" onClick={toggleGroup}>
                <h5>{client}</h5>
                <CustomButton
                    iconLeft={`${isOpen ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}`}
                    addedClassName="pt-0"
                    kind="text"
                />
            </div>
            <div className={`row ${isOpen ? 'is-open' : 'd-none'}`}>
                {Object.keys(credentials[environment][client]).map(credentialName => (
                    <CustomInput
                        key={credentialName}
                        type="text"
                        itemName="client_id"
                        autoComplete="off"
                        formGroupClass="col-xl-6 col-md-6 col-12 shifted"
                        label={credentialName}
                        defaultValue={credentials[environment][client][credentialName]}
                        onChange={(e) => changeCredential(e, credentialName)}
                    />
                ))}
            </div>
        </div>
    );
};

export default CredentialsGroup;
