import React from 'react';
import './SubMessage.scss';


const SubMessage = ({styleClass='', children}) => {
	return (
		<div className={`sub-message ${styleClass}`}>
			{children}
		</div>
	);
};

export default SubMessage;
