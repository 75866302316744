import React, { useContext, useState, useEffect } from 'react';
import CsvDownloader from 'react-csv-downloader';
import PageDataPanel from '../UI/PageDataPanel/PageDataPanel';
import CustomButton from '../UI/CustomButton/CustomButton';
import BarcodesPageContext from '../../context/pagesContext/barcodesPageContext';
import { useMediaQuery } from 'beautiful-react-hooks';
import { getBarcodesCsvData, reduceBarcodesGroups } from '../../helpers/barcodesFuctions';
import { useRouter } from '../hooks/useRouter';
import './BarcodesPanel.scss';
import useDialogType from '../hooks/useDialogType';
import BarcodesView from './BarcodesView';
import { useGlobal } from 'reactn';
import CustomInput from '../UI/СustomInput/CustomInput';
import useSearchAdvanced from '../hooks/useSearchAdvanced';
import RadioButtonsGroup from '../UI/RadioButtonGroup/RadioButtonGroup';
import { parse, stringify } from 'query-string';
import useListSort from '../hooks/useListSort';
import Modal from '../UI/Modal/Modal';
import Dialog from '../UI/Dialog/Dialog';
import useModal from '../hooks/useModal';
import NoDataMessage from '../UI/NoDataMessage/NoDataMessage';


const BarcodesPanel = () => {
	const {
		isFetching,
		barcodesData,
		getBarcodesData,
		deleteBarcodeRange
	} = useContext(BarcodesPageContext);


	const { isOpenModal, showModal, hideModal } = useModal();
	const [commonListsObj] = useGlobal('commonListsObj');
	const {carriers, users} = commonListsObj;
	const { ranges } = barcodesData || {};
	const reducedRanges = reduceBarcodesGroups(ranges, carriers, users);

	const {
		searchResultArr,
		searchDataStr,
		onChangeFn
	} = useSearchAdvanced(reducedRanges,[ 'carrierName', 'userName', 'barcode_group']);
	const {history, match, location} = useRouter();
	const queryParams = parse(location.search);
	const [sortParam, setSortParam] = useState(queryParams.sorting || 'acs');
	const [delBarcodeId, setDelBarcodeId] = useState(null);

	const { create } = useDialogType({history, match, location});
	const isLargeScreen = useMediaQuery('(min-width: 1200px)');
	const isLargestScreen = useMediaQuery('(min-width: 1400px)');
	const {numberObjSort} = useListSort();


	useEffect(()=> {
		const queryString = stringify({ ...queryParams, sorting: sortParam } );
		history.push({search: `?${queryString}`});
	}, [sortParam, searchDataStr]); //eslint-disable-line


const barcodesArr = searchDataStr ? searchResultArr : reducedRanges;
	const sorted = numberObjSort([...barcodesArr], 'remaining_percent_all', sortParam === 'desc');

	const onSortingChangeFn = (data) => {
		setSortParam(data);
	}

	const deleteHandler = ({ barcodeId }) => {
		showWarning(barcodeId);
	}

	function showWarning(barcodeId) {
		setDelBarcodeId(barcodeId)
		showModal();
	}

	const buttonsList = [
		{ name: 'acs', icon: 'sort' },
		{ name: 'desc', icon: 'sort' },
	];

	const barcodesColumns = [
		{
			id: 'userName',
			displayName: 'User',
		},
		{
			id: 'carrierName',
			displayName: 'Carrier',
		},
		{
			id: 'barcode_group',
			displayName: 'Barcode group',
		},
		{
			id: 'prefix',
			displayName: 'Prefix',
		},
		{
			id: 'rangeBegin',
			displayName: 'Range Begin',
		},
		{
			id: 'rangeEnd',
			displayName: 'Range End',
		},
		{
			id: 'remaining',
			displayName: 'Remaining',
		},
		{
			id: 'remainingPercent',
			displayName: '%',
		}
	];

	const barcodesDatas = getBarcodesCsvData(sorted).flat();

	const panelHeading = (
		<>
			{isLargeScreen &&<CustomInput
				iconLeft="search"
				formGroupClass="mb-0"
				type="text"
				itemName="healthCheckSearch"
				placeholder="Carrier, user or barcode group"
				value={searchDataStr}
				autoComplete="off"
				onChange={(e) => onChangeFn(e)}
				autoFocus={true}
				addedInputClass="barcodes-search"
			/> }
			{isLargeScreen && <RadioButtonsGroup
				buttonsList={buttonsList}
				onRadioChange={onSortingChangeFn}
				initialActiveButton={sortParam}
			/>}
			{isLargeScreen &&
			<CsvDownloader
				filename="barcode_ranges"
				className="download-button-wrap"
				separator=";"
				wrapColumnChar=""
				columns={barcodesColumns}
				datas={barcodesDatas}>
				<CustomButton
					addedClassName="download-button"
					iconLeft="get_app"
					kind="secondary"
					buttonText="download csv"
				/>
			</CsvDownloader> }
			<CustomButton
				isAnimated={isFetching}
				addedClassName="refresh-button"
				iconLeft="refresh"
				kind="secondary"
				onClick={() => getBarcodesData(location.search)}
			/>
			<CustomButton
				addedClassName="ml-0"
				iconLeft="add_circle"
				kind="black"
				buttonText="Create barcode "
				onClick={create}
			/>
		</>
	);


	const panelHeadingMobile = (
		<>
			<CustomInput
				iconLeft="search"
				type="text"
				itemName="healthCheckSearch"
				placeholder="Carrier, user or barcode group"
				value={searchDataStr}
				autoComplete="off"
				onChange={(e) => onChangeFn(e)}
				autoFocus={true}
				addedInputClass="barcodes-search"
			/>
			<RadioButtonsGroup
				buttonsList={buttonsList}
				onRadioChange={onSortingChangeFn}
				initialActiveButton={sortParam}
			/>
			<CsvDownloader
				filename="barcode_ranges"
				separator=";"
				wrapColumnChar=""
				columns={barcodesColumns}
				datas={barcodesDatas}>
				<CustomButton
					addedClassName="download-button"
					iconLeft="get_app"
					kind="secondary"
					buttonText="download csv"
				/>
			</CsvDownloader>
		</>
	)

		const panelContent = barcodesData && <>
		<BarcodesView
			barcodesRangesData={sorted}
			viewType={isLargestScreen ? "table" : 'cards'}
			deleteFn={deleteHandler}
			/>
	</>;

	return (
		<>
		<PageDataPanel
			panelHeadingMobile={panelHeadingMobile}
			panelHeading={panelHeading}
			panelContent={sorted.length > 0 ? panelContent : <NoDataMessage/>}
			addedClassName="barcodes-panel"
		/>
	<Modal show={isOpenModal} close={hideModal} customClass="small-dialog">
		<Dialog
			type="danger"
			headerText="Warning!"
			bodyText="Barcode range will be deleted. Continue?"
			iconName="remove_circle"
			actionButtonText="Delete"
			actionFn={() => {
				deleteBarcodeRange(delBarcodeId);
				hideModal();
			}}
			closeFn={() => {
				setDelBarcodeId(null);
				hideModal()
			}}
		/>
	</Modal>
			</>
	);
};

export default BarcodesPanel;
