import React from 'react';

import './Spinner.scss';

/**
 *
 * @param colorClass - "dark" or "light"
 */
const Spinner = ({colorClass='dark'}) => {
    return (
      <div className="spinner">
          <div className={`lds-dual-ring ${colorClass}`}/>
      </div>

    );
};

export default Spinner;
