import React, {Fragment} from 'react';

import { Card } from 'react-bootstrap';
import './AToolsHealthCheckListItem.scss';
import { useRouter } from '../../../hooks/useRouter';
import useTooltip from '../../../hooks/useTooltip';
import Portal from '../../../UI/Portal/Portal';
import Tooltip from '../../../UI/Tooltip/Tooltip';
import Icon from '../../../UI/Icon/Icon';

const AToolsHealthCheckListItem = ({ itemData }) => {

	const { periods, additional_label, label } = itemData;
	const { match, history } = useRouter();
	const {isOn, showTooltip, coordinates, hideTooltip, tooltipData} = useTooltip();
	const defaultPeriods = {
		'86400': {
			total: '',
			failed: ``,
			avg: ``,
			rate: ``,
		},
		'28800': {
			total: '',
			failed: ``,
			avg: ``,
			rate: ``,
		},
		'3600': {
			total: ``,
			failed: ``,
			avg: ``,
			rate: ``,
		},
		'600': {
			total: ``,
			failed: ``,
			avg: ``,
			rate: ``,
		},
	};

	const fullPeriods2 = Object.entries(defaultPeriods).reduce((acc, curr) => {
		const [key, value] = curr;
		acc = {
			...acc,
			[`${key}`]: (periods && periods[`${key}`] ? periods[`${key}`] : value),
		};
		return acc;
	}, {});

	const periodsArr = Object.entries(fullPeriods2).reverse();

	//3600 * 24, // 24 hours
	//3600 * 8, // 8 hours
	//3600, // 1 hour
	//600, // 10 minutes

	const timePeriodTitle = period => {
		switch (period) {
			case '86400':
				return '24h';
			case '28800':
				return '8h';
			case '3600':
				return '1h';
			case '600':
				return '10m';
			default:
				return 'Undefined Period';
		}
	};

	const rateColorsClass = (rate) => {
		if(rate){
			if (+rate >= 0 && +rate <= 25) {
				return 'text-danger'
			}
			if (+rate > 25 && +rate < 76) {
				return 'text-warning'
			}
			if (+rate > 75) {
				return 'text-success'
			}
		}
		return '';
	}

		const periodsList = periodsArr.map(([key, value]) => {
		const hRequest = `${value.total ||'-'}/${value.failed.toString() || '-'}`;
		const hResTime = `${value.avg ? value.avg + 'ms' : '-'}`
		return (
			<Fragment key={key}>
				<div className="period-title grid-cell">{timePeriodTitle(key)}</div>
				<div className={`health-rate grid-cell ${rateColorsClass(value.rate)}`}>
					{`${ value.rate || value.rate === 0 ? value.rate + '%' : '-'}`}
				</div>
				<div
					className="health-requests grid-cell"
					onMouseEnter={(e) => {
						showTooltip(e.currentTarget, hRequest);
					}}
					onMouseLeave={hideTooltip}>
					{hRequest}
				</div>
				<div className="health-response-time grid-cell"
						 onMouseEnter={(e) => {
							 showTooltip(e.currentTarget, hResTime);
						 }}
						 onMouseLeave={hideTooltip}>{hResTime}</div>
			</Fragment>
		);
	});

	const okIcon = <Icon iconName="check_circle" iconClass="text-success" size={20}/>
	const warningIcon = <Icon iconName="warning" iconClass="text-warning" size={20}/>
	const errorIcon = <Icon iconName="report" iconClass="text-danger" size={20}/>
	const nullIcon = <Icon iconName="circle" iconClass="invisible" size={20}/>

	const icons = {
		'well': okIcon,
		'issue': warningIcon,
		'critical': errorIcon
	}

	const detailsClickHandler = () => {
		history.push(`${match.url}/details?label=${label}&additional_label=${additional_label}`)
	}

	return (
			<Card className="card-item health-check-list-item">
				<Card.Header onClick={detailsClickHandler}>
					<div className="heading">
						<div className="title">
							<span>{label} {additional_label}</span>
							<Icon iconName="keyboard_arrow_right" size={20}/>
						</div>
						{icons[itemData.healthStatus] || nullIcon}
					</div>
				</Card.Header>
				<Card.Body>
					<div className="statuses-heading">
							<div className="">Period</div>
							<div className="">Health</div>
							<div className="">Total/Failed</div>
							<div className="">Response Time</div>
					</div>
					<div className="statuses-content">{periodsList}</div>
					{isOn && <Portal>
						<Tooltip text={tooltipData} coordinates={coordinates} />
					</Portal>}
				</Card.Body>
			</Card>
	);
};

export default AToolsHealthCheckListItem;
