import React, {useContext, useEffect} from 'react';
import PageDataPanel from '../UI/PageDataPanel/PageDataPanel';
import TrackingMapPageContext from '../../context/pagesContext/trackingMapPageContext';
import {useRouter} from '../hooks/useRouter';
import CustomInput from '../UI/СustomInput/CustomInput';
import useSearchAdvanced from '../hooks/useSearchAdvanced';
import {parse, stringify} from 'query-string';
import NoDataMessage from '../UI/NoDataMessage/NoDataMessage';
import './TrackingMapPanel.scss';
import CustomButton from "../UI/CustomButton/CustomButton";


const TrackingMapPanel = () => {
    const {
        carriers: carriersList,
    } = useContext(TrackingMapPageContext);
    const {
        searchResultArr,
        searchDataStr,
        onChangeFn
    } = useSearchAdvanced(carriersList, ['carrier']);
    const carriers = searchDataStr ? searchResultArr : carriersList;
    const {history, location, match} = useRouter();
    const queryParams = parse(location.search);


    useEffect(() => {
        const queryString = stringify({...queryParams});
        history.push({search: `?${queryString}`});
    }, [searchDataStr]); //eslint-disable-line

    const panelHeading = (
        <>
            <div className="d-flex flex-column">
                <CustomInput
                    iconLeft="search"
                    formGroupClass="mb-0"
                    type="text"
                    itemName="carriersSearch"
                    placeholder="Carrier name"
                    value={searchDataStr}
                    autoComplete="off"
                    onChange={(e) => onChangeFn(e)}
                    autoFocus={true}
                    addedInputClass="carriers-search"
                />
            </div>
            <div></div>
        </>
    );

    const panelContent = carriers && <div>
        <table className="table tracking-map-table-view">
            <thead>
            <tr>
                <th>Carrier</th>
                <th/>
            </tr>
            </thead>
            <tbody>
            {carriers && carriers.map(carrier => (
                <tr key={carrier.carrier}>
                    <td><span>{carrier.carrier}</span></td>
                    <td align="right">
                        <CustomButton
                            linkTo={`${match.path}/details/${carrier.carrier}`}
                            iconLeft="app_registration"
                            kind="secondary"
                            onClick={() => true}
                            tooltip="Edit Statuses"
                            addedClassName="edit-statuses"
                        />
                    </td>
                </tr>
            ))}
            </tbody>
        </table>
    </div>;

    return (
        <PageDataPanel
            panelHeading={panelHeading}
            panelContent={carriers ? panelContent : <NoDataMessage/>}
            mobileSwitcher={false}
        />
    );
};

export default TrackingMapPanel;
