import React, { useContext, useEffect, useRef, useState } from 'react';
import { useGlobal } from 'reactn';
import useCommonData from '../../../hooks/useCommonData';
import FilterLayout from '../FilterLayout';
import DropdownCheckboxList from '../../../UI/DropdownCheckboxList/DropdownCheckboxList';
import CustomInput from '../../../UI/СustomInput/CustomInput';
import RequestsLogPageContext from '../../../../context/pagesContext/requestsLogPageContext';
import useArrayFromObject from '../../../hooks/useArrayFromObject';
import DropdownSelect from '../../../UI/DropdownSelect/DropdownSelect';
import DateTimePickTool from '../../../UI/DateTimePickTool/DateTimePickTool';
import usePageFilterError from '../../../hooks/usePageFilterError';
import CustomSelectAdvanced from '../../../UI/CustomSelect/CustomSelectAdvanced';

const RequestLogFilter = ({
	currentData,
}) => {

	const [commonListsArr] = useGlobal('commonListsArr');
	// const [commonListsObj] = useGlobal('commonListsObj');
	// const { currentUser: { isSuperAdmin }} = commonListsObj;
	const { hasError, errorHandler } = usePageFilterError();

	const {
		requestLogsData,
		resetRequestLogFilter,
		register,
		handleSubmit,
		filterHandler,
		selectedUsers,
		selectedResponseCodes,
		selectedServices,
		getSelectedUsers,
		getSelectedServices,
		getSelectedResponseCodes,
		setValue,
		isFetching,
		errors
	} = useContext(RequestsLogPageContext);

	const resetRef = useRef();
	const resetDateRange = resetRef.current;
	const [resetTrigger, setResetTrigger] = useState(0);

	const changeTrigger = () => {
		setResetTrigger(resetTrigger + 1);
	};

	const handleResetFilter = () => {
		if(resetDateRange){
			resetDateRange();
		}
		resetRequestLogFilter();
		changeTrigger();
	};

	const {
		user_ids,
		service_ids,
		date_from,
		date_to,
		http_response_codes,
	} = currentData;

	const { users = [], availableServices = [] } = commonListsArr;
	const { uriList, codesList } = requestLogsData || {};

	const globalSearchFieldValidation = register({
		validate: value => {
			return (value.trim().length === 0 || value.trim().length > 2) || 'Minimum 3 symbols or empty'
		}
	})

	const {
		requestMethodListData,
	} = useCommonData();

	const { addEmptyValue, changeArrForSelectList } = useArrayFromObject();

	useEffect(() => {
			currentData && Object.entries(currentData).forEach(([name, value]) => setValue(name, value));
			user_ids && getSelectedUsers(user_ids);
			service_ids && getSelectedServices(service_ids);
			http_response_codes && getSelectedResponseCodes(http_response_codes);
	}, [currentData, user_ids, service_ids, http_response_codes]); //eslint-disable-line

	return (
		<FilterLayout
			filterFunc={filterHandler}
			handleSubmit={handleSubmit}
			discardFilterFunc={handleResetFilter}
			isFetching={isFetching}
			hasError={hasError}
		>
			<>
				<DateTimePickTool
					resetRef={resetRef}
					register={register}
					addedClassname="col-xxl-4 col-lg-6 col-md-6 col-12"
					dateFrom={date_from}
					dateTo={date_to}
					onError={errorHandler}
					hasTime
					autoApply
				/>
				{/*{!isSuperAdmin && <CustomInput*/}
				{/*	formGroupClass="col-xl-4 col-md-6 col-12 shifted"*/}
				{/*	type="text"*/}
				{/*	itemName="request_id"*/}
				{/*	label="Request ID"*/}
				{/*	register={register}*/}
				{/*/>}*/}
				<CustomInput
					formGroupClass="col-xl-4 col-md-6 col-12 shifted"
					type="text"
					itemName="global_search"
					label="Global search"
					register={globalSearchFieldValidation}
					errors={errors}
				/>
				<DropdownCheckboxList
					additionalClasses="col-xl-2 col-md-6 col-12 shifted"
					title="Resp Code"
					listArr={changeArrForSelectList(codesList)}
					register={register}
					checkedListArr={selectedResponseCodes || []}
					getSelectedArray={getSelectedResponseCodes}
				/>
				<CustomSelectAdvanced
					formGroupClass="col-xl-2 col-md-6 col-12 shifted"
					itemName="request_method"
					label="Req method"
					register={register}
					setValue={setValue}
					defaultValue={currentData["request_method"] || ''}
					selectOptionsList={addEmptyValue(requestMethodListData, 'Any', '') || []}
					outerTrigger={resetTrigger}
				/>
				<CustomInput
					formGroupClass="col-xl-2 col-md-6 col-12 shifted"
					type="text"
					itemName="ext_time"
					label="Ext time(seconds)"
					register={register}
				/>
				<DropdownCheckboxList
					additionalClasses="col-xl-3 col-md-6 col-12 shifted"
					title="Users"
					listArr={users}
					register={register}
					checkedListArr={selectedUsers || []}
					getSelectedArray={getSelectedUsers}
				/>
				<CustomInput
					formGroupClass="col-xxl-4 col-xl-5 col-md-6 col-12 shifted"
					type="text"
					itemName="host"
					label="Host"
					register={register}
				/>
				<DropdownSelect
					formGroupClass="col-xxl-3 col-xl-4 col-md-6 col-12 shifted"
					itemName="uri"
					label="URI"
					setValue={setValue}
					register={register}
					selectOptionsList={changeArrForSelectList(uriList || []) || []} />
				<CustomInput
					formGroupClass="col-xl-3 col-md-6 col-12 shifted"
					type="text"
					itemName="ip"
					label="IP address"
					register={register}
				/>
				<DropdownCheckboxList
					title="Carrier service"
					listArr={availableServices}
					register={register}
					additionalClasses="col-xxl-4 col-xl-5 col-md-6 col-12 shifted"
					checkedListArr={selectedServices || []}
					getSelectedArray={getSelectedServices}
				/>
			</>
		</FilterLayout>
	);
};

export default RequestLogFilter;
