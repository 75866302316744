import React from 'react';
import ScrollUpButton from "react-scroll-up-button";
import ButtonIcon from '../ButtonIcon/ButtonIcon';
import './ToTopButton.scss';


const ToTopButton = () => {
	return (
		<div className="to-top-button">
			<ScrollUpButton
				ContainerClassName="scroll-container"
				TransitionClassName="scroll-transition"
			>
				<ButtonIcon
				iconName="arrow_upward"
				variant="outline-light"
				size={20}/>
			</ScrollUpButton>
		</div>
	);
};

export default ToTopButton;
