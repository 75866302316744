import React from 'react';

const requestsLogPageContext = React.createContext({
	requestLogsData: {},
	resetRequestLogFilter: ()=>{},
	currentPage: '',
	quantity: '',
	changeCurrentPage: () => {
	},
	changeQuantity: () => {
	},
	isFetching: false,
	error: '',
	reset: ()=>{},
	register: ()=>{},
	handleSubmit: ()=>{},
	setValue: ()=> {},
	getCurrentPage: ()=> {},
	searchString: '',
	setSearchString: ()=> {},
	getRequestsLogList: ()=> {},
	control: {}
});

export default requestsLogPageContext;