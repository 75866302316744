import React from 'react';
import Form from 'react-bootstrap/Form';
import ModalDetails from "../UI/Modal/ModalDetails";
import Modal from "../UI/Modal/Modal";
import CustomButton from "../UI/CustomButton/CustomButton";
import CustomInput from "../UI/СustomInput/CustomInput";
import {useForm} from "react-hook-form";

const EditStatusModal = ({isOpenModal, hideModal, editedStatus, updateTrackingStatus, trackingStatuses}) => {

    const {handleSubmit, register, errors} = useForm();

    const headerLeft = (
        <div className="header-info">
            <h2>{'Edit status'}</h2>
        </div>);
    const detailsContent = (<div>
            <Form>
                <div className="row">
                    <CustomInput
                        formGroupClass="col-6 shifted mt-3"
                        type="text"
                        itemName="carrierStatus"
                        defaultValue={editedStatus.carrier_status}
                        label="Carrier Status"
                        register={register}
                        errors={errors}
                        required
                    />
                    <CustomInput
                        formGroupClass="col-6 shifted mt-3"
                        type="text"
                        itemName="sapiStatus"
                        defaultValue={editedStatus.status}
                        label="SAPI Status"
                        register={register}
                        errors={errors}
                        required
                    />
                    <CustomInput
                        formGroupClass="col-12 shifted mt-3"
                        type="text"
                        itemName="carrierStatusDescription"
                        defaultValue={editedStatus.status_description}
                        label="Carrier Status Description"
                        register={register}
                        errors={errors}
                        required
                    />
                </div>
            </Form>
            <div className="d-flex flex-row flex-wrap mt-2">
                <strong>Available SAPI Statuses:</strong> {Object.keys(trackingStatuses).map(key => (
                <span key={key} className="mx-3 mb-2">{key} ({trackingStatuses[key]})</span>))}
            </div>
        </div>
    );
    const footerContent = (
        <Form.Group controlId="formGridState" className="m-0">
            <div className="button-wrapper d-flex justify-content-between">
                <CustomButton
                    buttonText="Cancel"
                    kind="secondary"
                    onClick={() => hideModal()}
                />
                <CustomButton
                    buttonText="Update"
                    kind="primary"
                    onClick={handleSubmit(updateTrackingStatus)}
                />
            </div>
        </Form.Group>
    );

    return (
        <Modal show={isOpenModal} close={hideModal}>
            <ModalDetails
                customClass="create-tracking-status"
                headerLeft={headerLeft}
                detailsContent={detailsContent}
                footerContent={footerContent}
            />
        </Modal>
    );
}

export default EditStatusModal;