import RequestsLogPage from '../pages/RequestsLogPage';
import HealthCheckPage from '../pages/HealthCheckPage';
import HealthCheckDetailsPage from '../pages/HealthCheckDetailsPage';
import AToolsHealthCheckDetailsView from '../components/aToolsHealthCheckDetails/aToolsHealthCheckDetailsView/AToolsHealthCheckDetailsView.js';
import NotFoundPage from '../pages/NotFoundPage';
import BarcodesPage from '../pages/BarcodesPage/BarcodesPage';
import CredentialsPage from '../pages/CredentialsPage/CredentialsPage';
import CredentialsDetailsPage from "../pages/CredentialsDetailsPage/CredentialsDetailsPage";
import TrackingMapPage from "../pages/TrackingMapPage/TrackingMapPage";
import TrackingMapDetailsPage from "../pages/TrackingMapDetailsPage/TrackingMapDetailsPage";

const HOME_PAGE = '/requests_log';

export const fullRoutingConfig = [
	{
		text: 'Request Log',
		url: '/requests_log',
		path: '/requests_log',
		component: RequestsLogPage,
	},
	{
		url: '/healthcheck/detail',
		path: '/healthcheck/details',
		component: HealthCheckDetailsPage,
	},
	{
		text: 'Health Check',
		url: '/healthcheck',
		path: '/healthcheck',
		component: HealthCheckPage,
	},
	{
		url: '/healthcheck/detail',
		path: '/healthcheck/request_details/:request_id',
		component: AToolsHealthCheckDetailsView,
	},
	{
		text: 'Barcodes',
		url: '/barcode_ranges',
		path: '/barcode_ranges',
		component: BarcodesPage,
	},
	{
		url: '/credentials/details',
		path: '/credentials/details/:service_id',
		component: CredentialsDetailsPage,
	},
	{
		text: 'Credentials',
		url: '/credentials',
		path: '/credentials',
		component: CredentialsPage,
	},
	{
		url: '/tracking_map/details',
		path: '/tracking_map/details/:carrier_name',
		component: TrackingMapDetailsPage,
	},
	{
		text: 'Tracking Map',
		url: '/tracking_map',
		path: '/tracking_map',
		component: TrackingMapPage,
	},
	{
		from: '/',
		to: '/requests_log',
		exact: true
	},
	{
		from: '/login',
		to: `${sessionStorage.getItem('lastUrl') || HOME_PAGE}`,
		exact: true
	},
	{
		type: 'notFound',
		component: NotFoundPage
	}
];

export const createCurrentRoutesFn = (accessUrl) => fullRoutingConfig.reduce((acc, curr) => {
	//parent item or redirect or 404 page
	if (accessUrl.includes(curr.url) || curr.from || curr.to || curr.type ) {
		acc.push(curr);
	}
	if (curr.subItem) {  //child item
		curr.subItem.forEach(el => {
			if (accessUrl.includes(el.url)) {
				acc.push(el);
			}
		});
	}
	return acc;
}, []);

export const createCurrentSideBarConfigFn = (accessUrl) => fullRoutingConfig.reduce((acc, curr) => {
	if (curr.text) {
		if (accessUrl.includes(curr.url)) {
			acc.push(curr);
		}
		if (curr.subItem) {
			const subItem = curr.subItem.filter((el) => {
				return (accessUrl.includes(el.url) && el.text) || el.external;
			});
			if (subItem.length > 0) {
				acc.push({...curr, subItem});
			}
		}
	}
	return acc;
}, []);
