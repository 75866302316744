import React, { useContext } from 'react';
import RadioButtonsGroup from '../../UI/RadioButtonGroup/RadioButtonGroup';
import AToolsHealthCheckList from './AToolsHealthCheckList';

import HealthCheckPageContext from '../../../context/pagesContext/healthCheckPageContext';
import PageDataPanel from '../../UI/PageDataPanel/PageDataPanel';
import './AToolsHealthCheckPanel.scss';
import CustomInput from '../../UI/СustomInput/CustomInput';
import useSearch from '../../hooks/useSearch';
import useFilter from '../../hooks/useFilter';
import CustomButton from '../../UI/CustomButton/CustomButton';
import { useRouter } from '../../hooks/useRouter';
import NoDataMessage from '../../UI/NoDataMessage/NoDataMessage';

const AToolsHealthCheckPanel = () => {
	const { location } = useRouter();
	const {
		healthStatusesList,
		getHealthStatuses,
		isFetching
	} = useContext(HealthCheckPageContext);

	const cases = ['all', 'well', 'issue', 'critical'];

	const {
		searchResultArr,
		searchDataStr,
		onChangeFn
	} = useSearch(healthStatusesList, 'label', 'additional_label', 'ru');

	const { filteredResultArr, onFilterChangeFn, initialData } = useFilter(searchResultArr, 'healthStatus', cases);

	const buttonsList = [
		{ name: 'all', label: 'All' },
		{ name: 'issue', label: 'Issue' },
		{ name: 'critical', label: 'Critical' },
	];

	const healthCheckArr = location.search ? filteredResultArr : healthStatusesList;

	const panelHeading = <>
		<CustomInput
			iconLeft="search"
			formGroupClass="mb-0 d-none d-xl-inline-flex"
			type="text"
			itemName="healthCheckSearch"
			placeholder="Type service name"
			value={searchDataStr}
			autoComplete="off"
			onChange={(e) => onChangeFn(e)}
			autoFocus={true}
		/>
		<RadioButtonsGroup
			buttonsList={buttonsList}
			onRadioChange={onFilterChangeFn}
			initialActiveButton={initialData}
			customClass="d-none d-xl-inline-flex"
		/>
		<CustomButton
			kind="secondary"
			iconLeft="refresh"
			isAnimated={isFetching}
			addedClassName="refresh-button"
			onClick={getHealthStatuses}
		/>
	</>

	const panelHeadingMobile = <>
		<CustomInput
			iconLeft="search"
			formGroupClass="mb-2"
			type="text"
			itemName="healthCheckSearch"
			placeholder="Type service name"
			value={searchDataStr}
			onChange={(e) => onChangeFn(e)}
		/>
		<RadioButtonsGroup
			buttonsList={buttonsList}
			onRadioChange={onFilterChangeFn}
			initialActiveButton="all"
			customClass="mb-2"
		/>
	</>

	const panelContent = <AToolsHealthCheckList filteredResultArr={healthCheckArr}/>;

	return (
		<PageDataPanel
			panelHeading={panelHeading}
			panelHeadingMobile={panelHeadingMobile}
			panelContent={healthCheckArr.length > 0 ? panelContent : <NoDataMessage/>}
			addedClassName="healthCheck-panel"
		/>
	);
};

export default AToolsHealthCheckPanel;
