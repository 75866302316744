import React, { useEffect, useState } from 'react';
import { parse, stringify } from 'query-string';
import { Route } from 'react-router-dom';
import useSapiService from '../components/hooks/useSapiService';
import usePageFilter from '../components/hooks/usePageFilter';
import HealthCheckDetailsFilter from '../components/forms/filters/HealthCheckDetailsFilter/HealthCheckDetailsFilter';
import AToolsHealthCheckDetailsPanel from '../components/aToolsHealthCheckDetails/aToolsHealthCheckDetailsList/AToolsHealthCheckDetailsPanel';
import AToolsHealthCheckDetailsView from '../components/aToolsHealthCheckDetails/aToolsHealthCheckDetailsView/AToolsHealthCheckDetailsView';
import HealthCheckDetailsPageContext from '../context/pagesContext/healthCheckDetailsPageContext';
import { localsHealthStatusesDetailsData } from '../localTestingData';
import ErrorBoundary from '../components/ErrorBoundary/ErrorBoundary';
import Spinner from '../components/Spinner/Spinner';
import useAutoUpdateData from '../components/hooks/useAutoApdateData';
import ErrorIndicator from '../components/ErrorIndicator/ErrorIndicator';
import PageName from '../components/PageName/PageName';
import { useRouter } from '../components/hooks/useRouter';
import useDocumentTitle from '../components/hooks/useDocumentTitle';
import PageLayout from '../components/pageLayout/PageLayout';

const HealthCheckDetailsPage = () => {
	const [healthStatusesDetailsData, setHealthStatusesDetailsData] = useState(
		null
	);

	const { history, match, location } = useRouter();
	const {
		getHealthStatusesDetailsReq,
		isFetching,
		isProduction,
	} = useSapiService();

	const {
		currentFilterData,
		register,
		handleSubmit,
		setValue,
		reset,
		currentPage,
		getCurrentPage,
		changeCurrentPage,
		changeQuantity,
		changeHealthStatus,
		applyFilter,
	} = usePageFilter({ history, match, location });

	const initialSearchString = match.params.data;
	const stringFromParams = {
		label: parse(location.search).label,
		additional_label: parse(location.search).additional_label,
	};

	useEffect(() => {
		if (location.search !== '') {
			getHealthCheckDetails(
				initialSearchString ? initialSearchString : location.search
			);
		}
	}, [location.search]); //eslint-disable-line

	function resetHealthCheckDetailsFilter() {
		setValue('request_id', '');
		history.push({
			search: stringify(stringFromParams),
		});
	}

	function getLocalHealthCheckDetails() {
		console.log('Using Local HealthCheckDetails Data');
		setHealthStatusesDetailsData(localsHealthStatusesDetailsData);
	}

	function getHealthCheckDetails(queryData) {
		return isProduction()
			? getHealthStatusesDetailsReq(queryData)
			.then(({data}) => {
				setHealthStatusesDetailsData(data);
			})
			: getLocalHealthCheckDetails();
	}

	useAutoUpdateData({
		data: healthStatusesDetailsData,
		updateFunction: getHealthCheckDetails,
		updateFunctionParams: location.search,
		isAllowed: false
	});
	
	function filterHandler(data) {
		applyFilter(data, getHealthCheckDetails);
	}

	const providerValue = {
		healthStatusesDetailsData,
		isFetching,
		resetHealthCheckDetailsFilter,
		currentPage,
		changeQuantity,
		changeCurrentPage,
		changeHealthStatus,
		getCurrentPage,
		register,
		handleSubmit,
		filterHandler,
		setValue,
		reset,
		currentFilterData,
		getHealthCheckDetails,
	};

	const pageName = `${currentFilterData.label} ${currentFilterData.additional_label || ''}`
	useDocumentTitle(pageName);

	const topBarContent = <>
		<PageName pageName={pageName} breadCrumbs={true}/>
		<HealthCheckDetailsFilter currentData={currentFilterData} />
		</>
	const pageContent = <>
		{isFetching && !healthStatusesDetailsData && <Spinner/>}
		{!isFetching && !healthStatusesDetailsData && <ErrorIndicator globalError />}
		{healthStatusesDetailsData &&
		<>
			<AToolsHealthCheckDetailsPanel currentData={currentFilterData} />
		</>}

	</>


	return (
		<ErrorBoundary>
			<HealthCheckDetailsPageContext.Provider value={providerValue}>
				<PageLayout
					addedClassName="health-check-details"
					topBarContent={topBarContent}
					pageContent={pageContent}
				/>
				<Route
					path="/healthcheck/details/:id"
					render={props => <AToolsHealthCheckDetailsView {...props} />}
				/>
			</HealthCheckDetailsPageContext.Provider>
		</ErrorBoundary>
	);
};

export default HealthCheckDetailsPage;
