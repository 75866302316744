import {useEffect, useMemo} from 'react';
import { useGlobal } from 'reactn';
import { useRouter } from './useRouter';

const useDocumentTitle = (addToTitle = '') => {
	const [currentPageName] = useGlobal('currentPageName');
	const { location } = useRouter();

	const locationLength = useMemo(()=> {
		return location.pathname.split('/').length
	}, [location.pathname]);

	useEffect(() => {
		if(currentPageName) {
			if(locationLength === 2 && !addToTitle) {
				document.title = `${currentPageName} - SAPI`
			}
			if(locationLength > 2 && addToTitle) {
				document.title = `${addToTitle} - ${currentPageName} - SAPI`
			}
		}
	}, [currentPageName, addToTitle, locationLength])
};

export default useDocumentTitle;
