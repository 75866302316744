import React from 'react';
import useDate from '../../hooks/useDate';

const DateNow = () => {

	const { year, month, day, hour, min, sec } = useDate({isUtc:true});
	return (
		<div className="date-info">
			<div className="day-details">
				<div className="value">
					<span className="day mr-1">{day}</span>-<span className="month mr-1 text-uppercase">{month}</span>-<span className="year">{year}</span>
				</div>
			</div>
			<div className="time-details">
				<div className="value">
					<span className="hours">{hour}</span>
					<span className="mx-1">:</span>
					<span className="minutes">{min}</span>
					<span className="mx-1">:</span>
					<span className="seconds">{sec}</span>
					<span className="utc"> UTC</span>
				</div>
			</div>
		</div>
	);
};

export default DateNow;
