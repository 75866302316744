import React from 'react';
import moment from "moment";
import'./Calendar.scss';
import Icon from '../Icon/Icon';


const Calendar = ({
	selectedDateFrom, 	// "DD-MM-YYYY HH:mm:ss"
	selectedDateTo,
	calendarDate,
	changeMonth = () => {},
	selectDate = () => {},
	datePickerType,
	isSingle
}) => {

	const typeDateFrom = datePickerType === 'date-from';
	const typeDateTo = datePickerType === 'date-to';

	const weekDaysShort = moment.weekdaysShort();
	const todayDay = moment.utc().date();
	const todayMonth = moment.utc().month();
	const todayYear = moment.utc().year();
	const selectedDayFrom = moment.utc(selectedDateFrom).date();
	const selectedDayTo = moment.utc(selectedDateTo).date();
	const selectedMonthFrom  = moment.utc(selectedDateFrom).month();
	const selectedMonthTo  = moment.utc(selectedDateTo).month();
	const selectedYearFrom  = moment.utc(selectedDateFrom).year();
	const selectedYearTo  = moment.utc(selectedDateTo).year();
	const currentMonth = moment.utc(calendarDate).month();
	const currentMonthName = moment.utc(calendarDate).format("MMMM");
	const currentYear = moment.utc(calendarDate).year();
	const isNextMonthAvailable = (todayYear > currentYear) || (todayYear === currentYear && todayMonth > currentMonth);
	const singleAndDateFrom = isSingle && typeDateFrom;
	const singleAndDateTo = isSingle && typeDateTo;
	const daysOfMonth = () => {
		let firstDay = moment.utc(calendarDate)
			.startOf("month").day(); // Day of week 0...1..5...6
		let lastDay = moment.utc(calendarDate)
			.endOf("month").day(); // Day of week 0...1..5...6
		return { firstDay, lastDay };
	};
	const { firstDay, lastDay } = daysOfMonth();

		const prevMonthArr = [];
		const currentMonthArr = [];
		const nextMonthArr = [];
		const prevMonthDaysInMonth = moment.utc(calendarDate).subtract(1, 'month').daysInMonth();
		const currMonthDaysInMonth = moment.utc(calendarDate).daysInMonth();
		const nextMonthDaysInMonth = moment.utc(calendarDate).add(1, 'month').daysInMonth();


		for(let d = 1; d <= prevMonthDaysInMonth; d++) {
			prevMonthArr.push(d);
		}
		for(let d = 1; d <= currMonthDaysInMonth; d++) {
			currentMonthArr.push(d);
		}
		for(let d = 1; d <= nextMonthDaysInMonth; d++) {
			nextMonthArr.push(d);
		}

		const prevMonthDays = +firstDay !== 0 ? prevMonthArr.slice(-(+firstDay)) : [];
		const nextMonthDays = nextMonthArr.slice(0, 6 - (+lastDay));
		const calendarDaysArr =  [...prevMonthDays, ...currentMonthArr, ...nextMonthDays]

		const weekDaysList  = weekDaysShort.map(day => {
			return <div className="w-day" key={day}>{day}</div>
		})

	const getMonth = (e) => {
		const currentTarget = e.currentTarget;
		changeMonth(currentTarget)
	}

		const calendarDaysList = calendarDaysArr.map((day, i) => {
			const isPrevMonthDay = i + 1 <= prevMonthDays.length;
			const isNextMonthDay = i + 1 > prevMonthDays.length + currentMonthArr.length;
			const isCurrentMonth =  !isNextMonthDay && !isPrevMonthDay;
			const isAfterToday = (day > todayDay) && isCurrentMonth && todayMonth <= currentMonth && currentYear >= todayYear;
			const currentDayDate = moment.utc(calendarDate).date(day);

			const isAfterSelectFrom = isCurrentMonth && selectedDateFrom && selectedDateTo && currentDayDate.isAfter(selectedDateFrom.endOf('day')) && currentDayDate.isBefore(calendarDate.endOf('month'));

			const isBeforeSelectTo = isCurrentMonth && selectedDateFrom && selectedDateTo && currentDayDate.isBefore(selectedDateTo.startOf('day')) && currentDayDate.isAfter(calendarDate.startOf('month'));

			const inRange = isAfterSelectFrom && isBeforeSelectTo && !isAfterToday;

			const isBeforeSelectFrom = singleAndDateFrom && Boolean(selectedDateTo && currentDayDate.isAfter(selectedDateTo.endOf('day')));

			const isAfterSelectTo = singleAndDateTo && Boolean( selectedDateFrom && currentDayDate.isBefore(selectedDateFrom.startOf('day'))) ;

			const otherMonthClassname = isNextMonthDay || isPrevMonthDay
				? 'other-month'
				: '';
			const isAfterTodayClassName = isAfterToday ||	isBeforeSelectFrom || isAfterSelectTo ? 'forbidden'	: '';

			const isSelected = () => {

				const isSelectedFrom = (day === selectedDayFrom)
						&& isCurrentMonth
						&& (selectedMonthFrom === currentMonth)
						&& selectedYearFrom === currentYear;
				const isSelectedTo = (day === selectedDayTo)
						&& isCurrentMonth
						&& (selectedMonthTo === currentMonth)
						&& selectedYearTo === currentYear;
				if(!isSingle) {
					if (isSelectedFrom || isSelectedTo) {
						if (selectedDayFrom === selectedDayTo) {
							return 'selected same-date'
						}
						return 'selected'
					} else {
						return ''
					}
				}
				if(singleAndDateFrom) {
					return isSelectedFrom
						? 'selected'
						: '';
				}
				if(singleAndDateTo) {
					return isSelectedTo
						? 'selected'
						: '';
				}
				return ''
			}
			const selectedClassName = isSelected();

			const todayClassName = day === todayDay && !isNextMonthDay && !isPrevMonthDay && currentMonth === todayMonth && currentYear === todayYear
				? 'today':
				'';
			const inRangeClassName = inRange && !isSingle ? 'in-range' : '';
			return <div
				className={`day ${otherMonthClassname} ${todayClassName} ${isAfterTodayClassName} ${inRangeClassName} ${selectedClassName}`}
				key={i}
				onClick={() =>
					isCurrentMonth && !isAfterToday && !isBeforeSelectFrom && !isAfterSelectTo
						? selectDate(day, calendarDate)
						: () => {}
				}
			><span>{day}</span></div>
	})

	return (
		<div className="date-time-pick-calendar">
			<div className="calendar-navigation" >
				<div
					className="prev-month-nav month-nav prev"
					onClick={(e) => getMonth(e)}
				>
					<Icon iconName="keyboard_arrow_left" size={24}/>
				</div>
				<div className="month-year">
					<div className="month">{currentMonthName}</div>
					<div className="year">{currentYear}</div>
				</div>
				{isNextMonthAvailable && <div
					className="next-month-nav month-nav next"
					onClick={(e) => getMonth(e)}
				>
					<Icon iconName="keyboard_arrow_right" size={24}/>
				</div>}
			</div>
			<div className="week-days">
				{weekDaysList}
			</div>
			<div className="days-grid">
				{calendarDaysList}
			</div>
		</div>

	);
};

export default React.memo(Calendar);
