import React from 'react';
import { Link } from 'react-router-dom';
import Icon from '../UI/Icon/Icon';
import PropTypes from 'prop-types';
import { useMediaQuery } from 'beautiful-react-hooks';
import { useGlobal } from 'reactn';
import { createIdFromString } from '../../helpers/createIdFromString';

const NavBarLink = ({
	url,
	text,
	icon,
	hasSubmenu = false,
	isExpanded = false,
	clickHandler,
	isActive = false,
}) => {
	const [, setIsOpenSidebar] = useGlobal('isOpenSidebar');
	const isLargeScreen = useMediaQuery('(min-width: 1200px)');
	const parentClass = hasSubmenu ? 'parent' : '';
	const activeClass = isActive ? 'active' : '';

	return (
		<Link
			to={url || '#'}
			className={`nav-link ${parentClass} ${activeClass}`}
			id={createIdFromString(text)}
			aria-expanded={isLargeScreen && hasSubmenu ? true : isExpanded}
			onClick={(e) => {
				clickHandler && clickHandler(e)
				!hasSubmenu && setIsOpenSidebar(false)
			}}>
			{icon && <Icon iconName={icon} boxed size={17}/>}
			<span>{text}</span>
			{hasSubmenu &&
			<>
				<Icon iconName="chevron_right" size={20} iconClass="expand-icon"/>
			</>}
		</Link>
	);
};

NavBarLink.propTypes = {
	url: PropTypes.string,
	text: PropTypes.string.isRequired,
	icon: PropTypes.string,
	submenu: PropTypes.array,
	location: PropTypes.object,
	isExpanded: PropTypes.bool,
	isActive: PropTypes.bool,
	onClick: PropTypes.func,
};

export default NavBarLink;

