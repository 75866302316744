import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { parse, stringify } from 'query-string';
import { useRouter } from './useRouter';

const useSearchAdvanced = (
	defaultArr = [],
	searchParamsArr = [],
) => {
	const {history, location} = useRouter();
	const queryParams = parse(location.search);
	const { quickSearch } = queryParams;
	const [searchDataStr, setSearchDataStr] = useState(quickSearch || '');
	const [searchResultArr, setSearchResultArr] = useState([]);
	const onChangeFn = (e) => {
	const queryString = stringify({ ...queryParams, quickSearch: e.target.value } );
		history.push({search: `?${queryString}`})
		setSearchDataStr(e.target.value);
	}

	useEffect(() => {
		const timer = setTimeout(()=> {
			setSearchResultArr(() =>
				searchDataStr ? _search(defaultArr, searchDataStr, searchParamsArr) : defaultArr
			);
		}, 300)

		return () => {clearTimeout(timer)}

	}, [JSON.stringify(defaultArr), searchDataStr]);   //eslint-disable-line

	const _search = (items, term, searchParamsArr) => {
		if (term.length === 0) {
			return items;
		}

		return items.filter(item => {
			const stringArr = term.split(' ');
			return stringArr.every(el => {
					return searchParamsArr.length > 0 &&
						searchParamsArr.some(param => {
							return item[`${param}`] && item[`${param}`].toString().toLowerCase().includes(el.toLowerCase())
						})
				})

		});
	};

	return {
		searchResultArr,
		searchDataStr,
		onChangeFn
	};
};

useSearchAdvanced.propTypes = {
	defaultArr: PropTypes.array.isRequired,
	searchParams: PropTypes.array.isRequired,
};

export default useSearchAdvanced;
