import { useState } from 'react';

const usePageFilterError = () => {
	const [hasError, setHasError] = useState(false);

	const errorHandler = (error) => {
		setHasError(error)
	}

	return {hasError, errorHandler};
};

export default usePageFilterError;
