import {useCallback, useEffect, useState} from 'react';
import useSapiService from '../../components/hooks/useSapiService';


const useCredentialsDetailsPage = serviceId => {
    const [serviceCredentials, setServiceCredentials] = useState({});
    const [service, setService] = useState({
        id: '',
        name: '',
        carrier_display_name: '',
    });

    const {
        isFetching,
        getServiceCredentialsDetailsReq,
    } = useSapiService();


    const getServiceCredentials = useCallback(id => {
        return getServiceCredentialsDetailsReq(id)
            .then(({data}) => {
                setService(data.service);
                setServiceCredentials(data.credentials);
            });

    }, [getServiceCredentialsDetailsReq]); //eslint-disable-line


    useEffect(() => {
        getServiceCredentials(serviceId);
    }, [getServiceCredentials]); //eslint-disable-line

    return {
        service,
        serviceCredentials,
        isFetching,
        getServiceCredentials,
    };
};

export default useCredentialsDetailsPage;
