import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { createCurrentRoutesFn } from './routesMenuConfig';
import { useGlobal } from 'reactn';
import ErrorBoundary from '../components/ErrorBoundary/ErrorBoundary';

const Routes = () => {
	const [accessUrl] = useGlobal('accessUrl');
	const currentRoutes = createCurrentRoutesFn(accessUrl);

	const routesList = currentRoutes.map(({ path, url, component: Component, exact, from, to, type }, index) => {
		const currentPath = path || url;
		if(type && type === "notFound") {
			return <Route key={index} component={Component} />
		}
		if(from || to) {
			return <Redirect key={index} from={from || null} to={to || null} exact={exact} />
		}

		return <Route
			path={currentPath}
			render={props =>
				<ErrorBoundary>
					<Component {...props} />
				</ErrorBoundary>}
			key={`${path}`}
			exact={exact} />;
	});

	return (
		<Switch>
			{routesList}
		</Switch>
	);
};

export default React.memo(Routes);

