import React from 'react';
import TopBar from '../TopBar/TopBar';
import './PageLayout.scss';
import ToTopButton from '../UI/ToTopButton/ToTopButton';


const PageLayout = ({
	addedClassName,
	topBarContent,
	pageContent
}) => {
	return (
		<div className={`page ${addedClassName}`}>
			<TopBar>
				{topBarContent}
			</TopBar>
			<div className="page-content-wrapper">
				{pageContent}
				<ToTopButton />
			</div>
		</div>
	);
};

export default React.memo(PageLayout);
