import {useCallback, useEffect, useState} from 'react';
import {useRouter} from '../../components/hooks/useRouter';
import useSapiService from '../../components/hooks/useSapiService';
import usePageFilter from '../../components/hooks/usePageFilter';
import useAutoUpdateData from '../../components/hooks/useAutoApdateData';


const useCredentialsPage = () => {
    const {location} = useRouter();
    const [credentialsData, setCredentialsData] = useState();

    const {
        isFetching,
        getServicesCredentialsReq,
    } = useSapiService();

    const {
        currentFilterData,
        resetFilterForm,
        applyFilter,
        formMethods
    } = usePageFilter();

    const getCredentialsData = useCallback((queryData = '') => {
        return getServicesCredentialsReq(queryData)
            .then(({data}) => {
                setCredentialsData(data)
            });

    }, [getServicesCredentialsReq]); //eslint-disable-line

    const resetCredentialsFilter = () => {
        resetFilterForm();
        getCredentialsData()
    }

    const filterHandler = (data) => {
        applyFilter(data, getCredentialsData);
    }

    useEffect(() => {
        getCredentialsData(location.search);
    }, [getCredentialsData]); //eslint-disable-line

    useAutoUpdateData({
        data: credentialsData,
        updateFunction: getCredentialsData,
        updateFunctionParams: location.search,
    });

    return {
        credentialsData,
        isFetching,
        currentFilterData,
        resetFilterForm,
        filterHandler,
        getCredentialsData,
        resetCredentialsFilter,
        formMethods
    };
};

export default useCredentialsPage;
