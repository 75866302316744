import React from 'react';

const trackingMapPageContext = React.createContext({
    carriers: [],
    getTrackingMapCarriersList: () => {
    },
    isFetching: false,
    filterHandler: () => {
    },
    resetTrackingMapFilter: () => {

    }
});

export default trackingMapPageContext;
