import React, {useState} from 'react';
import Form from 'react-bootstrap/Form';
import ModalDetails from "../UI/Modal/ModalDetails";
import Modal from "../UI/Modal/Modal";
import CustomButton from "../UI/CustomButton/CustomButton";
import CustomInput from "../UI/СustomInput/CustomInput";
import {useForm} from "react-hook-form";

const CreateCredentialsGroupModal = ({isOpenModal, hideModal, fields, setCredentials}) => {

    const {handleSubmit, register, errors, reset} = useForm();
    const [groupError, setGroupError] = useState(false);

    const createCredentialsGroup = (data) => {
        const {groupEnvironment} = data || {live: false, test: false};
        if (!(groupEnvironment.live || groupEnvironment.test)) {
            setGroupError(true);
            return;
        }
        setGroupError(false);
        setCredentials(prev => ({
            ...prev,
            ...(groupEnvironment.live && {live: {...prev.live, [data.groupName]: data.credentials}}),
            ...(groupEnvironment.test && {test: {...prev.test, [data.groupName]: data.credentials}})
        }));

        hideModal();
        reset();
    }

    const headerLeft = (
        <div className="header-info">
            <h2>{'Add credentials group'}</h2>
        </div>);
    const detailsContent = (<div>
        <p>{'Choose which group to add the credentials to'}</p>
        <Form>
            <div>
                <Form.Check
                    inline
                    label="Live Group"
                    name="groupEnvironment.live"
                    type="checkbox"
                    id="inline-checkbox-1"
                    ref={register}
                />
                <Form.Check
                    inline
                    label="Test Group"
                    name="groupEnvironment.test"
                    type="checkbox"
                    id="inline-checkbox-2"
                    ref={register}
                />
                <p className={`${groupError ? 'd-block' : 'd-none'} sub-message text-danger`}>Choosing a group is
                    mandatory</p>
            </div>
            <CustomInput
                formGroupClass="col-12 col-md-6 col-lg-4 shifted mt-3"
                type="text"
                itemName="groupName"
                label="Group name"
                register={register}
                errors={errors}
                required
            />
            <div className="row">
                {fields.map(field => (
                    <CustomInput
                        key={field}
                        formGroupClass="col-6 col-md-6 col-lg-6 shifted mt-3"
                        type="text"
                        itemName={`credentials.${field}`}
                        label={field}
                        register={register}
                    />
                ))}
            </div>
        </Form>
    </div>);
    const footerContent = (
        <Form.Group controlId="formGridState" className="m-0">
            <div className="button-wrapper d-flex justify-content-between">
                <CustomButton
                    buttonText="Cancel"
                    kind="secondary"
                    onClick={() => hideModal()}
                />
                <CustomButton
                    buttonText="Add group"
                    kind="primary"
                    onClick={handleSubmit(createCredentialsGroup)}
                />
            </div>
        </Form.Group>
    );

    return (
        <Modal show={isOpenModal} bsSize="lg" close={hideModal}>
            <ModalDetails
                customClass="create-tracking-status"
                headerLeft={headerLeft}
                detailsContent={detailsContent}
                footerContent={footerContent}
            />
        </Modal>
    );
}

export default CreateCredentialsGroupModal;