import React, { useState, useCallback } from 'react';
import { toast } from 'react-toastify';

const useCustomToast = () => {
	const [isDialogCanceled, setIsDialogCanceled] = useState(0);

	const successInfo = useCallback((text = 'Success') => {
		return toast(
			<span className="content">
				<i className="material-icons">check_circle</i> {text}
			</span>,
			{
				className: 'alert alert-success',
				pauseOnFocusLoss: false,
				autoClose: 1000 * 5

			}
		);
	}, []);

	const failedInfo = useCallback((text = 'Failed') => {
		return toast(
			<span className="content">
				<i className="material-icons">report</i> {text}
			</span>,
			{
				className: 'alert alert-danger',
				pauseOnFocusLoss: false,
				autoClose: 1000 * 5
			}
		);
	}, []);

	const messageInfo = useCallback((text = 'Info') => {
		return toast(
			<span className="content">
				<i className="material-icons">notifications</i> {text}
			</span>,
			{
				className: 'alert alert-primary',
				pauseOnFocusLoss: false,
				autoClose: 1000 * 5
			}
		);
	}, []);

	const undoToast = useCallback(
		({
			text = 'Done',
			onClickFn,
			buttonText = 'Undo',
			onCloseFn,
			...options
		}) => {
			return toast(
				<div className="content">
					<div>{text}</div>
						<button 
							className="btn btn-outline-success btn-sm"
							onClick={onClickFn}
						>{buttonText}</button>
				</div>,
				{
					className: 'alert alert-success undo-toast',
					pauseOnHover: false,
					hideProgressBar: true,
					pauseOnFocusLoss: false,
					autoClose: 1000 * 7,
					onClose: onCloseFn,
					...options
				}
			);
		},
		[]
	);

	return {
		successInfo,
		failedInfo,
		messageInfo,
		undoToast,
		isDialogCanceled,
		setIsDialogCanceled,
		toast
	};
};

export default useCustomToast;
