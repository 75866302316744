import React from 'react';
import CredentialTableViewItem from './CredentialTableViewItem';
import './CredentialsView.scss';

const CredentialsView = (props) => {
    const {credentials, expanded} = props;

    const list = credentials && Object.keys(credentials).map(key => (<CredentialTableViewItem
        key={key}
        services={credentials[key]}
        expanded={expanded}
    />));

    return (<div className="credentials-view">
            <table className="table credentials-table-view">
                <thead>
                <tr>
                    <th/>
                    <th className="header-carrier">Carrier</th>
                    <th className="header-id">Service Id</th>
                    <th className="header-name">Service Name</th>
                    <th className="header-version">Carrier Version</th>
                    <th/>
                </tr>
                </thead>
                <tbody>
                {list}
                </tbody>
            </table>
        </div>
    );
};

export default CredentialsView;
