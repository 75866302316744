import React, { forwardRef, useState } from 'react';
import { Form } from 'react-bootstrap';
import PropTypes from 'prop-types';
import './CustomInput.scss';
import SubMessage from '../SubMessage/Submessage';
import formErrorsCheck from '../../../helpers/formErrorsCheck';
import Icon from '../Icon/Icon';

const CustomInput = forwardRef(
	(
		{
			type,
			itemName,
			label,
			formGroupClass = '',
			placeholder,
			addedInputClass,
			register,
			required,
			disabled,
			small,
			iconLeft,
			iconRight,
			onIconClick = () => {},
			onIconEnter = () => {},
			onIconLeave = () => {},
			iconSize = 20,
			errors = {},
			addRequiredMark,
			isError,
			children,
			...props
		},
		customRef
	) => {
		function isRequired() {
			if (register) {
				if (required) {
					return register({
						required: 'Field is required',
					});
				} else {
					return register;
				}
			} else {
				return customRef || null;
			}
		}

		const [inFocus, setInFocus] = useState(false);

		const { hasError, errorMessage } = formErrorsCheck(itemName, errors);

		const smallInputClassName = small ? 'form-control-sm' : '';
		const hasErrorClassName = hasError || isError ? 'border-danger' :	'';
		const leftIconClassName = iconLeft ? 'with-icon icon-left' : '';
		const rightIconClassName = iconRight ? 'with-icon icon-right' : '';
		const onFocusClassName = inFocus ? 'focused' : '';
		const iconItem = iconLeft || iconRight;


		const onFocus = () => {
			setInFocus(true);
		}
		const onBlur = () => {
			setInFocus(false);
		}

		return (
			<Form.Group
				className={`${formGroupClass} ${
					disabled ? 'disabled' : ''
				} custom-input-box ${leftIconClassName} ${rightIconClassName} ${onFocusClassName}`}
			>
				<div className="input-wrapper">
					{label && <Form.Label className="form-label">
						<span className={`${(hasError || isError && 'text-danger') || ''}`}>
							{label}
						</span>
						<span className={`${required || addRequiredMark ? '' : 'invisible'} required`}>*</span>
					</Form.Label>}
					{iconItem && <div
						className="icon-wrapper"
						style={{height: `${iconSize}px`, width: `${iconSize}px`}}
						onClick={onIconClick}
						onMouseEnter={onIconEnter}
						onMouseLeave={onIconLeave}
					>
						<Icon iconName={iconItem} size={iconSize}/>
					</div>}
					<Form.Control
						type={type}
						name={itemName || null}
						ref={isRequired()}
						placeholder={placeholder || null}
						className={`${addedInputClass || ''} ${hasErrorClassName} ${smallInputClassName}`}
						readOnly={disabled}
						onFocus={onFocus}
						onBlur={onBlur}
						{...props}
					/>
					{hasError && (
						<SubMessage styleClass="text-danger">
							{errorMessage}
						</SubMessage>
					)}
				</div>
				{children}
			</Form.Group>
		);
	}
);

CustomInput.propTypes = {
	type: PropTypes.string.isRequired,
	itemName: PropTypes.string,
	label: PropTypes.string,
	formGroupClass: PropTypes.string,
	placeholder: PropTypes.string,
	addedInputClass: PropTypes.string,
	errors: PropTypes.object,
	register: PropTypes.func,
	required: PropTypes.bool,
	disabled: PropTypes.bool,
};

export default React.memo(CustomInput);
