import React, { useState, useEffect, useContext } from 'react';
import HealthCheckDetailsPageContext from '../../../context/pagesContext/healthCheckDetailsPageContext';
import TableCardUI from '../../UI/TableCardUI/TableCardUI';
import useDateStamp from '../../hooks/useDateStamp';
import { useMediaQuery } from 'beautiful-react-hooks';
import { useRouter } from '../../hooks/useRouter';
import CustomButton from '../../UI/CustomButton/CustomButton';
import { useGlobal } from 'reactn';

const AToolsHealthCheckDetailsList = () => {

	const [statusesArr, setStatusesArr] = useState([]);
	const { getUtcDateFromStamp } = useDateStamp();
	const {match, location} = useRouter()
	const isLargeScreen = useMediaQuery('(min-width: 1200px)');
	const { healthStatusesDetailsData, isFetching } = useContext(HealthCheckDetailsPageContext);
	const [commonListsObj] = useGlobal('commonListsObj');
	const {users} = commonListsObj;

	useEffect(() => {
		if(healthStatusesDetailsData){
		setStatusesArr(healthStatusesDetailsData.items)
		}
	}, [healthStatusesDetailsData]);


	const actionsTpl = (id) =>
		<div className="buttons-wrapper">
			<CustomButton
				linkTo={`${match.path}/${id}${location.search}`}
				kind="secondary"
				iconLeft="navigate_next"
				small
				tooltip="View details"
			/>
		</div>

	const statusesLabelArr = [
		['Status','status'],
		['Date','date'],
		['Exec. time','exec_time'],
		['User', 'user'],
		['Actions', 'actions']
	]

	const modifiedStatusesArr = statusesArr.map((item) => {
		const status = item.status === '0'
			? <span className="badge badge-danger">Error</span>
			: <span className="badge badge-success">Success</span>
		return {...item,
			status: status,
			date:`${getUtcDateFromStamp(item.date)} UTC`,
			exec_time: `${item.exec_time} ms`,
			user: `${users[item.user_id] || 'No user'}`,
			actions: actionsTpl(item.id)
		};
	});

	return (
		<TableCardUI
			labelListArr={statusesLabelArr}
			tooltip={false}
			dataArr={modifiedStatusesArr}
			cardView={!isLargeScreen}
			addedCommonClassname={`health-check-details ${
				isFetching ? 'semi-transparent' : 'no-transparent'
			}`}
		/>
	);
};

export default AToolsHealthCheckDetailsList;
