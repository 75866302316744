import React, { useState } from 'react';
import { Col, Form } from 'react-bootstrap';
import './FilterLayout.scss';
import CustomButton from '../../UI/CustomButton/CustomButton';

const FilterLayout = ({
	handleSubmit = () => {
	},
	filterFunc = () => {
	},
	discardFilterFunc = () => {
	},
	hideButtons = false,
	children,
	discardFilterButtonName = 'Clear filter',
	applyFilterButtonName = 'Filter',
	hasError,
}) => {

	const [isVisible, setIsVisible] = useState(false);

	const toggleMobileFilter = () => {
		setIsVisible(!isVisible);
	};

	const togglerText = isVisible ? 'hide filter' : 'show filter';
	const hideClassName = isVisible ? '' : 'hidden-filter';

	return (
		<div className={`filter-layout ${hideClassName}`}>
			<CustomButton
				onClick={toggleMobileFilter}
				buttonText={togglerText}
				addedClassName="filter-toggler"
				kind="primary"
				small
				iconLeft="filter_list"
			/>
			<Form
				className="filter-form"
				onSubmit={(e) => hideButtons
					? e.preventDefault()
					: handleSubmit(filterFunc)
				}
			>
				<div className="row">
					{children}
					{!hideButtons && (
						<Form.Group
							as={Col}
							controlId="formGridState"
							className="footer-buttons"
						>
							<CustomButton
								onClick={discardFilterFunc}
								buttonText={discardFilterButtonName}
								addedClassName="mr-3"
								kind="secondary"
								iconLeft="clear"
								id="discard-filter"
							/>
							<CustomButton
								onClick={handleSubmit(filterFunc)}
								buttonText={applyFilterButtonName}
								kind="primary"
								type="submit"
								disabled={hasError}
								id="apply-filter"
							/>

						</Form.Group>
					)}
				</div>
			</Form>
		</div>
	);
};

export default FilterLayout;
