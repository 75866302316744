import React from 'react';
import { useGlobal } from 'reactn';
import  './SidebarToggleButton.scss';


const SidebarToggleButton = () => {

	const [isOpenTopbar, setIsOpenTopbar] = useGlobal('isOpenTopbar');
	const openedClass = isOpenTopbar ? `opened`: ``;

	const toggleSidebar = () => {
		setIsOpenTopbar(!isOpenTopbar);
	};


	return (
		<div
			onClick={toggleSidebar}
			className={`sidebar-toggle d-xl-none ${openedClass}`}>
			<span></span>
			<span></span>
			<span></span>
		</div>
	);
};

export default SidebarToggleButton;
