import React from 'react';
import Tooltip from '../Tooltip/Tooltip';
import  './TableCard.scss';
import useTooltip from '../../hooks/useTooltip';
import Portal from '../../UI/Portal/Portal';
 /**
 * @param dataArr:
 * 	[
		{keyName: value},
 		{keyName: value},
		...
	]
 * @param labelListArr : data array
	[
		['Label Name', 'keyName', 0, 0], //[string, string, bool- only desktop, bool - no tooltip for item]
		...
	]
 *
 * @param addedTableClassName : string
 * @param addedCardClassName : string
 * @param addedCommonClassname : string
 * @param cardView : boolean
 * @param tooltip : boolean
 */

const TableCardUI = ({
	dataArr,
	labelListArr,
	addedCommonClassname,
	addedTableClassName,
	addedCardClassName,
	cardView,
	tooltip = true,
}) => {

	 const {isOn, showTooltip, coordinates, hideTooltip, tooltipData, hideBgr} = useTooltip();

	const viewClassName = cardView ? 'card-view' : 'table-view';
	const headerTableData = labelListArr.map(([label, itemKey, desktopOnly]) => {
		const labelItem = itemKey !== 'actions' ? label : '';
		const actionsClassName = itemKey === 'actions' ? 'actions' : '';
		return <th
			key={itemKey}
			className={`${desktopOnly ? "desktop-only" : ''} ${actionsClassName}`}
			onMouseEnter={(e) => {
				showTooltip(e.currentTarget, labelItem.toUpperCase());
			}}
			//onMouseLeave={hideTooltip}
		>{labelItem}</th>
	});

	const contentTableData = dataArr.map( (tableListItem, i) => {
		const tableListItemData = labelListArr.map( ([, itemKey, desktopOnly, noTooltip, showTooltipBgr]) => {
			const needTooltip = tooltip && itemKey !== 'actions' && !noTooltip
			const actionsClassName = itemKey === 'actions' ? 'actions' : '';
			const showBgrOnTooltip = showTooltipBgr && needTooltip;
			return <td
				key={itemKey}
				onMouseEnter={(e) => {
					showTooltip(e.currentTarget,  tableListItem[itemKey], showBgrOnTooltip);
				}}
				onMouseLeave={hideTooltip}
				className={`${desktopOnly ? "desktop-only" : ''} ${needTooltip ? 'with-tooltip' : ''} ${actionsClassName}`}
			>
				{tableListItem[itemKey]}
			</td>
		})
		return <tr key={i}>{tableListItemData}</tr>
	})

	const tableTemplate =
		<table className={`table ${addedTableClassName ? addedTableClassName : ''}`}>
			<thead>
				<tr>
					{headerTableData}
				</tr>
			</thead>
			<tbody>
				{contentTableData}
			</tbody>
		</table>

	const contentCardData = dataArr.map((item, i) => {
		const cardItemData = labelListArr.map( ([label, itemKey, desktopOnly]) => {
			return <div
				key={itemKey}
				className={`${desktopOnly ? "desktop-only" : ''} card-item-section`}
			>
				<div className="card-item-label">{label}: </div><div className="card-item-label-data">{item[itemKey]}</div>
			</div>
		});
		return <div key={i}>
							<div className="card card-item">
								<div className="card-body">
									{cardItemData}
								</div>
							</div>
						</div>
	});

	const cardTemplate =
		<div className={`card-items ${addedCardClassName || ''} card-grid`}>
			{contentCardData}
		</div>;


	return (
		<div className={`table-card ${viewClassName} ${addedCommonClassname}`}>
			{cardView ? cardTemplate : tableTemplate}
			{isOn && <Portal>
				<Tooltip
					text={tooltipData}
					hideBgr={hideBgr}
					coordinates={coordinates} />
			</Portal>}
		</div>
	);
};

export default TableCardUI;
