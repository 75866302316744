import { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
const useCheckboxList = (
	listArray = [],
	checkedListArr = [],
	getSelectedItems = () => {},
	searchString
) => {
	const initialItemListChecked = checkedListArr.map( el => {
		return el.toString();
	})

	const [listItems, setListItems] = useState([]);
	const [itemListChecked, setItemListChecked] = useState(initialItemListChecked);
	const [lastCheckedItem, setLastCheckedItem] = useState();

	const itemList =
		listItems.length === 0 || !searchString
			? listItems
			: listItems.filter(item => {
				const stringArr = searchString.split(' ');
				return stringArr.every(el => {
					return item.name.toLowerCase().includes(el.toLowerCase());
				})
			});


	useEffect(() => {
		const listArrayValues = listArray.map( el => {
			return el.value.toString();
		});

		const currentCheckedList = initialItemListChecked.filter((el)=> {
			return listArrayValues.includes(el)
		});
		setItemListChecked(currentCheckedList);
	}, [checkedListArr.length]); //eslint-disable-line

	useEffect(() => {
		const fullList = listArray.length > 0 ? listArray.map(fullEl => {
			let isChecked = itemListChecked.includes(fullEl.value.toString());
			return { ...fullEl, isChecked: isChecked };
		}) : [];
		setListItems(fullList);
		getSelectedItems(itemListChecked);
	}, [itemListChecked.length, listArray.length]); //eslint-disable-line

	const handleSelectItem = (e) => {
		const value = e.currentTarget.dataset.value || e.currentTarget.value;
		const nextValue = getNextValue(value, e.shiftKey);
		setLastCheckedItem(value);
		setItemListChecked(nextValue)
	}

	const updateCheckedArray = (itemListChecked, newItemValue) => {
		// if it's already in there, remove it, otherwise append it
		return itemListChecked.includes(newItemValue)
			? itemListChecked.filter(item => item !== newItemValue)
			: [...itemListChecked, newItemValue];
	}


	const getNextValue = (value, isShiftDown = false) => {

		const hasBeenSelected = !itemListChecked.includes(value);

		if (isShiftDown) {
			const newSelectedItems = getNewSelectedItems(value);
			// de-dupe the array using a Set

			const selections = [...new Set([...itemListChecked, ...newSelectedItems])];

			if (!hasBeenSelected) {
				return selections.filter(item => !newSelectedItems.includes(item));
			}

			return selections;
		}
		return updateCheckedArray(itemListChecked, value)
	}

	const getNewSelectedItems = (value) => {

		const currentSelectedIndex = itemList.findIndex(item => item.value === value);
		const lastSelectedIndex = itemList.findIndex(
			item => item.value === lastCheckedItem
		);

		return itemList
			.slice(
				Math.min(lastSelectedIndex, currentSelectedIndex),
				Math.max(lastSelectedIndex, currentSelectedIndex) + 1
			)
			.map(item => item.value);
	}

	const keyPressHandler = (itemValue) => {
		setItemListChecked(updateCheckedArray(itemListChecked, itemValue));
	}

	const clickHandler = (e) => {
		handleSelectItem(e);
	}

	const selectAll = useCallback(() => {
		const fullCheckedList = listArray.map(item => {
			return item.value
			});
		setItemListChecked(fullCheckedList)
	}, [listArray]);

	
	const selectAllFiltered = (filteredArr) => {
		const filteredItemsArr = filteredArr.map( item => {
			return item.value;
		});
		
		const uniqueArr = [...new Set([...itemListChecked, ...filteredItemsArr])];
		setItemListChecked(uniqueArr)
	};

	const deselectAll = () => {
		setItemListChecked([]);
	};

	return {
		itemList,
		keyPressHandler,
		clickHandler,
		selectAll,
		deselectAll,
		selectAllFiltered
	};
};

useCheckboxList.propTypes = {
	listArray: PropTypes.array.isRequired,
	getSelectedItems: PropTypes.func,
	checkedListArr: PropTypes.array
};

export default useCheckboxList;
