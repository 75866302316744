import { useEffect, useState } from 'react';

function useKeyPress(targetKey, stopListen) {
	// State for keeping track of whether key is pressed
	const [keyPressed, setKeyPressed] = useState(false);

	// If pressed key is our target key then set to true
	function downHandler(e) {
		const { key } = e;
		if (key === targetKey) {
			e.preventDefault();
			setKeyPressed(true);
		}
	}

	// If released key is our target key then set to false
	const upHandler = (e) => {
		const { key } = e;
		if (key === targetKey) {
			e.preventDefault();
			setKeyPressed(false);
		}
	};

	// Add event listeners
	useEffect(() => {
		if(!stopListen) {
			window.addEventListener('keydown', downHandler);
			window.addEventListener('keyup', upHandler);
			return () => {
				window.removeEventListener('keydown', downHandler);
				window.removeEventListener('keyup', upHandler);
			};
		}
	}, [stopListen]); // eslint-disable-line


	return keyPressed;
}

export default useKeyPress;
