import React from 'react';
import Icon from '../Icon/Icon';
import './CustomButton.scss';
import { Link } from 'react-router-dom';

/**
 *
 * @param variant - bootstrap-4 button style
 * @param kind - button UI kind style
 * @param outline - outline button Style
 * @param type - button type
 * @param buttonText - button text
 * @param small - bool : small button
 * @param iconLeft - material icon name
 * @param iconRight - material icon name
 * @param iconSize  - font size for google-font icon
 * @param onClickFn  - on click handler function
 * @param isAnimated  - spin icon if true
 * @param addedClassName  - custom class
 * @param href  - url for link
 * @param props - custom props
 */


const CustomButton = ({
	variant,
	kind,
	outline,
	buttonText,
	iconLeft,
	iconRight,
	small,
	type='button',
	iconSize = 20,
	isAnimated,
	onClick =() => {},
	addedClassName = '',
	linkTo,
	href,
	target,
	tooltip,
	id,
	...props}) => {

	const smallButtonClassName = small ? 'btn-sm': '';
	const buttonVariantClassName = outline && variant ? `btn-outline-${variant}` : '';
	const isAnimatedClassName = isAnimated ? 'animated-icon' : '';
	const leftIconClassName = iconLeft ? 'with-icon icon-left' : '';
	const rightIconClassName = iconRight ? 'with-icon icon-right' : '';
	const iconOnlyClassName = !buttonText && iconLeft? 'with-icon icon-only' : '';
	const isLinkClassName = linkTo && kind === 'text' || href && kind === 'text' ? 'is-link' : '';

	let kindClassName = '';

	switch(kind){
		case 'secondary':
			kindClassName = `btn-outline-primary`
			break;
		case 'success':
			kindClassName = `btn-success`
			break;
		case 'warning':
			kindClassName = `btn-warning`
			break;
		case 'destructive':
			kindClassName = `btn-danger`
			break;
		case 'text':
			kindClassName = `btn-link`
			break;
		case 'black':
			kindClassName = `btn-black`
			break;
		case 'primary':
		default:
			kindClassName = `btn-primary`
	}


	const buttonInner = (
		<>
		{iconLeft && <div className="icon-wrapper" style={{height: `${iconSize}px`, width: `${iconSize}px`}}>
			<Icon iconName={iconLeft} size={iconSize} />
		</div>}
	<span>{buttonText}</span>
	{iconRight && <div className="icon-wrapper" style={{height: `${iconSize}px`, width: `${iconSize}px`}}>
		<Icon iconName={iconRight} size={iconSize} />
	</div>}
	</>
	);

	const classNames = `custom-button btn ${kindClassName} ${addedClassName} ${buttonVariantClassName}  align-items-center justify-content-center ${isAnimatedClassName} ${smallButtonClassName} ${leftIconClassName} ${rightIconClassName} ${iconOnlyClassName} ${isLinkClassName}`


	const tooltipText = tooltip ? tooltip : null;

	return (
		<>
			{linkTo && <Link to={linkTo}  id={id} onClick={onClick} className={classNames}  aria-label={tooltipText}>
				{buttonInner}
							</Link>}
			{href && <a href={`${href}`} id={id} target={target} className={classNames} aria-label={tooltipText}>
				{buttonInner}
			</a>}
			{!linkTo && !href &&<button
				type={type}
				className={classNames}
				onClick={onClick}
				aria-label={tooltipText}
				id={id}
				{...props}
			>
				{buttonInner}
			</button>}
		</>
	);
};

export default CustomButton;
