import React from 'react';

const healthCheckPageContext = React.createContext({
	searchResultArr: [],
	filteredResultArr: [],
	searchDataStr: '',
	setFilterParam: () => {},
	getHealthStatuses:() => {},
	healthStatusesList: [],
	setSearchDataString: () => {
	},
	searchDataString: '',
	error: '',
	isFetching: false,
});

export default healthCheckPageContext;