import React from 'react';
import Logo from '../../../assets/images/Rebound.svg';
import './AuthLayout.scss';


const AuthLayout = ({
	contentData,
	addedClassName
}) => {

	// const { protocol, host } = window.location;
	// const docsHost = host.split('.').slice(1).join('.');
	return (
		<div className={`auth-layout ${addedClassName}`}>
				<div className="container">
					{contentData && <div className="content-wrapper">
						<div className="logo-wrapper">
							<img src={Logo} className="d-inline-block mb-4" alt="Logo"/>
						</div>
						{contentData}
					</div>}
					<div className="footer text-center">
						© {new Date().getFullYear()} ReboundSapi. All rights reserved.
					</div>
				</div>
		</div>
	);
};

export default AuthLayout;
