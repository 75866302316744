import React, { useState, useEffect, createRef } from 'react';
import { ButtonGroup } from 'react-bootstrap';
import useCheckboxList from '../../hooks/useCheckboxList';
import PropTypes from 'prop-types';
import './CheckboxList.scss';
import CustomInput from '../СustomInput/CustomInput';
import CustomButton from '../CustomButton/CustomButton';
import useKeyPress from '../../hooks/useKeyPress';
import useListKeyNavigation from '../../hooks/useListKeyNavigation';

const CheckboxList = ({
	listArray = [],
	checkedListArr = [],
	getSelectedItems = () => {},
	quickSearch = true,
	isVisible = true,
	scrollToActiveItem,
	dropdownRef,
	activeItemEl,
	hideDropdown,
}) => {
	const [searchString, setSearchString] = useState('');
	const [activeItemValue, setActiveItemValue] = useState('');
	const [searchInFocus, setSearchInFocus] = useState(false);
	const {getActiveListItem} = useListKeyNavigation();

	const customRef = createRef();

	const arrowUpPress = useKeyPress('ArrowUp');
	const arrowDownPress = useKeyPress('ArrowDown');
	const spacePress = useKeyPress(' ', searchInFocus);
	const enterPress = useKeyPress('Enter');

	const changeActiveListItem = (activeItemValue, list, order ='next', filteredOptionsList) => {
		const newActiveItemValue = getActiveListItem(activeItemValue, list, order, filteredOptionsList)
		setActiveItemValue(newActiveItemValue)
	}

	useEffect(() => {
		if(isVisible && customRef.current){
			customRef.current.focus();
		}
	}, [isVisible]); //eslint-disable-line

	const {
		itemList,
		keyPressHandler,
		clickHandler,
		deselectAll,
		selectAllFiltered
	} = useCheckboxList( listArray,	checkedListArr,	getSelectedItems, searchString );


	const handleClick = (e) => {
		clickHandler(e)
		const value = e.currentTarget.dataset.value || e.currentTarget.value;
		setActiveItemValue(value)
	}

	useEffect(()=> {
		if(isVisible) {
			if (spacePress) {
				keyPressHandler(activeItemValue);
			}
			if (arrowDownPress ) {
				changeActiveListItem(activeItemValue, itemList, 'next', itemList);
				scrollToActiveItem( dropdownRef, activeItemEl);
				customRef.current.blur();
			}
			if (arrowUpPress) {
				changeActiveListItem(activeItemValue, itemList, 'prev', itemList)
				scrollToActiveItem( dropdownRef, activeItemEl);
				customRef.current.blur();
			}
			if (enterPress) {
				hideDropdown();
			}
		}
	}, [spacePress, arrowDownPress, arrowUpPress, enterPress, isVisible]);  //eslint-disable-line

	const searchField = <CustomInput
		type="text"
		ref={customRef}
		small
		iconLeft="search"
		itemName="quickSearch"
		formGroupClass="mb-2 mt-1 px-2"
		autoComplete="off"
		value={searchString}
		onFocus={() => setSearchInFocus(true)}
		onBlur={() => setSearchInFocus(false)}
		onChange={(e) => setSearchString(e.target.value)}
/>;

	const selectButtons = (
		<ButtonGroup className="mb-2 w-100 px-2">
			<CustomButton
				kind="secondary"
				buttonText="Select all"
				onClick={() => selectAllFiltered(itemList)}
				small
			/>
			<CustomButton
				kind="secondary"
				buttonText="Deselect all"
				onClick={deselectAll}
				small
			/>
		</ButtonGroup>
	);

	const list = itemList.map((el) => {
		const isActive = el.value === activeItemValue;
		const activeItemClassName = isActive ? 'active' : '';
		return (<li
			key={el.value}
			onClick={(e) => handleClick(e)}
			data-value={el.value}
			ref ={isActive ? activeItemEl : null}
			className={`px-2 checkbox-item ${el.isChecked ? 'checked' : ''} ${activeItemClassName}`}
		>
			<div className="item-box"/>
			<div className="item-label">
				{el.name}
			</div>
			</li>);
	});
	
	return (
		<ul className="checkbox-list list-unstyled mb-0"
		>
			{quickSearch && searchField}
			{selectButtons}
			{list}
		</ul>
	);
};

CheckboxList.propTypes = {
	listArray: PropTypes.array.isRequired,
	getSelectedItems: PropTypes.func,
	checkedListArr: PropTypes.array,
};

export default React.memo(CheckboxList);
