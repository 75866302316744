import React from 'react';
import  './TopBar.scss';

import SidebarToggleButton from './SidebarToggleButton/SidebarToggleButton';
import DateNow from '../InfoPanel/DateNow/DateNow';
import Logo from '../Logo/Logo';
import UserInfo from '../UserInfo/UserInfo';
import Navbar from '../Sidebar/Navbar';
import { useGlobal } from 'reactn';

const TopBar = ({children}) => {
	const [isOpenTopbar] = useGlobal('isOpenTopbar');
	const openedClass = isOpenTopbar ? `opened` : ``;
	const noFilterClass = children ? '' : 'no-filter';

	return <div className={`topbar ${noFilterClass}`}>
		<div className={`top-bar-data ${openedClass}`}>
			<div className="logo-wrapper">
				<Logo />
				<SidebarToggleButton />
			</div>
			<Navbar></Navbar>
			<div className="info-wrapper">
				<DateNow />
				<UserInfo />
			</div>
		</div>
		{children && <div className="top-bar-inner">
			{children}
		</div>}

	</div>;
};

export default  React.memo(TopBar);
