import React, { useEffect, useRef, useState, forwardRef, useImperativeHandle } from 'react';
import PropTypes from 'prop-types';

import './CustomSelectAdvanced.scss';
import SubMessage from '../SubMessage/Submessage';
import formErrorsCheck from '../../../helpers/formErrorsCheck';
import useDropDown from '../../hooks/useDropDown';
import Icon from '../Icon/Icon';
import CustomSelectAdvancedDropdown from './CustomSelectAdvancedDropdown';
import useListKeyNavigation from '../../hooks/useListKeyNavigation';

const CustomSelectAdvanced = forwardRef(({
	itemName,
	label,
	small,
	selectOptionsList,
	formGroupClass = '',
	addedSelectClass,
	addRequiredMark,
	register,
	setValue = () => {},
	required,
	errors = {},
	disabled,
	children,
	onSelect = ()=> {},
	defaultValue = '',
	outerTrigger,
	quickSearch,
	iconLeft,
	onIconClick = () => {},
	onIconEnter = () => {},
	onIconLeave = () => {},
	iconSize = 20,
}, customRef) => {

	const hasOptionsList = selectOptionsList && selectOptionsList.length > 0;
	const initialValue = defaultValue && hasOptionsList
		? defaultValue
		:	hasOptionsList
		&& selectOptionsList[0].value;

	const [selectedValue, setSelectedValue] = useState(initialValue);
	const [activeItemValue, setActiveItemValue] = useState(initialValue);

	const dropdown = useRef(null);
	const activeItemEl = useRef(null);

	const { isVisible, hideDropdown, showDropdown, scrollToActiveItem } = useDropDown(dropdown);
	const {findItemByValue, getActiveListItem} = useListKeyNavigation();

	const listItemName = findItemByValue(selectedValue, selectOptionsList);

	function isRequired() {
		if (register) {
			if (required) {
				return register({ required: 'Field is required' });
			} else {
				return register;
			}
		} else {
			return customRef;
		}
	}


	useImperativeHandle(customRef, ()=> ({
		updateSelection: () => setSelectedValue(defaultValue),
		updateFormValue: () => setValue(itemName, defaultValue)
	}), [defaultValue]) //eslint-disable-line

	useEffect(()=> {
		setSelectedValue(initialValue);
		setValue(itemName, initialValue)
	}, [hasOptionsList]); //eslint-disable-line

	useEffect(()=> {
		if(selectedValue && isVisible) {
			setActiveItemValue(selectedValue)
		}
		return () => {
			setActiveItemValue(selectedValue)
		}
	}, [selectedValue, isVisible])

	useEffect(() => {
		if(outerTrigger && outerTrigger !== 0) {
			resetSelection();
		}
	},[outerTrigger]); // eslint-disable-line

	const handleSelectChange = (value) => {
		setSelectedValue(value);
		setActiveItemValue(value);
		onSelect(value);
		setValue(itemName, value)
		hideDropdown();
	};
	const resetSelection = () => {
		setSelectedValue(selectOptionsList && selectOptionsList[0]
			? selectOptionsList[0].value : '');
	}

	const changeActiveListItem = (activeItemValue, list, order ='next', filteredOptionsList) => {
		const newActiveItemValue = getActiveListItem(activeItemValue, list, order, filteredOptionsList)
		setActiveItemValue(newActiveItemValue)
	}

	const { hasError } = formErrorsCheck(itemName, errors);
	const smallInputClassName = small ? 'form-control-sm' : '';
	const leftIconClassName = iconLeft ? 'with-icon icon-left' : '';

	return (
		<div
			className={`${formGroupClass} ${
				disabled ? 'disabled' : ''
			} ${leftIconClassName} custom-select-advanced-box form-group`}
		>
			<div className="custom-select-advanced-wrap">
				{!!label && (
					<label className="form-label">
						<span className={`${(hasError && 'text-danger') || ''}`}>
							{label}
						</span>
						<span className={`${required || addRequiredMark ? '' : 'invisible'} required`}>*</span>
					</label>
				)}
				<div
					className={`form-control custom-select-advanced ${
						addedSelectClass ? addedSelectClass : ''
					} ${(hasError && 'border-danger') || ''} ${smallInputClassName}`}
					onClick={() => {
						!isVisible && !disabled && showDropdown();
					}}
					tabIndex="0"
				><span>{listItemName}</span></div>
				<input
					type="text"
					name={itemName}
					ref={isRequired()}
					readOnly
					style={{display: "none"}}
				/>
				<Icon iconName={isVisible ? 'arrow_drop_up':'arrow_drop_down'} size={22} />
				{hasError && (
					<SubMessage styleClass="text-danger">Field is required</SubMessage>
				)}
				{isVisible && hasOptionsList && <CustomSelectAdvancedDropdown
					ref={dropdown}
					selectOptionsList={selectOptionsList}
					selectedValue={selectedValue}
					activeItemValue={activeItemValue}
					handleSelectChange={handleSelectChange}
					activeItemEl={activeItemEl}
					small={small}
					changeActiveListItem={changeActiveListItem}
					scrollToActiveItem={scrollToActiveItem}
					quickSearch={quickSearch && hasOptionsList}
					hideDropdown={hideDropdown}
				/>}
				{iconLeft && <div
					className="icon-wrapper custom-icon"
					style={{height: `${iconSize}px`, width: `${iconSize}px`}}
					onClick={onIconClick}
					onMouseEnter={onIconEnter}
					onMouseLeave={onIconLeave}
				>
					<Icon iconName={iconLeft} size={iconSize}/>
				</div>}
			</div>
			{children}
		</div>
	);
});

CustomSelectAdvanced.propTypes = {
	register: PropTypes.func,
	selectOptionsList: PropTypes.arrayOf(PropTypes.object).isRequired,
	itemName: PropTypes.string.isRequired,
	formGroupClass: PropTypes.string,
	addedSelectClass: PropTypes.string,
	label: PropTypes.string,
	required: PropTypes.bool,
};

export default React.memo(CustomSelectAdvanced);
