import React from 'react';
import './NoDataMessage.scss';
import {ReactComponent as NoDataImage } from '../../../assets/images/no_result.svg';

const NoDataMessage = ({
	message = "There is no data for your request"
}) => {


	return (
		<div className="no-data">
			<div className="image-wrapper">
				<NoDataImage/>
			</div>
			<h1>No results</h1>
			{message}
		</div>
	);
};

export default NoDataMessage;
