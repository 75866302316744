import React from 'react';
import './PaginationBox.scss';
import CustomButton from '../UI/CustomButton/CustomButton';
import NoDataMessage from '../UI/NoDataMessage/NoDataMessage';

const PaginationBoxLite = ({
	currentPage,
	changeCurrentPage,
	hasNextPage,
	noItems = false,
}) => {


	const goToNextPage = (currentPage) => {
		changeCurrentPage(+currentPage + 1)
	}

	const goToPreviousPage = (currentPage) => {
		if(currentPage && +currentPage > 1) {
			changeCurrentPage(+currentPage - 1)
		}
	}

	const pageSwitcher = <div className="page-switcher">
		<CustomButton
			kind="secondary"
			iconLeft="arrow_back"
			buttonText="prev"
			onClick={() => goToPreviousPage(currentPage)}
			disabled={!currentPage || +currentPage <= 1}
			id="pagination-prev"
		/>
		<CustomButton
			kind="secondary"
			iconRight="arrow_forward"
			buttonText="next"
			onClick={() => goToNextPage(currentPage)}
			disabled={!hasNextPage}
			id="pagination-next"
		/>
	</div>

	return (
		<div className="pagination-component">
			{!noItems && pageSwitcher}
			{noItems && <NoDataMessage/> }
		</div>
	);
};

export default React.memo(PaginationBoxLite);
