import React, {useContext, useEffect, useState} from 'react';
import {Prompt} from 'react-router-dom';
import './CredentialsDetailsPanel.scss';
import CredentialsDetailsPageContext from "../../context/pagesContext/credentialsDetailsPageContext";
import CredentialsGroup from "./CredentialsGroup";
import CustomButton from "../UI/CustomButton/CustomButton";
import useSapiService from '../hooks/useSapiService';
import useCustomToast from "../hooks/useCustomToast";
import CreateCredentialsGroupModal from "./CreateCredentialsGroupModal";
import useModal from "../hooks/useModal";
import useExitPrompt from "../hooks/useExitPrompt";

const sortKeys = (a, b) => {
    if (a === 'default') {
        return -1;
    }
    if (b === 'default') {
        return 1;
    }
    if (a > b) {
        return 1;
    }
    return -1;
}

const CredentialsDetailsPanel = () => {

    const {updateServiceCredentialsReq} = useSapiService();
    const {successInfo} = useCustomToast();
    const {isOpenModal, showModal, hideModal} = useModal();
    const [showExitPrompt, setShowExitPrompt] = useExitPrompt(false);

    const {
        service,
        serviceCredentials,
    } = useContext(CredentialsDetailsPageContext);

    const [credentials, setCredentials] = useState({});
    const [disabledUpdate, setDisabledUpdate] = useState(false);

    const {live, test} = credentials || {};

    useEffect(() => {
        setCredentials(serviceCredentials);
    }, [serviceCredentials]);

    const updateCredentials = () => {
        setDisabledUpdate(true);
        updateServiceCredentialsReq({
            serviceId: service.id,
            credentials,
        })
            .then(({data}) => {
                const {message} = data;
                successInfo(`${message}`);
                setDisabledUpdate(false);
                setShowExitPrompt(false);
            }).catch(() => setDisabledUpdate(false));
    }

    const beforeUpdateCredentials = (credentials) => {
        setShowExitPrompt(true);
        setCredentials(credentials);
    };

    return (
        <div>
            <Prompt
                when={showExitPrompt}
                message="You have unsaved changes. Are you sure you want to leave the page?"/>
            <div>
                <h3>{service.id && `${service.id} ${service.name} ( ${service.carrier_display_name} )`}</h3>
                <p>Credentials for <b>Cycleon</b> user. If you want to delete a group, leave it empty. Don't forget to
                    save at the end.</p>
            </div>
            <h4 className="mt-5 mb-2">Live:</h4>
            {live && Object.keys(live).sort(sortKeys).map(client =>
                <CredentialsGroup
                    key={client}
                    environment="live"
                    client={client}
                    credentials={credentials}
                    setCredentials={beforeUpdateCredentials}
                />)}
            <h4 className="mt-5 mb-2">Test:</h4>
            {test && Object.keys(test).sort(sortKeys).map(client =>
                <CredentialsGroup
                    key={client}
                    environment="test"
                    client={client}
                    credentials={credentials}
                    setCredentials={beforeUpdateCredentials}
                />)}
            <div className="fixed-buttons">
                <CustomButton
                    addedClassName="ml-0"
                    iconLeft="add_circle"
                    kind="secondary"
                    buttonText="Add credentials group"
                    onClick={() => showModal()}
                />
                <CustomButton
                    addedClassName="ml-0"
                    iconLeft="change_circle"
                    kind="primary"
                    buttonText="Save Credentials"
                    onClick={() => updateCredentials()}
                    disabled={disabledUpdate}
                />
            </div>
            <CreateCredentialsGroupModal
                isOpenModal={isOpenModal}
                hideModal={hideModal}
                fields={Object.keys(live ? live.default : {})}
                setCredentials={beforeUpdateCredentials}
            />
        </div>
    );
};

export default CredentialsDetailsPanel;
