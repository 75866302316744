import { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useRouter } from './useRouter';
import { updateQueryString } from '../../helpers/updateQueryString';


const useFilter = (defaultArr = [], filterType = '', filterCases = []) => {

	const { query, push} = useRouter();
	const queryData = query[filterType];
	const initialData = queryData || filterCases[0];
	const [filteredResultArr, setFilteredResultArr] = useState(defaultArr);
	const [filterParam, setFilterParam] = useState(initialData);

	const onFilterChangeFn = (param) => {
		updateQueryString(filterType, param, query, push);
		setFilterParam(param);
	};

	const _filter = useCallback((items, filterParam, filterCases, filterType) => {
		const idx = filterCases.findIndex(el => {
			return el === filterParam;
		});

		if (idx === 0 || idx === -1) {
			return items;
		}
		return items.filter((item) => {
			return item[filterType] === filterCases[idx];
		});

	}, [filterType, filterParam]); //eslint-disable-line

	useEffect(() => {
		setFilteredResultArr(_filter(defaultArr, filterParam, filterCases, filterType));
	}, [defaultArr, filterParam, _filter]);  //eslint-disable-line

	useEffect(() => {
		if (queryData !== filterParam) {
			setFilterParam(queryData);
		}
	}, [queryData]); //eslint-disable-line

	return {
		onFilterChangeFn,
		filteredResultArr,
		initialData,
	};
};

useFilter.propTypes = {
	defaultArr: PropTypes.array.isRequired,
	filterCases: PropTypes.array.isRequired,
	filterParam: PropTypes.string.isRequired,
	filterType: PropTypes.string.isRequired,
};

export default useFilter;
