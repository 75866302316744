import React from 'react';
import LoginForm from '../components/forms/LoginForm';
import './LoginPage.scss';
import AuthLayout from '../components/UI/Layout/AuthLayout';

const LoginPage = () => {

const content =	<LoginForm/>

	return <AuthLayout
				addedClassName="login-page"
				contentData={content}
			/>;
};

export default LoginPage;
