import { useState, useEffect } from 'react';
import moment from 'moment';

const useDate = ({isUtc = false}) => {

	const dateMoment = () => {
		return isUtc ? moment.utc() : moment()
	};

	const [now, setNow] = useState(dateMoment());

	const currentMoment = () => {
		setNow(dateMoment());
	};

	useEffect(() => {
		setInterval(currentMoment, 1000);
		return clearInterval(currentMoment);
	}, []); //eslint-disable-line

	let year = now.format('YYYY');
	let month = now.format('MMM');
	let day = now.format('DD');
	let hour = now.format('kk');
	let min = now.format('mm');
	let sec = now.format('ss');

	return {
		year,
		month,
		day,
		hour,
		min,
		sec,
	};
};

export default useDate;
