import React from 'react';
import {ReactComponent as ErrorImage } from '../../assets/images/error_img.svg';
import './ErrorIndicator.scss';

const ErrorIndicator = ({ children, globalError = false, offline = false }) => {

	const errorText = globalError
		? <>
				<div className="h1">Can't get data from server!</div>
				<div className="subtext">Don`t worry. We will fix it soon. </div>
			</>
		: <>
		<div className="h1">Something went wrong!</div>
		<div className="subtext">Don`t worry. We will fix it soon. <br/>
			Use main navigation to go to any other page.
		</div>
		</>;

		const offlineText = <>
			<div className="h1">You are offline now!</div>
			<div className="subtext">Please check your internet connection! </div>
		</>

	 const defaultErrorPlaceholder =
		 <div className="error-placeholder">
			 <div className="image-wrapper">
				 <ErrorImage/>
			 </div>
		 <div className="error-text">
			 {!offline && errorText}
			 {offline && offlineText}
		 </div>
	 </div>;

	return (
		<div className="error-indicator text-center">
			{children || defaultErrorPlaceholder}
		</div>
	);
};

export default ErrorIndicator;
