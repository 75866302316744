import { stringify } from 'query-string';

export const updateQueryString = (keyName, value, queryObj, pushFn) => {
	let currentQuery = { ...queryObj, [keyName]: value };
	if (!currentQuery[keyName]) {
		delete currentQuery[keyName];
	}

	const queryString = stringify(currentQuery);
		pushFn({ search: `?${queryString}`});
};


