import React from 'react';
import { Form } from 'react-bootstrap';
import PropTypes from 'prop-types';

import './Dialog.scss';
import Icon from '../Icon/Icon';
import CustomButton from '../CustomButton/CustomButton';

const Dialog = ({
	type,
	headerText = 'Dialog header',
	bodyText,
	actionButtonText = 'ok',
	hasCancelButton = true,
	cancelButtonText = 'Cancel',
	iconName,
	closeFn,
	actionFn,
	addBackground = false,
}) => {

	const dialogTypeButton = type === 'danger' ? 'destructive' : type;

	return (
		<>
			{ addBackground && <div className="dialog-bgr" /> }
			<div className="dialog">
				<div className="dialog-header">
					<h4 className={`text-${type}`}>
						{iconName && <Icon iconName={iconName} size={20} />}
						<span>{headerText}</span>
					</h4>
				</div>
				<div className="dialog-text">{bodyText}</div>
				<div className="dialog-footer">
					<Form.Group className="m-0">
						<div className="button-wrapper d-flex justify-content-end">
							{hasCancelButton && (
								<CustomButton
									kind="text"
									onClick={closeFn}
									buttonText={cancelButtonText}
								/>
							)}
							<CustomButton
								kind={`${dialogTypeButton}`}
								onClick={actionFn}
								buttonText={actionButtonText}
							/>
						</div>
					</Form.Group>
				</div>
			</div>
		</>
	);
};

Dialog.propTypes = {
	type: PropTypes.string.isRequired,
	headerText: PropTypes.string,
	bodyText: PropTypes.string,
	actionButtonText: PropTypes.string,
	cancelButtonText: PropTypes.string,
	hasCancelButton: PropTypes.bool,
	closeFn: PropTypes.func.isRequired,
	actionFn: PropTypes.func.isRequired,
	addBackground:  PropTypes.bool
};

export default Dialog;
