import React from 'react';
import './ModalDetails.scss';


const ModalDetails = ({
	headerLeft,
	headerMiddle,
	headerRight,
	headerWide,
	detailsContent,
	footerContent,
	customClass
}) => {
	return (
		<div className={`modal-details ${customClass}`}>
			<div className="modal-details-header">
				<div className="flex-header">
					{headerLeft && <div className="header-left">{headerLeft}</div>}
					{headerMiddle && <div className="header-middle">{headerMiddle}</div>}
					{headerRight && <div className="header-right">{headerRight}</div>}
				</div>
				{headerWide && <div className="header-main">{headerWide}</div>}
			</div>
			<div className="modal-details-content">
				{detailsContent}
			</div>
			{footerContent && <div className="modal-details-footer">
				{footerContent}
			</div>}
		</div>
	);
};

export default ModalDetails;
