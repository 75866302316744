import moment from 'moment';

const useDateStamp = () => {
	function getUtcDateFromStamp(stamp) {
		return `${moment
			.unix(stamp)
			.utc()
			.format('DD.MM.YYYY, HH:mm:ss')
			.valueOf()}`;
	}

	function getDateFromStamp(stamp) {
		return `${moment
			.unix(stamp)
			.format('DD.MM.YYYY, HH:mm:ss')
			.valueOf()}`;
	}
	return {
		getUtcDateFromStamp,
		getDateFromStamp
	};
};

export default useDateStamp;
