import React from 'react';
import Modal from "../UI/Modal/Modal";
import Dialog from "../UI/Dialog/Dialog";

const DeleteStatusModal = ({isOpenModal, hideModal, deletedStatus, deleteTrackingStatus}) => {
    return (
        <Modal show={isOpenModal} close={hideModal} customClass="small-dialog">
            <Dialog
                type="danger"
                headerText="Warning!"
                bodyText={`Status ${deletedStatus.carrier_status} will be deleted. Continue?`}
                iconName="remove_circle"
                actionButtonText="Delete"
                actionFn={() => {
                    deleteTrackingStatus();
                    hideModal();
                }}
                closeFn={() => {
                    hideModal()
                }}
            />
        </Modal>
    );
}

export default DeleteStatusModal;