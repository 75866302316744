import React from 'react';
import { ToastContainer } from 'react-toastify';
import Icon from '../Icon/Icon';
import './CustomToast.scss';


const CustomToast = ({ closeToast, autoClose = 5000, ...props }) => {

	return (
		<>
			<ToastContainer
				{...props}
				position="top-right"
				autoClose={autoClose}
				closeOnClick={true}
				closeButton={
					<Icon
						iconName="close"
						size={16}
						onClick={closeToast}/>}
			/>
		</>
	);
};

export default	CustomToast;
