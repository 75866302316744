const useListKeyNavigation = () => {

	const findItemByValue = (itemValue, itemsList) => {
		const hasItemList = itemsList && itemsList.length > 0;
		let currItem;

		if(itemValue && hasItemList) {
			 currItem = itemsList.find((el) => {
				 return el.value === itemValue;
			 });
		}
		if (!itemValue && hasItemList) {
			currItem = itemsList[0]
		}
		if(!itemValue && !hasItemList) {
			return ''
		}
		return currItem ? currItem.name : '';
	};

	const findItemIndex = (itemValue, list) => {
		if (itemValue) {
			const currIndex = list.findIndex((el) => {
				return el.value === itemValue;
			});
			return currIndex !== -1 ? currIndex : 0;
		}
		return 0;
	};

	const getActiveListItem = (activeItemValue, list, order = 'next', filteredOptionsList) => {
		const activeItemIndex = findItemIndex(activeItemValue, list);
		let newActiveItemValue;
		if (order === 'next') {
			newActiveItemValue = list.length === (activeItemIndex + 1)
				? activeItemValue
				: list[activeItemIndex + 1].value;
		}
		if (order === 'prev') {
			newActiveItemValue = activeItemIndex === 0
				? activeItemValue
				: filteredOptionsList[activeItemIndex - 1].value;
		}
		return newActiveItemValue;
	};

	return {
		getActiveListItem,
		findItemByValue,
		findItemIndex,
	};
};

export default useListKeyNavigation;
