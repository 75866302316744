import React from 'react';
import CredentialsPageContext from '../../context/pagesContext/credentialsPageContext';
import CredentialsPanel from '../../components/credentials/CredentialsPanel';
import useCredentialsPage from './useCredentialsPage';
import Spinner from '../../components/Spinner/Spinner';
import ErrorIndicator from '../../components/ErrorIndicator/ErrorIndicator';
import PageLayout from '../../components/pageLayout/PageLayout';

const CredentialsPage = () => {

    const credentialsPageData = useCredentialsPage();
    const {credentialsData, isFetching} = credentialsPageData;

    const pageContent = <>
        {!credentialsData && isFetching && <Spinner/>}
        {!credentialsData && !isFetching && <ErrorIndicator globalError/>}
        {credentialsData && <CredentialsPanel/>}
    </>


    return (
        <CredentialsPageContext.Provider value={credentialsPageData}>
            <PageLayout
                pageContent={pageContent}
            />
        </CredentialsPageContext.Provider>
    );
};

export default React.memo(CredentialsPage);
