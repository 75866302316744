
const multipleSplitString = (string, separators = []) => {
	if(separators.length === 0 || !Array.isArray(separators) || typeof string !== 'string') {
		console.error("multipleSplitString : WRONG PARAMETERS ENTERED");
		return []
	}
	const separatorsArr = separators.map((item)=> {
		return `\\${item}`;
	});
	const tempArr = string.split(new RegExp(separatorsArr.join('|'), 'g'));
	return tempArr.reduce((acc, curr)=> curr === '' ? acc : [...acc, curr], [])

}

export default multipleSplitString;
