import React, {useState, useEffect} from 'react';
import CustomButton from '../CustomButton/CustomButton';
import './RadioButtonGroup.scss';
import { createIdFromString } from '../../../helpers/createIdFromString';


const RadioButtonsGroup = ({
	onRadioChange,
	buttonsList=[],
	customClass='',
	initialActiveButton,
	small=false
}) => {

	const [activeButton, setActiveButton] = useState(initialActiveButton);

	useEffect(()=> {
		setActiveButton(initialActiveButton);
	}, [initialActiveButton]);

	function onClickHandler(e) {
		setActiveButton(e.currentTarget.name);
		onRadioChange(e.currentTarget.name);
	}

	const group = buttonsList.map(({ name, label, icon }) => {

		const isActive = activeButton === name;

		const kind = isActive ? 'primary' : 'secondary';
		return (
			<CustomButton
				onClick={onClickHandler}
				buttonText={label}
				key={name}
				name={name}
				iconLeft={icon}
				kind={kind}
				small={small}
				id={createIdFromString(name)}
			/>
		);
	});

	return (
		<div className={`btn-group radio-button-group ${customClass}`}>
			{group}
		</div>
	);
};

export default RadioButtonsGroup;
