import React from 'react';

const credentialsDetailsPageContext = React.createContext({
	service: {
		id: '',
		name: '',
		carrier_display_name: '',
	},
	error: '',
	isFetching: false,
	filterHandler: ()=>{},
});

export default credentialsDetailsPageContext;
