import React, { useState, useEffect, useContext } from 'react';
import AToolsHealthCheckListItem from './AToolsHealthCheckListItem/AToolsHealthCheckListItem';
import HealthCheckPageContext from '../../../context/pagesContext/healthCheckPageContext';
// import Spinner from '../../Spinner/Spinner';

const AToolsHealthCheckList = ({filteredResultArr}) => {

	const [statusesArr, setStatusesArr] = useState([]);
	const { isFetching } = useContext(HealthCheckPageContext);

	useEffect(() => {
		setStatusesArr(filteredResultArr);
	}, [filteredResultArr]);

	const list = statusesArr.length ? statusesArr.map((item) => {
		return item.label && <AToolsHealthCheckListItem
			key={`${item.label}_${item.additional_label}`}
			itemData={item}/>;
			}) : [];

	return (
		<div className={`health-check-statuses-list ${isFetching ? "semi-transparent" : "no-transparent"}`}>
			{list}
		</div>
	);
};

export default AToolsHealthCheckList;
