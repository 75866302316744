import React from 'react';
import { Link } from 'react-router-dom';
import LogoImg from '../../assets/images/Rebound.svg';
import './Logo.scss';

const Logo = ({customClassName}) => {
	return (
		<Link to="/" className={`logo ${customClassName || ''}`}>
			<img src={LogoImg} alt="Logo"/>
		</Link>
	);
};

export default Logo;
