import multipleSplitString from './multipleSplitString';

const formErrorsCheck = (itemName, errors) => {
	const namesArr = itemName
		? multipleSplitString(itemName, ['[', ']'])
		: [];

	const errorDeepLevel = namesArr.length - 1;

	switch(errorDeepLevel) {
		case 0 :
			return {
				hasError:  !!errors[`${namesArr[0]}`],
				errorMessage: (errors[`${namesArr[0]}`] || {}).message,
			}
		case 1 :
			return {
				hasError: !!(errors[`${namesArr[0]}`] || {})[`${namesArr[1]}`],
				errorMessage: ((errors[`${namesArr[0]}`] || {})[`${namesArr[1]}`]|| {}).message,
			}
		case 2 :
			return {
				hasError: !!((errors[`${namesArr[0]}`] || {})[`${namesArr[1]}`]|| {})[`${namesArr[2]}`],
				errorMessage: (((errors[`${namesArr[0]}`] || {})[`${namesArr[1]}`]|| {})[`${namesArr[2]}`] || {}).message,
			}
		case 3 :
			return {
				hasError: !!(((errors[`${namesArr[0]}`] || {})[`${namesArr[1]}`]|| {})[`${namesArr[2]}`] || {})[`${namesArr[3]}`],
				errorMessage: ((((errors[`${namesArr[0]}`] || {})[`${namesArr[1]}`]|| {})[`${namesArr[2]}`] || {})[`${namesArr[3]}`] || {}).message,
			}
		default:
			return {
				hasError: false,
				errorMessage: '',
			}
	}
}

export default formErrorsCheck
