import React from 'react';

const trackingMapDetailsPageContext = React.createContext({
    carrier: '',
    statuses: [],
    getTrackingMap: () => {},
    isFetching: false,
});

export default trackingMapDetailsPageContext;
