import React, { useEffect, useState, useCallback } from 'react';
import { useGlobal } from 'reactn';
import { Link } from 'react-router-dom';
import useModal from '../../hooks/useModal';
import Spinner from '../../Spinner/Spinner';
import Modal from '../../UI/Modal/Modal';

import CustomPre from '../../UI/CustomPre/CustomPre';
import useSapiService from '../../hooks/useSapiService';
import useDateStamp from '../../hooks/useDateStamp';
import { localRequestLogDetails } from '../../../localTestingData';
import ModalDetails from '../../UI/Modal/ModalDetails';
import useDialogType from '../../hooks/useDialogType';
import { formatBytes } from '../../../helpers/formatBytes';

import './RequestLogDetails.scss';
import useCommonListUpdate from '../../hooks/useCommonListUpdate';
import InfoItem from '../../UI/InfoItem/InfoItem';
import useDocumentTitle from '../../hooks/useDocumentTitle';
import useListSort from '../../hooks/useListSort';

const RequestLogDetails = ({ match }) => {
	const [requestDetailsData, setRequestDetailsData] = useState(null);

	const [commonListsObj] = useGlobal('commonListsObj');

	const { goBack } = useDialogType();

	const { isOpenModal, showModal } = useModal();
	const { getRequestsLogDetailsReq, isFetching, isProduction } = useSapiService();
	const { getUtcDateFromStamp } = useDateStamp();
	const { checkItemInCommonList } = useCommonListUpdate();
	const { alphabetSort } = useListSort();
	const { users, services } = commonListsObj;

	const getLocalRequestLogDetails = useCallback(() => {
		console.log('Using Local Data');
		setRequestDetailsData(localRequestLogDetails);
	}, []);

	const getRequestLogDetails = useCallback((id) => {
		return isProduction()
			? getRequestsLogDetailsReq(id)
				.then(({ data }) => {
					setRequestDetailsData(data);
				})
				.catch(() => {
					goBack();
				})
			: getLocalRequestLogDetails();
	}, [getLocalRequestLogDetails, isProduction, getRequestsLogDetailsReq]); //eslint-disable-line

	useEffect(() => {
		showModal();
		getRequestLogDetails(match.params.id);
	}, [getRequestLogDetails, match.params.id, showModal]);

	useEffect(() => {
		requestDetailsData && checkItemInCommonList({ commonList: users, itemId: requestDetailsData.userId });
	}, [checkItemInCommonList]); //eslint-disable-line

	const getServicesArray = (servicesList) => {
		const servicesArray = Array.isArray(servicesList)
			? servicesList
			: Object.values(servicesList);

		const servicesListArray = servicesArray.map(serviceId => {
			return { id: serviceId, name: services[serviceId] };
		});
		const alphabetSortedServices = alphabetSort(servicesListArray);

		return alphabetSortedServices.map(service => {
			return (
				<div key={service.id}>{service.name}</div>
				// <Link
				// 	key={service.id}
				// 	to={`/services/${service.id}`}
				// 	className="d-block"
				// >
				// 	{service.name}
				// </Link>
			);
		});
	};

	const userLogin = requestDetailsData && users[requestDetailsData.userId]
		? users[requestDetailsData.userId]
		: 'NO USER';

	const title = requestDetailsData ? `Request ID: ${requestDetailsData.requestId}` : '';
	useDocumentTitle(title);

	const { host, uri } = requestDetailsData || {};
	const [firstSubdomain] = host ? host.split('.') : '';
	const fullHostString = requestDetailsData
		&& <><span>{`${firstSubdomain}...`}</span><span className="font-weight-bold">{`${uri}`}</span></>;

	const execTimeData = requestDetailsData && <div className="d-flex">
		<div className="exec-time-item mr-2">
			<span>{requestDetailsData.execTime}s</span>
		</div>
		<div className="exec-time-item mr-2">
			(<span>Inner:&nbsp;</span><span>{requestDetailsData.innerTime}s,</span>
		</div>
		<div className="exec-time-item">
			<span>Outer:&nbsp;</span><span>{requestDetailsData.outerTime}s</span>)
		</div>
	</div>;

	const currentHealthCheckId = requestDetailsData && requestDetailsData.healthCheckIds && Object.keys(requestDetailsData.healthCheckIds)[0];

	const headerContent = requestDetailsData && <div className="additional-info">
		<div className={`heading-left ${!currentHealthCheckId ? 'mb-3' : ''}`}>
			<h3>{title}</h3>
		</div>
		{currentHealthCheckId && <div className="subtitle mb-4">
			Healthcheck:
			<Link
				to={`/requests_log/request_details/${currentHealthCheckId}`}
				onClick={() => {
					sessionStorage.clear();
					sessionStorage.setItem('healthCheckIds', JSON.stringify(requestDetailsData.healthCheckIds));
				}}
			> {currentHealthCheckId}</Link>
		</div>}
		<div className="common-info row">
			<div className="common-info-col col-12 col-sm-6 col-xl-3">
				<InfoItem
					iconName="access_time"
					infoData={`${getUtcDateFromStamp(requestDetailsData.requestTime)} UTC`}
					title="Request time" />
				<InfoItem
					iconName="history"
					infoData={execTimeData}
					title="Execution time"
				/>
			</div>
			<div className="common-info-col col-12 col-sm-6 col-xl-3">
				<InfoItem
					text={requestDetailsData.request.method}
					infoData={fullHostString}
					title="Host"
				/>
				<InfoItem
					iconName="language"
					infoData={requestDetailsData.ip}
					title="IP"
				/>
			</div>
			<div className="common-info-col col-12 col-sm-6 col-xl-3">
				<InfoItem
					iconName="person"
					// infoData={<Link
					// 	to={`/users/${requestDetailsData.userId}`}
					// 	className="d-block"
					// >
					// 	{userLogin}
					// </Link>}
					infoData={userLogin}
					title="User login"
				/>
				<InfoItem
					iconName="cloud"
					infoData={formatBytes(requestDetailsData.memoryUsage)}
					title="Memory usage"
				/>
			</div>
			<div className="common-info-col col-12 col-sm-6 col-xl-3">
				{requestDetailsData && requestDetailsData.services && <InfoItem
					iconName="widgets"
					infoData={getServicesArray(requestDetailsData.services)}
					title="Services"
					expandable
				/>}
			</div>
		</div>
		<div className="col-12">
			<div className="divider" />
		</div>
	</div>


	const detailsContent = requestDetailsData && (
		<div className="mb-lg-5">
			<div className="row">
				<div className="col-12 col-md-6">
					<div className="heading d-flex align-items-center">
						<span className="badge badge-secondary mr-2">
							{requestDetailsData.request.method}
						</span>
						<h5 className="mb-0"> Request</h5>
					</div>
					<div className="req-header">
						<CustomPre
							title="header"
							data={requestDetailsData.request.header}
						/>
					</div>
					<div className="req-body">
						<CustomPre title="body" data={requestDetailsData.request.body} />
					</div>
				</div>
				<div className="col-12 col-md-6">
					<div className="heading d-flex align-items-center">
						<span
							className={`badge ${requestDetailsData.response.code === 200 ? 'badge-success' : 'badge-danger'} mr-2`}>
							{requestDetailsData.response.code}
						</span>
						<h5 className="mb-0"> Response</h5>
					</div>
					<div className="res-header">
						<CustomPre
							title="header"
							data={requestDetailsData.response.header}
						/>
					</div>
					<div className="res-body">
						<CustomPre title="body" data={requestDetailsData.response.body} />
					</div>
				</div>
			</div>

		</div>
	);

	return (
		<Modal show={isOpenModal} close={goBack} bsSize="xl">
			{isFetching ? (
				<Spinner />
			) : (
				requestDetailsData && (
					<ModalDetails
						customClass="request-log-details"
						headerWide={headerContent}
						detailsContent={detailsContent}
					/>
				)
			)}
		</Modal>
	);
};

export default React.memo(RequestLogDetails);
