import React, { useState, useEffect } from 'react';
import { Route } from 'react-router-dom';

import AToolsHealthCheckPanel from '../components/aToolsHealthCheck/aToolsHealthChecklList/AToolsHealthCheckPanel';
import useSapiService from '../components/hooks/useSapiService';
import HealthCheckPageContext from '../context/pagesContext/healthCheckPageContext';
import { localsHealthStatusesList } from '../localTestingData';
import AToolsHealthCheckDetailsView
	from '../components/aToolsHealthCheckDetails/aToolsHealthCheckDetailsView/AToolsHealthCheckDetailsView';
import Spinner from '../components/Spinner/Spinner';
import useAutoUpdateData from '../components/hooks/useAutoApdateData';
import ErrorIndicator from '../components/ErrorIndicator/ErrorIndicator';
import useListSort from '../components/hooks/useListSort';
import PageLayout from '../components/pageLayout/PageLayout';

const HealthCheckPage = () => {
	const [healthStatusesList, setHealthStatusesList] = useState();
	const { alphabetSort } = useListSort();

	const {
		getHealthStatusesReq,
		isFetching,
		isProduction,
		error
	} = useSapiService();

	useEffect(() => {
		getHealthStatuses();
	}, []); //eslint-disable-line

	function getLocalHealthStatuses(){
		console.log('USING LOCAL DATA');
		setHealthStatusesList(updateHealthStatusesList(localsHealthStatusesList));
	}

	function getHealthStatuses() {
		isProduction()
		?	getHealthStatusesReq()
		.then(({data}) => {
			setHealthStatusesList(updateHealthStatusesList(data));
		})
			: getLocalHealthStatuses();
	}

	useAutoUpdateData({
		data:healthStatusesList,
		updateFunction: getHealthStatuses,
		isAllowed: false
	});

	function updateHealthStatusesList(list) {

		function _statusLabel(percent) {
			if (percent >= 0 && percent < 25) {
				return 'critical';
			}
			if (percent >= 25 && percent <= 74) {
				return 'issue';
			}
			if (percent > 74 && percent <= 100) {
				return 'well';
			}
			if (percent === 'empty') {
				return null;
			}
		}

		return list
			? alphabetSort(list, 'label').map(el => {
			const healthStatus = !!el.periods && el.periods["3600"]
			? el.periods["3600"].rate : 'empty' ;
			return {...el, healthStatus: _statusLabel(healthStatus)}
		})
			:[];
	}


	const pageContent = <>
		{!healthStatusesList && isFetching && <Spinner/>}
		{!healthStatusesList && !isFetching && <ErrorIndicator globalError/>}
		{	healthStatusesList &&	<AToolsHealthCheckPanel/>	}
	</>
	return (
		<HealthCheckPageContext.Provider value={{
			healthStatusesList,
			isFetching,
			error,
			getHealthStatuses
		}}>
			<PageLayout
				addedClassName="health-check-page"
				pageContent={pageContent}
			/>
				<Route
					path="/healthcheck/request_details/:request_id"
					render={(props) => <AToolsHealthCheckDetailsView {...props}/>}/>
		</HealthCheckPageContext.Provider>
	);
};

export default HealthCheckPage;
