import React, {useState} from 'react';
import Icon from '../Icon/Icon';
import './InfoItem.scss';

const InfoItem = ({iconName, title, infoData, text, addedClassName, expandable=false}) => {
	const isExpandable = expandable && Array.isArray(infoData) && infoData.length > 5;
	 const [expand, setExpand] = useState(false);

	 const toggleList = () => {
		 if (isExpandable) {
			 setExpand((curr) => !curr )
		 }
	 }

	return <div className={`info-item ${addedClassName || ''} ${isExpandable ? 'expandable' : ''} ${expand ? 'show' : '' }`} onClick={toggleList}>
		<div className="info-icon">
			{iconName && <Icon iconName={`${iconName}`} size={20}/>}
			{text && <span className="icon-text">{text}</span>}
		</div>
		<div className="info-content">
			<div className="info-title">
				<span>{title}</span>
				{isExpandable && <Icon iconName="unfold_more" size={20}/>}
			</div>
			<div className="info-data">
				{infoData}
			</div>
		</div>
	</div>
}

export default InfoItem;
