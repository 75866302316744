import React, { useEffect } from 'react';
import { useGlobal } from 'reactn';

import './Layout.scss';
import { useRouter } from '../../hooks/useRouter';
import { fullRoutingConfig } from '../../../routes/routesMenuConfig';

const Layout = ({ children }) => {

	const [, setCurrentPageUrl] = useGlobal('currentPageUrl');
	const [, setCurrentPageName] = useGlobal('currentPageName');

	const { location } = useRouter();

	useEffect(() => {
		setCurrentPageUrl(location.pathname.split('/')[1]);
		setCurrentPageName(getCurrentPageName());
	}, [location.pathname]); //eslint-disable-line

	function getCurrentPageName() {
		let reduced = fullRoutingConfig.reduce((acc, cur) => {
			if (cur.path && (cur.path.split('/')[1] === location.pathname.split('/')[1])) {
				cur.path.split('/').length === 2 && acc.push(cur);
			} else if (Object.keys(cur).includes('subItem')) {
				const filtered = cur.subItem.filter((el) => {
					const subUrl = el.path;
					return subUrl && subUrl.split('/')[1] === location.pathname.split('/')[1];
				});
				filtered[0] && acc.push(filtered[0]);
			}
			return acc;
		}, []);
		const [pageData] = reduced;
		return pageData ? pageData.text : '';
	}

	return (
		<div className="wrapper">
			<div className="content-wrapper">
				{children}
			</div>
		</div>
	);
};

export default React.memo(Layout);
