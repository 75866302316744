export const updateServicesCommonLists = (data) => {
  return Object.entries(data).reduce((acc, [key, value]) => {
    if (key === 'services' ||
        key === 'availableServices') {
      const newValue = value.map((el) => {
        return {
          name: `${el.name}: ${el.value}`,
          value: el.value
        }
      } )
      return {...acc, [key]: newValue}
    }
    return {...acc, [key]: value}
  }, {})
}

export const createAdvancedCarriersList = ({arrayToChange, namesListObj}) => {
  return arrayToChange.map((el) => {
    const item = Object.entries(namesListObj).find(([, name]) => {
      return name.toLowerCase() === el.value.toLowerCase();
    })
    const [id, name] = item || [];
    return item ?{name: `${name}: ${id}`, value: name} : el
  })
}
