import React, { useState } from 'react';
import './PageDataPanel.scss';
import CustomButton from '../CustomButton/CustomButton';


const PageDataPanel = ({
	panelHeading='',
	panelHeadingMobile = '',
	panelContent='',
	addedClassName = '',
	mobileSwitcher = true,
}) => {

	const[isVisible, setIsVisible] = useState(false);

	const toggleMobileItem = () => {
		setIsVisible(!isVisible);
	}

	const hideClassName = panelHeadingMobile && isVisible  ? '' : 'hidden-item';

	return (
		<div className={`page-data-panel ${addedClassName}`}>
			<div className="panel">
				{panelHeading &&
				<div className="panel-heading">
					<div className="full-heading-content">
						{ mobileSwitcher && <CustomButton
							onClick={toggleMobileItem}
							iconLeft="format_line_spacing"
							kind="secondary"
							addedClassName="mobile-switcher"
						/>}
						{panelHeading}
					</div>
					{panelHeadingMobile && <div className={`mobile-heading-content  ${hideClassName}`}>
						{panelHeadingMobile}
					</div>}
				</div>}
				<div className="panel-content">
					{panelContent}
				</div>
			</div>
		</div>
	);
};

export default PageDataPanel;
