import React, {Fragment} from 'react';
import { percentClassName } from '../../helpers/barcodesFuctions';
import BarcodeTableViewItem from './BarcodeTableViewItem';
import BarcodesCardsViewItem from './BarcodesCardsViewItem';
import './BarcodesView.scss';

const BarcodesView = (props) => {
	const { barcodesRangesData, viewType = 'table', hideCarrier, deleteFn} = props;

	const isTable = viewType === 'table';

	const handleDelete = (data) => {
		deleteFn(data);
	}

	const list = barcodesRangesData && barcodesRangesData.map(rangeItem => {

		const { remaining_all, total_all, itemKey } = rangeItem;
		const percentLeft = Math.round((+remaining_all * 100) / +total_all);
		const percentClass = percentClassName(percentLeft);
		return <Fragment key={itemKey}>
			{isTable &&
			<BarcodeTableViewItem
				rangeItemData={rangeItem}
				percentClass={percentClass}
				percentLeft={percentLeft}
				hideCarrier={hideCarrier}
				handleDelete={handleDelete}
			/>}
			{!isTable &&
			<BarcodesCardsViewItem
				rangeItemData={rangeItem}
				percentClass={percentClass}
				percentLeft={percentLeft}
				hideCarrier={hideCarrier}
				handleDelete={handleDelete}
			/>}
		</Fragment>;
	});

	return (<div className="barcodes-view">
			{isTable &&
			<table className="table barcodes-table-view">
				<thead>
					<tr>
						<th />
						<th className="header-user">User</th>
						{!hideCarrier && <th className="header-carrier">Carrier</th>}
						<th className="header-group">Barcode Group</th>
						<th className="header-prefix">Prefix</th>
						<th className="header-begin">Begin</th>
						<th className="header-end">End</th>
						<th className="header-remaining">Remaining</th>
						<th className="actions"> </th>
					</tr>
				</thead>
				<tbody>
					{list}
				</tbody>
			</table>}
			{!isTable &&
			<div className="barcodes-card-view">
				{list}
			</div>}

		</div>
	);
};

export default BarcodesView;
