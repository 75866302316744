
const useArrayFromObject = () => {
	function arrFromObj(obj, keyName="key", valueName="value" ) {
		let arr = [];
		for(let key in obj) {
			arr = [...arr, {
				[keyName]: key,
				[valueName]: obj[key]
			}]
		}
		return arr;
	}

	function changeObjValues(obj){
		let newObj = {};
		for(let key in obj) {
			newObj = {...newObj, [key]: arrFromObj(obj[key], "value", "name")}
		}
		return newObj
	}


	function addEmptyValue(arr, name="Any", value='') {
		return [...[{ name: name, value: value }], ...arr]
	}

	function changeArrForSelectList(arr = [], needEmptyItemFlag) {
		const arrOfObjects = arr.map(el => {
			return { name: el, value: el };
		});
		const arrOfObjectsWithEmptyItem = [...[{ name: 'Any', value: '' }], ...arrOfObjects];
		return needEmptyItemFlag ? arrOfObjectsWithEmptyItem : arrOfObjects;
	}




	return {
		arrFromObj,
		changeObjValues,
		addEmptyValue,
		changeArrForSelectList
	}
};




export default useArrayFromObject;
