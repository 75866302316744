import React, { useState, useRef, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';

import CheckboxList from '../CheckboxList/CheckboxList';
import './DropdownCheckboxList.scss';
import Icon from '../Icon/Icon';
import useDropDown from '../../hooks/useDropDown';

const DropdownCheckboxList = ({
	title,
	listArr,
	checkedListArr = [],
	additionalClasses,
	getSelectedArray = () => {},
	customId = '',
	quickSearch = true,
}) => {

	const [selectedItemsArr, setSelectedItemsArr] = useState(checkedListArr);

	const list = useRef(null);
	const activeItemEl = useRef(null);
	const { isVisible, hideDropdown, showDropdown, scrollToActiveItem } = useDropDown(list);

	useEffect(() => {
		setSelectedItemsArr(checkedListArr);
	}, [checkedListArr.length]); //eslint-disable-line

	useEffect(() => {
		getSelectedArray(selectedItemsArr);
	}, []); //eslint-disable-line

	function toggleHandler() {
		return isVisible ? hideDropdown() : showDropdown();
	}

	const getSelectedItems = useCallback(arr => {
		setSelectedItemsArr(arr);
		getSelectedArray(arr);
	}, [getSelectedArray]);

	return (
		<div className={`dropdown-checkbox-list form-group ${additionalClasses}`}>
			<div className="dropdown">
				{title ? (
					<label className="form-label">
						<span>{title}</span>
					</label>
				) : null}

				<input
					type="text"
					name={`dropdownCheckbox[${title}]`}
					value={`Selected ${selectedItemsArr.length} items`}
					onClick={toggleHandler}
					readOnly
					className="form-control"
				/>
				<Icon iconName="arrow_drop_down" size={18} onClick={toggleHandler}/>
				{isVisible && <div className={`dropdown-menu py-2 mb-0 show`} ref={list}>
					<CheckboxList
						listArray={listArr}
						checkedListArr={selectedItemsArr}
						getSelectedItems={getSelectedItems}
						customId={customId}
						quickSearch={quickSearch}
						isVisible={isVisible}
						hideDropdown={hideDropdown}
						dropdownRef={list}
						activeItemEl={activeItemEl}
						scrollToActiveItem={scrollToActiveItem}
					/>
				</div>}
			</div>
		</div>
	);
};

DropdownCheckboxList.propTypes = {
	title: PropTypes.string,
	listArr: PropTypes.array.isRequired,
	additionalClasses: PropTypes.string,
	checkedListArr: PropTypes.array,
	register: PropTypes.func,
};

export default React.memo(DropdownCheckboxList);
