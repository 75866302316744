import { useState, useEffect, useCallback, useMemo } from 'react';
import axios from 'axios';
import useCustomToast from './useCustomToast';
import { useGlobal } from 'reactn';

const useSapiService = () => {
	const [isFetching, setIsFetching] = useState(false);
	const [response, setResponse] = useState(null);
	const [error, setError] = useState(null);
	const [requestsList, setRequestsList] = useState([]);

	const { failedInfo } = useCustomToast();
	const [, setIsUserLogged] = useGlobal('isUserLogged');
	const [jsBuild, setJsBuild] = useGlobal('jsBuild');

	const _apiBase = ''; // API common URL for build
	const defaultConfig = useMemo(()=> ({
			baseURL: `${_apiBase}`,
			headers: { 'X-Requested-With': 'XMLHttpRequest' },
			method: 'GET'
	}), []);

	//TODO: Remove after finishing work
	const isProduction = useCallback(() => {
		return process.env.NODE_ENV === 'production';
	}, []);


	useEffect(()=> {
		return () => {
			requestsList.forEach(req => req[1].cancel('Cancelled'))
		}
	}, [requestsList.length]); //eslint-disable-line


	const errorInfo = (error) => {
		const { errors } = error.response.data;
		errors
			? errors.forEach(el => {
				return failedInfo(
					`Error ${error.response.status}. ${el.message}`
				);
			})
			: failedInfo(`${error}`);
	}

	const catchRequestError = useCallback((error) => {
		if(error.response){
			switch (error.response.status) {
				case 401:
					setIsUserLogged(false);
					break;
				case 404:
					failedInfo(`${error}`);
					break;
				case 400:
					errorInfo(error);
					break;
				case 403:
				case 414:
					failedInfo(`Error ${error.response.status}. ${error.response.statusText}`);
					break;
				case 500:
					errorInfo(error);
					break;
				default:
					failedInfo(`Unknown error. ${error}`);
			}
		} else {
			failedInfo(`Can't get response from server.`);
		}
	}, [])  //eslint-disable-line

	/*--------------------
	 Watch/Download files
	 --------------------------*/

	const createConfig = (useCallback(({
		url,
		query,
		method,
		headers,
		data,
	}) => {
		return {
			url: query
				? `${_apiBase}${url}${query}`
				: `${_apiBase}${url}`,
			...(method && {method: method}) ,
			...(headers && {headers: headers}) ,
			...(data && {data: data}),
		};
	}, []));

	function viewRemoteFile(data) {
		const file = new Blob([data], { type: data.type });
		const fileURL = URL.createObjectURL(file);
		window.open(fileURL);
	}

	function downloadRemoteFile(fileData, name = 'file') {
		let fileType;
		const hasTypeField = fileData.type !== undefined;
		if(hasTypeField) {
			switch (fileData.type) {
				case 'pdf':
					fileType = 'application/pdf';
					break;
				case 'png':
					fileType = 'image/png';
					break;
				case 'txt':
					fileType = 'text/plain';
					break;
				default:
					fileType = 'text/plain';
			}
		} else {
			fileType = 'application/octet-stream'
		}

		const url = hasTypeField
			? `data:${fileType};base64,${fileData.data}`
			: `data:${fileType};base64,${fileData.printDriver}`;

		const link = document.createElement('a');
		link.href = url;
		const fileName = fileData.type
			? `${name}.${fileData.type}`
			: `${name}`;
		link.setAttribute('download', `${fileName}`);
		link.click();
	}


	const createRequest = useCallback((requestConfig) => {
		setIsFetching(true);
		const source = axios.CancelToken.source();
		const request = axios({
			...defaultConfig,
			...createConfig(requestConfig),
			cancelToken: source.token
		});
		setRequestsList([...requestsList, [request,source]]);
		request
			.then(response => {
				const {headers} = response;
				if(headers['x-assets-hash']) {
					!jsBuild && setJsBuild(headers['x-assets-hash']);
					(jsBuild && jsBuild !== headers['x-assets-hash']) && document.location.reload(true);
				}

				setResponse(response);
			})
			.catch(error => {
				if(!axios.isCancel(error)) {
					setError(error);
					catchRequestError(error);
				}
			})
			.finally(() => {
				setIsFetching(false);
				setRequestsList(requestsList.filter(req => {
					return req[0] !== request;
				}));
			});
		return request;
	}, [catchRequestError, defaultConfig, createConfig]) //eslint-disable-line



	/*--------------------
	 Login
	 --------------------------*/

	const loginReq = (data) => {
		return createRequest({
			url: '/auth/login',
			method: 'POST',
			data: data,
		});
	}

	/*--------------------
	 Logout
	 --------------------------*/

	function logoutReq() {
		return createRequest({
			url: '/auth/logout',
			method: 'POST'
		});
	}

	/*--------------------
	 Login
	 --------------------------*/

	const activationReq = (data) => {
		return createRequest({
			url: '/activation',
			method: 'POST',
			data: data,
		});
	}


	/*--------------------
	 Common Data
	 --------------------------*/
	const getCommonListsReq = useCallback(() => {
		return createRequest({
			url: '/main/get_all_lists',
		});
	}, [createRequest]);

	/*--------------------
	 ReportsPage Page
	 --------------------------*/
	// const getReportsReq = useCallback((queryData) => {
	// 	return createRequest({
	// 		url: '/reports',
	// 		query: queryData,
	// 	});
	// }, [createRequest]);

	const getDashboardParcelsReq = useCallback((queryData) => {
		return createRequest({
			url: '/dashboard/parcelsStatistics',
			query: queryData,
		});
	}, [createRequest]);

	const getDashboardHealthCheckReq = useCallback(() => {
		return createRequest({
			url: '/dashboard/healthCheck',
		});
	}, [createRequest]);

	const getDashboardErrorMappingReq = useCallback(() => {
		return createRequest({
			url: '/dashboard/errorMapping',
		});
	}, [createRequest]);

	const getDashboardTrackingMappingReq = useCallback(() => {
		return createRequest({
			url: '/dashboard/trackingMapping',
		});
	}, [createRequest]);

	const getDashboardRequestsReq = useCallback((queryData) => {
		return createRequest({
			url: '/dashboard/requests',
			query: queryData,
		});
	}, [createRequest]);


	/*--------------------
	 Parcels Page
	 --------------------------*/
	const getParcelsListReq = useCallback((queryData) => {
			return createRequest({
				url: '/parcels',
				query: queryData,
			});
		},
		[createRequest]
	);

	function getParcelReq(id) {
		return createRequest({
			url: '/parcels/details/',
			query: `?id=${id}`,
		});
	}

	function getParcelInvoiceReq(id) {
		return createRequest({
			url: '/parcels/download_invoice',
			query: `?id=${id}`,
		});
	}

	function getParcelLabelReq(id, isOriginal) {
		return createRequest({
			url: '/parcels/download_label',
			query: `?id=${id}&original=${isOriginal}`,
		});
	}

	function getParcelManifestReq(id) {
		return createRequest({
			url: '/parcels/download_manifest',
			query: `?id=${id}`,
		});
	}

	function setParcelDataReq(data) {
		return createRequest({
			url: '/parcels/create_new',
			data: data,
			method: 'POST',
		});
	}

	function generatePrintHashReq(parcelId) {
		return createRequest({
			url: '/parcels/generate_print_hash',
			data: { id: parcelId },
			method: 'POST',
		});
	}

	function generateLabelBuilderPrintHashReq(stampId) {
		return createRequest({
			url: '/label_builder/generate_print_hash ',
			data: { id: stampId },
			method: 'POST',
		});
	};


	function getPrintDriverReq() {
		return createRequest({
			url: '/print_driver/download_driver',
		});
	}

	/*--------------------
	 Pallets Page
	 --------------------------*/
	const getPalletsListReq = useCallback(
		(queryData) => {
			return createRequest({
				url: '/pallets',
				query: queryData,
			});
		},
		[createRequest]
	);

	const getPalletReq = useCallback(
		(id) => {
			return createRequest({
				url: `/pallets/details`,
				query: `?id=${id}`,
			});
		},
		[createRequest]
	);

	const getPalletLabelReq = useCallback((id) => {
		return createRequest({
			url: '/pallets/download_label',
			query: `?id=${id}`,
		})
	},[createRequest])

	const getPalletManifestReq = useCallback((id) => {
		return createRequest({
			url: '/pallets/download_manifest',
			query: `?id=${id}`,
		})
	},[createRequest])

	/*--------------------
	 Requests log Page
	 --------------------------*/
	const getRequestsLogListReq =  useCallback((queryData) =>  {
		return createRequest({
			url: '/requests_log',
			query: queryData,
		});
	}, [createRequest])

	const getRequestsLogDetailsReq = useCallback((id) => {
		return createRequest({
			url: '/requests_log/details/',
			query: `?id=${id}`,
		});
	}, [createRequest]);


	/*--------------------
	 Services Page
	 --------------------------*/
	const getServicesListReq = useCallback(() => {
		return createRequest({
			url: '/services',
		});
	}, [createRequest]);

	const getServiceReq = useCallback((id) => {
		return createRequest({
			url: '/services/save/',
			query: `?id=${id}`,
		});
	}, [createRequest]);

	const saveServiceDataReq = useCallback((data) => {
		return createRequest({
			url: '/services/save_process',
			method: 'POST',
			data: data,
		});
	}, [createRequest]);

	/*--------------------
	 Users Page
	 --------------------------*/

	const getUsersDataReq = (queryData) => {
		return createRequest({
			url: `/users`,
			query: queryData,
		});
	}

	const getUserReq = useCallback((id) =>  {
		return createRequest({
			url: '/users/save_page/',
			query: `?id=${id}`,
		});
	}, [createRequest]);

	function saveUserDataReq(data) {
		return createRequest({
			url: '/users/save_process',
			method: 'POST',
			data: data,
		});
	}

	/*--------------------
	 Health Check Page
	 --------------------------*/

	function getHealthStatusesReq() {
		return createRequest({
			url: '/healthcheck',
		});
	}

	/*--------------------
	 Health Check Details Page
	 --------------------------*/

	function getHealthStatusesDetailsReq(queryData) {
		return createRequest({
			url: '/healthcheck/detail',
			query: queryData,
		});
	}

	function getHealthStatusesDetailsViewReq(param = 'id', id) {
		return createRequest({
			url: '/healthcheck/getData',
			query: `?${param}=${id}`
		});
	}

	/*--------------------
	 Carrier Errors Mapping
	 --------------------------*/

	function getCarrierErrorsMappingDataReq(queryData) {
		return createRequest({
			url: '/carrier_errors',
			query: queryData,
		});
	}

	function getCarrierErrorsMappingExampleReq(id) {
		return createRequest({
			url: '/carrier_errors/get_example',
			query: `?id=${id}`,
		});
	}

	function saveCarrierErrorMappingReq(data) {
		return createRequest({
			url: '/carrier_errors/save',
			method: 'POST',
			data: data,
		});
	}

	function deleteCarrierErrorMappingReq(id) {
		return createRequest({
			url: '/carrier_errors/delete',
			method: 'POST',
			data: {
				id: id,
			},
		});
	}

	/*--------------------
	 Label Builder
	 --------------------------*/

	function labelBuilderFieldsReq() {
		return createRequest({
			url: '/label_builder',
		});
	}

	function createLabelReq(queryData) {
		return createRequest({
			url: '/label_builder/get_label',
			query: queryData,
		});
	}

	/*--------------------
	 App Version
	 --------------------------*/

	function getCommitsReq(data) {
		return createRequest({
			url: '/app/version',
			method: 'POST',
			data: data
		});
	}


	function getRobotsDataReq() {
		return createRequest({
			url: '/robots',
		});
	}

	// function deleteRobotsItemReq(robotStr) {
	// 	return createRequest({
	// 		url: '/robots/stop',
	// 		method: 'POST',
	// 		data: {
	// 			name: robotStr,
	// 		},
	// 	});
	// }

	function deleteRobotsItemReq(robotStr) {
		return axios( {
			method: 'POST',
			headers: { 'X-Requested-With': 'XMLHttpRequest' },
			url: '/robots/stop',
			data: {
				name: robotStr,
			}
		})
	}

	/*--------------------
	 API console
	 --------------------------*/

	function getApiConsoleListsReq() {
		return createRequest({
			url: '/api_console',
		});
	}

	function getApiConsoleDataReq(data) {
		return createRequest({
			url: '/api_console/process_request',
			method: 'post',
			data: data,
		});
	}

	/*--------------------
	 PudoPointsPage Page
	 --------------------------*/
	const getPudoCarriersListReq = useCallback(() => {
			return createRequest({
				url: '/pudo_points',
			});
		},
		[createRequest]
	);

	const getPudoPointsReq = useCallback((queryData) => {
			return createRequest({
				url: '/pudo_points/map',
				query: queryData,
			});
		},
		[createRequest]
	);

	/*--------------------
	 Barcodes page
	 --------------------------*/

	const getBarcodesDataReq = useCallback(queryData => {
		return createRequest({
			url: '/barcode_ranges',
			query: queryData,
		});
	}, [createRequest])


	const saveBarcodeRangeReq = useCallback((data)=> {
		return createRequest({
			url: '/barcode_ranges/create',
			method: 'POST',
			data: data,
		});
	}, [createRequest])

	const deleteBarcodeRangeReq = useCallback((id) => {
		return createRequest({
			url: '/barcode_ranges/delete',
			method: 'POST',
			data: {
				id: id,
			},
		})
	}, [createRequest]);

	/*--------------------
	 Credentials page
	 --------------------------*/

	const getServicesCredentialsReq = useCallback(queryData => {
		return createRequest({
			url: '/credentials',
			query: queryData,
		});
	}, [createRequest])

	const getServiceCredentialsDetailsReq = useCallback(id => {
		return createRequest({
			url: '/credentials/details',
			query: `?id=${id}`
		});
	}, [createRequest])

	const updateServiceCredentialsReq = useCallback((data)=> {
		return createRequest({
			url: '/credentials/update',
			method: 'POST',
			data,
		});
	}, [createRequest])

	/*--------------------
	 Tracking Statuses page
	 --------------------------*/

	const getTrackingMapCarriersListReq = useCallback(queryData => {
		return createRequest({
			url: '/tracking_map',
			query: queryData,
		});
	}, [createRequest])

	const getTrackingMapReq = useCallback(carrierName => {
		return createRequest({
			url: '/tracking_map/details',
			query: `?carrier_name=${carrierName}`
		});
	}, [createRequest])

	const saveTrackingMapStatusReq = useCallback((data)=> {
		return createRequest({
			url: '/tracking_map/update',
			method: 'POST',
			data,
		});
	}, [createRequest])

	const deleteTrackingMapStatusReq = useCallback((data)=> {
		return createRequest({
			url: '/tracking_map/delete',
			method: 'POST',
			data,
		});
	}, [createRequest])


	return {
		isFetching,
		response,
		error,
		isProduction,
		viewRemoteFile,
		downloadRemoteFile,
		loginReq,
		logoutReq,
		activationReq,
		getCommonListsReq,
		getDashboardParcelsReq,
		getDashboardHealthCheckReq,
		getDashboardErrorMappingReq,
		getDashboardTrackingMappingReq,
		getDashboardRequestsReq,
		getParcelsListReq,
		getParcelReq,
		getParcelInvoiceReq,
		getParcelLabelReq,
		getParcelManifestReq,
		setParcelDataReq,
		generatePrintHashReq,
		generateLabelBuilderPrintHashReq,
		getPrintDriverReq,
		getPalletsListReq,
		getPalletReq,
		getPalletLabelReq,
		getPalletManifestReq,
		getRequestsLogListReq,
		getRequestsLogDetailsReq,
		getServicesListReq,
		getServiceReq,
		saveServiceDataReq,
		getUsersDataReq,
		getUserReq,
		saveUserDataReq,
		getHealthStatusesReq,
		getHealthStatusesDetailsReq,
		getHealthStatusesDetailsViewReq,
		getCarrierErrorsMappingDataReq,
		getCarrierErrorsMappingExampleReq,
		saveCarrierErrorMappingReq,
		deleteCarrierErrorMappingReq,
		labelBuilderFieldsReq,
		createLabelReq,
		getCommitsReq,
		getRobotsDataReq,
		deleteRobotsItemReq,
		getApiConsoleListsReq,
		getApiConsoleDataReq,
		getPudoCarriersListReq,
		getPudoPointsReq,
		saveBarcodeRangeReq,
		deleteBarcodeRangeReq,
		getBarcodesDataReq,
		getServicesCredentialsReq,
		getServiceCredentialsDetailsReq,
		updateServiceCredentialsReq,
		getTrackingMapCarriersListReq,
		getTrackingMapReq,
		saveTrackingMapStatusReq,
		deleteTrackingMapStatusReq,
	};
};

export default useSapiService;



