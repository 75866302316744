import { useContext, useCallback } from 'react';
import AppGlobalContext from '../../context/appGlobalContext';

const useCommonListUpdate = () => {

	const { getCommonLists } = useContext(AppGlobalContext);

	const checkItemInCommonList  = useCallback(({itemId = '', commonList}) => {
		//checks if item ID exist in commonList
		if(commonList && itemId){
			const hasUndefinedId = !commonList[`${itemId}`];
			hasUndefinedId && getCommonLists()
		}
	}, []); //eslint-disable-line

	const checkListInCommonList  = useCallback(({dataListArr = [], commonList, dataListArrItemParam}) => {
		//checks if all ID's in dataListArr exist in commonList
		if(commonList && dataListArr.length > 0) {
			const hasUndefinedIdInList = dataListArr && dataListArr.length > 0
				? dataListArr.some( item => {
					const currentItemId = item[`${dataListArrItemParam}`];
					return !commonList[currentItemId];
				})
				: false;
			hasUndefinedIdInList && getCommonLists()
		}
	}, []); //eslint-disable-line
	
	return {
		checkItemInCommonList,
		checkListInCommonList
	}
};

export default useCommonListUpdate;
