import React, { useReducer, useRef, useState, useEffect, useCallback } from 'react';
import Calendar from '../Calendar';
import CustomButton from '../../CustomButton/CustomButton';
import useOnClickOutside from '../../../hooks/useOnClickOutside';
import moment from 'moment';
import { formatToString, formatToMoment } from '../../../../helpers/momentDatesFunctions';
import TimePicker from '../TimePicker';

const CalendarPanel = ({
	hasTime,
	closeDatePicker,
	getDate,
	dateFrom,
	dateTo,
	datePickerType,
	singleCalendar,
	autoApply
}) => {

	const panelRef = useRef();

	const ranges = {
		'Today': [moment.utc().startOf('day'), moment.utc().endOf('day')],
		'Yesterday': [moment.utc().subtract(1, 'days').startOf('day'), moment.utc().subtract(1, 'days').endOf('day')],
		'Last 7 Days': [moment.utc().subtract(6, 'days').startOf('day'), moment.utc().endOf('day')],

		'Last Month': [moment.utc().subtract(1, 'month').startOf('month'), moment.utc().subtract(1, 'month').endOf('month')],
		'This Month': [moment.utc().startOf('month'), moment.utc().endOf('day')],
		'Last 30 Days': [moment.utc().subtract(29, 'days'), moment.utc().endOf('day')],
	};

	const presetRanges = Object.entries(ranges).map(([rangeName, rangeItem]) => {
		const [from, to] = rangeItem;
		return 	<div
			key={rangeName}
			className="range-item"
			onClick={() => applySelection(from, to, selectedTimeFrom, selectedTimeTo)}
		>{rangeName}</div>
	})

	const initialDateFrom = dateFrom && formatToMoment(dateFrom, hasTime);
	const initialDateTo = dateTo && formatToMoment(dateTo, hasTime);

	const initialTimeFrom = dateFrom
		? dateFrom.split(' ')[1]
		: '00:00:00'

	const initialTimeTo = dateTo
		? dateTo.split(' ')[1]
		: '23:59:59';

	const initialCalendarDate = initialDateTo || initialDateFrom || moment.utc();

	const calendarDateReducer = (calendarDate, action) => {
		switch (action.type) {
			case 'PREV_MONTH':
				return moment.utc(calendarDate).subtract(1, 'month');
			case 'NEXT_MONTH':
				return moment.utc(calendarDate).add(1, 'month');
			case 'SET_DATE' :
				return moment.utc().month(action.payload.month).year(action.payload.year);
			default:
				return calendarDate;
		}
	};


	const [calendarDate, dispatch] = useReducer(calendarDateReducer, initialCalendarDate);
	const [selectedDateFrom, setSelectedDateFrom] = useState('');
	const [selectedDateTo, setSelectedDateTo] = useState('');
	const [selectedTimeFrom, setSelectedTimeFrom] = useState(initialTimeFrom);
	const [selectedTimeTo, setSelectedTimeTo] = useState(initialTimeTo);
	const prevMonthCalendarDate = moment.utc(calendarDate).subtract(1, 'month');

	useOnClickOutside(panelRef, () => {
			applySelection(selectedDateFrom, selectedDateTo, selectedTimeFrom, selectedTimeTo)
	}
	);

	useEffect(()=> {
		const date = dateTo && formatToMoment(dateTo, hasTime)
		setSelectedDateTo(date)
	}, [dateTo]); //eslint-disable-line

	useEffect(()=> {
		const date = dateFrom && formatToMoment(dateFrom, hasTime)
		setSelectedDateFrom(date)
	}, [dateFrom]); //eslint-disable-line

	const currentTime = (datePickerType) => {
		if(datePickerType === 'date-from'){
			return selectedTimeFrom;
		}
		if(datePickerType === 'date-to'){
			return selectedTimeTo;
		}
	}


	const applySelection = (dateFrom, dateTo, timeFrom="", timeTo="") => {
		const [hf, mf, sf] = timeFrom.split(":");
		const [ht, mt, st] = timeTo.split(":");
		const fullDateFrom = moment.utc(dateFrom).hour(+hf).minute(+mf).second(+sf);
		const fullDateTo = moment.utc(dateTo).hour(+ht).minute(+mt).second(+st);
		const dateFromString = dateFrom ? formatToString(fullDateFrom, hasTime) : '';
		const dateToString = dateTo ? formatToString(fullDateTo, hasTime): '';
		getDate(dateFromString, dateToString);
		// closeDatePicker();
	};

	const selectTime = (time, datePickerType) => {
		if(datePickerType === 'date-from') {
			setSelectedTimeFrom(time);
		}
		if(datePickerType === 'date-to') {
			setSelectedTimeTo(time);
		}
	}

	const selectDate = useCallback((number, calendarDate) => {
		const currDate = moment.utc(calendarDate).date(number);
		if(singleCalendar) {
			if( datePickerType === "date-from") {
				if(selectedDateTo && selectedDateFrom && currDate.isAfter(selectedDateTo)){
					setSelectedDateFrom(selectedDateTo);
					setSelectedDateTo(currDate);
				} else {
					setSelectedDateFrom(currDate);
				}
			}
			if( datePickerType === "date-to") {
				if(selectedDateTo && selectedDateFrom && currDate.isBefore(selectedDateFrom)){
					setSelectedDateFrom(currDate);
					setSelectedDateTo(selectedDateFrom);
				} else {
					setSelectedDateTo(currDate);
				}
			}
		}
		else if (selectedDateFrom && !selectedDateTo && currDate.isAfter(selectedDateFrom) || (currDate.isSame(selectedDateFrom)) ) {
			//set date TO
			setSelectedDateTo(currDate);
		} else {
			//set date FROM
			setSelectedDateFrom(currDate);
			if (selectedDateTo) {
				//reset date TO
				setSelectedDateTo(null);
			}
		}
	}, [selectedDateFrom, selectedDateTo, datePickerType, singleCalendar]);

	const changeMonth = (currentTarget) => {
		if (currentTarget.className.includes('next')) {
			dispatch({ type: 'NEXT_MONTH' });
		}
		if (currentTarget.className.includes('prev')) {
			dispatch({ type: 'PREV_MONTH' });
		}
	};

	useEffect(()=> {
		if(initialDateTo && datePickerType === "date-to") {
			dispatch({type:"SET_DATE", payload: {
				month: initialDateTo.month(),
				year: initialDateTo.year()
				}})
		}
		if(initialDateFrom && datePickerType === "date-from") {
			dispatch({type:"SET_DATE", payload: {
					month: initialDateFrom.month(),
					year: initialDateFrom.year()
				}})
		}
	}, [datePickerType]); //eslint-disable-line

	const calendarItem = singleCalendar
		? <div className="calendar-item single">
			<Calendar
				selectedDateFrom={selectedDateFrom}
				selectedDateTo={selectedDateTo}
				isSingle={singleCalendar}
				calendarDate={calendarDate}
				changeMonth={changeMonth}
				selectDate={selectDate}
				datePickerType={datePickerType}
			/>
			{hasTime && <TimePicker
				currentTime={currentTime(datePickerType)}
				selectTime={selectTime}
				datePickerType={datePickerType}
			/>}
		</div>
		: <>
			<div className="calendar-ranges">
				{presetRanges}
			</div>
			<div className="calendar-prev-month">
				<Calendar
					selectedDateFrom={selectedDateFrom}
					selectedDateTo={selectedDateTo}
					calendarDate={prevMonthCalendarDate}
					changeMonth={changeMonth}
					selectDate={selectDate}
					datePickerType={datePickerType}
				/>
				{hasTime && <TimePicker
					currentTime={currentTime("date-from")}
					selectTime={selectTime}
					datePickerType={"date-from"}
				/>}
			</div>
			<div className="calendar-curr-month">
				<Calendar
					selectedDateFrom={selectedDateFrom}
					selectedDateTo={selectedDateTo}
					isSingle={singleCalendar}
					calendarDate={calendarDate}
					changeMonth={changeMonth}
					selectDate={selectDate}
					datePickerType={datePickerType}
				/>
				{hasTime && <TimePicker
					currentTime={currentTime("date-to")}
					selectTime={selectTime}
					datePickerType={"date-to"}
				/>}
			</div>
		</>;

	return (
		<div className="calendar-panel card" ref={panelRef}>
			<div className={`calendar-panel-content ${singleCalendar ? 'single' : ''}`}>
				{calendarItem}
			</div>
			{!autoApply && <div className="calendar-panel-footer">
				<div className="button-wrapper">
					<CustomButton
						buttonText="Apply"
						onClick={() => applySelection(selectedDateFrom, selectedDateTo, selectedTimeFrom,selectedTimeTo)}
						small
					/>
					<CustomButton
						buttonText="Cancel"
						kind="secondary"
						onClick={closeDatePicker}
						small
					/>
				</div>
			</div>}
		</div>
	);
};

export default CalendarPanel;
