import React, { createRef, useEffect, useLayoutEffect, useState } from 'react';
import CustomInput from '../СustomInput/CustomInput';
import useKeyPress from '../../hooks/useKeyPress';

const CustomSelectAdvancedDropdown = React.forwardRef((props, dropdownRef) => {

const {
	selectOptionsList,
	selectedValue,
	activeItemValue,
	handleSelectChange,
	activeItemEl,
	small,
	changeActiveListItem,
	scrollToActiveItem,
	quickSearch,
	hideDropdown
} = props;

	const [isDropUp, setIsDropUp] = useState(false);
	const [searchString, setSearchString] = useState('');

	const searchFieldRef = createRef();


	const searchField = <CustomInput
		type="text"
		ref={searchFieldRef}
		small
		iconLeft="search"
		itemName="quickSearch"
		formGroupClass="mb-2 mt-1"
		autoComplete="off"
		value={searchString}
		onChange={(e) => setSearchString(e.target.value)}
	/>;

	const arrowUpPress = useKeyPress('ArrowUp');
	const arrowDownPress = useKeyPress('ArrowDown');
	const enterPress = useKeyPress('Enter');
	const escPress = useKeyPress('Escape');



	const filteredOptionsList =
		selectOptionsList.length === 0
			? selectOptionsList
			: selectOptionsList.filter(item => {
				const stringArr = searchString.split(' ');
				return stringArr.every(el => {
					return item.name.toLowerCase().includes(el.toLowerCase());
				})
			});

	const optionsList = filteredOptionsList.map((option) => {
		const isSelected = option.value === selectedValue;
		const isActive = option.value === activeItemValue;
		const isTooLong = option.name.length > 20;
		const selectedItemClassName = isSelected ? 'selected' : '';
		const activeItemClassName = isActive ? 'active' : '';
		const wordBreakClassName = isTooLong ? 'text-break' : '';

		return <div
			key={`${option.name} ${option.value}`}
			data-value={option.value}
			ref ={isActive ? activeItemEl : null}
			className={`dropdown-list-item ${selectedItemClassName} ${activeItemClassName} ${wordBreakClassName}`}
			onClick={() => handleSelectChange(option.value)}
		>
			{option.name}
		</div>
	});


	const dropUpClassName = isDropUp && !small ? 'drop-up' : '';
	const dropUpSmallClassName = isDropUp && small ? 'drop-up-small' : '';

	useLayoutEffect(()=> {
			const dropdownBottomEdge = dropdownRef.current.getBoundingClientRect().bottom;
			const isOutOfViewport = dropdownBottomEdge >= window.innerHeight;
			setIsDropUp(isOutOfViewport);

	}, [dropdownRef])

	useEffect(()=> {
		if(activeItemEl.current && activeItemEl.current.classList.contains('selected')) {
			scrollToActiveItem( dropdownRef, activeItemEl);
		}
	}, [dropdownRef, activeItemEl.current, activeItemValue]); //eslint-disable-line

	useEffect(()=> {
		if (enterPress) {
			handleSelectChange(activeItemValue);
		}
		if (arrowDownPress ) {
			changeActiveListItem(activeItemValue, filteredOptionsList, 'next', filteredOptionsList)
			scrollToActiveItem( dropdownRef, activeItemEl);
		}
		if (arrowUpPress) {
			changeActiveListItem(activeItemValue, filteredOptionsList, 'prev', filteredOptionsList)
			scrollToActiveItem( dropdownRef, activeItemEl);
		}
		if (escPress) {
			hideDropdown();
		}
	}, [enterPress, arrowDownPress, arrowUpPress, escPress]); // eslint-disable-line

	useEffect(() => {
			if(searchFieldRef.current) {
				searchFieldRef.current.focus();
			}
	}, []); // eslint-disable-line

	return (
		<div className={`custom-dropdown card big-shadow dropdown-list ${dropUpClassName} ${dropUpSmallClassName}`} ref={dropdownRef}>
			{quickSearch && searchField}
			{optionsList}
		</div>
	);
});

export default CustomSelectAdvancedDropdown;
