import moment from 'moment';

export const formatToMoment = (date, addTime) => {
	return (typeof date === 'string')
		? moment.utc(date, `DD-MM-YYYY ${addTime ? "HH:mm:ss" : ''}`)
		: date;
}

export const formatToString = (date, addTime) => {
	return (typeof date === 'string')
		? date
		: date.format(`DD-MM-YYYY ${addTime ? "HH:mm:ss" : ''}`);
}
