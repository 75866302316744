import React, {Fragment, useEffect, useState} from 'react';
import CustomButton from "../UI/CustomButton/CustomButton";
import {useRouter} from "../hooks/useRouter";

const CredentialTableViewItem = (props) => {

    const {services, expanded} = props;
    const { match} = useRouter();
    const [isOpen, setIsOpen] = useState(expanded);
    const toggleSublist = () => {
        setIsOpen(!isOpen)
    }

    useEffect(() => {
        setIsOpen(expanded)
    }, [expanded]);

    const servicesList = services.map(service => {
        const {id, service_name, carrier_version} = service;
        return <tr key={id} className={`services-list ${isOpen ? 'is-open' : ''}`}>
            <td/>
            <td/>
            <td>
                <span>{id}</span>
            </td>
            <td>
                <span>{service_name}</span>
            </td>
            <td>
                <span>{carrier_version}</span>
            </td>
            <td>
                <CustomButton
                    linkTo={`${match.path}/details/${id}`}
                    iconLeft="app_registration"
                    kind="secondary"
                    onClick={() => true}
                    tooltip="Edit Credentials"
                />
            </td>
        </tr>
    });

    return (
        <Fragment>
            <tr className={`ranges-common-data ${isOpen ? 'is-open' : ''}`}
                onClick={toggleSublist}
            >
                <td>
                    <CustomButton
                        iconLeft={`${isOpen ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}`}
                        addedClassName="p-0 w-100"
                        kind="text"
                        onClick={toggleSublist}
                    />
                </td>
                <td>
                    <span>{services[0].display_name}</span>
                </td>
                <td/>
                <td/>
                <td/>
                <td/>
            </tr>
            {servicesList}
        </Fragment>
    );
};

export default CredentialTableViewItem;
