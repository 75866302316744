import React from 'react';
import { Link } from 'react-router-dom';

import { useGlobal } from 'reactn';
import './PageName.scss';

const PageName = ({ pageName, breadCrumbs }) => {

	const [currentPageName] = useGlobal('currentPageName');
	const pName = pageName || currentPageName;
	return (
		<>
			<div className="page-name h3">
				{pName}
			</div>
			{breadCrumbs && <div className="breadcrumbs">
				<Link to={'/healthcheck'}>Healthcheck</Link> / {pName}
			</div>}
		</>
	);
};

export default PageName;
