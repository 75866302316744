export const percentClassName = (percentLeft) => {
	let percentClass = '';
	if (percentLeft < 25) {
		percentClass = 'danger';
	}
	if (percentLeft >= 25 && percentLeft < 75) {
		percentClass = 'warning';
	}
	if (percentLeft >= 75 && percentLeft <= 100) {
		percentClass = 'success';
	}
	return percentClass;
};

export const barcodesLeftFn = (startValue, endValue, currentValue) => {
	const current = currentValue
		? currentValue
		: startValue;
	const quantityLeft = endValue - current;
	const total = endValue - startValue + 1;
	const percentLeft = Math.round((+quantityLeft * 100) / +total);
	const percentClass = percentClassName(percentLeft);

	return {
		quantityTotal: total,
		quantityLeft,
		percentLeft,
		percentClass,
	};
};

export const reduceBarcodesGroups = (barcodesArray, carriersListObj, usersListObj) => {
	return barcodesArray.reduce((acc, currRange) => {
		const {
			user_id, carrier_id, barcode_group, prefix,
			range_begin, range_end, range_left, range_current, id,
		} = currRange;
		const { quantityTotal } = barcodesLeftFn(range_begin, range_end, range_current);
		const percentLeft = Math.round((+range_left * 100) / +quantityTotal);
		const currentKey = `${user_id}_${carrier_id}_${barcode_group}`;
		const existingItemIndex = acc.findIndex(el => {
			return el.itemKey === currentKey;
		});

		const currItem = {
			itemKey: currentKey,
			user_id: user_id,
			...(!!carrier_id && { carrier_id: carrier_id }),
			...(!!carriersListObj && { carrierName: carriersListObj[carrier_id] }),
			...(!!usersListObj && { userName: usersListObj[user_id] }),
			barcode_group: barcode_group,
			remaining_all: range_left.toString(),
			remaining_percent_all: percentLeft,
			total_all: quantityTotal.toString(),
			ranges: [
				{
					prefix: prefix,
					range_begin: range_begin.toString(),
					range_end: range_end.toString(),
					range_current: range_current.toString(),
					remaining: range_left.toString(),
					id: id,
				},
			],
		};

		if (acc.length === 0 || existingItemIndex === -1) {
			acc.push(currItem);
		} else {
			const remaining_all = +acc[existingItemIndex].remaining_all + +(range_left);
			const total_all = +acc[existingItemIndex].total_all + +(quantityTotal);
			const remaining_percent_all = Math.round((+remaining_all * 100) / +total_all);
			const updatedRanges = [...acc[existingItemIndex].ranges, {
				prefix: prefix,
				range_begin: range_begin.toString(),
				range_end: range_end.toString(),
				ranges_total: quantityTotal.toString(),
				remaining: range_left.toString(),
				id: id,
			}];

			acc[existingItemIndex] = {
				...acc[existingItemIndex],
				remaining_all: remaining_all.toString(),
				remaining_percent_all: remaining_percent_all,
				total_all: remaining_all.toString(),
				ranges: updatedRanges,
			};
		}
		return acc;
	}, []);

};

export const getBarcodesCsvData = (arrOfObj) => {
	return arrOfObj.reduce((acc, curr) => {
		const {userName, carrierName, barcode_group, remaining_all, remaining_percent_all, ranges } = curr;
			const item = {
				userName: userName,
				carrierName: carrierName,
				barcode_group: barcode_group,
				prefix: '',
				rangeBegin: '',
				rangeEnd: '',
				remaining: remaining_all,
				remainingPercent: remaining_percent_all
			}
		 	const subItems = ranges.reduce((subAcc, subCurr) => {
			 const {prefix, range_begin, range_end, remaining } = subCurr;
			 const total = +range_end - (+range_begin) + 1;
			 const remainingPercent = Math.round((remaining * 100) / total);
			 const rangesItem = {
				 userName: '',
				 carrierName: '',
				 barcode_group: '',
				 prefix: prefix,
				 rangeBegin: range_begin,
				 rangeEnd: range_end,
				 remaining: remaining,
				 remainingPercent: remainingPercent
			 }
		 	return [...subAcc, rangesItem]
		 }, []);
			const allItems = [...[], item, ...subItems];


		return [...acc, allItems];
	}, []);
};

