import { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useRouter } from './useRouter';
import toEnglishText from '../../helpers/toEnglishText';
import { updateQueryString } from '../../helpers/updateQueryString';

const useSearch = (
	defaultArr = [],
	searchParam = '',
	secondSearchParam = '',
	langToChangeLayout,
) => {

	const isKey = useRef(false);
	const { query, push } = useRouter();
	const { quickSearch } = query;
	const [searchDataStr, setSearchDataStr] = useState('');
	const [searchResultArr, setSearchResultArr] = useState(defaultArr);

	const onChangeFn = (e) => {
		const transformedValue = langToChangeLayout
			? toEnglishText(e.target.value, langToChangeLayout)
			: e.target.value;
		setSearchDataStr(transformedValue);
		if (!isKey.current) {
			isKey.current = true;
		}
	};

	useEffect(() => {
		const timer = setTimeout(() => {
			if (searchDataStr) {
				setSearchResultArr(_search(defaultArr, searchDataStr, searchParam, secondSearchParam));
			} else {
				setSearchResultArr(defaultArr);
			}

			if (isKey.current) {
				updateQueryString('quickSearch', searchDataStr, query, push);
			}

		}, 700);
		return () => {
			clearTimeout(timer);
		};

	}, [JSON.stringify(defaultArr), searchDataStr]);  //eslint-disable-line

	useEffect(() => {
		if (!quickSearch) {
			setSearchDataStr('');
		} else if (quickSearch !== searchDataStr) {
			setSearchDataStr(quickSearch);
		}

	}, [quickSearch]); //eslint-disable-line

	const _search = (items, term, param, secondParam) => {
		if (term.length === 0) {
			return items;
		}

		return items.filter(item => {
			const stringArr = term.split(' ');
			return stringArr.every(el => {
				return (
					(item[param] &&
						item[param].toString().toLowerCase().includes(el.toLowerCase())) ||
					(item[secondParam] &&
						item[secondParam].toString().toLowerCase() === el.toLowerCase())
				);
			});
		});
	};

	return {
		searchResultArr,
		searchDataStr,
		onChangeFn,
	};
};

useSearch.propTypes = {
	defaultArr: PropTypes.array.isRequired,
	searchData: PropTypes.string.isRequired,
	searchParam: PropTypes.string.isRequired,
};

export default useSearch;
