export const stringObjectCheck = (stringData) => {

	if (typeof stringData !== 'object') {
		try {
			stringData = JSON.parse(stringData);
		} catch (e) {
		}
	}
	if (typeof stringData === 'object') {
		stringData = JSON.stringify(stringData, null, 2);
	}
	return stringData;
};

export const isJsonString = (str) =>  {
	try {
		JSON.parse(str);
	} catch (e) {
		return false;
	}
	return true;
};

export const isBase64 = (str) => {
	try {
		return btoa(atob(str)) === str;
	} catch (err) {
		return false;
	}
}

