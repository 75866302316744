import React, { useEffect, useState } from 'react';
import useClipboard from 'react-use-clipboard';

import useModal from '../../hooks/useModal';
import Spinner from '../../Spinner/Spinner';
import Modal from '../../UI/Modal/Modal';

import CustomPre from '../../UI/CustomPre/CustomPre';
import useSapiService from '../../hooks/useSapiService';
import { localHealthStatussesDetailsViewList } from '../../../localTestingData';

import './AToolsHealthCheckDetailsView.scss';
import ModalDetails from '../../UI/Modal/ModalDetails';
import useDialogType from '../../hooks/useDialogType';
import { stringObjectCheck } from '../../../helpers/stringObjectCheck';
import { Link } from 'react-router-dom';
import useWatchDownloadFile from '../../hooks/useWatchDownloadFile';
import CustomButton from '../../UI/CustomButton/CustomButton';
import { useGlobal } from 'reactn';
import useDocumentTitle from '../../hooks/useDocumentTitle';
import CustomSelectAdvanced from '../../UI/CustomSelect/CustomSelectAdvanced';
import InfoItem from '../../UI/InfoItem/InfoItem';

const AToolsHealthCheckDetailsView = ({ history, match}) => {
	const [healthCheckViewData, setHealthCheckViewData] = useState({});
	const [clipboardString, setClipboardString] = useState('');
	const [currentHealthCheck, setCurrentHealthCheck] = useState(match.params.id);
	const [healthCheckIds, setHealthCheckIds] = useState({});

	useEffect(() => {
		if(sessionStorage.getItem('healthCheckIds')) {
			setHealthCheckIds(JSON.parse(sessionStorage.getItem('healthCheckIds')))
		}
		return () => {
			sessionStorage.clear();
		}
	}, [sessionStorage.getItem('healthCheckIds')]);  //eslint-disable-line
	const [commonListsObj] = useGlobal('commonListsObj');
	const { users } = commonListsObj;
	const userName = users[healthCheckViewData.user_id] || "NO USER";

	const { goBack } = useDialogType();
	const [isCopied, setCopied] = useClipboard(clipboardString, {
		successDuration: 1000,
	});

	const { isOpenModal, showModal } = useModal();
	const { downloadLocalTxtFile } = useWatchDownloadFile();

	const {
		getHealthStatusesDetailsViewReq,
		isFetching,
		isProduction,
		error,
	} = useSapiService();

	const { requestId, requestsList: healthCheckViewList, label, additionalLabel, status} = healthCheckViewData;

	const carriersListArr = healthCheckIds
		? Object.entries(healthCheckIds).map(([key, value]) => {
			return {
				name: value,
				value:key
			}
		}) : []

	const hasLongList = carriersListArr.length > 1;

	function getLocalHealthStatusesDetailsView() {
		console.log('Using Local HealthStatusesDetailsView Data');
		setHealthCheckViewData(localHealthStatussesDetailsViewList);
	}

	function getHealthStatusesDetailsView(id) {
		const [param] = Object.keys(match.params);
		return isProduction()
			? getHealthStatusesDetailsViewReq(param, id)
				.then(({ data }) => {
					setHealthCheckViewData(data);
				})
			: getLocalHealthStatusesDetailsView();
	}

	useEffect(() => {
		const clipboardData = healthCheckViewList
			? healthCheckViewList.reduce((acc, { request, response }, index) => {
				const illegalRequest = !!request && !Object.keys(request).includes('body');
				const illegalResponse = !!response && !Object.keys(response).includes('body');

				const illegalStringRequest = `
REQUEST #${index + 1}
REQUEST DATA:
${stringObjectCheck(request) || ' '}
`;

				const illegalStringResponse = `
RESPONSE #${index + 1}
RESPONSE DATA:
${stringObjectCheck(response) || ' '}
`;

				const normalRequestString = `
REQUEST #${index + 1}
URL:
${stringObjectCheck(request.url) || ' '}
HEADERS:
${stringObjectCheck(request.headers) || ' '}
BODY:
${stringObjectCheck(request.body) || ' '}
`;

				const normalResponseString = !!response ? `
RESPONSE #${index + 1}
CODE:
${stringObjectCheck(response.url) || ' '}
HEADERS:
${stringObjectCheck(response.headers) || ' '}
BODY:
${stringObjectCheck(response.body) || ' '}
`
:
`RESPONSE #${index + 1}
RESPONSE DATA: ${response}
`;

				const currentRequestString = illegalRequest
					? illegalStringRequest
					: normalRequestString;

				const currentResponseString = illegalResponse
					? illegalStringResponse
					: normalResponseString;

				const currentString = `
${currentRequestString + ' '} ${currentResponseString}
					`;
				acc = acc + currentString;
				return acc;
			}, '')
			: 'NO DATA';

		setClipboardString(clipboardData);
	}, [healthCheckViewList]);

	useEffect(() => {
		showModal();
		getHealthStatusesDetailsView(currentHealthCheck);
	}, [currentHealthCheck]); //eslint-disable-line

	const list = healthCheckViewList
		? healthCheckViewList.map(({ request, response }, index) => {
			const illegalRequest = !!request && !Object.keys(request).includes('body');
			const illegalResponse = !!response && !Object.keys(response).includes('body');

			return (
				<div className="row mb-3" key={index}>
					<div className="col-12">
						<div className="heading">
							<h4 className="mb-4"> Request #{index + 1}</h4>
						</div>
						{!!request && !illegalRequest ? (
							<>
								<div className="req-url">
									<CustomPre title="url" data={request.url || ' '} />
								</div>
								<div className="req-header">
									<CustomPre title="headers" data={request.headers || ' '} />
								</div>
								<div className="req-body">
									<CustomPre title="body" data={request.body || ' '} />
								</div>
							</>
						) : (
							<div className="req-body">
								<CustomPre title="request data" data={request || ' '} />
							</div>
						)}
					</div>
					<div className="col-12">
						<div className="heading">
							<h4 className="mb-4"> Response #{index + 1}</h4>
						</div>
						{!!response && !illegalResponse ? (
							<>
								<div className="res-code">
									<CustomPre title="Code" data={response.code || ' '} />
								</div>
								<div className="res-header">
									<CustomPre title="headers" data={response.headers || ' '} />
								</div>
								<div className="res-body">
									<CustomPre title="body" data={response.body || ' '} />
								</div>
							</>
						) : (
							<div className="res-body">
								<CustomPre title="response data" data={response || ' '} />
							</div>
						)}
					</div>
				</div>
			);
		})
		: [];

	const currentHealthCheckTitle = healthCheckIds && hasLongList && healthCheckIds[currentHealthCheck];
	const title = hasLongList ? currentHealthCheckTitle : `${label} ${additionalLabel}`;
	useDocumentTitle(title);

	const parentPage = match.path.split("/")[1];
	const statusClass =
		status === '0' ? 'danger' : 'success';

	const statusTitle = status === '0' ? 'Request Error' : 'Request Success';
	const statusBadge = status && <span className={`badge badge-${statusClass}`}>{statusTitle}</span>

	const headerLeft = (
		requestId && <>
			{healthCheckIds && hasLongList &&<CustomSelectAdvanced
				formGroupClass="mb-1"
				selectOptionsList={carriersListArr}
				defaultValue={currentHealthCheck}
				onSelect={(value) => {
					setCurrentHealthCheck(value);
					history.push(`/requests_log/request_details/${value}`);
				}}
				itemName="carrierItem" />}
			{!hasLongList &&
			<div className="h2 title">{title}</div>}
			<div className="health-chek-details-info">
				<InfoItem
					iconName="settings_input_component"
					infoData={statusBadge}
					title="Request Status" />
				<InfoItem
					iconName="public"
					infoData={<Link
						to={{ pathname: `/requests_log`, search: `?request_id=${requestId}`, state: { pageFrom: parentPage } }}>
						{requestId}
					</Link>}
					title="Request ID" />
				<InfoItem
					iconName="person"
					infoData={userName}
					title="User" />
				{/*<div className="request-id">*/}
				{/*<span>Request ID: {' '}</span>*/}
				{/*	<Link*/}
				{/*		to={{ pathname: `/requests_log`, search: `?request_id=${requestId}`, state: { pageFrom: parentPage } }}>*/}
				{/*		{requestId}*/}
				{/*	</Link>*/}
				{/*</div>*/}
				{/*<div className="user-name">*/}
				{/*	<span>User: {' '}</span>*/}
				{/*	<span className="user-name-value">{userName}</span>*/}
				{/*</div>*/}
			</div>
		</>
	);

	const healthCheckName = Object.keys(healthCheckIds).length && currentHealthCheck
		? healthCheckIds[currentHealthCheck]
		: `${label} ${additionalLabel}`;

	const fileName = (`${healthCheckName || ''}`).split(' ').join('_');

	const headerRight = (
		<>
			<CustomButton
				kind="secondary"
				onClick={() => downloadLocalTxtFile(
					clipboardString,
					`${fileName}_${requestId}`)
				}
				small
				iconLeft="file_download"
				buttonText="Download"
				addedClassName="mr-4"
			/>
			<CustomButton
				kind="secondary"
				onClick={setCopied}
				small
				iconLeft="library_books"
				buttonText={isCopied ? 'Copied!' : 'Copy'}
			/>
		</>
	);

	const detailsContent = (
		<>
			{!healthCheckViewList && error && (
				<div className="d-flex h-100">
					<div className="h4 text-primary m-auto d-flex">{`There is no data about request with ID ${match.params.id || match.params.request_id}`}</div>
				</div>
			)}
			{healthCheckViewList && healthCheckViewList.length === 0 && (
				<div className="d-flex h-100">
					<div className="h4 text-primary m-auto d-flex">
						Request data is empty !
					</div>
				</div>
			)}
			{healthCheckViewList && !error && healthCheckViewList.length > 0 && (
				<div className="mt-0 mb-2 mb-lg-4"> {list}</div>
			)}
		</>
	);

	return (
		<Modal show={isOpenModal} close={goBack} bsSize="xl">
			{isFetching ? (
				<Spinner />
			) : (
				<ModalDetails
					customClass="health-check-details-view"
					headerLeft={headerLeft}
					headerRight={headerRight}
					detailsContent={detailsContent}
				/>
			)}
		</Modal>
	);
};

export default React.memo(AToolsHealthCheckDetailsView);
