import { useCallback } from 'react';

const useWatchDownloadFile = () => {
	const viewRemoteFile = useCallback((data) => {
		const file = new Blob([data], { type: data.type });
		const fileURL = URL.createObjectURL(file);
		window.open(fileURL);
	}, [])

	const downloadRemoteFile = useCallback((fileData, name = 'file') => {
		let fileType;
		switch (fileData.type) {
			case 'pdf':
				fileType = 'application/pdf';
				break;
			case 'png':
				fileType = 'image/png';
				break;
			case 'txt':
				fileType = 'text/plain';
				break;
			default:
				fileType = 'text/plain';
		}

		const url = `data:${fileType};base64,${fileData.data}`;

		const link = document.createElement('a');
		link.href = url;
		link.setAttribute('download', `${name}.${fileData.type}`);
		link.click();
	}, [])

	const downloadLocalTxtFile = useCallback((string, fileName) =>  {
		const element = document.createElement("a");
		const file = new Blob([string],
			{type: 'text/plain;charset=utf-8'});
		element.href = URL.createObjectURL(file);
		element.download = `${fileName}.txt`;
		document.body.appendChild(element);
		element.click();
	}, []);

	return {
		viewRemoteFile,
		downloadRemoteFile,
		downloadLocalTxtFile
	}
}



export default useWatchDownloadFile;
