const useCommonData = () => {

	const mappingStatusList = [
		{
			name: 'All',
			value: '',
		},
		{
			name: 'Mapped',
			value: '0',
		},
		{
			name: 'Not Mapped',
			value: '1',
		},
	];

	const showItemsOnPage = [
		{
			name: '16',
			value: '16',
		},
		{
			name: '32',
			value: '32',
		},
		{
			name: '48',
			value: '48',
		},
	];

	const weightUnitsList = [
		{
			name: 'Not Selected',
			value: '',
		},
		{
			name: 'KG',
			value: 'kg',
		},
		{
			name: 'LB',
			value: 'lb',
		},

	];

	const dimentionUnitsList = [
		{
			name: 'Not Selected',
			value: '',
		},
		{
			name: 'M',
			value: 'm',
		},
		{
			name: 'CM',
			value: 'cm',
		},
		{
			name: 'IN',
			value: 'in',
		},

	];

	const carrierKindList = [
		{
			value: '',
			name: 'Not Selected',
		},
		{
			value: 'return',
			name: 'Return',
		},
		{
			value: 'direct',
			name: 'Direct',
		},
		{
			value: 'pallet',
			name: 'Pallet',
		},
	];

	const labelTypeList = [
		{
			name: 'PDF',
			value: 'pdf',
		},
		{
			name: 'ZPL',
			value: 'zpl',
		},
		{
			name: 'ZPL preview',
			value: 'zpl-preview',
		},
		{
			name: 'HTML',
			value: 'html',
		},
	];

	const labelDpiList = [
		{
			name: '200',
			value: '200',
		},
		{
			name: '300',
			value: '300',
		},

	];

	const statusesList = [
		{
			name: 'Active',
			value: '1',
		},
		{
			name: 'Not active',
			value: '0',
		},

	];

	const requestMethodListData = [
		{
			name: 'GET',
			value: 'GET',
		},
		{
			name: 'POST',
			value: 'POST',
		},
		{
			name: 'PUT',
			value: 'PUT',
		},
		{
			name: 'DELETE',
			value: 'DELETE',
		},
	];

	const requestParamsTypes = [
		{
			name: 'Parameters',
			value: 'parameters',
		},
		{
			name: 'JSON',
			value: 'json',
		},
	]

	const uriList = [
		'/pallets',
		'/direct_webhook_tracking',
		'/health_check/detail',
		'/health_check',
		'/pudo',
		'/return_webhook_tracking',
		'/services/credentials',
		'/services/courier_dates',
		'/services',
		'/parcels/bulk',
		'/parcels',
		'/tracking/by_date_range',
		'/tracking',
	];

	const httpCodeList = [
		{
			name: '400',
			value: '400',
		},
		{
			name: '500',
			value: '500',
		},
	];

	return {
		showItemsOnPage,
		weightUnitsList,
		dimentionUnitsList,
		requestMethodListData,
		mappingStatusList,
		uriList,
		carrierKindList,
		labelTypeList,
		labelDpiList,
		statusesList,
		httpCodeList,
		requestParamsTypes
	};
};

export default useCommonData;



