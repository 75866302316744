import {useCallback, useEffect, useState} from 'react';
import {useRouter} from '../../components/hooks/useRouter';
import useSapiService from '../../components/hooks/useSapiService';
import usePageFilter from '../../components/hooks/usePageFilter';

const useTrackingMapDetailsPage = () => {
    const {match} = useRouter();
    const [statuses, setStatuses] = useState();

    const {
        isFetching,
        getTrackingMapReq,
    } = useSapiService();

    const {
        currentFilterData,
        resetFilterForm,
        applyFilter,
        formMethods
    } = usePageFilter();

    const getTrackingMap = useCallback(() => {
        return getTrackingMapReq(match.params.carrier_name)
            .then(({data}) => {
                if (data.statuses) {
                    setStatuses(data.statuses);
                }
            });

    }, [getTrackingMapReq]); //eslint-disable-line

    const resetTrackingMapFilter = () => {
        resetFilterForm();
        getTrackingMap()
    }

    const filterHandler = (data) => {
        applyFilter(data, getTrackingMap);
    }

    useEffect(() => {
        getTrackingMap();
    }, [getTrackingMap]); //eslint-disable-line

    return {
        carrier: match.params.carrier_name,
        statuses,
        getTrackingMap,
        isFetching,
        currentFilterData,
        resetFilterForm,
        filterHandler,
        resetTrackingMapFilter,
        formMethods
    };
};

export default useTrackingMapDetailsPage;
