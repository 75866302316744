import React, {useContext, useState} from 'react';
import {useGlobal} from "reactn";
import {useForm} from "react-hook-form";
import PageDataPanel from '../UI/PageDataPanel/PageDataPanel';
import TrackingMapDetailsPageContext from '../../context/pagesContext/trackingMapDetailsPageContext';
import CustomInput from '../UI/СustomInput/CustomInput';
import NoDataMessage from '../UI/NoDataMessage/NoDataMessage';
import './TrackingMapDetailsPanel.scss';
import CustomButton from "../UI/CustomButton/CustomButton";
import useModal from "../hooks/useModal";
import EditStatusModal from "./EditStatusModal";
import DeleteStatusModal from "./DeleteStatusModal";
import useSapiService from "../hooks/useSapiService";
import useCustomToast from "../hooks/useCustomToast";
import useSearchAdvanced from "../hooks/useSearchAdvanced";

const TrackingMapDetailsPanel = () => {
    const {saveTrackingMapStatusReq, deleteTrackingMapStatusReq} = useSapiService();
    const [commonListsObj] = useGlobal('commonListsObj');
    const {trackingStatuses} = commonListsObj;
    const {
        carrier,
        statuses: statusesList,
        getTrackingMap,
    } = useContext(TrackingMapDetailsPageContext);
    const {
        searchResultArr,
        searchDataStr,
        onChangeFn
    } = useSearchAdvanced(statusesList, ['carrier_status']);
    const statuses = searchDataStr ? searchResultArr : statusesList;
    const {isOpenModal: isOpenEditModal, showModal: showEditModal, hideModal: hideEditModal} = useModal();
    const {isOpenModal: isOpenDeleteModal, showModal: showDeleteModal, hideModal: hideDeleteModal} = useModal();
    const {handleSubmit, register, errors, reset} = useForm();
    const {successInfo} = useCustomToast();
    const [editedStatus, setEditedStatus] = useState({
        carrier_status: '',
        status: 0,
        status_description: '',
    });

    const updateTrackingStatus = (data) => {
        saveTrackingMapStatusReq({
            carrier,
            ...data,
        })
            .then(({data}) => {
                successInfo(`${data.message}`);
                reset();
                getTrackingMap();
                hideEditModal();
            });
    }

    const [deletedStatus, setDeletedStatus] = useState({});
    const deleteTrackingStatus = () => {
        deleteTrackingMapStatusReq({id: deletedStatus.id})
            .then(({data}) => {
                successInfo(`${data.message}`);
                getTrackingMap();
                hideDeleteModal();
            });
    }

    const panelHeading = (
        <>
            <div className="d-flex flex-column">
                <CustomInput
                    iconLeft="search"
                    formGroupClass="mb-0"
                    type="text"
                    placeholder="Carrier Status"
                    value={searchDataStr}
                    autoComplete="off"
                    onChange={(e) => onChangeFn(e)}
                    autoFocus={true}
                    addedInputClass="statuses-search"
                />
            </div>
            <div></div>
        </>
    );

    const panelContent = statuses && <div>
            <table className="table tracking-map-table-view">
                <thead>
                <tr>
                    <th>Carrier</th>
                    <th>Carrier Status</th>
                    <th>SAPI Status</th>
                    <th>Carrier Status Description</th>
                    <th className="actions"/>
                    <th className="actions"/>
                </tr>
                </thead>
                <tbody>
                {statuses && statuses.map(status => (
                    <tr key={status.carrier_status}>
                        <td><span>{status.carrier}</span></td>
                        <td><span>{status.carrier_status}</span></td>
                        <td>
                            <span>{status.status} ({trackingStatuses[status.status] ? trackingStatuses[status.status] : 'Not mapped'}) </span>
                        </td>
                        <td><span>{status.status_description}</span></td>
                        <td>
                            <CustomButton
                                iconLeft="app_registration"
                                kind="secondary"
                                onClick={() => {
                                    setEditedStatus(status);
                                    showEditModal();
                                }}
                                addedClassName="edit-status"
                            />
                        </td>
                        <td>
                            <CustomButton
                                onClick={() => {
                                    setDeletedStatus(status);
                                    showDeleteModal();
                                }}
                                kind="text"
                                buttonText="delete"
                                iconLeft="delete_forever"
                                small
                                addedClassName="text-capitalize font-weight-light delete-status"
                            />
                        </td>
                    </tr>
                ))}
                </tbody>
            </table>
            <EditStatusModal
                isOpenModal={isOpenEditModal}
                hideModal={hideEditModal}
                editedStatus={editedStatus}
                updateTrackingStatus={updateTrackingStatus}
                trackingStatuses={trackingStatuses}
            />
            <DeleteStatusModal
                isOpenModal={isOpenDeleteModal}
                hideModal={hideDeleteModal}
                deletedStatus={deletedStatus}
                deleteTrackingStatus={deleteTrackingStatus}
            />
        </div>
    ;

    return (
        <>
            <form>
                <div className="mb-5">
                    <div>Add new tracking status for <strong>{carrier}</strong> carrier</div>
                    <div className="d-flex flex-row">
                        <div>
                            <CustomInput
                                formGroupClass="shifted mt-3 mr-3"
                                type="text"
                                itemName="carrierStatus"
                                label="Carrier Status"
                                register={register}
                                errors={errors}
                                required
                            />
                        </div>
                        <div>
                            <CustomInput
                                formGroupClass="shifted mt-3 mr-3"
                                type="text"
                                itemName="sapiStatus"
                                label="SAPI Status"
                                register={register}
                                errors={errors}
                                required
                            />
                        </div>
                        <div>
                            <CustomInput
                                formGroupClass="shifted mt-3 mr-3 width-500"
                                type="text"
                                itemName="carrierStatusDescription"
                                label="Carrier Status Description"
                                register={register}
                                errors={errors}
                            />
                        </div>
                        <div>
                            <CustomButton
                                addedClassName="mt-3"
                                buttonText="Add"
                                kind="primary"
                                onClick={handleSubmit(updateTrackingStatus)}
                            />
                        </div>
                    </div>
                    <div className="d-flex flex-row flex-wrap">
                        <strong>Available SAPI Statuses:</strong> {Object.keys(trackingStatuses).map(key => (
                        <span key={key} className="mx-3 mb-2">{key} ({trackingStatuses[key]})</span>))}
                    </div>
                </div>
            </form>

            <PageDataPanel
                panelHeading={panelHeading}
                panelContent={statuses ? panelContent : <NoDataMessage/>}
                mobileSwitcher={false}
            />
        </>
    )
        ;
};

export default TrackingMapDetailsPanel;
