import React, { useContext, useEffect } from 'react';
import { useGlobal } from 'reactn';

import PaginationBoxLite from '../../PaginationBox/PaginationBoxLite';
import RequestsLogPageContext from '../../../context/pagesContext/requestsLogPageContext';

import PageDataPanel from '../../UI/PageDataPanel/PageDataPanel';
import './RequestLogPanel.scss';
import useCommonListUpdate from '../../hooks/useCommonListUpdate';
import TableCardUI from '../../UI/TableCardUI/TableCardUI';
import { useMediaQuery } from 'beautiful-react-hooks';
import useDateStamp from '../../hooks/useDateStamp';
import ReactCountryFlag from 'react-country-flag';
import { useRouter } from '../../hooks/useRouter';
import CustomButton from '../../UI/CustomButton/CustomButton';
import useListSort from '../../hooks/useListSort';

const RequestsLogPanel = ({ currentData }) => {

	const { checkListInCommonList } = useCommonListUpdate();
	const { match, location } = useRouter();

	const {
		isFetching,
		changeCurrPageHandler,
		changeCurrQuantityHandler,
		handleSubmit,
		filterHandler,
		requestLogsData
	} = useContext(RequestsLogPageContext);

	const [commonListsObj] = useGlobal('commonListsObj');
	const isLargeScreen = useMediaQuery('(min-width: 1400px)');
	const { users } = commonListsObj;
	const { getUtcDateFromStamp } = useDateStamp();
	const { alphabetSort } = useListSort();
	const { page } = currentData;

	const { requests, hasMoreItems } = requestLogsData;

	useEffect(()=> {
		checkListInCommonList({dataListArr: requests, dataListArrItemParam: 'userId', commonList: users})
	}, [checkListInCommonList]); //eslint-disable-line

	const requestsLogLabelsListArr = [
		['Resp. code', 'httpResponseCode', 0],
		['Date', 'requestTime', 0],
		['Request ID', 'request_id', 0],
		['IP Address', 'ip', 0, 0, 1], // 1 - show item bgr in tooltip
		['Exec time', 'execTime', 0],
		['Req. method', 'requestMethod', 0],
		['Uri', 'url', 0],
		['User', 'userId', 0],
		['Actions', 'actions', 0],
	]

	const actionsTpl = (id, healthCheckId, healthCheckIds) => {
		return <div className="buttons-wrapper">
			{healthCheckId && <CustomButton
				linkTo={`${match.path}/request_details/${healthCheckId}${location.search}`}
				kind="secondary"
				iconLeft="local_hospital"
				small
				tooltip="Health check details"
				onClick={() => {
					sessionStorage.clear();
					sessionStorage.setItem('healthCheckIds', JSON.stringify(healthCheckIds))}}
			/>}
			<CustomButton
				linkTo={`${match.path}/${id}${location.search}`}
				kind="secondary"
				iconLeft="navigate_next"
				small
				tooltip="Request details"
			/>
		</div>;
	}

	const modifiedRequestListArr = requests
		? requests.map(item => {
			const responseCodeClassName =
				+item.httpResponseCode === 200 ? 'text-success' : 'text-danger';

			const countryCode = item.country ? item.country : '';
			const userLogin = users[item.userId] ? users[item.userId] : "NO USER";

			const healthCheckIdArr = item.healthCheckIds ? Object.entries(item.healthCheckIds).map(([id, name]) => {
				return {
					name: name,
					value: `${id}`}
			}) : [];

			const alphabetHealthCheckIdArr = alphabetSort(healthCheckIdArr, 'name');
			const healthCheckId = alphabetHealthCheckIdArr[0] ? alphabetHealthCheckIdArr[0].value : '';
			return {
				...item,
				httpResponseCode: <span className={`${responseCodeClassName}`}>{item.httpResponseCode}</span>,
				requestTime: `${getUtcDateFromStamp(item.requestTime)} UTC`,
				execTime: `${item.execTime}s (Inner: ${item.innerTime}s, Outer: ${item.outerTime}s)`,
				ip: <>
					<span className="ip">{item.ip} </span>
					<ReactCountryFlag
						code={countryCode}
						className="country-flag"
						svg
						styleProps={{
							width: '1rem',
							height: '1rem',
							borderRadius: '50%',
							backgroundSize: 'cover',
							marginLeft: '.5rem',
						}}
					/>
				</>,
				requestMethod: <div className="badge badge-secondary">{item.requestMethod}</div>,
				url: item.uri,
				userId: userLogin,
				actions: actionsTpl(item.id, healthCheckId, item.healthCheckIds)
			}
		})
		: [];

	const panelHeading = (
		<CustomButton
			iconLeft="refresh"
			isAnimated={isFetching}
			addedClassName="refresh-button"
			kind="secondary"
			onClick={handleSubmit(filterHandler)}
		/>
	);

	const panelContent = (
		<>
			<TableCardUI
				labelListArr={requestsLogLabelsListArr}
				dataArr={modifiedRequestListArr}
				cardView={!isLargeScreen}
				addedCommonClassname={`request-log ${
					isFetching ? 'semi-transparent' : 'no-transparent'
				}`}
			/>
			<PaginationBoxLite
				currentPage={page || '1'}
				changeCurrentPage={changeCurrPageHandler}
				changeQuantity={changeCurrQuantityHandler}
				hasNextPage={hasMoreItems}
				noItems={requests && requests.length === 0}
			/>
		</>
	);

	return (
			<PageDataPanel
				panelHeading={panelHeading}
				panelContent={panelContent}
				mobileSwitcher={false}
				addedClassName="request-log-panel"
			/>
	);
};

export default React.memo(RequestsLogPanel);
