import React from 'react';
import { useForm } from 'react-hook-form';
import InlineError from '../messages/InlineError';
import useAuthentication from '../hooks/authentication/useAuthentication';
import './LoginForm.scss';
import CustomInput from '../UI/СustomInput/CustomInput';
import { Form } from 'react-bootstrap';
import CustomButton from '../UI/CustomButton/CustomButton';

const LoginForm = () => {

	const { register, errors, handleSubmit } = useForm();
	const { login, isFetching, error } = useAuthentication();

	let reqErrorText = '';

	if(error && error.message === "Network Error") {
		reqErrorText = `${error.message}. Check internet connection`;
	}

	if (error && error.response && error.response.status === 404) {
		reqErrorText = `Server ${error.response.statusText.toLowerCase()}`;
	}
	if (error && error.response && error.response.status !== 404) {
		reqErrorText = error && error.response
			? error.response.data.errors[0].message
			: '\xa0';
	}

	return (
			<Form
				className="login-form"
			>
				<div className="">
					<CustomInput
						formGroupClass="shifted"
						iconLeft="person"
						type="text"
						itemName="login"
						placeholder="Enter your login"
						label="login"
						register={register({
							required: 'Required field',
						})}
						errors={errors}
					/>
					<CustomInput
						formGroupClass="shifted mb-2"
						iconLeft="lock"
						type="password"
						itemName="password"
						placeholder="Enter your password"
						label="password"
						register={register({
							required: 'Required field',
						})}
						errors={errors}
					/>
					<div className="error-info">
						{error && <InlineError text={reqErrorText} />}
					</div>
					<CustomButton
						onClick= {handleSubmit(login)}
						buttonText={isFetching ? '' : 'Login'}
						iconLeft={isFetching ? 'autorenew' : ''}
						isAnimated={isFetching}
						addedClassName="login-button"
						kind="primary"
						type="submit"
						id="apply-filter"
					/>
				</div>
			</Form>
	);
};

export default LoginForm;
