import React from 'react';
import TrackingMapDetailsPageContext from '../../context/pagesContext/trackingMapDetailsPageContext';
import TrackingMapDetailsPanel from '../../components/trackingMapDetails/TrackingMapDetailsPanel';
import useTrackingMapDetailsPage from './useTrackingMapDetailsPage';
import Spinner from '../../components/Spinner/Spinner';
import ErrorIndicator from '../../components/ErrorIndicator/ErrorIndicator';
import PageLayout from '../../components/pageLayout/PageLayout';

const TrackingMapDetailsPage = () => {

    const trackingMapDetailsPageData = useTrackingMapDetailsPage();
    const {statuses, isFetching} = trackingMapDetailsPageData;

    const pageContent = <>
        {!statuses && isFetching && <Spinner/>}
        {!statuses && !isFetching && <ErrorIndicator globalError/>}
        {statuses && <TrackingMapDetailsPanel/>}
    </>

    return (
        <TrackingMapDetailsPageContext.Provider value={trackingMapDetailsPageData}>
            <PageLayout
                pageContent={pageContent}
            />
        </TrackingMapDetailsPageContext.Provider>
    );
};

export default React.memo(TrackingMapDetailsPage);
