import React, { useState, useEffect, useImperativeHandle } from 'react';
import './DateTimePickTool.scss';
import CustomButton from '../CustomButton/CustomButton';
import CustomInput from '../СustomInput/CustomInput';
import CalendarPanel from './CalendarPanel/CalendarPanel';
import moment from 'moment';
import { formatToString } from '../../../helpers/momentDatesFunctions';
import SubMessage from '../SubMessage/Submessage';

const DateTimePickTool = ({
	addedClassname,
	hasTime,
	register,
	dateFrom,
	dateTo,
	autoApply = false,
	resetRef,
	hideRangeButton = false,
	hideInputs = false,
	onError = () => {},
	}) => {

	const [datePickerType, setDatePickerType] = useState('');
	const [isOpen, setIsOpen] = useState(false);
	const [error, setError] = useState(false);

	const initialDateFrom = '';
	const initialDateTo = formatToString(moment().endOf('day'), hasTime);
	const [currentDateFrom, setCurrentDateFrom] = useState( '');
	const [currentDateTo, setCurrentDateTo] = useState('');



	useEffect(()=> {
		setCurrentDateFrom(dateFrom || initialDateFrom);
	}, [dateFrom])

	useEffect(()=> {
		setCurrentDateTo(dateTo || initialDateTo);
	}, [dateTo]); //eslint-disable-line

	useEffect(() => {
			onError(error);
	}, [error]); //eslint-disable-line

const convertDateString = (string) =>{
	const [date, time] = string.split(' ');
	const formattedDate = date.split('-').reverse().join('-');
	return `${formattedDate}T${time}`
}
	useEffect(() => {
		if(currentDateFrom && currentDateTo){
			const cDateFrom = new Date (convertDateString(currentDateFrom));
			const cDateTo = new Date (convertDateString(currentDateTo));
			setError(() => cDateFrom > cDateTo);

		} else {
			setError(false)
		}
	}, [currentDateFrom, currentDateTo])

	useImperativeHandle(resetRef, () => () => applyDateRange(initialDateFrom, initialDateTo));

	const toggleDatePicker = (e) => {
		const targetItemId = e.currentTarget.id;
		setIsOpen(!isOpen);
		setDatePickerType(targetItemId);
	};

	const closeDatePicker = () => {
		setIsOpen(false);
	};

	const hideInputsClassName = hideInputs ? 'hidden-input' : '';
	const activeInputClassName = datePickerType && isOpen ? datePickerType : '';

	const applyDateRange = (dateFrom, dateTo) => {
		setCurrentDateFrom(dateFrom);
		setCurrentDateTo(dateTo);
		closeDatePicker()
	}

	return (
		<div className={`date-time-picker ${addedClassname || ''}`}>
			<div className={`inputs-wrapper ${activeInputClassName}`}>
				{!hideRangeButton && <CustomButton
					kind="secondary"
					iconLeft="event_note"
					id="date-range"
					buttonText="Range"
					onClick={(e) => toggleDatePicker(e)}
				/>}
				<CustomInput
					formGroupClass={`shifted ${hideInputsClassName}`}
					type="text"
					label="date from"
					id="date-from"
					name="date_from"
					register={register}
					value={currentDateFrom}
					onClick={(e) => toggleDatePicker(e)}
					disabled
					isError={error}
				/>
				<CustomInput
					formGroupClass={`shifted ${hideInputsClassName}`}
					type="text"
					id="date-to"
					label="date to"
					name="date_to"
					register={register}
					value={currentDateTo}
					disabled
					onClick={(e) => toggleDatePicker(e)}
					isError={error}
				/>
				{error && <SubMessage styleClass="text-danger">
					Wrong date range
				</SubMessage>}
			</div>
			{isOpen && <CalendarPanel
				hasTime={hasTime}
				closeDatePicker={closeDatePicker}
				dateFrom={currentDateFrom}
				dateTo={currentDateTo}
				datePickerType={datePickerType}
				singleCalendar={datePickerType !== "date-range"}
				getDate={applyDateRange}
				autoApply={autoApply}
			/>}
		</div>
	);
};

export default DateTimePickTool;
