import { useState, useEffect } from 'react';

const useDropDown = (ref, handler = () => {}) => {
	const [isVisible, setIsVisible] = useState(false);

	useEffect(()=> {
		if(isVisible) {
			document.addEventListener('click', handleClickOutside);
			return () => {
				document.removeEventListener('click', handleClickOutside);
			}
		}
	}, [isVisible]); //eslint-disable-line

	function showDropdown() {
		setIsVisible(true);
	}

	function hideDropdown() {
		setIsVisible(false);
	}

	function toggleDropdown() {
		return isVisible ? hideDropdown() : showDropdown();
	}

	function handleClickOutside(event) {
		if (ref && ref.current && !ref.current.contains(event.target)) {
			hideDropdown();
			handler();
		}
	}

	const scrollToActiveItem = (containerRef, activeItemRef) => {
		const dropdownTopEdge = containerRef.current.getBoundingClientRect().top + 35;
		const dropdownHeight = containerRef.current && containerRef.current.getBoundingClientRect().height;
		const dropdownBottomEdge = containerRef.current && containerRef.current.getBoundingClientRect().bottom - 35;
		const activeItemTop = activeItemRef.current && activeItemRef.current.getBoundingClientRect().top;
		const activeItemBottom = activeItemRef.current && activeItemRef.current.getBoundingClientRect().bottom;
		const diff = dropdownBottomEdge - dropdownHeight/2 -activeItemTop;

		if(activeItemBottom >= dropdownBottomEdge || activeItemTop <= dropdownTopEdge) {
			containerRef.current.scrollTo({
				left: 0,
				top: containerRef.current.scrollTop - diff,
				behavior: 'smooth'
			});
		}
	}


	return {
		isVisible,
		showDropdown,
		hideDropdown,
		toggleDropdown,
		scrollToActiveItem
	};
};

export default useDropDown;
