import React, {useContext, useEffect} from 'react';
import PageDataPanel from '../UI/PageDataPanel/PageDataPanel';
import CredentialsPageContext from '../../context/pagesContext/credentialsPageContext';
import {useRouter} from '../hooks/useRouter';
import './CredentialsPanel.scss';
import CredentialsView from './CredentialsView';
import CustomInput from '../UI/СustomInput/CustomInput';
import useSearchAdvanced from '../hooks/useSearchAdvanced';
import {parse, stringify} from 'query-string';
import NoDataMessage from '../UI/NoDataMessage/NoDataMessage';

const groupCredentials = (acc, currCredentials) => {
    const {carrier_name: carrier} = currCredentials;
    if (acc[carrier]) {
        acc[carrier].push(currCredentials);
    } else {
        acc[carrier] = [currCredentials];
    }
    return acc;
}


const CredentialsPanel = () => {
    const {
        credentialsData,
    } = useContext(CredentialsPageContext);


    const {credentials} = credentialsData || {};
    const {
        searchResultArr,
        searchDataStr,
        onChangeFn
    } = useSearchAdvanced(credentials, ['id', 'service_name', 'display_name']);
    const groupedCredentials = (searchDataStr ? searchResultArr : credentials).reduce(groupCredentials, []);
    const {history, location} = useRouter();
    const queryParams = parse(location.search);


    useEffect(() => {
        const queryString = stringify({...queryParams});
        history.push({search: `?${queryString}`});
    }, [searchDataStr]); //eslint-disable-line

    const panelHeading = (
        <>
            <div className="d-flex flex-column">
                <p>Carrier credentials for <b>Cycleon</b> user</p>
                <CustomInput
                    iconLeft="search"
                    formGroupClass="mb-0"
                    type="text"
                    itemName="healthCheckSearch"
                    placeholder="Carrier, service name or id"
                    value={searchDataStr}
                    autoComplete="off"
                    onChange={(e) => onChangeFn(e)}
                    autoFocus={true}
                    addedInputClass="credentials-search"
                />
            </div>
            <div></div>
        </>
    );

    const panelContent = credentialsData && <>
        <CredentialsView
            credentials={groupedCredentials}
            expanded={!!searchDataStr}
        />
    </>;

    return (
        <PageDataPanel
            panelHeading={panelHeading}
            panelContent={Object.keys(groupedCredentials).length > 0 ? panelContent : <NoDataMessage/>}
            addedClassName="credentials-panel"
        />
    );
};

export default CredentialsPanel;
