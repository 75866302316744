import React from 'react';
import TrackingMapPageContext from '../../context/pagesContext/trackingMapPageContext';
import TrackingMapPanel from '../../components/trackingMap/TrackingMapPanel';
import useTrackingMapPage from './useTrackingMapPage';
import Spinner from '../../components/Spinner/Spinner';
import ErrorIndicator from '../../components/ErrorIndicator/ErrorIndicator';
import PageLayout from '../../components/pageLayout/PageLayout';

const TrackingMapPage = () => {

    const trackingMapPageData = useTrackingMapPage();
    const {carriers, isFetching} = trackingMapPageData;

    const pageContent = <>
        {!carriers && isFetching && <Spinner/>}
        {!carriers && !isFetching && <ErrorIndicator globalError/>}
        {carriers && <TrackingMapPanel/>}
    </>


    return (
        <TrackingMapPageContext.Provider value={trackingMapPageData}>
            <PageLayout
                pageContent={pageContent}
            />
        </TrackingMapPageContext.Provider>
    );
};

export default React.memo(TrackingMapPage);
