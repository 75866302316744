import { useState } from 'react';
import isEllipsisActive from '../../helpers/isEllipsisActive';


const useTooltip = () => {
	const [isOn, setIsOn] = useState(false);
	const [coordinates, setCoordinates] = useState({});
	const [tooltipData, setTooltipData] = useState(null);
	const [hideBgr, setHideBgr] = useState(true);
	const showTooltip = (el, tooltipData, hideBgrData) => {
		const rect = el.getBoundingClientRect();
		if (isEllipsisActive(el)) {
			setCoordinates({
				left: rect.left,
				top: rect.bottom + window.pageYOffset
			});
			setIsOn(true);
			tooltipData && setTooltipData(tooltipData);
			setHideBgr(hideBgrData);
		}

	}
	const hideTooltip = () => {
		setCoordinates({})
		setIsOn(false)
	}

	return {
		isOn,
		coordinates,
		tooltipData,
		hideBgr,
		showTooltip,
		hideTooltip
};
};

export default useTooltip;
