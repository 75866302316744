import React, {useState, useEffect, useRef} from 'react';
import Icon from '../Icon/Icon';
import './TimePicker.scss';

const TimePicker = ({
	currentTime = '',
	selectTime,
	datePickerType
}) => {

	const hourRef = useRef();
	const minRef = useRef();
	const secRef = useRef();

	const initHourRef = useRef('');
	const initMinuteRef = useRef('');
	const initSecondRef = useRef('');

const currentTimeArr = currentTime ? currentTime.split(":") : ['00', '00', '00'];
	const [initHours, initMinutes, initSeconds] = currentTimeArr;
	const [hours, setHours] = useState(initHours);
	const [minutes, setMinutes] = useState(initMinutes);
	const [seconds, setSeconds] = useState(initSeconds);

	useEffect(() => {
		initHourRef.current = initHours;
		initMinuteRef.current = initMinutes;
		initSecondRef.current = initSeconds;
	}, []); //eslint-disable-line

	useEffect(()=> {
		selectTime(`${hours}:${minutes}:${seconds}`, datePickerType)
	}, [hours, minutes, seconds]); //eslint-disable-line

	const minValue = 0;
	const maxHourValue = 23;
	const maxMinSecValue = 59;

	const minMaxCheck = (value, [min, max]) => {
		if(+value < min) {
			return max
		}
		if( +value > max) {
			return min
		}
		return value
	} ;

	const addZero = (value) => {
		return value.toString().length < 2 ? '0' + value : value;
	};

	const onHoursChange = (hours) => {
		let hoursValue;
		if (hours > maxHourValue) {
			hoursValue = maxHourValue;
			minRef.current.focus();
		} else {
			if (hours.length > 2) {
				hoursValue = hours.slice(0, -1);
				minRef.current.focus();
			} else {
				hoursValue = hours;
			}
		}
			setHours(hoursValue);
	}

	const onHoursBlur = (timeValue) => {
		if (!timeValue) {
			setHours(initHourRef.current);
			return;
		}
		if (timeValue.length === 1) {
			initHourRef.current = addZero(timeValue);
			setHours(addZero(timeValue));
			return;
		}
		initHourRef.current = timeValue;
		setHours(timeValue);
	}

	const onMinutesChange = (minutes) => {
		let minutesValue;
		if (minutes > maxMinSecValue) {
			minutesValue = maxMinSecValue;
			secRef.current.focus();
		} else {
			if (minutes.length > 2) {
				minutesValue = minutes.slice(0, -1);
				secRef.current.focus();
			} else {
				minutesValue = minutes;
			}
		}
		setMinutes(minutesValue);
	}

	const onMinutesBlur = (timeValue) => {
		if (!timeValue) {
			setMinutes(initMinuteRef.current);
			return;
		}
		if (timeValue.length === 1) {
			initMinuteRef.current = addZero(timeValue);
			setMinutes(addZero(timeValue));
			return;
		}
		initMinuteRef.current = timeValue;
		setMinutes(timeValue);
	}

	const onSecondsChange = (seconds) => {
		let secondsValue;
		if (seconds > maxMinSecValue) {
			secondsValue = maxMinSecValue;
			secRef.current.blur();
		} else {
			if (seconds.length > 2) {
				secondsValue = seconds.slice(0, -1);
				secRef.current.blur();
			} else {
				secondsValue = seconds;
			}
		}
		setSeconds(secondsValue);
	}

	const onSecondsBlur = (timeValue) => {
		if (!timeValue) {
			setSeconds(initSecondRef.current);
			return;
		}
		if (timeValue.length === 1) {
			initSecondRef.current = addZero(timeValue);
			setSeconds(addZero(timeValue));
			return;
		}
		initSecondRef.current = timeValue;
		setSeconds(timeValue);
	}


	const changeTimeValue = (e, value) => {
		const isHour = e.currentTarget.classList.value.includes("hour");
		const isMinute = e.currentTarget.classList.value.includes("minute");
		const isSecond = e.currentTarget.classList.value.includes("second");
		const isNext = e.currentTarget.classList.value.includes("next");

		const newValue = isNext ? `${+value + 1}` : `${+value - 1}`;

		if (isHour) {
			const formattedValue = minMaxCheck(newValue, [minValue, maxHourValue]);
			setHours(addZero(formattedValue));
		}
		if (isMinute) {
			const formattedValue = minMaxCheck(newValue, [minValue, maxMinSecValue]);
			setMinutes(addZero(formattedValue))
		}
		if (isSecond) {
			const formattedValue = minMaxCheck(newValue, [minValue, maxMinSecValue]);
			setSeconds(addZero(formattedValue))
		}
	}

	const selectFieldData  = (e) => {
		e.target.select();
	}

	// const resetField  = (e) => {
	// 	const field = e.target.name;
	// 	if (field === 'hours') {
	// 		setHours('');
	// 	}
	// 	if (field === 'minutes') {
	// 		setMinutes('');
	// 	}
	// 	if (field === 'seconds') {
	// 		setSeconds('');
	// 	}
	// }

	return (
		<div className="time-picker">
			<div className="hours">
				<div className="next-hour next"
						 onClick={(e) => changeTimeValue(e, hours)}
				>
					<Icon iconName="keyboard_arrow_up" size={32}/>
				</div>
				<input
					type="number"
					name="hours"
					value={hours}
					onFocus={selectFieldData}
					onBlur={(e) => onHoursBlur(e.target.value)}
					onChange={(e) => {onHoursChange(e.target.value)}}
					ref={hourRef}
				/>
				<div className="prev-hour prev"
						 onClick={(e) => changeTimeValue(e, hours)}
				>
					<Icon iconName="keyboard_arrow_down" size={32}/>
				</div>
			</div>
			<div className="minutes">
				<div className="next-minute next"
						 onClick={(e) => changeTimeValue(e, minutes)}
				>
					<Icon iconName="keyboard_arrow_up" size={32}/>
				</div>
				<input
					type="number"
					name="minutes"
					value={minutes}
					onFocus={selectFieldData}
					onChange={(e) => onMinutesChange(e.target.value)}
					onBlur={(e) => onMinutesBlur(e.target.value)}
					ref={minRef}
				/>
				<div className="prev-minute prev"
						 onClick={(e) => changeTimeValue(e, minutes)}
				>
					<Icon iconName="keyboard_arrow_down" size={32}/>
				</div>
			</div>
			<div className="seconds">
				<div className="next-second next"
						 onClick={(e) => changeTimeValue(e, seconds)}
				>
					<Icon iconName="keyboard_arrow_up" size={32}/>
				</div>
				<input
					type="number"
					name="seconds"
					onFocus={selectFieldData}
					value={seconds}
					onChange={(e) => onSecondsChange(e.target.value)}
					onBlur={(e) => onSecondsBlur(e.target.value)}
					ref={secRef}
				/>
				<div className="prev-second prev"
						 onClick={(e) => changeTimeValue(e, seconds)}
				>
					<Icon iconName="keyboard_arrow_down" size={32}/>
				</div>
			</div>
		</div>
	);
};

export default TimePicker;
