import { useCallback, useEffect, useState } from 'react';
import { parse, stringify } from 'query-string';
import { useForm } from 'react-hook-form';
import { useRouter } from './useRouter';

const usePageFilter = () => {
	const {	history, match, location } = useRouter();
	const [currentFilterData, setCurrentFilterData] = useState({});

	const [selectedUsers, setSelectedUsers] = useState([]);
	const [selectedServices, setSelectedServices] = useState([]);

	const [selectedSenderCountries, setSelectedSenderCountries] = useState([]);
	const [
		selectedDestinationCountries,
		setSelectedDestinationCountries,
	] = useState([]);

	const [selectedResponseCodes, setSelectedResponseCodes] = useState([]);
	const [selectedAccessScope, setSelectedAccessScope] = useState([]);
	const [selectedTrackingStatuses, setSelectedTrackingStatuses] = useState([]);

	const [currentPage, setCurrentPage] = useState('');
	// const [quantity, setQantity] = useState('');
	// const [mode, setMode] = useState('');

	const {
		register,
		handleSubmit,
		setValue,
		reset,
		watch,
		errors,
		getValues,
	} = useForm();

	const formMethods = useForm();

	const getCurrentFilterItemsData = useCallback(
		() => parse(location.search, { arrayFormat: 'bracket' }),
		[location.search]
	);

	useEffect(() => {
		setCurrentFilterData(location.search ? getCurrentFilterItemsData() : {});
	}, [location.search, getCurrentFilterItemsData]);

	const getSelectedUsers = useCallback(data => {
		setSelectedUsers(data);
	}, []);
	const getSelectedServices = useCallback(data => {
		setSelectedServices(data);
	}, []);

	const getSelectedSenderCountries = useCallback(data => {
		setSelectedSenderCountries(data);
	}, []);

	const getSelectedResponseCodes = useCallback(data => {
		setSelectedResponseCodes(data);
	}, []);

	const getAccessScope = useCallback(data => {
		setSelectedAccessScope(data);
	}, []);

	const getSelectedDestinationCountries = useCallback(data => {
		setSelectedDestinationCountries(data);
	}, []);

	const getCurrentPage = useCallback(data => {
		setCurrentPage(data);
	}, []);

	const getSelectedTrackingStatuses = useCallback(data => {
		setSelectedTrackingStatuses(data);
	}, []);
	// const getQuantity =  useCallback(data => {
	// 	setQantity(data);
	// }, []);
	//
	// const getMode =  useCallback(data => {
	// 	setMode(data);
	// }, []);

	//
	// function changeSearchParam(data, paramName) {
	// 	const curr = parse(location.search, { arrayFormat: 'bracket' });
	// 	getFilterData({ ...curr, [paramName]: data });
	// }

	const getFilterData = useCallback((data, actionFn = () => {}) => {
		const queryString = `${stringify(data, {
			arrayFormat: 'bracket',
		})}`;
		history.push(`${match.url}?${queryString}`);
		actionFn(`?${queryString}`);
	}, []); //eslint-disable-line

	const changeCurrentPage = useCallback(
		(data, actionFn) => {
			const curr = parse(location.search, { arrayFormat: 'bracket' });

			getFilterData({ ...curr, page: data }, actionFn);
		},
		[location.search, getFilterData]
	);

	const changeQuantity = useCallback(
		(data, actionFn) => {
			const curr = parse(location.search, { arrayFormat: 'bracket' });
			getFilterData({ ...curr, quantity: data }, actionFn);
		},
		[location.search, getFilterData]
	);

	const changeMode = useCallback(
		(data, actionFn) => {
			const curr = parse(location.search, { arrayFormat: 'bracket' });
			getFilterData({ ...curr, mode: data === 'all' ? '' : data }, actionFn);
		},
		[location.search, getFilterData]
	);

	const changeHealthStatus = useCallback(
		data => {
			const curr = parse(location.search, { arrayFormat: 'bracket' });
			getFilterData({ ...curr, status: data === 'all' ? '' : data });
		},
		[location.search, getFilterData]
	);

	const resetFilterForm = useCallback(() => {
		reset();
		setValue('quantity', '');
		setValue('currentPage', '');
		setValue('mode', '');
		setValue('status', '');
		setValue('group_by', 'day');
		setSelectedUsers([]);
		setSelectedServices([]);
		setSelectedSenderCountries([]);
		setSelectedDestinationCountries([]);
		setSelectedAccessScope([]);
		setSelectedTrackingStatuses([]);
		setSelectedResponseCodes([]);

		history.push(`${match.url}`);
	}, [history, match.url, setValue, reset]);

	const applyFilter = useCallback(
		(data, actionFn) => {
			const curr = parse(location.search, { arrayFormat: 'bracket' });
			// if(Object.values(data).some( item => item)) {
			getFilterData(
				{
					...curr,
					date_from: data.date_from || undefined,
					date_to: data.date_to || undefined,
					countryFrom: data.countryFrom || undefined,
					countryTo: data.countryTo || undefined,
					countryReturn: data.countryReturn || undefined,
					carrierKind: data.carrierKind || undefined,
					carrier: data.carrier || undefined,
					carrier_id: data.carrier_id || undefined,
					request_id: data.request_id || undefined,
					error_code: data.error_code || undefined,
					carrier_error_pattern: data.carrier_error_pattern || undefined,
					carrierStatus: data.carrierStatus || undefined,
					labelName: data.labelName || undefined,
					labelType: data.labelType || undefined,
					labelDpi: data.labelDpi || undefined,
					tracking: data.tracking || undefined,
					secondary: data.secondary || undefined,
					request_method: data.request_method || undefined,
					uri: data.uri || undefined,
					http_response_code: data.http_response_code || undefined,
					http_response_codes: selectedResponseCodes || undefined,
					ext_time: data. ext_time || undefined,
					global_search: data.global_search || undefined,
					host: data.host || undefined,
					ip: data.ip || undefined,
					id: data.id || undefined,
					tracking_number: data.tracking_number || undefined,
					is_new: data.is_new || undefined,
					order_reference: data.order_reference || undefined,
					label: data.label || undefined,
					additional_label: data.additional_label || undefined,
					commit_count: data.commit_count || undefined,
					status: data.status || undefined,
					mawb_number: data.mawb_number || undefined,
					accountGroupId: data.accountGroupId || undefined,
					group_by: data.group_by || undefined,
					// Pudo points settings
					'services_list[0][id]': data.services_list
						? data.services_list[0]["id"]
						: undefined,
					'services_list[0][credentials_key]': data.services_list
						? data.services_list[0]["credentials_key"]
						: undefined,
					'address[country]': data.address ? data.address.country : undefined,
					'address[city]': data.address ? data.address.city : undefined,
					'address[state]':  data.address ? data.address.state : undefined,
					'address[zip]':  data.address ? data.address.zip : undefined,
					'address[addr1]':  data.address ? data.address.addr1 : undefined,
					'address[addr2]':  data.address ? data.address.addr2 : undefined,
					'address[addr3]':  data.address ? data.address.addr3 : undefined,
					'address[lng]':  data.address ? data.address.lng : undefined,
					'address[lat]':  data.address ? data.address.lat : undefined,
					user_id: data.user_id || undefined,
					is_test: data.is_test || undefined,
					// Pudo points settings end
					service_ids: selectedServices || undefined,
					user_ids: selectedUsers || undefined,
					tracking_statuses: selectedTrackingStatuses || undefined,
					destination_countries: selectedDestinationCountries || undefined,
					sender_countries: selectedSenderCountries || undefined,
					accessScope: selectedAccessScope || undefined,
					page: currentPage || undefined,
					// quantity: quantity || undefined,
					// mode: mode || undefined,
				},
				actionFn
			);
			// }
		},
		[
			location.search,
			getFilterData,
			selectedSenderCountries,
			selectedResponseCodes,
			selectedAccessScope,
			currentPage,
			selectedServices,
			selectedUsers,
			selectedDestinationCountries,
			selectedTrackingStatuses
		]
	);

	return {
		/*for page component*/
		getFilterData,
		currentFilterData,
		resetFilterForm,
		register,
		handleSubmit,
		setValue,
		getValues,
		reset,
		watch,
		errors,
		formMethods,
		/*for filter component*/
		//getDateRange,
		// getStartDate,
		// getEndDate,
		// startDate,
		// endDate,
		selectedResponseCodes,
		selectedUsers,
		selectedTrackingStatuses,
		selectedServices,
		getSelectedUsers,
		getSelectedServices,
		getSelectedResponseCodes,
		selectedSenderCountries,
		selectedDestinationCountries,
		selectedAccessScope,
		getSelectedSenderCountries,
		getSelectedDestinationCountries,
		getAccessScope,
		getSelectedTrackingStatuses,
		applyFilter,
		getCurrentPage,
		currentPage,
		changeCurrentPage,
		changeQuantity,
		changeMode,
		changeHealthStatus,
	};
};

export default usePageFilter;
