import React, { Fragment, useState } from 'react';
import { useGlobal } from 'reactn';
import { barcodesLeftFn, percentClassName } from '../../helpers/barcodesFuctions';
import CustomButton from '../UI/CustomButton/CustomButton';
import useTooltip from '../hooks/useTooltip';
import Portal from '../UI/Portal/Portal';
import Tooltip from '../UI/Tooltip/Tooltip';
import { formatNumber } from '../../helpers/formatData';

const BarcodeTableViewItem = (props) => {

	const [isOpen, setIsOpen] = useState(false);
	const {isOn, showTooltip, coordinates, hideTooltip, tooltipData, hideBgr} = useTooltip();

	const toggleSublist = () => {
		setIsOpen(!isOpen)
	}

	const {rangeItemData, percentLeft, hideCarrier, handleDelete } = props;

	const {user_id, barcode_group, remaining_all, ranges, carrierName, remaining_percent_all } = rangeItemData;
	const [commonListsObj] = useGlobal('commonListsObj');
	const {apiUsers} = commonListsObj;
	const percentClass = percentClassName(remaining_percent_all);

	const subRangeList = ranges.map(subItemRange => {
			const {prefix, range_begin, range_end, remaining, range_current, id} = subItemRange;
			const { percentClass, percentLeft} = barcodesLeftFn(range_begin, range_end, range_current);
			// const isActiveRange = range_current >= range_begin && range_current <= range_end;

			return <tr className={`ranges-details-list ${isOpen ? 'is-open': ''}`}  key={`${range_begin}${range_end}${remaining}${prefix}`}>
				<td/>
				<td/>
				{!hideCarrier && <td className="body-carrier" />}
				<td/>
				<td	onMouseEnter={(e) => showTooltip(e.currentTarget, prefix)}
						onMouseLeave={hideTooltip}>{prefix || "--"}</td>
				<td onMouseEnter={(e) => showTooltip(e.currentTarget, formatNumber(range_begin))}
						onMouseLeave={hideTooltip}>{formatNumber(range_begin)}</td>
				<td onMouseEnter={(e) => showTooltip(e.currentTarget, formatNumber(range_end))}
						onMouseLeave={hideTooltip}>{formatNumber(range_end)}</td>
				<td onMouseEnter={(e) => showTooltip(e.currentTarget, `${formatNumber(remaining)}(${percentLeft}%)`)}
						onMouseLeave={hideTooltip}>{formatNumber(remaining)} <span className={`text-${percentClass} font-weight-normal`}>({percentLeft}%)</span>
				</td>
				<td className="actions">
					<CustomButton
						onClick={() => handleDelete({ barcodeId: id, userId: user_id})}
						kind="text"
						buttonText="delete"
						iconLeft="delete_forever"
						small
						tooltip="Delete barcode range"
						//disabled={isActiveRange}
						addedClassName="text-capitalize font-weight-light delete-barcode"
					/>
				</td>
			</tr>
		});


	return (
		<Fragment>
			<tr className={`ranges-common-data ${isOpen ? 'is-open': ''}`}
					onClick={toggleSublist}
			>
				<td>
					<CustomButton
						iconLeft={`${isOpen ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}`}
						addedClassName="p-0 w-100"
						kind="text"
						onClick={toggleSublist}
					/>
				</td>
				<td>
					<span onClick={(e) => {e.stopPropagation()}}>{apiUsers[user_id]}</span>
				</td>
				{!hideCarrier && <td
					className="body-carrier"
					onMouseEnter={(e) => showTooltip(e.currentTarget, carrierName)}
					onMouseLeave={hideTooltip}><span onClick={(e) => {
					e.stopPropagation();
				}}>{carrierName}</span>
				</td>}
				<td onMouseEnter={(e) => showTooltip(e.currentTarget,  barcode_group)}
						onMouseLeave={hideTooltip}><span onClick={(e) => {e.stopPropagation()}}>{barcode_group}</span>
				</td>
				<td/>
				<td/>
				<td/>
				<td onMouseEnter={(e) => showTooltip(e.currentTarget,  `${formatNumber(remaining_all)}(${percentLeft}%)`)}
						onMouseLeave={hideTooltip}><span onClick={(e) => {e.stopPropagation()}}>{formatNumber(remaining_all)}</span> <span className={`text-${percentClass} font-weight-normal`}>({remaining_percent_all}%)</span>
				</td>
				<td className="actions"/>
			</tr>
			{subRangeList}
			{isOn && <Portal>
				<Tooltip
					text={tooltipData}
					hideBgr={hideBgr}
					coordinates={coordinates} />
			</Portal>}
		</Fragment>
	);
};

export default BarcodeTableViewItem;
