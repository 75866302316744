import React, { useContext, useEffect, useRef } from 'react';
import FilterLayout from '../FilterLayout';
import HealthCheckDetailsPageContext from '../../../../context/pagesContext/healthCheckDetailsPageContext';
import CustomInput from '../../../UI/СustomInput/CustomInput';
import DateTimePickTool from '../../../UI/DateTimePickTool/DateTimePickTool';
import usePageFilterError from '../../../hooks/usePageFilterError';

const HealthCheckDetailsFilter = ({
	currentData,
}) => {

	const { hasError, errorHandler } = usePageFilterError();

	const {
		register,
		handleSubmit,
		filterHandler,
		setValue,
		resetHealthCheckDetailsFilter,
		isFetching,
	} = useContext(HealthCheckDetailsPageContext);

	const resetRef = useRef();
	const resetDateRange = resetRef.current;
	const handleResetFilter = () => {
		if(resetDateRange){
			resetDateRange();
		}
		resetHealthCheckDetailsFilter();
	}

	const {
		date_from,
		date_to,
		label,
		additional_label,
	} = currentData;

	useEffect(() => {
		if (currentData) {
			Object.entries(currentData).forEach(([name, value]) => setValue(name, value));
		}
	}, [currentData]); //eslint-disable-line

	return (
		<FilterLayout
			filterFunc={filterHandler}
			handleSubmit={handleSubmit}
			discardFilterFunc={handleResetFilter}
			isFetching={isFetching}
			hasError={hasError}
		>
			<DateTimePickTool
				resetRef={resetRef}
				register={register}
				addedClassname="col-xxl-4 col-lg-5 col-md-6 col-12"
				dateFrom={date_from}
				dateTo={date_to}
				onError={errorHandler}
				hasTime
				autoApply
			/>
			<input
				type="text"
				name="additional_label"
				defaultValue={label}
				ref={register}
				readOnly
				className="form-control d-none"/>
			<input
				type="text"
				name="label"
				defaultValue={additional_label}
				ref={register}
				readOnly
				className="form-control d-none"/>
			<input
				type="text"
				name="status"
				ref={register}
				readOnly
				className="form-control d-none"/>
			<CustomInput
				formGroupClass="col-xl-3 col-md-6 col-12 shifted"
				type="text"
				itemName="request_id"
				label="Request ID"
				register={register}
			/>

		</FilterLayout>
	);
};

export default HealthCheckDetailsFilter;
