const useListSort = () => {

	function alphabetSort(arrOfObjects, sortParam = 'name') {
		return arrOfObjects.sort((a, b) => {
			const itemA = String(a[sortParam]).toUpperCase();
			const itemB = String(b[sortParam]).toUpperCase();
			let comparison = 0;
			if (itemA > itemB) {
				comparison = 1;
			} else if (itemA < itemB) {
				comparison = -1;
			}
			return comparison;
		});
	}

	function numberObjSort(arrOfObjects, sortParam = '', reverse = false) {
		return arrOfObjects.sort((a, b) => {
			const itemA = Number(a[sortParam]);
			const itemB = Number(b[sortParam]);
			if(itemA && typeof itemA === "number" && itemB && typeof itemB === "number") {
				return reverse ? itemB - itemA : itemA - itemB;
			}
			return 0;
		});
	}


	const numberSort = (arrOfArrays, elNumber = 1, reverse = false) => {
		return arrOfArrays.sort((a, b) => {
			return reverse ? b[elNumber] - a[elNumber] : a[elNumber] - b[elNumber];
		});
	};
	

	return { alphabetSort, numberSort, numberObjSort };
};

export default useListSort;
