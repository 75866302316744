import { useCallback, useEffect, useState } from 'react';
import { useRouter } from '../../components/hooks/useRouter';
import useSapiService from '../../components/hooks/useSapiService';
import usePageFilter from '../../components/hooks/usePageFilter';
import { localBarcodeRanges } from '../../localTestingData';
import useCustomToast from '../../components/hooks/useCustomToast';
// import useDialogType from '../../components/hooks/useDialogType';
import useAutoUpdateData from '../../components/hooks/useAutoApdateData';


const useBarcodesPage = () => {
	const {location} = useRouter();
	const [barcodesData, setBarcodesData] = useState();
	const {successInfo} = useCustomToast();
	// const { goBack } = useDialogType({ history, match, location });

	const {
		isProduction,
		isFetching,
		getBarcodesDataReq,
		deleteBarcodeRangeReq,
	} = useSapiService();

	const {
		currentFilterData,
		resetFilterForm,
		applyFilter,
		formMethods
	} = usePageFilter();

	const getLocalBarcodesData = useCallback(()=> {
		// setTimeout(()=> {
			setBarcodesData(localBarcodeRanges);
			console.log('Using LOCAL barcodes data');
		// }, 2000)

	}, [])

	const getBarcodesData = useCallback((queryData = '') => {
		return isProduction()
			? getBarcodesDataReq(queryData)
				.then(({data}) => {
					setBarcodesData(data)
				})
			: getLocalBarcodesData();

	}, [getBarcodesDataReq]); //eslint-disable-line


	const deleteBarcodeRange = (id) => {
		deleteBarcodeRangeReq(id)
			.then(({data})=> {
				const {message} = data;
				getBarcodesData(location.search);
				successInfo(`${message}`);
			})
	}


	const resetBarcodesFilter = () => {
		resetFilterForm();
		getBarcodesData()
	}

	const filterHandler = (data) => {
		applyFilter(data, getBarcodesData);
	}

	useEffect(() => {
		getBarcodesData(location.search);
	}, [getBarcodesData]); //eslint-disable-line

	useAutoUpdateData({
		data:barcodesData,
		updateFunction: getBarcodesData,
		updateFunctionParams:location.search,
	});

	return {
			barcodesData,
			isFetching,
			currentFilterData,
			resetFilterForm,
			filterHandler,
			getBarcodesData,
			deleteBarcodeRange,
			resetBarcodesFilter,
			formMethods
		};
};

export default useBarcodesPage;
