import React from 'react';
import ErrorIndicator from '../components/ErrorIndicator/ErrorIndicator';

const ErrorPage = ({offline}) => {
	return (
		<div className="error-page">
			{offline
				? <ErrorIndicator offline/>
				: <ErrorIndicator globalError/>}
		</div>
	);
};

export default ErrorPage;
