import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import { Form, Dropdown, FormControl } from 'react-bootstrap';
import './DropdownSelect.scss';
import SubMessage from '../SubMessage/Submessage';
import ButtonIcon from '../ButtonIcon/ButtonIcon';

const DropdownSelect = ({
	itemName,
	label,
	selectOptionsList,
	formGroupClass = '',
	addedSelectClass,
	register,
	required,
	errors = {},
	disabled,
	children,
	setValue,
	onSelect = () => {},
	defaultValue,
	prefix,
	...props
}) => {
	function isRequired() {
		if (register) {
			if (required) {
				return register({ required: true });
			} else {
				return register;
			}
		} else {
			return null;
		}
	}

	const selectHandler = (value) => {
		setValue(`${itemName}`, prefix ? `${prefix}/${value}`: value);
		onSelect(value);
	}
	const selectOptions = useMemo(()=> selectOptionsList.map((item) => {
		return (<Dropdown.Item
			key={item.value}
			onClick={() => selectHandler(item.value)}
		>{item.name}</Dropdown.Item>);
	}), [selectOptionsList.length]); //eslint-disable-line

	return (
		<Form.Group
			className={`${formGroupClass} ${
				disabled ? 'disabled' : ''
			} dropdown-select-box`}
		>
			<div className="dropdown-select-wrap">
				{!!label && (
					<Form.Label>
						<span className={`${(errors[itemName] && 'text-danger') || ''}`}>
							{label}
						</span>
						<span className={`${required ? '' : 'invisible'} required`}>*</span>
					</Form.Label>
				)}

				<div className="dropdown">
					<ButtonIcon
						className="btn btn-outline-light dropdown-toggle btn-block"
						iconName="arrow_drop_down"
						variant="outline-light"
						size={18}
						data-toggle="dropdown"
						aria-haspopup="true"
						aria-expanded="false"
					/>
						<FormControl
							type="text"
							className={`${(errors[itemName] && 'border-danger') || ''}`}
							onChange={(e) => {
								selectHandler(e.target.value)
							}}
							defaultValue={defaultValue || prefix || null}
							name={itemName}
							ref={isRequired()}
							{...props}
						/>
					<div className="dropdown-menu">
						{selectOptions}
					</div>
				</div>
				{errors[itemName] && (
					<SubMessage styleClass="text-danger">Field is required</SubMessage>
				)}
			</div>
			{children}
		</Form.Group>
	);
};

DropdownSelect.propTypes = {
	register: PropTypes.func,
	selectOptionsList: PropTypes.arrayOf(PropTypes.object).isRequired,
	itemName: PropTypes.string.isRequired,
	formGroupClass: PropTypes.string,
	addedSelectClass: PropTypes.string,
	label: PropTypes.string,
	required: PropTypes.bool,
};

export default React.memo(DropdownSelect);
