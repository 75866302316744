import React, { useContext, useEffect, useState } from 'react';
import Modal from '../UI/Modal/Modal';
import ModalDetails from '../UI/Modal/ModalDetails';
import useModal from '../hooks/useModal';
import { useForm } from 'react-hook-form';
import { useRouter } from '../hooks/useRouter';
import useDialogType from '../hooks/useDialogType';
import useArrayFromObject from '../hooks/useArrayFromObject';
import { useGlobal } from 'reactn';
import { Form } from 'react-bootstrap';
import BarcodesPageContext  from '../../context/pagesContext/barcodesPageContext';
import CustomInput from '../UI/СustomInput/CustomInput';
import CustomSelectAdvanced from '../UI/CustomSelect/CustomSelectAdvanced';
import useCustomToast from '../hooks/useCustomToast';
import useSapiService from '../hooks/useSapiService';
import CustomButton from '../UI/CustomButton/CustomButton';


const CreateBarcodeRange = () => {
	const { history, match, location } = useRouter();
	const { isOpenModal, showModal} = useModal();
	const { goBack } = useDialogType({ history, match, location });
	const { handleSubmit, register, errors, setValue, reset, getValues } = useForm();
	const { addEmptyValue } = useArrayFromObject();
	const [commonListsArr] = useGlobal('commonListsArr');
	const { carriers, apiUsers } = commonListsArr;
	const [resetTrigger, setResetTrigger] = useState(0);
	const {successInfo} = useCustomToast();
	const { getBarcodesData } = useContext(BarcodesPageContext);
	const {	saveBarcodeRangeReq } = useSapiService();

	useEffect(() => {
		showModal();
	}, [showModal]);

	const createBarcodeRange = (data) => {
		saveBarcodeRangeReq(data)
			.then(({data})=> {
				const {message} = data;
				reset();
				getBarcodesData(location.search);
				successInfo(`${message}`);
				reset();
				setResetTrigger((current) => current + 1);
			})
	}

	const inRange = () => {
		const beginValue = getValues({nest:true}).beginValue;
		const endValue = getValues({nest:true}).endValue;
		return beginValue < endValue
	}

	const title = 'Create barcode range';
	const headerLeft = (
		<div className="header-info">
			<h2>{title}</h2>
		</div>);


	const positiveAndRequired = register({
		required: 'Field is required',
		min: {
			value: 1,
			message: 'Only positive value'
		},
		validate: () => inRange() || 'Incorrect range'
	});


	const detailsContent = (
		<div className="create-barcode-range">
			<Form>
				<div className="row">
					<CustomSelectAdvanced
						formGroupClass="col-12 col-md-6 col-lg-4 shifted"
						itemName="user"
						label="User"
						register={register}
						setValue={setValue}
						selectOptionsList={addEmptyValue(apiUsers, "Select user") || []}
						errors={errors}
						required
						outerTrigger={resetTrigger}
						quickSearch
					/>
					<CustomSelectAdvanced
						formGroupClass="col-12 col-md-6 col-lg-4 shifted"
						itemName="carrier_id"
						label="Carrier"
						register={register}
						setValue={setValue}
						selectOptionsList={addEmptyValue(carriers, "Select carrier") || []}
						errors={errors}
						outerTrigger={resetTrigger}
						required
						quickSearch
					/>
					<CustomInput
						formGroupClass="col-12 col-md-6 col-lg-4 shifted"
						type="text"
						itemName="barcodeGroup"
						label="Barcode group"
						register={register}
						errors={errors}
						required
					/>
					<CustomInput
						formGroupClass="col-12 col-md-6 col-lg-2 shifted"
						type="text"
						itemName="prefix"
						label="Prefix"
						register={register}
					/>
					<CustomInput
						formGroupClass="col-12 col-md-6 col-lg-2 shifted"
						type="number"
						itemName="beginValue"
						label="Begin"
						register={positiveAndRequired}
						errors={errors}
						addRequiredMark
					/>
					<CustomInput
						formGroupClass="col-12 col-md-6 col-lg-2 shifted"
						type="number"
						itemName="endValue"
						label="End"
						register={positiveAndRequired}
						errors={errors}
						addRequiredMark
					/>
				</div>
			</Form>
		</div>
	)

	const footerContent = (
		<Form.Group controlId="formGridState" className="m-0">
			<div className="button-wrapper d-flex justify-content-between">
				<CustomButton
					buttonText="Cancel"
					kind="secondary"
					onClick={goBack}
				/>
				<CustomButton
					buttonText="Save"
					kind="primary"
					onClick={handleSubmit(createBarcodeRange)}
				/>

				{/*<Button variant="outline-primary" onClick={goBack}>*/}
				{/*	Cancel*/}
				{/*</Button>*/}
				{/*<Button variant="primary" onClick={handleSubmit(createBarcodeRange)}>*/}
				{/*	Create barcode range*/}
				{/*</Button>*/}
			</div>
		</Form.Group>
	);

	return (
		<Modal show={isOpenModal} close={goBack} bsSize="lg">
			<ModalDetails
				customClass="create-tracking-status"
				headerLeft={headerLeft}
				detailsContent={detailsContent}
				footerContent={footerContent}
			/>
		</Modal>
	);
};

export default CreateBarcodeRange;
