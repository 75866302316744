import React from 'react';
import './Tooltip.scss';


const Tooltip = React.forwardRef(({text, hideBgr = true, coordinates}, ref) => {
	const top = `${coordinates && coordinates.top || 0 }px`
	const left = `${coordinates && coordinates.left || 0 }px`

	return <div
		className={`custom-tooltip ${hideBgr ? 'hide-bgr' : ''}`}
		style={coordinates && {top, left}}
		ref={ref}>
		{text}
	</div>

});

export default Tooltip;
