import React from 'react';
import CredentialsDetailsPageContext from '../../context/pagesContext/credentialsDetailsPageContext';
import useCredentialsDetailsPage from './useCredentialsDetailsPage';
import Spinner from '../../components/Spinner/Spinner';
import ErrorIndicator from '../../components/ErrorIndicator/ErrorIndicator';
import PageLayout from '../../components/pageLayout/PageLayout';
import CredentialsDetailsPanel from "../../components/credentialsDetails/CredentialsDetailsPanel";

const CredentialsDetailsPage = ({match}) => {
    const pageData = useCredentialsDetailsPage(match.params.service_id);
    const {serviceCredentials, isFetching} = pageData;

    const pageContent = <>
        {!serviceCredentials && isFetching && <Spinner/>}
        {!serviceCredentials && !isFetching && <ErrorIndicator globalError/>}
        {serviceCredentials && <CredentialsDetailsPanel/>}
    </>


    return (
        <CredentialsDetailsPageContext.Provider value={pageData}>
            <PageLayout
                pageContent={pageContent}
            />
        </CredentialsDetailsPageContext.Provider>
    );
};

export default React.memo(CredentialsDetailsPage);
