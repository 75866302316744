import { useState, useCallback } from 'react';

const useModal = () => {
	const [isOpenModal, setIsOpenModal] = useState(false);
	const showModal = useCallback(() => setIsOpenModal(true), []);
	const hideModal = useCallback(() => setIsOpenModal(false), []);

	return {
		isOpenModal,
		showModal,
		hideModal
	};
};

export default useModal;