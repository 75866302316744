import {useCallback, useEffect, useState} from 'react';
import {useRouter} from '../../components/hooks/useRouter';
import useSapiService from '../../components/hooks/useSapiService';
import usePageFilter from '../../components/hooks/usePageFilter';
import useAutoUpdateData from '../../components/hooks/useAutoApdateData';


const useTrackingMapPage = () => {
    const {location} = useRouter();
    const [carriers, setCarriers] = useState();

    const {
        isFetching,
        getTrackingMapCarriersListReq,
    } = useSapiService();

    const {
        currentFilterData,
        resetFilterForm,
        applyFilter,
        formMethods
    } = usePageFilter();

    const getTrackingMapCarriersList = useCallback((queryData = '') => {
        return getTrackingMapCarriersListReq(queryData)
            .then(({data}) => {
                if (data.carriers) {
                    setCarriers(data.carriers);
                }
            });

    }, [getTrackingMapCarriersListReq]); //eslint-disable-line

    const resetTrackingMapFilter = () => {
        resetFilterForm();
        getTrackingMapCarriersList()
    }

    const filterHandler = (data) => {
        applyFilter(data, getTrackingMapCarriersList);
    }

    useEffect(() => {
        getTrackingMapCarriersList(location.search);
    }, [getTrackingMapCarriersList]); //eslint-disable-line

    useAutoUpdateData({
        data: carriers,
        updateFunction: getTrackingMapCarriersList,
        updateFunctionParams: location.search,
    });

    return {
        carriers,
        getTrackingMapCarriersList,
        isFetching,
        currentFilterData,
        resetFilterForm,
        filterHandler,
        resetTrackingMapFilter,
        formMethods
    };
};

export default useTrackingMapPage;
