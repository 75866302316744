import React, { useState, useEffect, useCallback } from 'react';
import { useGlobal } from 'reactn';
import { Collapse } from 'react-bootstrap';
import NavBarLink from './NavbarLink';

const NavBarItem = ({
	text,
	url,
	icon,
	submenu,
	isExpanded,
	setTargetItem,
	hasActiveChildFn,
}) => {
	const [currentPageUrl] = useGlobal('currentPageUrl');
	const [currentPageName] = useGlobal('currentPageName');

	const initialActive = url ? url.split('/')[1] === currentPageUrl : false;
	const [isActive, setIsActive] = useState(initialActive);

	const isActiveMenuItem = useCallback(
		(currentPageName, itemText) =>
			itemText && currentPageName
				? itemText.toLowerCase() === currentPageName.toLowerCase()
				: false,
		[]
	);

	useEffect(() => {
		setIsActive(isActiveMenuItem(currentPageName, text));
	}, [currentPageName, isActiveMenuItem, text]);

	function clickHandler(e) {
		if (!url) {
			e.preventDefault();
		}
		setTargetItem(text);
	}

	let newText = text
		.toLowerCase()
		.split(' ')
		.join('');

	const linkItem = (text, path) => {
		const { protocol, host } = window.location;
		const docsHost = host
			.split('.')
			.slice(1)
			.join('.');

		return text && text === 'API docs' ? (
			<a
				href={`${protocol}//docs.${docsHost}`}
				target="_blank"
				className="nav-link"
				rel="noopener noreferrer"
			>
				{text}
			</a>
		) : (
			<NavBarLink
				url={path}
				text={text}
				isActive={isActiveMenuItem(currentPageName, text)}
			/>
		);
	};

	const subItems = submenu => {
		if (submenu) {
			return (
				<Collapse in={isExpanded}>
					<div id={`#${newText}`}>
						<ul className="nav">
							{submenu.map(item => {
								return (
									<li className="nav-item" key={item.text}>
										{linkItem(item.text, item.path)}
									</li>
								);
							})}
						</ul>
					</div>
				</Collapse>
			);
		}
	};
	return (
		<li className="nav-item">
			<NavBarLink
				text={text}
				icon={icon}
				url={url}
				isActive={isActive || hasActiveChildFn(submenu, currentPageUrl)}
				isExpanded={isExpanded}
				hasSubmenu={!!submenu}
				clickHandler={clickHandler}
			/>
			{subItems(submenu)}
		</li>
	);
};
export default NavBarItem;
